import { NoteParams, useSaveNoteMutation } from "generated/graphql";
import removeTypename from "lib/helpers/removeTypename";

const formatNoteForMutation = (params): NoteParams => {
  const noteParam = {
    title: params?.title,
    description: params?.description,
    contactId: params?.contactId,
    businessId: params?.businessId,
    projectId: params?.projectId,
    siteId: params?.siteId,
    attachments: params?.attachments,
  };

  return removeTypename(noteParam);
};

export default function useSaveNote() {
  const [saveNote, { loading }] = useSaveNoteMutation();
  const onSubmitNote = async (params: NoteParams, id?: string) => {
    try {
      await saveNote({
        variables: {
          id,
          params: formatNoteForMutation(params),
        },
        refetchQueries: ["notes"],
      });
    } catch (err: any) {
      throw new Error(err?.message);
    }
  };

  return {
    onSubmitNote,
    savingNote: loading,
  };
}
