import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
// APOLLO
import { useMutation } from "@apollo/client";
import IMPERSONATE_CUSTOMER from "ApolloClient/Mutations/impersonateCustomer";
import currentUser from "ApolloClient/Queries/currentUser";
import client from "ApolloClient/index";

const Container = styled.div`
  background: ${(p) => p.theme.colors.red9};
  height: 50px;
  width: 150px;
  border-radius: 25px;
  z-index: 1000;
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: ${(p) => p.theme.colors.red1};
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) => p.theme.colors.red8};
  }
`;

export default function AdminView() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [impersonateCustomer] = useMutation(IMPERSONATE_CUSTOMER);

  //
  const onTurnOffImpersonate = async () => {
    try {
      setLoading(true);
      await impersonateCustomer({
        variables: {
          customerId: "",
          turnOff: true,
        },
        refetchQueries: [{ query: currentUser }],
      });
      await client.query({
        query: currentUser,
      });

      setTimeout(() => {
        navigate(`/admin/customers`);
      }, 750);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // return JSX
  return (
    <Container>
      <div>
        {!loading ? (
          <div onClick={onTurnOffImpersonate}>
            <ArrowLeftOutlined style={{ fontSize: 14, marginRight: 4 }} />
            Back to Admin
          </div>
        ) : (
          <LoadingOutlined />
        )}
      </div>
    </Container>
  );
}
