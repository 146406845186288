import Loading from "components/common/Loading";
import SurveyDashboard from "components/common/SurveyDashboard";
import {
  useGetManyAnswersQuery,
  useGetOneSurveyTemplateNewQuery,
} from "generated/graphql";
import { useLocation } from "react-router-dom";

export default function AppSurveyReport() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const templateId = queryParams.get("templateId");
  const surveyCampaignId = queryParams.get("surveyCampaignId");
  const { data: template, loading: templateLoading } =
    useGetOneSurveyTemplateNewQuery({
      variables: { templateId: templateId || "" },
    });
  const { data: surveyAnswers, loading: answersLoading } =
    useGetManyAnswersQuery({
      variables: { templateId: templateId || "", surveyCampaignId },
    });
  const surveyData =
    surveyAnswers?.getManyAnswers.map((surveyAnswer) =>
      JSON.parse(surveyAnswer.stringifiedAnswers)
    ) || [];

  if (templateLoading || answersLoading) return <Loading></Loading>;

  return (
    <SurveyDashboard
      surveyData={surveyData}
      surveyTemplateObject={template?.getOneSurveyTemplateNew.template}
    />
  );
}
