import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import {
  GetManyDefaultSurveyTemplatesDocument,
  useGetOneDefaultSurveyTemplateQuery,
  useSaveDefaultSurveyTemplateMutation,
} from "generated/graphql";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

const creatorOptions = {
  isAutoSave: false,
  showLogicTab: false,
  showJSONEditorTab: false,
  haveCommercialLicense: true,
  showTranslationTab: false,
  allowModifyPages: false,
  enableLinkFileEditor: false,
  pageEditMode: "single" as any,
  questionTypes: [
    "boolean",
    "matrix",
    "matrixdropdown",
    "matrixdynamic",
    "multipletext",
    "radiogroup",
    "rating",
    "ranking",
    "tagbox",
    "comment",
    "text",
    "date",
    "checkbox",
    "radiogroup",
    "dropdown",
    "panel",
    "paneldynamic",
    "signaturepad",
  ],
};

export default function DefaultSurveyTemplateCreatorWidget({
  defaultTemplateId,
}) {
  const { data: template } = useGetOneDefaultSurveyTemplateQuery({
    variables: { defaultTemplateId },
  });
  const navigate = useNavigate();
  const [saveDefaultSurveyTemplate] = useSaveDefaultSurveyTemplateMutation({
    refetchQueries: [
      {
        query: GetManyDefaultSurveyTemplatesDocument,
      },
    ],
  });
  const creator = useMemo(() => {
    const newCreator = new SurveyCreator(creatorOptions);
    newCreator.saveSurveyFunc = async (saveNo, callback) => {
      try {
        const surveyTemplate = await saveDefaultSurveyTemplate({
          variables: {
            params: { id: defaultTemplateId, template: newCreator.JSON },
          },
        });
        defaultTemplateId = surveyTemplate.data?.saveDefaultSurveyTemplate._id;
      } catch (error) {
        callback(saveNo, false);
        return;
      }
      callback(saveNo, true);
      navigate("/admin/defaultSurveyTemplates");
    };
    return newCreator;
  }, []);

  if (template?.getOneDefaultSurveyTemplate.template) {
    creator.JSON = template?.getOneDefaultSurveyTemplate.template;
  }

  creator.showSidebar = false;

  return <SurveyCreatorComponent creator={creator} />;
}
