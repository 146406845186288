import { get } from "lodash";
import * as Sentry from "@sentry/browser";

/**
 * Given a user ID, this will identify a user in mixpanel analyltics and fullstory
 */
export default function deidentifyUser() {
  const mixpanel = get(window, "mixpanel");
  const FS = get(window, "FS");

  mixpanel?.identify(undefined);
  mixpanel?.reset(); // https://help.mixpanel.com/hc/en-us/articles/115004497803-Identity-Management-Best-Practices-OLD-
  if (FS) {
    FS?.identify(false);
    FS?.anonymize(); // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users#01FWSBTCJSK4FY7R8HEZTGWDXJ
  }
  if (Sentry) {
    Sentry?.setUser(null); // unset the user
  }
}
