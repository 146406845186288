import styled from "styled-components";

const Input = styled.input`
  background: ${(p) => p.theme.colors.neutral11};
  border: 0px;
  width: 100%;
  height: 50px;
  padding-left: 8px;
  border-radius: 5px;
  border: 2px solid ${(p) => p.theme.colors.neutral11};
  &:hover {
    outline: 0;
    border: 2px solid
      ${(p) => {
        if (p.disabled) return p.theme.colors.neutral11;
        return p.theme.colors.neutral8;
      }};
  }
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:focus {
    outline: 0;
    background: #fff;
    border: 2px solid ${(p) => p.theme.colors.primary7};
  }

  ::placeholder {
    color: ${(p) => p.theme.colors.neutral7};
    font-weight: 500;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

export default function TextInput(props) {
  return (
    <Input
      autocomplete="false"
      {...props}
      onChange={(e) => props?.onChange(e.target.value)}
    />
  );
}

// // TOP LEVEL IMPORTS
// import React from 'react';
// import PropTypes from 'prop-types';
// // ANTD
// import Input from 'antd/lib/input';
// import 'antd/lib/input/style/css';

// // EXPORTED COMPONENT
// // ===================================
// export class TextInput extends React.Component {
//   render() {
//     return (
//       <Input
//         {...this.props}
//         onChange={e => this.props.onChange(e.target.value)}
//       />
//     );
//   }
// }

// // PROPS
// // ===================================
// TextInput.propTypes = {
//   size: PropTypes.string,
//   style: PropTypes.object,
// };

// // Specifies the default values for props:
// TextInput.defaultProps = {
//   size: 'large',
//   onChange: () => {},
//   style: {
//     width: '100%',
//     // minWidth: '120px',
//     maxWidth: '100%',
//     height: 50,
//   },
// };

// // EXPORT
// // ===================================
// export default TextInput;
