// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  query searchUsers($params: UserSearchParams) {
    searchUsers(params: $params) {
      id
      email
      roles
      firstName
      lastName
      avatar {
        id
        url
      }
    }
  }
`;
