import React from "react";
import styled from "styled-components";
import filterIcon from "lib/media/filter-icon.svg";

const Text = styled.div`
  color: ${(p) => p.theme.colors.neutral4};
  font-size: 16px;
  font-weight: 600;
`;

const Btn = styled.button`
  background: ${(p) => p.theme.colors.neutral9};
  width: 130px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  transition: background-color 0.3s ease;
  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.neutral8};
  }
  &:focus {
    outline: 0;
  }
`;

interface FiltersButtonProps {
  style?: React.CSSProperties;
  text?: string;
  onClick: any;
}

export default function FiltersButton({
  onClick,
  style,
  text = "Filters",
}: FiltersButtonProps) {
  return (
    <Btn onClick={onClick} style={style} data-testid="filters-button">
      <img src={filterIcon} alt="icon" style={{ marginRight: 6 }} />
      <Text>{text}</Text>
    </Btn>
  );
}
