import {
  VisualizationPanel,
  VisualizationManager,
  HistogramPlotly,
} from "survey-analytics";
import { Model } from "survey-core";
import "survey-analytics/survey.analytics.min.css";
import { useEffect } from "react";
import PageContainer from "../PageContainer";
import styled from "styled-components";
import theme from "lib/theme";

const vizPanelOptions = {
  allowHideQuestions: false,
};

VisualizationManager.registerVisualizer("naics-growlab-crm", HistogramPlotly);
VisualizationManager.registerVisualizer(
  "optin-optout-growlab-crm",
  HistogramPlotly
);

const DashboardContainer = styled.div`
  font-family: ${theme.fontFamily} !important;
  .sa-question__content {
    background-color: #fff !important;
    border-radius: 5px !important;
  }
`;

export default function SurveyDashboard({
  surveyTemplateObject,
  surveyData,
}: {
  surveyTemplateObject: object;
  surveyData: object[];
}) {
  useEffect(() => {
    const surveyTemplate = new Model(surveyTemplateObject);

    const vizPanel = new VisualizationPanel(
      surveyTemplate.getAllQuestions(),
      surveyData,
      vizPanelOptions
    );

    vizPanel.render("surveyDashboardContainer");
  }, []);

  return (
    <PageContainer style={{ width: 1300 }}>
      <DashboardContainer>
        <div id="surveyDashboardContainer" />
      </DashboardContainer>
    </PageContainer>
  );
}
