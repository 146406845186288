import React from "react";
import { PageHeader, SubHeader, Text } from "../index";
import constants from "lib/constants";

export default function TermsOfService() {
  return (
    <div>
      <PageHeader>GrowLab Terms Of Service</PageHeader>
      <Text style={{ textAlign: "right" }}>Last Updated: 03/05/2021</Text>
      <Text>
        Our Terms of Service (<strong>“Terms”</strong>) is a contract that
        governs visitors and our customers' use of the GrowLab Services and
        websites (collectively, the “Website”). Our Terms include a Privacy
        Policy which You also may find on Our Website.
      </Text>
      <Text>
        Your <strong>Order Form</strong> is the form created following your
        purchase of one of our Services. It contains all of the details about
        your purchase, including your subscription term, products purchased and
        your fees. You’ll find your Order Form(s) in the Documents section of
        your GrowLab portal.
      </Text>
      <Text>
        We update these terms from time to time. If you have an active GrowLab
        subscription, we will let you know when we update the terms via in-app
        notification or by email (if you subscribe to receive email updates).
      </Text>
      {/* 
      
      
       */}
      <SubHeader>DEFINITIONS</SubHeader>
      <Text>For purposes of these Terms:</Text>
      <ul>
        <li>
          <Text>
            <strong>Agreement</strong> means collectively the following parts:
            GrowLab Order Form, SaaS Agreement, these Terms including applicable
            Privacy Policy and all materials referred or linked to in here.
          </Text>
        </li>
        <li>
          <Text>
            <strong>Affiliate</strong> means an entity controlling, controlled
            by or under common control with a Party;
          </Text>
        </li>
        <li>
          <Text>
            <strong>SaaS Agreement</strong> means this agreement, the Order and
            the Terms of Use and any other documents included by reference, as
            may be amended from time to time in accordance with the terms of
            these Terms;
          </Text>
        </li>
        <li>
          <Text>
            <strong>Customer Data</strong> or Client Data means any data that
            Customer loads or enters into the Website and all results from
            processing such data, including compilations;
          </Text>
        </li>
        <li>
          <Text>
            <strong>Personal Data</strong> means any information relating to an
            identified or identifiable individual where such information is
            contained within Customer Data and is protected similarly as
            personal data or personally identifiable information under
            applicable Data Protection Laws
          </Text>
        </li>
        <li>
          <Text>
            <strong>Documentation</strong> shall mean the user’s guide,
            compilation instructions, and requirements including documents,
            manuals and computer-readable files, regarding the installation,
            use, operation, functionality, troubleshooting, specifications and
            other technical information, being hosted online, sufficient for the
            purpose of usage of the Services;
          </Text>
        </li>
        <li>
          <Text>
            <strong>Force Majeure Event</strong> means any and all events beyond
            Company’s direct fault and control including, without limitation,
            acts of God, strikes, lockouts, riots, war, terrorism or other civil
            disturbance, earthquakes, weather, fires, explosions, disruptions of
            communications and other essential services, labor stoppages,
            computer viruses and malicious attacks;
          </Text>
        </li>
        <li>
          <Text>
            <strong>Intellectual Property Rights</strong> mean any copyright,
            trademark, patent, trade secret or data privacy right of any person
            valid under any national law or in any applicable local
            jurisdiction;
          </Text>
        </li>
        <li>
          <Text>
            <strong>Order Form</strong> means the Order Form for Customer’s
            Initial Term of subscription to the Services substantially in the
            format set out in Order
          </Text>
        </li>
        <li>
          <Text>
            <strong>Personnel</strong> mean employees of the Customer;
          </Text>
        </li>
        <li>
          <Text>
            <strong>GrowLab Services or Service(s)</strong> means the GrowLab
            Subscription Service as described in this Agreement and associated
            Documentation specified in the Order Form/Sales Proposal and
            accessed at https://www.growlabcrm.com or such other URL as the
            Company may specify. The Services would be provided through a
            web-based platform enabling the individual Users to communicate to
            their subscriber/contacts.
          </Text>
        </li>
        <li>
          <Text>
            <strong>Software</strong> means the GrowLab software and associated
            Documentation specified in and accessed at the Website;
          </Text>
        </li>
        <li>
          <Text>
            <strong>User or Customer</strong> means Customer and its Personnel
            who use the Service through User IDs issued under this Agreement;
          </Text>
        </li>
        <li>
          <Text>
            <strong>“User Account” or “User Id” or “Account”</strong> shall mean
            the online account maintained by the User at the Website to avail of
            the Services;
          </Text>
        </li>
      </ul>
      {/* 
      
      
       */}
      <SubHeader>2. ACCOUNT</SubHeader>
      <Text>
        <strong>2.1.</strong> You are fully responsible for all activity that
        occurs under Your account. It is then required that You keep Your
        account confidential except for the purposes provided under the terms of
        this Terms. In case You grant access to other person/s, You shall be
        fully bound by their actions while using Your account. Notwithstanding
        anything to the contrary, You understand and acknowledge that We are not
        acting as a “producer” as defined by the 18 USC § 2257 Record Keeping
        Requirements (the “2257 Regulations”).
      </Text>
      {/* 
      
      
       */}
      <SubHeader>3. USE OF SERVICES</SubHeader>
      <Text>
        <strong>3.1.</strong> The Service; Permitted Use. For the Subscription
        Term, Customer may access and use the Services exclusively for its
        internal business and/or personal purposes in accordance with the Terms
        stated herein. Some limits to the usage of Our Service may apply.
      </Text>
      <Text>
        <strong>3.2.</strong> For the entire duration of the Subscription Term,
        We will provide Your Users access to use the Subscription Service as
        described in this Agreement and the applicable Order. We may also
        provide Your Users access to use our Services at any time by activating
        them in Your GrowLab account. We might provide some or all elements of
        the Subscription Service through third party service providers. You may
        provide access and use of the Subscription Service to Your Affiliate's
        Users or allow them to receive the Services purchased under the Order;
        provided that, all such access, use and receipt by Your Affiliate's
        Users is subject to and in compliance with the Agreement and You will at
        all times remain liable for Your Users' compliance with the Agreement.
      </Text>
      <SubHeader>4. ACCOUNT PRIVACY</SubHeader>
      <Text>
        <strong>4.1.</strong> You are solely responsible for maintaining the
        confidentiality of Your account and password and for restricting access
        to Your computer to prevent unauthorized access to Your account. You
        agree to accept responsibility for all activities that occur under Your
        account or password. You should take all necessary steps to ensure that
        the password is kept confidential and secure and should inform Us
        immediately (at {constants.supportEmail}) if You have any reason to
        believe that Your password has become known to anyone else, or if the
        password is being, or is likely to be, used in an unauthorized manner.
      </Text>
      <Text>
        <strong>4.2.</strong> Using Corporate Email for Registration. By
        creating an account using Your corporate email address, You represent
        and warrant that You Were granted such right and authority to create an
        account on behalf of such entity and You also agree that such account
        will be owned by such company regardless of Your temporary control and
        possession of such account. The administrator controlling the account
        has the following rights: (i) access, disclose, restrict or remove
        information from the account, (ii) restrict or terminate Your access to
        the Service and (iii) restrict You from later disassociating such
        account from Customers.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>5. CUSTOMER RESTRICTIONS</SubHeader>
      <Text>As a Customer of the Service, You agree not to:</Text>
      <ul>
        <li>
          <Text>
            Circumvent, disable, or otherwise interfere with security-related
            features of the Service;
          </Text>
        </li>
        <li>
          <Text>
            Disparage, tarnish, or otherwise harm, in Our opinion, us and/or the
            Service;
          </Text>
        </li>
        <li>
          <Text>
            Use any information obtained from the Website in order to harass,
            abuse, or harm another User(s);
          </Text>
        </li>
        <li>
          <Text>
            Use the Service in a manner inconsistent with any applicable laws or
            regulations;
          </Text>
        </li>
        <li>
          <Text>
            Interfere with, disrupt, or create an undue burden on the Service or
            the networks or services connected to the Service;
          </Text>
        </li>
        <li>
          <Text>
            Use the Services to send spam and/or illegal content to others;
          </Text>
        </li>
        <li>
          <Text>Upload photographs of minors;</Text>
        </li>
        <li>
          <Text>
            Engage in any fraudulent, misleading, illegal or unethical
            activities related to the Service;
          </Text>
        </li>
        <li>
          <Text>
            Attempt to bypass any measures of the Service designed to prevent or
            restrict access to the Service, or any portion of the Service;
          </Text>
        </li>
        <li>
          <Text>
            Make any unauthorized use of the Service, including collecting
            usernames and/or email addresses of Users by electronic or other
            means for the purpose of sending unsolicited email, or creating User
            accounts by automated means or under false pretenses;
          </Text>
        </li>
        <li>
          <Text>Allow multiple Users to share User account;</Text>
        </li>
        <li>
          <Text>
            Scrape, decompile, data mine, reverse engineer, disassemble or seek
            to access the source code and/or non-public APIs or unauthorized
            data from the Service, except to the extent expressly permitted by
            the Terms.
          </Text>
        </li>
      </ul>
      {/* 
      
      
       */}
      <SubHeader>6. SUPPORT AND TECHNICAL SERVICES </SubHeader>
      <Text>
        <strong>6.1.</strong> During the Subscription Term, We will provide
        Customer Support services (including technical support) to Our
        registered Users via both telephone and electronic mail on weekdays
        during the hours of 9:00 am through 6:00 pm Eastern time, with the
        exclusion of Federal Holidays (“Support Hours”).
      </Text>
      <Text>
        <strong>6.2. </strong> Company will use commercially reasonable efforts
        to respond to all Helpdesk tickets within one (1) business day.
      </Text>
      <Text>
        <strong>6.3. </strong> Customer may initiate a helpdesk ticket during
        Support Hours by calling {constants.supportPhone} or any time by
        emailing {constants.supportEmail}
      </Text>
      <Text>
        <strong>6.4. </strong> Customers are solely responsible for allowing Our
        Customer Support to render certain technical services which may be time
        to time required for proper usage of Our Service. We will be fully
        exempt from rendering such technical services until the access requested
        by Our Customer Support is provided.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>7. FREE TRIAL OR PILOT</SubHeader>
      <Text>
        If You register for a free trial or pilot of the Services, We will make
        the applicable Subscription Service available to You on a trial basis
        free of charge until the earlier of (a) the end of the free trial period
        (if not terminated earlier) or (b) the start date of Your paid
        subscription. Unless You purchase a subscription to the applicable
        subscription Service before the end of the free trial, all of Your data
        in the Subscription Service may be permanently deleted at the end of the
        trial, and We will not recover it. If We include additional terms and
        conditions on the trial registration web page, those will apply as well.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>8. FEES</SubHeader>
      <Text>
        <strong>8.1. </strong>
        Subscription Fees. During the Initial Period of the Subscription Term
        the Subscription Fee will remain fixed unless (i) You exceed Your
        Maximum Business Contacts, Email Send Limit, User or other applicable
        limits (see the ‘Limits’ section above), (ii) You upgrade products or
        base packages, (iii) You subscribe to additional features or products,
        including additional Contacts, or (iv) otherwise agreed to in Your
        Order.
      </Text>
      <Text>
        <strong>8.2. </strong>
        Fee Adjustments at Renewal. Upon renewal, We may increase Your fees up
        to our then-current list price set out in our Product and Services
        Catalog. If this increase applies to you, We will notify You at least
        thirty (30) days in advance of Your renewal and the increased fees will
        apply at the start of the next renewal term. If You do not agree to this
        increase, either party can choose to terminate Your subscription at the
        end of Your then-current term by giving the notice required in the
        ‘Notice of Non-Renewal’ section below.
      </Text>
      <Text>
        <strong>8.3. </strong>
        Payment options:
      </Text>
      <Text>
        i) by credit card. Customers may choose to pay by credit card and in
        this case Customer authorizes the Company to charge Customer’s credit
        card or bank account for all fees payable during the Initial Service
        Term. Customer further authorizes Company to use a third party to
        process payments, and consents to the disclosure of Customer’s payment
        information to such third party.
      </Text>
      <Text>
        Your purchases can be made via credit/ debit cards, and are processed
        through Our third-party Internet payment service providers. By
        purchasing any Product through Our Website, You hereby consent and agree
        to abide by such third-party Internet payment service providers’
        customer terms and conditions, and privacy policies, and understand that
        We have no control whatsoever on such customer terms and conditions, and
        privacy policies. IF You DO NOT AGREE to such third-party Internet
        payment service providers’ customer terms and conditions or privacy
        policies, DO NOT PURCHASE ANY PRODUCT. This is Your sole responsibility
        to find, read and understand any third party policies.
      </Text>
      <Text>
        ii) against invoice. If Customer chooses to pay by invoice Company will
        invoice Customer no more than forty-five (45) days before the beginning
        of the Initial Service Term and each subsequent billing period, and
        other times during the next Subscription Term when fees are payable. All
        amounts invoiced are due and payable within thirty (30) days from the
        date of the Invoice/Order.
      </Text>
      <Text>
        <strong>8.4. </strong>
        By making a purchase on this Website, You represent and warrant that:
        (i) all billing and payment information You provide to Us is truthful
        and accurate; (ii) You are the holder (or an authorized user) of the
        credit card or PayPal account You use to pay for Your order, and the
        account is active and has sufficient funds to cover the full transaction
        amount of Your order (i.e., product price plus shipping/handling charges
        and applicable taxes).
      </Text>
      <Text>
        <strong>8.5. </strong>
        Unless otherwise specified in Customer’s SaaS Agreement all fees are
        non-refundable except as provided by this Agreement and Company shall
        not issue any refunds for any parts or periods of non-use of the
        Services by Customer.
      </Text>
      <Text>
        <strong>8.6. </strong>
        Payment Information. You will keep Your contact information, billing
        information and credit card information (where applicable) up to date.
        Changes may be made on Your Billing Page within Your GrowLab account.
        All payment obligations are non-cancelable and all amounts paid are
        non-refundable, except as specifically provided for in this Agreement.
        All fees are due and payable in advance throughout the Subscription
        Term.
      </Text>
      <Text>
        <strong>8.7. </strong>
        Fees and Taxes. Subscription Fees will be invoiced via GrowLab Order
        Form (“Order”) or its substitute provided through Our Website. Unless
        the Order provides otherwise, all Fees are due within 30 days of the
        billing date specified in the relevant Order. Fees presented on Our
        Website are subject to change without notice. The price charged for
        Service will be the price in effect at the time the order is placed and
        will be set out in the Order. All prices and other charges are in the
        U.S. Dollars (USD), unless expressly otherwise stated. Late payments are
        subject to a service charge of 1.5% per month or the maximum amount
        allowed by Law, whichever is less. Customers are personally responsible
        for any sales, use, GST, value-added, withholding or similar taxes that
        apply to their Orders, whether domestic or foreign. Subscription Fees
        and expenses are exclusive of any taxes.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>9. TERM AND TERMINATION</SubHeader>
      <Text>
        <strong>9.1. </strong>
        Your Initial subscription term will be specified in Your Order.
      </Text>
      <Text>
        <strong>9.2.</strong>
        Following expiration of the Initial Term, the Subscription Term shall
        will automatically renew for successive periods of one (1) year (each a
        “Renewal Term”) unless and until either party gives the other party
        written Notice of Non-renewal at least 30 days prior to the next
        scheduled renewal date. Company may change the Service Fee for any
        Renewal Term upon written notice to Customer at least sixty (60) days
        prior to the next scheduled renewal date (which may be sent by email).
      </Text>
      <Text>
        <strong>9.3. </strong>
        Notice of Non-Renewal. Unless otherwise specified in Your Order, to
        prevent renewal of Your subscription, You or We must give written notice
        of non-renewal. The deadline for sending this notice is at least 30 days
        prior to the next scheduled renewal date.
      </Text>
      <Text>
        <strong>9.4. </strong>
        9.4. Either party may terminate this Agreement in case the other party:
      </Text>
      <Text>
        (a) is in material breach of this Agreement and fails to cure it within
        30 days upon receipt of a notice,
      </Text>
      <Text>
        (b) violates the terms of this Agreement regarding Customer
        Restrictions;
      </Text>
      <Text>
        (c) is in the process of bankruptcy, receivership, creditors’
        arrangement, composition, trust deed, or similar proceeding, and/or if
        such a proceeding is instituted against that party and not dismissed
        within a reasonable timeframe.
      </Text>
      <Text>
        <strong>9.5. </strong>
        Unless otherwise specified in SaaS Agreement or by these Terms in case
        of termination Customer will not be entitled to a refund of any pre-paid
        fees.
      </Text>
      <Text>
        <strong>9.6. </strong>
        Effect of Termination Upon termination or expiration of these Terms
        and/or Customer’s Subscription Term all rights granted to User hereunder
        shall terminate. It is Customer’s sole liability to export the Customer
        Data prior to such termination or expiration. In the event that Customer
        did not delete the Customer Data from the Account, We may continue to
        store and host it until either Customer or We, at Our sole discretion,
        delete such Customer Data, and during such period, Customer shall still
        be able to make a limited use of the Service in order to export the
        Customer Data, We are not under any obligation to maintain Your data.
        Customer acknowledges the foregoing and its sole responsibility to
        export and/or delete the Customer Data prior to the termination or
        expiration of these Terms, and therefore We shall not have any liability
        either to Customer, nor to any User or third party, in connection
        thereto. Unless expressly indicated herein otherwise, the termination or
        expiration of these Terms shall not relieve Customer from its obligation
        to pay due Subscription Fees.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>10. CUSTOMER DATA</SubHeader>
      <Text>
        <strong>10.1. </strong>
        GrowLab processes Customer Data under the direction of Our Customers,
        and has no direct control or ownership of the Personal Data we process
        on behalf of Our Customers. Customers are solely responsible for
        complying with any regulations and laws that require providing notice,
        disclosure, and/or obtaining consent prior to transferring the Personal
        Data to GrowLab for processing purposes. This Agreement does not grant
        Us any ownership rights to Customer Materials or Customer Data. Customer
        grants permission to Us and Our licensors and affiliates to use the
        Customer Materials and Customer Data only as necessary to provide the
        Service to Customer and as otherwise permitted by this Agreement. If
        Customer is using the Services on behalf of another party, then such
        Customer represents and warrants that Customer has all sufficient and
        necessary rights and permissions to do so.
      </Text>
      <Text>Customer data includes:</Text>
      <ul>
        <li>
          <Text>Business Contacts</Text>
        </li>
        <li>
          <Text>People Contacts</Text>
        </li>
        <li>
          <Text>Survey Data</Text>
        </li>
        <li>
          <Text>Attachments</Text>
        </li>
        <li>
          <Text>Todos</Text>
        </li>
        <li>
          <Text>Cases</Text>
        </li>
        <li>
          <Text>
            Ecosystem Records (organizations, sites, support programs, funding
            programs)
          </Text>
        </li>
      </ul>
      <Text>
        <strong>10.2. </strong>
        10.2. No Sensitive Data. You are restricted submit to the Service any
        data that is protected under a special legislation and requires a unique
        treatment, including, without limitations, any protected health
        information subject to the Health Insurance Portability and
        Accountability Act (“HIPAA”), as amended and supplemented, or any
        similar legislation in other jurisdiction; credit, debit or other
        payment card data subject to PCI DSS or any other credit card schemes.
      </Text>
      <Text>
        <strong> 10.3.</strong>
        10.3. Security. We are committed to protecting Your privacy and
        security. We will only use information collected (such as names, email
        addresses, etc., more details please find in Our Privacy Policy) to
        facilitate and deliver Services as a part of Our commitment. Moreover,
        as We are determined to provide You better Services, We may also use
        Your email address to promote Our other Services. You can opt out
        anytime by pressing the unsubscribe button that can be found in every
        email We send.
      </Text>
      <Text>
        <strong> 10.4. </strong>
        Export of Customer Data. For the duration of the Subscription Term
        and/or within 30 days upon its expiration Customer may submit its
        written request to export its Customer Data from the Service. After this
        export period, We may delete Customer Data at Our sole discretion.
      </Text>
      <Text>
        <strong> 10.5. </strong>
        Data Practices and Machine Learning. We may monitor use of the
        Subscription Service by all of our customers and use the information
        gathered in an aggregate and anonymized manner. We may use Customer Data
        in an anonymized manner for machine learning.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>11. INTELLECTUAL PROPERTY</SubHeader>
      <Text>
        <strong>11.1. </strong>
        With these Terms Company grants Customer an access to Services for the
        duration of Subscription Term and subject to Customer’s compliance with
        the terms of the Agreement but Customer is not granted a license to any
        software by this Agreement.
      </Text>
      <Text>
        <strong>11.2. </strong>
        Unless otherwise indicated, this Website is Our proprietary property and
        all source code, databases, functionality, software, Website designs,
        audio, video, text, photographs, and graphics on the Website
        (collectively, the “Content”) and the trademarks, service marks, and
        logos contained therein (the “Marks”) are owned or controlled by Us or
        licensed to Us, and are protected by copyright and trademark laws and
        various other intellectual property rights and unfair competition laws
        in various jurisdictions including EU and US, international copyright
        laws, and international conventions. Except as expressly provided in
        these Terms and Conditions, no part of the Website and no Content or
        Marks may be copied, reproduced, aggregated, republished, uploaded,
        posted, publicly displayed, encoded, translated, transmitted,
        distributed, sold, licensed, or otherwise exploited for any commercial
        purpose whatsoever, without Our prior written permission.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>12. CONFIDENTIALITY</SubHeader>
      <Text>
        <strong>12.1. </strong>
        During the course of our relationship, Customer may disclose to Us its
        Confidential Information in connection with its business. We agree to
        hold in confidence and not disclose to any third party any of
        Confidential Information, except as approved or directed in writing by
        Customer, and will use Confidential Information for no purpose other
        than for the Services. We will limit access to Confidential Information
        to only those employees, officers, directors, contractors,
        representatives and agents who are involved in providing Services to
        You. For purposes of these Terms, the term “Confidential Information”
        means non-public or proprietary information, including, without
        limitation, information relating to current or future business, products
        and services, strategies, images, development, design and design
        details, marketing plans, etc.
      </Text>
      <Text>
        <strong>12.2. </strong>
        Notwithstanding anything to the contrary in these Terms, the following
        is not Confidential Information: (a) information that was in the public
        domain at the time of its disclosure or has entered the public domain
        without breach of this Agreement; (b) information that was already in
        the rightful possession of a party at the time of disclosure; (c)
        information that is independently developed by a party without breaching
        this Agreement; or (d) information that becomes known to a party,
        without restriction, from a third party source not directly or
        indirectly involving a breach of this Agreement.
      </Text>
      <Text>
        <strong>12.3.</strong>
        Any information concerning the Customer and its respective Customer
        Records may be passed to third parties. However, Customer records are
        regarded as confidential and therefore will not be divulged to any third
        parties, other than Our Service Providers and if legally required to do
        so to the appropriate authorities. We will not sell, share, or rent Your
        personal information to a third party with the exception of cases
        described in these Terms.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>13. PUBLICITY</SubHeader>
      <Text>
        You grant us the right to add Your name and company logo to our customer
        list and Website. You can opt-out by emailing us at
        support@growlabcrm.com
      </Text>
      {/* 
      
      
       */}
      <SubHeader>14. DISCLAIMER</SubHeader>
      <Text>
        <strong>14.1. </strong>
        The information on this Website is provided on an “AS IS” basis. To the
        fullest extent permitted by law, this Company: (i) excludes all
        representations and warranties relating to this Website and its content
        or which is or may be provided by any affiliates or any other third
        party, including in relation to any inaccuracies or omissions in this
        Website and/or the Company’s materials; (ii) excludes all liability for
        damages arising out of or in connection with Your use of this Website.
        This includes, without limitation, direct loss, loss of business or
        profits (whether or not the loss of such profits was foreseeable, arose
        in the normal course of things or You have advised this Company of the
        possibility of such potential loss), damage caused to Your computer,
        computer software, systems and programs and the data thereon or any
        other direct or indirect, consequential and incidental damages.
      </Text>
      <Text>
        <strong>14.2. </strong>
        Any action taken in response to information, advice, or suggestion
        offered through this Website, including any content downloaded or
        otherwise obtained through Your use of this Website is at Your own risk.
        You will be solely responsible for any actions taken in response to any
        such information, opinions, advice, or suggestions, or for any loss or
        damage of any kind arising out of any such action, including any damage
        to Your computer system or loss of data that results from the download
        of any such content.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>15. INDEMNIFICATIONS</SubHeader>
      <Text>
        <strong>15.1. CUSTOMER’S:</strong>
        Customer agrees to defend, indemnify and hold harmless Our Company and
        its licensee and licensors, and their employees, contractors, agents,
        officers and directors, from and against any and all claims, damages,
        obligations, losses, liabilities, costs or debt, and expenses (including
        but not limited to attorney's fees), resulting from or arising out of a)
        Customer’s use and access of the Service, by Customer or any person
        using Customer’s account and password; b) a breach of these Terms, or c)
        Content posted on the Service.
      </Text>
      <Text>
        <strong>15.2. OURS:</strong>
        In Our turn, We will defend Customer from and against any third-party
        claim to the extent alleging that the Service, when used by Customer as
        authorized in this Agreement, infringes a third party’s Intellectual
        Property like trademark or trade secret, patent, copyright, and will
        indemnify and hold harmless Customer against any damages or costs
        awarded against Customer (including reasonable attorneys’ fees) or
        agreed in settlement by Our Company resulting from the claim.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>16. LIMITATION OF LIABILITY</SubHeader>
      <Text>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL OUR
        COMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR
        LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
        SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT
        LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
        INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, OR INABILITY TO USE,
        THIS WEBSITE. UNDER NO CIRCUMSTANCES WILL OUR COMPANY BE RESPONSIBLE FOR
        ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER
        UNAUTHORIZED ACCESS OR USE OF THE WEBSITE. TO THE MAXIMUM EXTENT
        PERMITTED BY APPLICABLE LAW, OUR COMPANY ASSUMES NO LIABILITY OR
        RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT;
        (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER,
        RESULTING FROM YOUR ACCESS TO OR USE OF OUR WEBSITE; (III) ANY
        UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL
        PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION
        OF TRANSMISSION TO OR FROM THE WEBSITE; (V) ANY BUGS, VIRUSES, TROJAN
        HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR WEBSITE BY
        ANY THIRD PARTY; AND/OR (VI) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR
        FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE USE OF ANY CONTENT
        POSTED, EMAILED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE
        WEBSITE. IN NO EVENT SHALL OUR COMPANY, ITS AFFILIATES, AGENTS,
        DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO YOU FOR ANY
        CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS
        IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO OUR COMPANY HEREUNDER.
        THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
        LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR
        ANY OTHER BASIS, EVEN IF OUR COMPANY HAS BEEN ADVISED OF THE POSSIBILITY
        OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE
        FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE JURISDICTION.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>17. FORCE MAJEURE EVENT</SubHeader>
      <Text>
        Neither party shall be liable in damages or have the right to terminate
        this Agreement for any delay or default in performing hereunder if such
        delay or default is caused by conditions beyond its control including
        but not limited to acts of God, government restrictions (including the
        denial or cancellation of any export of other necessary license), wars,
        insurrections and/or any other cause beyond the reasonable control of
        the party whose performance is affected (including mechanical,
        electronic, or communications failure).
      </Text>
      {/* 
      
      
       */}
      <SubHeader>18. MITIGATION AND EXCEPTIONS</SubHeader>
      <Text>
        <strong>18.1</strong> Governing Law, Jurisdiction and Venue. These Terms
        are governed by the laws of the State of California and the United
        States without regard to conflicts of laws provisions and without regard
        to the United Nations Convention on the International Sale of Goods. The
        jurisdiction and venue for actions related to this Agreement will be the
        New Hampshire State and United States courts of Rockingham County shall
        apply and both parties submit to the personal jurisdiction of those
        courts.
      </Text>
      <Text>
        <strong>18.2.</strong> Class Action Waiver. WHERE PERMITTED UNDER
        APPLICABLE LAW, You AND US AGREE THAT EACH PARTY MAY BRING CLAIMS
        AGAINST THE OTHER PARTY ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT
        AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE
        ACTION. Unless both You and Us mutually agree, no arbitrator or judge
        may consolidate more than one person’s claims or otherwise preside over
        any form of a representative or class proceeding.
      </Text>
      <Text>
        <strong>18.3.</strong> Attorneys’ Fees and Costs. The prevailing party
        in any action to enforce this Agreement will be entitled to recover its
        attorneys’ fees and costs in connection with such action.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>19. CHANGES TO THESE TERMS </SubHeader>
      <Text>
        <strong>19.1.</strong>
        We may modify these Terms of Service at any time by posting changes on
        the Website; however:
      </Text>
      <Text>
        (i) these changes will only become effective and binding with respect to
        You after Company provides notice on the Website that these Terms have
        changed and You first use any of the Website following the date of such
        posting,
      </Text>
      <Text>
        (ii) the changes will only apply with respect to Your use of the Website
        after such changes become effective, and
      </Text>
      <Text>
        (iii) any change in payment obligations will only apply to Your
        subsequent purchases on, or usage of, the Website. If at any time You
        find these Terms unacceptable and do not agree with them, You thereafter
        will have no right to use or access the Site.
      </Text>
      <Text>
        <strong>19.2.</strong>
        If We specify that the modifications to the Agreement will take effect
        prior to Customer’s next renewal or Order and Customer notifies Us of
        its objection to the modifications within 30 days after the date of such
        notice, We (at Our sole discretion) will either: (a) permit Customer to
        continue under the existing version of these Terms until expiration of
        the then-current Subscription Term (after which time the modified
        Agreement will go into effect) or (b) allow Customer to terminate this
        Agreement and receive a refund of any prepaid Service fees allocable to
        the terminated portion of the applicable Subscription Term. The Customer
        may be required to click to accept or otherwise agree to the modified
        Agreement in order to continue using the Service, and, in any event,
        continued use of the Service after the updated version of this Agreement
        goes into effect will constitute Customer’s acceptance of such updated
        version.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>20. ASSIGNMENT </SubHeader>
      <Text>
        Neither party may assign this Agreement without the prior consent of the
        other party, except that either party may assign this Agreement in
        connection with a merger, reorganization, acquisition or other transfer
        of all or substantially all its assets or voting securities. Any
        non-permitted assignment is void. This Agreement will bind and inure to
        the benefit of each party’s permitted successors and assigns.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>21. CONTACTS</SubHeader>
      <Text>
        For questions or clarifications please contact Us using the following
        email address: {constants.supportEmail}
      </Text>
      <Text>Or via mail:</Text>
      <Text>Attention: Growlab, LLC</Text>
      <Text>
        {" "}
        <strong>Address:</strong>
      </Text>
      <Text>
        123 Market St.
        <br />
        Portsmouth, NH 03801
      </Text>
    </div>
  );
}
