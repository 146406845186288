// COMPONENTS
import Loading from "components/common/Loading";
import TodoItem from "./TodoItem";
import EmptyState from "./EmptyState";
// HOOKs
import useUrlChange from "hooks/useUrlChange";
import { useTodosQuery } from "generated/graphql";

export default function Todos({
  queryVariables,
  project,
  business,
  contact,
  site,
}: {
  queryVariables?: any;
  project?: any;
  business?: any;
  site?: any;
  contact?: any;
}) {
  const { data, loading, error } = useTodosQuery({
    variables: queryVariables,
    fetchPolicy: "cache-and-network",
  });
  const { onUrlChange } = useUrlChange();

  if (loading) return <Loading />;
  if (error) return null;

  const setSelectedTodo = (todoId) => {
    onUrlChange({ selectedTodo: todoId });
  };

  if (!data?.todos?.todos?.[0])
    return (
      <EmptyState
        business={business}
        project={project}
        site={site}
        contact={contact}
      />
    );

  return (
    <>
      <div>
        {data?.todos?.todos?.map((todo) => (
          <TodoItem
            key={todo.id}
            todo={todo}
            onClick={() => setSelectedTodo(todo.id)}
          />
        ))}
      </div>
    </>
  );
}
