import React, { useState } from "react";
import { Card, CardTitle } from "../index";
import styled from "styled-components";
import Skeleton from "components/common/Skeleton";
import { ResponsiveBar } from "@nivo/bar";
// APOLLO
import REPORTS_PROJECTS_BY_STAGE from "ApolloClient/Queries/reports_ProjectsByStage";
import PIPELINES_BY_CUSTOMER from "ApolloClient/Queries/pipelinesByCustomer";
import { useQuery } from "@apollo/client";
import Select from "antd/lib/select";
import "antd/lib/select/style/css";
import chartColors from "lib/constants/chartColors";
const { Option } = Select;

const Container = styled.div`
  position: absolute;
  right: 8px;
  top: 12px;
`;

const PipelineDropdown = ({ value, onChange, pipelines }) => {
  return (
    <Container>
      <Select
        style={{ width: 190 }}
        value={value}
        onChange={onChange}
        size="small"
      >
        {pipelines.map((item) => {
          return (
            <Option value={item.id} key={item.id}>
              {item.title}
            </Option>
          );
        })}
      </Select>
    </Container>
  );
};

export default function ProjectsByStage() {
  const [pipelineId, setPipelineId] = useState(false);
  const { data, loading, error } = useQuery(REPORTS_PROJECTS_BY_STAGE, {
    variables: {
      pipelineId,
    },
    skip: !pipelineId,
  });
  const { data: pipelinesData } = useQuery(PIPELINES_BY_CUSTOMER, {
    onCompleted: (data) => {
      if (data.pipelinesByCustomer && data.pipelinesByCustomer[0]) {
        setPipelineId(data.pipelinesByCustomer[0].id);
      }
    },
  });

  if (loading) {
    return (
      <Card title="Projects By Stage">
        <Skeleton round />
      </Card>
    );
  }

  let keys = data && data.reports_ProjectsByStage.map((item) => item.title);
  let chartData =
    data &&
    data.reports_ProjectsByStage.map((item) => {
      return {
        [item.title]: item.total,
        title: item.title,
      };
    });

  return (
    <Card
      title="Projects By Stage"
      empty={!data || data.reports_ProjectsByStage.length === 0}
    >
      <PipelineDropdown
        value={pipelineId}
        pipelines={pipelinesData && pipelinesData.pipelinesByCustomer}
        onChange={(pipelineId) => setPipelineId(pipelineId)}
      />
      <ResponsiveBar
        keys={keys}
        data={chartData}
        colors={chartColors}
        indexBy="title"
        margin={{ top: 24, right: 0, bottom: 50, left: 0 }}
        padding={0.3}
        axisBottom={{
          tickRotation: -15,
        }}
      />
    </Card>
  );

  // let keys = data.reports_ProjectsByStage.map((item) => item.title);
  // let chartData = data.reports_ProjectsByStage.map((item) => {
  //   return {
  //     [item.title]: item.total,
  //     title: item.title,
  //   };
  // });

  // return (
  //   <Card>
  //     <CardTitle>Projects By Stage</CardTitle>
  //     <PipelineDropdown
  //       value={pipelineId}
  //       pipelines={pipelinesData.pipelinesByCustomer}
  //       onChange={(pipelineId) => setPipelineId(pipelineId)}
  //     />
  //     <ResponsiveBar
  //       keys={keys}
  //       data={chartData}
  //       colors={{scheme: 'blue_green'}}
  //       indexBy="title"
  //       margin={{top: 24, right: 0, bottom: 50, left: 0}}
  //       padding={0.3}
  //       axisBottom={{
  //         tickRotation: -15,
  //       }}
  //     />
  //   </Card>
  // );
}
