// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import resourceFragment from 'ApolloClient/Fragments/resourceFragment';

export default gql`
  query resourceById($id: ID!) {
    resourceById(id: $id) {
      ...resourceFragment
    }
  }
  ${resourceFragment}
`;
