// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  fragment caseOutcomeFragment on CaseOutcome {
    id
    title
    description
  }
`;
