import message from "components/common/message";
import { Project, useSaveProjectMutation } from "generated/graphql";
import { omit } from "lodash";

const getProjectValues = (project) => {
  try {
    //  if (proejct.)

    let shapedProject = {
      ...project,
      order: 0,
      stage: 0,
    };
    if (shapedProject.extraFields) {
      shapedProject.extraFields = shapedProject.extraFields.map((item) => {
        if (item.fieldType === "naics") {
          return omit(
            {
              ...item,
              value: JSON.stringify(item.value),
            },
            ["__typename"]
          );
        }
        return omit(item, ["__typename"]);
      });
    }
    if (shapedProject.customFields) {
      shapedProject.customFields = shapedProject.customFields.map((item) => {
        return omit(item, ["__typename"]);
      });
    }
    return shapedProject;
  } catch (err) {
    console.log("error in get project values");
    console.log(err);
  }
};

export default function useSaveProjectHook() {
  const [saveProject, { loading }] = useSaveProjectMutation();
  const onSubmitProject = async (
    id: string | null,
    params: any,
    refetchQueries?: any[],
    onComplete?: () => void
  ): Promise<any> => {
    try {
      // check if it has a title
      if (!params.title) {
        return message.error("Project title is required");
      }
      if (!params.pipeline) {
        return message.error("Please choose a pipeline");
      }

      const variableParams = getProjectValues(params);

      if (!variableParams) return;

      let res = await saveProject({
        variables: {
          id,
          params: variableParams,
        },
        refetchQueries,
      });
      // setLoading(false);
      onComplete && onComplete();
      return res?.data?.saveProject;
    } catch (err) {
      console.log(err);
    }
  };

  return [onSubmitProject, { loading }] as const;
}
