import React from "react";
import Select from "antd/lib/select";
import theme from "lib/theme";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { Pipeline, PipelineFragmentFragment } from "generated/graphql";

const { Option } = Select;

export default function PipelinesDropdown({
  setShowNewForm,
  currentPipeline,
  pipelines,
  setSelectedPipline,
  loading,
  containerStyle,
}: {
  setShowNewForm?: any;
  currentPipeline?: Pipeline | PipelineFragmentFragment;
  pipelines?: Pipeline[] | PipelineFragmentFragment[] | null;
  setSelectedPipline?: any;
  loading?: boolean;
  containerStyle?: React.CSSProperties;
}) {
  // show main component
  return (
    <DropdownStyleWrapper
      background={
        typeof containerStyle?.backgroundColor === "string"
          ? containerStyle?.backgroundColor
          : "#fff"
      }
      style={containerStyle}
    >
      <Select
        optionFilterProp="children"
        style={{ width: 260 }}
        onChange={(value) => {
          if (value === "newPipeline") {
            return setShowNewForm(true);
          }
          setSelectedPipline(value);
        }}
        value={currentPipeline && (currentPipeline?.id as any)}
      >
        {!loading &&
          pipelines &&
          pipelines?.map(
            ({ id, title }) =>
              id && (
                <Option key={id} value={id} style={{ fontWeight: 600 }}>
                  {title}
                </Option>
              )
          )}
        {setShowNewForm && (
          <Option
            key={"newPipeline"}
            value={"newPipeline"}
            style={{
              paddingBottom: 8,
              fontWeight: 600,
              color: theme.colors.primary5,
            }}
          >
            <div
              style={{
                height: 1,
                background: theme.colors.neutral9,
                marginBottom: 8,
              }}
            />
            + Add New Pipeline
          </Option>
        )}
      </Select>
    </DropdownStyleWrapper>
  );
}
