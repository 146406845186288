// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import eventFragment from 'ApolloClient/Fragments/eventFragment';

export default gql`
  query events($searchText: String) {
    events(searchText: $searchText) {
      count
      events {
        ...eventFragment
      }
    }
  }
  ${eventFragment}
`;
