import numeral from "numeral";
import diversityTypes from "lib/constants/diversityTypes";
import {
  CurrentUserCustomerFragmentFragment,
  CurrentUserFragmentFragment,
  CustomerFeatureSettingKeyEnum,
  RoleType,
  UserPermissionEnum,
  UserProfile,
} from "generated/graphql";

const GeneralHelpers = {
  cleanNumber: (number) => numeral(number).format("0,0.[00]"),
  mapRoleToColor: (role) => {
    switch (role) {
      case "appAdmin":
        return "#DAC4FF";
      default:
        return null;
    }
  },

  /**
   *
   * @param {*} userId
   * @param {*} projectId
   */
  addRecentlyViewedProject: (userId, projectId) => {
    // 1. get the current most recently viewed projects
    // if it's less than 4/5 projects, just add the project ID, if it's more, than remove one and add the new ID
    // save it in local storage again
  },
  // has permission
  hasPermission(
    user: CurrentUserFragmentFragment,
    permissions: UserPermissionEnum[]
  ) {
    if (!user) return false;
    if (user.roles && user.roles.includes(RoleType.AppAdmin)) return true;
    if (!user.permission) return false;
    return permissions?.includes(user?.permission);
  },
  capitalize: (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  },

  cleanTypenameFromArray: (values: object | any[]) => {
    // Deep copy of values
    const arrayValue = JSON.parse(JSON.stringify(values));
    // if an array, strip the __typename from each
    if (Array.isArray(arrayValue)) {
      return arrayValue.map((value) => {
        if (value && value.__typename) {
          delete value.__typename;
        }
        return value;
      });
    }
    // if for some reason it's an object with a __typename, just stripe it
    if (arrayValue && arrayValue.__typename) {
      delete arrayValue.__typename;
    }
    return arrayValue;
  },
  getDiversityLabel: (tag) => {
    let label;
    diversityTypes.forEach((item) => {
      if (item.value === tag) {
        label = item.label;
      }
    });
    return label;
  },
  mapRoleToLabel: (role) => {
    switch (role) {
      case RoleType.Gov:
        return "Government";
      case RoleType.AppAdmin:
        return "Admin";
      default:
        return null;
    }
  },
  featureIsOn: ({
    customer,
    feature,
  }: {
    customer?: CurrentUserCustomerFragmentFragment | undefined | null;
    feature: CustomerFeatureSettingKeyEnum;
  }) => {
    let turnedOnFeatures: any = [];
    if (!customer) return false;
    if (!customer.features) return false;
    customer.features.forEach((feat) => {
      if (feat?.active) {
        turnedOnFeatures.push(feat?.key);
      }
    });
    return turnedOnFeatures.includes(feature);
  },
};

export default GeneralHelpers;
