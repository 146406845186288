import styled from 'styled-components';

export default styled.div`
  height: 18px;
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  color: ${(p) => p.theme.colors.neutral1};
`;
