import React, { useState } from "react";
import styled from "styled-components";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Button from "components/common/Button";
import Avatar from "components/common/Avatar";
import TextAreaInput from "components/inputs/TextAreaInput";

const AddComment = styled.div`
  height: 50px;
  /* border: 1px solid ${(p) => p.theme.colors.neutral9}; */
  padding: 16px;
  background: ${(p) => p.theme.colors.neutral10};
  color: ${(p) => p.theme.colors.neutral5};
`;

const Container = styled.div`
  position: sticky;
  z-index: 10;
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1) 0.1s;
  transform: translate(0px, 0px);
  bottom: -1px;
  background: white;
  padding-left: 8px;
  margin-left: -8px;
  border-top: 2px solid ${(p) => p.theme.colors.neutral9};
  padding-top: 16px;

  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    margin: 0px;
  }
`;

export default function CommentForm({
  comment,
  onSubmit,
  currentUser,
  onCancel,
}: {
  comment?: any;
  onSubmit;
  currentUser: any;
  onCancel?: any;
}) {
  const [active, setActive] = useState(false);
  const [content, setContent] = useState(comment?.content);

  const handleOnSubmit = () => {
    onSubmit({
      content,
    });
    setActive(false);
  };

  return (
    <Container>
      <Row align="top">
        <Col xs={0} sm={2}>
          {" "}
          <Avatar
            src={currentUser && currentUser.avatar && currentUser.avatar.url}
          />
        </Col>
        <Col xs={24} sm={22}>
          {" "}
          {!active && (
            <AddComment
              data-testid="comment-form-input-btn"
              onClick={() => setActive(true)}
            >
              Add a comment...
            </AddComment>
          )}
          {active && (
            <>
              {" "}
              <TextAreaInput
                rows="6"
                onChange={(content) => setContent(content)}
                value={content}
                data-testid="comment-form-input"
              />
              <div style={{ marginTop: 8 }}>
                <Button
                  onClick={handleOnSubmit}
                  style={{ width: 100 }}
                  data-testid="comment-form-submit-btn"
                >
                  Save
                </Button>
                <Button
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={() => {
                    setActive(false);
                    if (onCancel) {
                      return onCancel();
                    }
                  }}
                  grey
                >
                  Cancel
                </Button>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
}
