// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

const currentUserCustomerFragment = gql`
  fragment currentUserCustomerFragment on Customer {
    id
    title
    integrations {
      constantContact {
        exists
      }
    }
    features {
      title
      key
      active
      available
      featureSettings {
        key
        extraFields {
          fieldName
          value
          fieldType
        }
      }
    }
  }
`;

export default gql`
  fragment currentUserFragment on UserProfile {
    id
    __typename
    emails {
      address
    }
    customerId
    customer {
      ...currentUserCustomerFragment
    }
    permission
    title
    notificationSettings {
      notificationType
      email
    }
    avatar {
      filename
      encoding
      mimetype
      url
      key
    }
    email
    firstName
    lastName
    roles
  }
  ${currentUserCustomerFragment}
`;
