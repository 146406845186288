import { get } from "lodash";
import * as Sentry from "@sentry/browser";

export default function identifyUser(userId: string, email?: string) {
  if (!userId) {
    console.log(
      "userId is required to identify a user in mixpanel or fullstory"
    );
    return;
  }
  const mixpanel = get("mixpanel", window);
  const FS = get("FS", window);

  if (mixpanel) {
    mixpanel.identify(userId);
  }
  if (FS) {
    FS.identify(userId);
  }

  if (Sentry) {
    Sentry?.setUser({ id: userId, email });
  }
}
