import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import Popconfirm from "components/common/Popconfirm";
import {
  Card,
  CardTitle,
  CardCaption,
  CardAvatar,
  CardActions,
  RemoveBtn,
} from "../index";
import { Business } from "generated/graphql";
import Text from "components/text/Text";
import Caption from "components/text/Caption";

export default function BusinessCard({
  item,
  onRemove,
}: {
  item: Business;
  onRemove?: any;
}) {
  return (
    <Card>
      <div style={{ display: "flex" }}>
        <CardAvatar type="businesses" src={item?.logo?.url || undefined} />
        <div>
          <CardTitle
            to={`/app/businesses/${item.id}?tab=1`}
            data-testid={`business-card-title-${item?.title}`}
          >
            {item.title}
          </CardTitle>
          <CardCaption>{item.website}</CardCaption>
          <CardCaption>{item.phone}</CardCaption>
        </div>
      </div>
      {onRemove && (
        <CardActions className="card-actions">
          <Popconfirm
            title={
              <div style={{ maxWidth: "100%", width: 300 }}>
                <Text>Are you sure you want to remove this business?</Text>
                <Caption>
                  This won't delete the business. It will only remove the
                  business's association to this record.
                </Caption>
              </div>
            }
            onConfirm={onRemove}
            okText="Yes"
          >
            <RemoveBtn>
              <DeleteOutlined />
            </RemoveBtn>
          </Popconfirm>
        </CardActions>
      )}
    </Card>
  );
}
