import styled from 'styled-components';
import Geosuggest from 'react-geosuggest';
import parseGoogle from 'parse-google-place';
// console.log({
//     gmaps,
//     description,
//     label,
//     name,
//     fullAddress: gmaps.formatted_address,
//     postal: address_components[6] && address_components[6].long_name,
//     country: address_components[5] && address_components[5].long_name,
//     state: address_components[4].long_name,
//     city: address_components[2] && address_components[2].long_name,
//     street: `${address_components[0].long_name} ${
//       address_components[1].long_name
//     }`,
//     phone: gmaps.formatted_phone_number,
//     icon: gmaps.icon,
//     gmapsId: gmaps.id,
//     lat: location.lat,
//     lng: location.lng,
//     placeId
//   });

//<input class="jfk-textinput b3id-text-input b3-text-input" name="ADDRESS_LINE_1" type="text" aria-required="true" aria-describedby="ariaId_35" aria-labelledby="ariaId_36" autocomplete="off-street-address" role="combobox" aria-autocomplete="list" data-was-visible="true" aria-invalid="false" aria-haspopup="false" aria-expanded="false"></input>

const Wrapper = styled.div`
  input {
    border-radius: 5px;
    background: ${(p) => p.theme.colors.neutral11};
    border: 0px;
    padding-left: 8px;
    width: 100%;
    height: 50px;
    border: 2px solid ${(p) => p.theme.colors.neutral11};
    &:focus {
      outline: 0;
      background: #fff;
      border: 2px solid ${(p) => p.theme.colors.primary7};
    }
  }
  .geosuggest__suggests {
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #efefef;
    border-top-width: 0;
    border-radius: ${(p) => p.theme.borderRadius}px;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    -webkit-transition: max-height 0.2s, border 0.2s;
    transition: max-height 0.2s, border 0.2s;
  }
  .geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
  }

  .geosuggest__item {
    font-size: 18px;
    font-size: 1rem;
    padding: 0.5em 0.65em;
    cursor: pointer;
    background: #fff !important;
  }

  .ant-select-lg,
  .ant-number,
  .ant-input-lg,
  .ant-input,
  input,
  .ant-select-selection--multiple,
  .ant-select-selection,
  .ant-select-selection--single {
    outline: 0;
    min-width: 100%;
  }
  .geosuggest__item--active {
    background: #267dc0;
    color: #fff;
  }
  .geosuggest__item--active:hover,
  .geosuggest__item--active:focus {
    background: ${(p) => p.theme.colors.neutral11};
  }
  .geosuggest__item__matched-text {
    font-weight: 700;
  }
`;

export default function LocationAutocomplete(props) {
  return (
    <Wrapper>
      <Geosuggest
        style={{
          input: {
            width: '100%',
            ...props.style,
          },
        }}
        country="us"
        value={props.value}
        types={['establishment', 'geocode']}
        {...props}
        onSuggestSelect={(response) => {
          if (
            !response ||
            !response.gmaps ||
            !response.gmaps.address_components
          )
            return null;
          const {gmaps, location, placeId} = response;

          const {address_components} = gmaps;

          let parsed = parseGoogle({address_components});

          props.onSuggestSelect({
            lat: location.lat,
            lng: location.lng,
            placeId,
            fullAddress: gmaps.formatted_address,
            street: parsed.address,
            postal: parsed.zipCode,
            country: parsed.countryShort,
            state: parsed.stateShort,
            city: parsed.city,
          });
        }}
      />
    </Wrapper>
  );
}
