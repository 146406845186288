// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  fragment projectFragmentShort on Project {
    id
    __typename
    title
    description
    projectType
    pipeline
    order
    amount
    stageId
    image {
      id
      filename
      mimetype
      encoding
      url
      key
    }
    updatedAt
    createdAt
  }
`;
