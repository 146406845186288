import React from 'react';
import styled from 'styled-components';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import Button from 'components/common/Button';
import Avatar from 'components/common/Avatar';
import TextAreaInput from 'components/inputs/TextAreaInput';

const AddComment = styled.div`
  height: 50px;
  /* border: 1px solid ${(p) => p.theme.colors.neutral9}; */
  padding: 16px;
  background: ${(p) => p.theme.colors.neutral10};
  color: ${(p) => p.theme.colors.neutral5};
`;

const Container = styled.div`
  position: sticky;
  z-index: 10;
  transition: transform 0.3s cubic-bezier(0.86, 0, 0.07, 1) 0.1s;
  transform: translate(0px, 0px);
  bottom: -1px;
  background: white;
  padding-left: 8px;
  margin-left: -8px;
  border-top: 2px solid ${(p) => p.theme.colors.neutral9};
  padding-top: 16px;
`;

class CommentForm extends React.PureComponent {
  state = {
    active: this.props.comment || false,
    content: this.props.comment && this.props.comment.content,
  };
  onSubmit = () => {
    let values = {
      content: this.state.content,
    };
    this.props.onSubmit(values);
    this.setState({active: false});
  };
  render() {
    const {currentUser} = this.props;
    return (
      <Container>
        <Row align="top">
          <Col xs={2}>
            {' '}
            <Avatar
              src={currentUser && currentUser.avatar && currentUser.avatar.url}
            />
          </Col>
          <Col xs={22}>
            {' '}
            {!this.state.active && (
              <AddComment onClick={() => this.setState({active: true})}>
                Add a comment...
              </AddComment>
            )}
            {this.state.active && (
              <React.Fragment>
                {' '}
                <TextAreaInput
                  rows="6"
                  onChange={(content) => this.setState({content})}
                  value={this.state.content}
                />
                <div style={{marginTop: 8}}>
                  <Button onClick={this.onSubmit} style={{width: 100}}>
                    Save
                  </Button>
                  <Button
                    style={{width: 100, marginLeft: 8}}
                    onClick={() => {
                      this.setState({active: false});
                      if (this.props.onCancel) {
                        return this.props.onCancel();
                      }
                    }}
                    grey
                  >
                    Cancel
                  </Button>
                </div>
              </React.Fragment>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default CommentForm;
