import React from "react";
import BusinessStagesMultiSelect from "components/inputs/BusinessStagesMultiSelect";
import DiversityInput from "components/inputs/DiversityInput";
import ClustersMultiselectInput from "components/inputs/ClustersMultiselectInput";
import NaicsInput from "components/inputs/NaicsInput";
import FormItem from "components/common/FormItem";
import { FILTER_ACTIONS } from "../index";
import SelectInput from "components/inputs/SelectInput";
import { BusinessStatusEnum, useBusinessSectorsQuery, useTagsQuery } from "generated/graphql";
import theme from "lib/theme";
import moment from "moment";
import RangePicker from "components/inputs/RangePicker";
import GeneralHelpers from "lib/helpers/GeneralHelpers";

const SectorsInput = ({ onChange, value }) => {
  const { data } = useBusinessSectorsQuery();

  const options = data?.businessSectors?.map((sector) => {
    return {
      id: sector?.id,
      label: sector?.title,
    };
  });
  return (
    <SelectInput
      value={value}
      placeholder="Select all that apply"
      options={options || []}
      onChange={onChange}
      mode="multiple"
      containerStyle={{
        width: "100%",
        backgroundColor: theme.colors?.neutral11,
      }}
    />
  );
};

const TagsInput = ({ onChange, value }) => {
  const { data } = useTagsQuery();
  const options = data?.tags?.tags?.map((sector) => {
    return {
      id: sector?.id,
      label: sector?.title,
    };
  });
  return (
    <SelectInput
      value={value}
      placeholder="Select all that apply"
      options={options || []}
      onChange={onChange}
      data-testid="tags-multiselect"
      mode="multiple"
      containerStyle={{
        width: "100%",
        backgroundColor: theme.colors?.neutral11,
      }}
    />
  );
};

const StatusInput = ({ onChange, value }) => {
  const businessStatuses = Object.values(BusinessStatusEnum)?.map(key => {
    return {
      label: GeneralHelpers.capitalize(key),
      value: key
    }
  });

  return (
    <SelectInput
      value={value}
      placeholder="Select status"
      options={businessStatuses.map((status) => ({
        id: status.value,
        label: status.label,
      }))}
      onChange={onChange}
      mode="multiple"
      containerStyle={{
        width: "100%",
        backgroundColor: theme.colors?.neutral11,
      }}
    />
  );
};

export default function Filters({ filters, onChangeFilters }) {
  return (
    <div>
      <FormItem label={<>Filter by Sectors</>}>
        <SectorsInput
          value={filters.businessSectorIds}
          onChange={(businessSectorIds) => {
            onChangeFilters({
              type: FILTER_ACTIONS.SECTORS,
              payload: { businessSectorIds },
            });
          }}
        />
      </FormItem>
      <FormItem label={<>Filter by Tags</>}>
        <TagsInput
          value={filters.tagIds}
          onChange={(tagIds) => {
            onChangeFilters({
              type: FILTER_ACTIONS.TAGS,
              payload: { tagIds },
            });
          }}
        />
      </FormItem>
      <FormItem label={<>Filter by Status</>}>
        <StatusInput
          value={filters.statuses}
          onChange={(statuses) => {
            onChangeFilters({
              type: FILTER_ACTIONS.STATUSES,
              payload: { statuses },
            });
          }}
        />
      </FormItem>
      <FormItem
        label={
          <>
            Filter by Classification
            {/* {filters.diversityTypes && (
              <ClearBtn
                onClick={() => {
                  onChangeFilters({
                    type: FILTER_ACTIONS.DIVERSITY_TYPES,
                    payload: {diversity: undefined},
                  });
                }}
              >
                Clear
              </ClearBtn>
            )} */}
          </>
        }
      >
        <DiversityInput
          value={filters.diversity}
          onChange={(diversity) => {
            onChangeFilters({
              type: FILTER_ACTIONS.DIVERSITY_TYPES,
              payload: { diversity },
            });
          }}
        />
      </FormItem>
      <FormItem
        label={
          <>
            Filter by Business Stage
            {/* {filters.businessStages && (
              <ClearBtn
                onClick={() => {
                  onChangeFilters({
                    type: FILTER_ACTIONS.BUSINESS_STAGES,
                    payload: {businessStages: undefined},
                  });
                }}
              >
                Clear
              </ClearBtn>
            )} */}
          </>
        }
      >
        <BusinessStagesMultiSelect
          value={filters.businessStages}
          onChange={(businessStages) => {
            onChangeFilters({
              type: FILTER_ACTIONS.BUSINESS_STAGES,
              payload: { businessStages },
            });
          }}
        />
      </FormItem>
      <FormItem
        label={
          <>
            Filter by Cluster
            {/* {filters.clusters && (
              <ClearBtn
                onClick={() => {
                  onChangeFilters({
                    type: FILTER_ACTIONS.CLUSTERS,
                    payload: {clusters: undefined},
                  });
                }}
              >
                Clear
              </ClearBtn>
            )} */}
          </>
        }
      >
        <ClustersMultiselectInput
          value={filters.clusters}
          onChange={(clusters) => {
            onChangeFilters({
              type: FILTER_ACTIONS.CLUSTERS,
              payload: { clusters },
            });
          }}
        />
      </FormItem>
      <FormItem
        label={
          <>
            Filter by NAICs
            {/* {filters.naicsIds && (
              <ClearBtn
                onClick={() => {
                  onChangeFilters({
                    type: FILTER_ACTIONS.NAICS,
                    payload: {naicsIds: undefined},
                  });
                }}
              >
                Clear
              </ClearBtn>
            )} */}
          </>
        }
      >
        <NaicsInput
          value={filters.naicsIds}
          placeholder="Select all that apply"
          onChange={(naicsIds) => {
            onChangeFilters({
              type: FILTER_ACTIONS.NAICS,
              payload: { naicsIds },
            });
          }}
        />
      </FormItem>

      <FormItem label={<>Filter by Business Open Date</>}>
        <RangePicker
          value={[
            filters?.start ? moment(parseInt(filters?.start)) : moment(),
            filters?.end ? moment(parseInt(filters?.end)) : moment(),
          ]}
          onChange={(values) => {
            onChangeFilters({
              payload: {
                start: values?.[0]?.valueOf()?.toString(),
                end: values?.[1]?.valueOf()?.toString(),
              },
            });
          }}
        />
      </FormItem>
      <FormItem label={<>Filter by Business Closed Date</>}>
        <RangePicker
          value={[
            filters?.startClosed
              ? moment(parseInt(filters?.startClosed))
              : moment(),
            filters?.endClosed
              ? moment(parseInt(filters?.endClosed))
              : moment(),
          ]}
          onChange={(values) => {
            onChangeFilters({
              payload: {
                startClosed: values?.[0]?.valueOf()?.toString(),
                endClosed: values?.[1]?.valueOf()?.toString(),
              },
            });
          }}
        />
      </FormItem>
    </div>
  );
}

// import React from 'react';
// import styled from 'styled-components';
// // COMPONENTS
// import Popover from 'components/common/Popover';
// import Row from 'components/common/Row';
// import CheckboxInput from 'components/inputs/CheckboxInput';
// import Col from 'components/common/Col';
// // APOLLO
// import {Query} from '@apollo/client';
// import tagsQuery from 'ApolloClient/Queries/tags';
// // LIB
// import constants from 'lib/constants';
// import filterIcon from 'lib/media/filter-icon.svg';
// import businessStatuses from 'lib/constants/businessStatuses';

// const Container = styled.div`
//   background: ${(p) => p.theme.colors.neutral9};
//   width: 85px;
//   height: 30px;
//   border-radius: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-left: 10px;
//   margin-bottom: 10px;
//   &:hover {
//     cursor: pointer;
//     background: ${(p) => p.theme.colors.neutral8};
//   }
// `;

// const ContentContainer = styled(Row)`
//   width: 650px;
//   min-height: 250px;
// `;

// const CheckboxWrapper = styled.div`
//   height: 32px;
// `;

// const Content = (props) => {
//   const {onChange, tagIds, statuses, clusters} = props;

//   const onFilterChange = (id, field = 'clusters') => {
//     let newTagIds = [...props[field]];
//     if (!newTagIds.includes(id)) {
//       newTagIds.push(id);
//     } else {
//       newTagIds = newTagIds.filter((item) => item !== id);
//     }
//     return onChange({[field]: newTagIds});
//   };

//   const onTagChange = ({id}) => {
//     let newTagIds = [...tagIds];
//     if (!newTagIds.includes(id)) {
//       newTagIds.push(id);
//     } else {
//       newTagIds = newTagIds.filter((item) => item !== id);
//     }
//     return onChange({tagIds: newTagIds});
//   };

//   const onStatusChange = ({id}) => {
//     console.log({props: statuses});
//     let newStatuses = [...statuses];
//     console.log({newStatuses});
//     if (!newStatuses.includes(id)) {
//       newStatuses.push(id);
//     } else {
//       newStatuses = newStatuses.filter((item) => item !== id);
//     }
//     console.log({newStatuses});
//     return onChange({statuses: newStatuses});
//   };

//   return (
//     <div style={{padding: 16}}>
//       <ContentContainer align="top" justify="start">
//         <Col xs={6}>
//           <div>Status</div>
//           <div style={{paddingTop: 16}}>
//             {businessStatuses.map(({label, value}) => (
//               <CheckboxWrapper key={value}>
//                 <CheckboxInput
//                   label={label}
//                   value={statuses.includes(value)}
//                   onChange={() => onStatusChange({id: value})}
//                 />
//               </CheckboxWrapper>
//             ))}
//           </div>
//         </Col>
//         <Col xs={8}>
//           <div>Tags</div>
//           <div
//             style={{
//               height: 230,
//               paddingTop: 16,
//               overflowY: 'scroll',
//             }}
//           >
//             <Query query={tagsQuery}>
//               {({data, loading, error}) => {
//                 if (loading) return 'loading...';
//                 return data.tags.tags.map(({id, title}) => (
//                   <CheckboxWrapper key={id}>
//                     <CheckboxInput
//                       label={title}
//                       value={tagIds.includes(id)}
//                       onChange={() => onTagChange({id})}
//                     />
//                   </CheckboxWrapper>
//                 ));
//               }}
//             </Query>
//           </div>
//         </Col>
//         <Col xs={10}>
//           <div>Clusters</div>
//           <div style={{height: 300, overflowY: 'scroll'}}>
//             {constants.clusterTypes.map(({value, label}) => (
//               <CheckboxWrapper key={value}>
//                 <CheckboxInput
//                   label={label}
//                   width={300}
//                   value={clusters.includes(value)}
//                   onChange={() => onFilterChange(value)}
//                 />
//               </CheckboxWrapper>
//             ))}
//           </div>
//         </Col>
//       </ContentContainer>
//     </div>
//   );
// };

// const Text = styled.div`
//   color: ${(p) => p.theme.colors.neutral5};
//   font-family: ${(p) => p.theme.fontFamily};
// `;

// export default function Filters({statuses, tagIds, clusters, onChange}) {
//   return (
//     <Popover
//       placement="bottom"
//       trigger={['click']}
//       content={
//         <Content
//           statuses={statuses}
//           tagIds={tagIds}
//           clusters={clusters || []}
//           onChange={onChange}
//         />
//       }
//     >
//       <Container>
//         <img src={filterIcon} alt="icon" style={{marginRight: 6}} />
//         <Text>Filters</Text>
//       </Container>
//     </Popover>
//   );
// }
