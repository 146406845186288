import { LoadingOutlined } from "@ant-design/icons";
import theme from "lib/theme";

export default function Loading({ height }: { height?: number }) {
  return (
    <div
      data-testid="loading-component"
      style={{
        height: height || 500,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingOutlined style={{ fontSize: 25, color: theme.colors.primary5 }} />
    </div>
  );
}
