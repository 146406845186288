// TOP LEVEL IMPORTS
import React from 'react';
// COMPONENTS
import Alert from 'components/common/Alert';
import styled from 'styled-components';

const Container = styled.div`
  min-height: 25px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

class ErrorList extends React.PureComponent {
  render() {
    return (
      <Container>
        {this.props.errors &&
          this.props.errors.map(err => (
            <Alert message={err} key={err} type="error" showIcon />
          ))}
      </Container>
    );
  }
}

export default ErrorList;
