import React, { Component, ErrorInfo, ReactNode } from "react";
import ErrorState from "../ErrorState";
import * as Sentry from "@sentry/react";

const PAGE_HAS_BEEN_FORCE_REFRESHED = "page-has-been-force-refreshed";

const retryPageLoading = () => {
  const pageHasAlreadyBeenForceRefreshed = JSON.parse(
    window.localStorage.getItem(PAGE_HAS_BEEN_FORCE_REFRESHED) || "false"
  ) as boolean;

  if (!pageHasAlreadyBeenForceRefreshed) {
    window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, "true");
    window.location.reload();
  } else {
    window.localStorage.setItem(PAGE_HAS_BEEN_FORCE_REFRESHED, "false");
  }
};

interface ErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    retryPageLoading();
    this.setState({ hasError: true });

    // Create a custom object with context information
    const customContext = {
      errorInfo: info.componentStack, // You can customize this
      // Add more context information as needed
    };

    // Log the error to Sentry with custom context
    Sentry.withScope((scope) => {
      scope.setExtras(customContext);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorState title="We seem to be having trouble connecting..." />;
    }
    return <>{this.props.children}</>;
  }
}

export default Sentry.withProfiler(ErrorBoundary);
