// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import tagFragment from "ApolloClient/Fragments/tagFragment";
import attachmentFragment from "ApolloClient/Fragments/attachmentFragment";
import contactShortFragment from "./contactShortFragment";

export default gql`
  fragment businessFragment on Business {
    id
    __typename
    title
    optResult
    description
    phone
    twitter
    instagram
    linkedin
    facebook
    businessSectorId
    tradeName
    businessSector {
      id
      title
    }
    logo {
      id
      filename
      mimetype
      encoding
      url
      key
    }
    attachments {
      ...attachmentFragment
    }
    fax
    website
    businessStage
    clusters
    serviceNeeds
    surveyPermission
    naics {
      id
      title
      code
    }
    naicsIds
    diversity
    amenities
    employeeCount
    tags {
      ...tagFragment
    }
    businessType
    closed
    opened
    status
    createdAt
    primaryContact {
      ...contactShortFragment
    }
    lat
    lng
    placeId
    fullAddress
    street
    street2
    postal
    country
    state
    city
    annualRevenue
  }
  ${tagFragment}
  ${attachmentFragment}
  ${contactShortFragment}
`;

// "Contacts related to this business"
// contacts: [Contact]
// "A list of interactions for this business"
// interactions: [Interaction]
