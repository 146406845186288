import React, {useState} from 'react';
import styled from 'styled-components';
//COMPONENTS
import Todo from './Todo';
import Modal from 'components/common/Modal';
import Col from 'components/common/Col';
import Row from 'components/common/Row';
import {UpOutlined, DownOutlined} from '@ant-design/icons';
import Popconfirm from 'components/common/Popconfirm';
import message from 'components/common/message';
import TodoDetail from 'components/common/TodoDetail';
// APOLLO
import SAVE_TODO_LIST from 'ApolloClient/Mutations/saveTodoList';
import todoListsQuery from 'ApolloClient/Queries/todoLists';
import DELETE_TODO_LIST from 'ApolloClient/Mutations/deleteTodoList';
import {useMutation} from '@apollo/client';

const ListContainer = styled.div`
  min-height: 150px;
  max-width: 100%;
  background: ${(p) => p.theme.colors.neutral10};
  border-radius: 5px;
`;

const ListTitle = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.neutral2};
  margin-bottom: 16px;
`;

const NewTodoButton = styled.button`
  color: ${(p) => p.theme.colors.primary3};
  font-weight: 700;
  margin-bottom: 8px;
  border: 0px;
  background: transparent;
  &:hover {
    cursor: pointer;
    color: ${(p) => p.theme.colors.primary1};
  }
  &:focus {
    outline: 0;
  }
`;

const EmptyText = styled.div`
  height: 29px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7b8794;
`;

const TodosContainer = styled.div`
  margin-bottom: 32px;
  background: white;
  padding: 8px 24px;
  max-height: 500px;
  overflow-y: scroll;
  border-radius: 5px;
`;

const EmptyTodo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 100px;
  border-radius: 5px;
  border: 1px solid #fff;
  max-width: 100%;
`;

const GreyText = styled.span`
  font-weight: 500;
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.neutral6};
`;

const ShowHide = styled.span`
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.primary3};
  &:hover {
    color: ${(p) => p.theme.colors.primary2};
  }
`;

const Archive = styled(ShowHide)`
  color: ${(p) => p.theme.colors.red5};
  &:hover {
    color: ${(p) => p.theme.colors.red3};
  }
`;

const Delete = styled(ShowHide)`
  font-weight: 700;
  color: ${(p) => p.theme.colors.neutral3};
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
`;

export default function TodoList({variables, list, currentUser, onNewTodo}) {
  const [saveTodoList, {loading: saving}] = useMutation(SAVE_TODO_LIST);
  const [deleteTodoList, {loading: deleting}] = useMutation(DELETE_TODO_LIST);
  const [show, setShow] = useState(true);
  const [todo, setTodo] = useState({});
  const [todoDetail, setTodoDetail] = useState(null);

  const getRefetchQueries = () => {
    return [
      {query: todoListsQuery, variables},
      {
        query: todoListsQuery,
        variables: {
          ...variables,
          archived: !variables.archived,
        },
      },
    ];
  };

  const archiveList = async () => {
    try {
      await saveTodoList({
        variables: {
          id: list.id,
          params: {
            archived: !list.archived,
          },
        },
        refetchQueries: getRefetchQueries(),
        optimisticResponse: {
          __typename: 'Mutation',
          saveTodoList: {
            id: list.id,
            __typename: 'TodoList',
            archived: !list.archived,
          },
        },
      });
      message.success('Success!');
    } catch (err) {
      console.log(err);
    }
  };

  const deleteList = async () => {
    try {
      await deleteTodoList({
        variables: {
          id: list.id,
        },
        refetchQueries: getRefetchQueries(),
      });
      message.success('Successfully deleted!');
      //window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const listIsDone =
    list.todos.filter((item) => item.done).length === list.todos.length;

  return (
    <div>
      <Row>
        <Col xs={12} style={{textAlign: 'left'}}>
          <ListTitle>
            {list.title}
            <GreyText>
              {' '}
              ({list.todos.filter((item) => item.done).length} /{' '}
              {list.todos.length})
            </GreyText>

            <ShowHide onClick={() => setShow(() => !show)}>
              {show ? 'Hide' : 'Show'}
              {show ? (
                <DownOutlined style={{marginLeft: 4, fontSize: 8}} />
              ) : (
                <UpOutlined style={{marginLeft: 4, fontSize: 8}} />
              )}
            </ShowHide>
            {listIsDone && show && list.todos.length !== 0 && (
              <Popconfirm
                title={`${list.archived ? 'Unarchive' : 'Archive'} this list?`}
                okText="Yes"
                onConfirm={archiveList}
              >
                <Archive>{list.archived ? 'Unarchive' : 'Archive'}</Archive>
              </Popconfirm>
            )}
            {(list.archived || list.todos.length === 0) && (
              <Popconfirm
                title={`Delete this list? This will delete all todos with it.`}
                okText="Yes"
                onConfirm={deleteList}
              >
                <Delete>Delete List</Delete>
              </Popconfirm>
            )}
          </ListTitle>
        </Col>
        <Col xs={12} style={{textAlign: 'right'}}>
          {this.state.show && (
            <NewTodoButton onClick={() => onNewTodo(list.id)}>
              + Create todo
            </NewTodoButton>
          )}
        </Col>
      </Row>
      {this.state.show && (
        <ListContainer>
          <TodosContainer>
            {!list.todos || list.todos.length === 0 ? (
              <EmptyTodo>
                {' '}
                <EmptyText>Your list is empty...</EmptyText>
              </EmptyTodo>
            ) : null}
            {list.todos &&
              list.todos
                //.sort((x, y)=> new Date(y.dueDate) - new Date(x.dueDate)) // sort by date first
                .sort((x, y) => (x.done === y.done ? 0 : x.done ? 1 : -1)) // then sort by done
                .map((todo, i) => {
                  return (
                    <Todo
                      key={todo.id}
                      todo={todo}
                      isFirst={i === 0}
                      onCheck={() => {
                        console.log(todo.id);
                      }}
                      onClick={() => {
                        setTodoDetail(true);
                        setTodo(todo);
                      }}
                    />
                  );
                })}
          </TodosContainer>
        </ListContainer>
      )}

      <Modal
        onCancel={() => setTodoDetail(false)}
        visible={todoDetail ? true : false}
        footer={null}
        width={880}
      >
        <TodoDetail
          defaultTodo={todo}
          loading={saving || deleting}
          onComment={() => console.log('onComment')}
          currentUser={currentUser}
          onCancel={() => setTodoDetail(false)}
        />
      </Modal>
    </div>
  );
}
