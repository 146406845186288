import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import isValidDomain from "is-valid-domain";
// COMPONENTS
import FormItem from "components/common/FormItem";
import Col from "components/common/Col";
import Row from "components/common/Row";
import TextInput from "components/inputs/TextInput";
import TextAreaInput from "components/inputs/TextAreaInput";
import CurrencyInput from "components/inputs/CurrencyInput";
import Button from "components/common/Button";
import LocationAutocomplete from "components/inputs/LocationAutocomplete";
import NumberInput from "components/inputs/NumberInput";
import SideFormButtonContainer from "components/common/SideFormButtonContainer";
// APOLLO
import client from "ApolloClient/index";
// LIB
import theme from "lib/theme";
import StatesInput from "components/inputs/StatesInput/index";
import { DeepPartial } from "utility-types";
import {
  Business,
  GetCompanyByDomainDocument,
  GetCompanyByDomainQueryVariables,
} from "generated/graphql";
import { toString } from "lodash";
import PhoneInput from "components/inputs/PhoneInput";
import DateInput from "components/inputs/DateInput";
import moment from "moment";
import formatPhoneNumebr from "lib/helpers/formatPhoneNumber";
import formatPhoneNumber from "lib/helpers/formatPhoneNumber";

const HideFields = styled.div`
  background: #fff;
  opacity: 0.75;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 100;
  color: #000;
  display: flex;
  justify-content: center;
  font-size: 18px;
`;

// country: country && country.value,
// street: address && address.value,
// facebook: facebook_company_page && facebook_company_page.value,
// industry: industry && industry.value,
// twitter: twitterhandle && twitterhandle.value,
// founded: founded_year && founded_year.value,
// logo: `https://logo.clearbit.com/${domain}`,

const CardButton = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.primary4};
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  font-size: 14px;
`;

const CardTitle = styled.h2`
  margin: 0px;
  padding: 0px;
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 18px;
  font-weight: 600;
  a {
    color: ${(p) => p.theme.colors.neutral1};
    cursor: pointer;
  }
`;

const CardCaption = styled.p`
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral4};
`;

const Image = styled.img`
  width: 40px;
  border: 1px solid ${(p) => p.theme.colors.neutral10};
`;

const CompanyCard = ({ biz, attachable, onSaveExisting }) => {
  return (
    <div
      style={{
        border: `1px solid ${theme.colors.neutral8}`,
        borderRadius: 5,
        padding: 16,
        margin: 16,
        width: 250,
        display: "flex",
      }}
    >
      <div style={{ marginRight: 6 }}>
        <Image src={biz.logo} />
      </div>
      <div>
        <CardTitle>
          <Link to={`/app/businesses/${biz.id}`}>{biz.title}</Link>
        </CardTitle>
        <CardCaption>{biz.website}</CardCaption>
        {attachable && (
          <CardButton onClick={onSaveExisting}>Attach this business</CardButton>
        )}
      </div>
    </div>
  );
};

export default function BusinessForm({
  defaultValues,
  loading,
  onSubmit,
  onCancel,
  onSaveExisting = () => {},
  attachable = false,
}: {
  defaultValues?: any;
  loading?: boolean;
  onSubmit: (values?: any) => void;
  onCancel: () => void;
  onSaveExisting: (values: any) => void;
  attachable?: boolean;
}) {
  const FORM_DEFAULT_VALUES = {
    opened: moment(),
  };
  const [values, setValues] = useState<any>(
    {
      ...FORM_DEFAULT_VALUES,
      ...defaultValues,
    } || FORM_DEFAULT_VALUES
  );
  const [searchComplete, setSearchComplete] = useState<boolean>(false);
  const [searching, setSearching] = useState<boolean>(false);
  const [existingBiz, setExistingBiz] = useState<DeepPartial<Business> | false>(
    false
  );
  const [errors, setErrors] = useState<any>({});
  const {
    title,
    website,
    description,
    phone,
    city,
    postal,
    state,
    street,
    street2,
    employeeCount,
    annualRevenue,
    opened,
  } = values;

  const onSearchDomain = async (newValue) => {
    //
    setExistingBiz(false);
    setSearching(true);

    const queryVars: GetCompanyByDomainQueryVariables = {
      domain: newValue,
    };

    const res = await client.query({
      query: GetCompanyByDomainDocument,
      variables: queryVars,
      fetchPolicy: "network-only",
    });

    setSearching(false);

    // if the company already exists in my customer DB
    if (res?.data?.getCompanyByDomain?.exists) {
      return setExistingBiz({
        id: res.data.getCompanyByDomain.businessId,
        title: res.data.getCompanyByDomain.title,
        website: res.data.getCompanyByDomain.website,
        logo: res.data.getCompanyByDomain.logo,
        phone: formatPhoneNumber(res.data.getCompanyByDomain.phone),
      });
    }

    // otherwise, if it doesn't exist, populate the form
    if (res?.data?.getCompanyByDomain) {
      let {
        phone,
        city,
        title,
        state,
        description,
        postal,
        revenue,
        employees,
        industry,
        street,
        logo,
      } = res.data.getCompanyByDomain;
      onChange({
        phone: formatPhoneNumber(phone),
        city,
        title,
        state,
        description,
        postal,
        industry,
        street,
        logo: {
          url: logo,
        },
        website: newValue,
        annualRevenue: revenue,
        employeeCount: employees,
      });
      setSearchComplete(true);
    }
  };

  const onChange = (newValues) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const handleOnSubmit = () => {
    setErrors({});
    if (!values.title) {
      setErrors({ title: "Please provide a company name" });
    }
    onSubmit({
      ...values,
      annualRevenue: toString(annualRevenue),
      lat: values.lat && values.lat.toString(),
      lng: values.lng && values.lng.toString(),
      opened: values?.opened ? values?.opened?.valueOf()?.toString() : null,
    });
  };

  const disabled = !title || loading;

  return (
    <div style={{ paddingBottom: 64 }}>
      <FormItem
        label="Company Website"
        hint={`If you know the company's website, input it here and we may be able to auto-fill additional information about the company (formatted like "acme.com").`}
      >
        <TextInput
          value={website}
          data-testid="business-form-website-input"
          onChange={(newWebsite) => {
            newWebsite = newWebsite.replace("www.", "");
            newWebsite = newWebsite.replace("https://", "");
            newWebsite = newWebsite.replace("http://", "");
            newWebsite = newWebsite.replace("/", "");

            onChange({ website: newWebsite });

            let tld = newWebsite.substr(newWebsite.indexOf("."));
            if (isValidDomain(newWebsite) && tld && tld.length > 3) {
              return onSearchDomain(newWebsite);
            }
          }}
        />
      </FormItem>
      <FormItem label="Company Name" required error={errors?.title}>
        <TextInput
          data-testid="business-form-name-input"
          value={title || ""}
          onChange={(title) => onChange({ title })}
        />
      </FormItem>
      <div style={{ position: "relative" }}>
        {existingBiz && (
          <HideFields>
            <div>
              You mean {existingBiz?.title}?
              <CompanyCard
                biz={existingBiz}
                attachable={attachable} // can the existin business be attached to something? e.g. when adding a business globally there is nothing to attach to VS when adding a business through a detail page like a project or a contact
                onSaveExisting={() => onSaveExisting(existingBiz)}
              />
            </div>
          </HideFields>
        )}
        {!searching && !website && !searchComplete && !title && (
          <HideFields style={{ textAlign: "center" }}>
            Start by entering a website address, <br /> an account name, or
            both.
          </HideFields>
        )}
        {searching && <HideFields>Searching...</HideFields>}
        <FormItem label="Description">
          <TextAreaInput
            value={description}
            onChange={(description) => onChange({ description })}
          />
        </FormItem>

        <Row gutter={8}>
          <Col xs={12}>
            <FormItem label="Phone">
              <PhoneInput
                value={phone}
                onChange={(newPhone) => onChange({ phone: newPhone })}
              />
            </FormItem>
          </Col>
          <Col xs={12} />
          <Col xs={24}>
            <FormItem label="Address">
              <LocationAutocomplete
                value={street}
                initialValue={street}
                onChange={(street) => onChange({ street })}
                onSuggestSelect={(value) => {
                  onChange({ ...value });
                }}
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            <FormItem label="Address 2 (Suite, Unit etc)">
              <TextInput
                value={street2}
                initialValue={street2}
                onChange={(street2) => onChange({ street2 })}
              />
            </FormItem>
          </Col>
          <Col xs={11}>
            <FormItem label="City">
              <TextInput
                value={city || ""}
                onChange={(city) => onChange({ city })}
              />
            </FormItem>
          </Col>
          <Col xs={9}>
            <FormItem label="State">
              <StatesInput
                value={state}
                onChange={(state) => onChange({ state })}
              />
            </FormItem>
          </Col>
          <Col xs={4}>
            <FormItem label="Zip">
              <TextInput
                value={postal}
                onChange={(postal) => onChange({ postal })}
              />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label="Number of Employees">
              <NumberInput
                value={employeeCount}
                onChange={(employeeCount) => onChange({ employeeCount })}
              />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem label="Business Open Date">
              <DateInput
                value={opened}
                onChange={(opened) => onChange({ opened })}
                allowClear
              />
            </FormItem>
          </Col>

          <Col xs={12}>
            <FormItem label="Annual Revenue">
              <CurrencyInput
                value={annualRevenue}
                onChange={(annualRevenue) => onChange({ annualRevenue })}
              />
            </FormItem>
          </Col>
          <Col xs={12} />
        </Row>
        <SideFormButtonContainer>
          <Button
            disabled={disabled}
            loading={loading}
            onClick={handleOnSubmit}
            style={{ marginLeft: 16 }}
            data-testid="business-form-submit-btn"
          >
            Save Business
          </Button>
          <Button
            disabled={loading}
            onClick={onCancel}
            grey
            style={{ marginLeft: 8 }}
          >
            Cancel
          </Button>
        </SideFormButtonContainer>
      </div>
    </div>
  );
}

// // TOP LEVEL IMPORTS
// import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
// // COMPONENTS
// import TextInput from 'components/inputs/TextInput';
// import TextAreaInput from 'components/inputs/TextAreaInput';
// import DiversityInput from 'components/inputs/DiversityInput';
// import BusinessStagesSelect from 'components/inputs/BusinessStagesSelect';
// import BusinessStatusInput from 'components/inputs/BusinessStatusInput';
// import Button from 'components/common/Button';
// import Row from 'components/common/Row';
// import Col from 'components/common/Col';
// import SupportTypeSelect from 'components/inputs/SupportTypesMultiSelect';
// import FormItem from 'components/common/FormItem';
// import SectionBreak from 'components/common/SectionBreak';
// import CustomTagsInput from 'components/inputs/CustomTagsInput';

// import FundingTypesSelect from 'components/inputs/FundingTypesSelect';

// import NumberInput from 'components/inputs/NumberInput';
// import NaicsInput from 'components/inputs/NaicsInput';
// import ClustersMultiselectInput from 'components/inputs/ClustersMultiselectInput';
// import PrimaryContact from 'components/inputs/ContactInput';
// import OtherContacts from './OtherContacts';
// import PhoneInput from 'components/inputs/PhoneInput';
// import DateInput from 'components/inputs/DateInput';
// import LocationAutocomplete from 'components/inputs/LocationAutocomplete';
// import ImageInput from 'components/inputs/ImageInput';
// // LIb
// import helpers from 'lib/helpers/GeneralHelpers';

// const FormContainer = styled.form`
//   width: 900px;
//   margin: auto;
//   max-width: 100%;
// `;

// const ButtonContainer = styled.div`
//   position: fixed;
//   bottom: 0;
//   right: 0;
//   width: 550px;
//   z-index: 1000;
//   height: 65px;
//   display: flex;
//   align-items: center;
//   background: ${(p) => p.theme.colors.neutral10};
// `;

// // defaultValues={defaultValues}
// // loading={loading}
// // onSubmit={onSubmit}
// // customer={currentUser.customer}
// // onCancel={onClose}

// export default function BusinessForm({ defaultValues, loading, onSubmit, customer, onCancel }) {
//   const [values, setValues] = useState(defaultValues || {})
// }

// class BusinessForm extends React.PureComponent {
//   state = {
//     title: this.props.title || null,
//     logo: this.props.logo || null,
//     description: this.props.description || null,
//     phone: this.props.phone || null,
//     website: this.props.website || null,
//     fax: this.props.fax || null,
//     employeeCount: this.props.employeeCount || null,
//     status: this.props.status || null,
//     businessStage: this.props.businessStage || null,
//     clusters: this.props.clusters || [],
//     diversity: this.props.diversity || [],
//     opened: this.props.opened || null,
//     closed: this.props.closed || null,
//     naics: this.props.naicsIds || [],
//     tags: this.props.tagIds || [],
//     contacts: this.props.contacts || [],
//     primaryContact: this.props.primaryContact || null,
//     lat: this.props.lat || null,
//     lng: this.props.lng || null,
//     placeId: this.props.placeId || null,
//     fullAddress: this.props.fullAddress || null,
//     street: this.props.street || null,
//     postal: this.props.postal || null,
//     country: this.props.country || null,
//     state: this.props.state || null,
//     city: this.props.city || null,
//     serviceNeeds: this.props.serviceNeeds || [],
//   };

//   getValues = () => {
//     return {
//       title: this.state.title || null,
//       description: this.state.description || null,
//       logo:
//         (this.state.logo && helpers.cleanTypenameFromArray(this.state.logo)) ||
//         null,
//       phone: this.state.phone || null,
//       website: this.state.website || null,
//       fax: this.state.fax || null,
//       employeeCount: this.state.employeeCount || null,
//       status: this.state.status || null,
//       businessStage: this.state.businessStage || null,
//       clusters: this.state.clusters || [],
//       diversity: this.state.diversity || [],
//       naicsIds: this.state.naics || [],
//       tagIds: this.state.tagIds || [],
//       // location
//       lat: (this.state.lat && this.state.lat.toString()) || null,
//       lng: (this.state.lng && this.state.lng.toString()) || null,
//       placeId: this.state.placeId || null,
//       fullAddress: this.state.fullAddress || null,
//       street: this.state.street || null,
//       postal: this.state.postal || null,
//       country: this.state.country || null,
//       state: this.state.state || null,
//       city: this.state.city || null,
//       serviceNeeds: this.state.serviceNeeds || [],
//       opened:
//         (this.state.opened && this.state.opened.valueOf().toString()) || null,
//       closed:
//         (this.state.closed && this.state.closed.valueOf().toString()) || null,
//       contactIds:
//         (this.state.contacts &&
//           this.state.contacts.map(contact => contact.id)) ||
//         [],
//       primaryContactId:
//         (this.state.primaryContact && this.state.primaryContact.id) || null,
//     };
//   };

//   onSubmit = e => {
//     e.preventDefault();

//     if (this.props.loading) return null;
//     // do some logic to verify everything is okay

//     // create the product object to pass up to parent
//     let values = this.getValues();
//     console.log({values});

//     // pass back up to the parent component
//     this.props.onSubmit(values);
//   };
//   onCancel = e => {
//     e.preventDefault();
//     if (this.props.loading) return null;
//     this.props.onCancel();
//   };

//   render() {
//     return (
//       <FormContainer>
//         <SectionBreak
//           header="Basics"
//           body="Provide general information about this business."
//         >
//           <FormItem>
//             <ImageInput
//               value={this.state.logo}
//               onChange={logo => this.setState({logo})}
//               label="Click to upload logo"
//               cloudinaryOptions={{
//                 maxImageWidth: 500,
//                 maxImageHeight: 500,
//               }}
//             />
//           </FormItem>
//           <FormItem
//             label="What is the business Name?"
//             hint="You can always change the name later"
//           >
//             <TextInput
//               value={this.state.title}
//               onChange={title => this.setState({title})}
//             />
//           </FormItem>
//           <FormItem label="How would you describe this business?">
//             <TextAreaInput
//               value={this.state.description}
//               rows="6"
//               onChange={description => this.setState({description})}
//             />
//           </FormItem>{' '}
//           <FormItem label="Location">
//             {this.state.lat && this.state.lng ? (
//               <div>{this.state.fullAddress}</div>
//             ) : (
//               <LocationAutocomplete
//                 onSuggestSelect={value => this.setState({...value})}
//               />
//             )}
//           </FormItem>{' '}
//         </SectionBreak>
//         <SectionBreak
//           header="Main Contact"
//           body="Who is the main point of contact for this business?"
//         >
//           <PrimaryContact
//             contact={this.state.primaryContact}
//             onSelect={primaryContact => this.setState({primaryContact})}
//           />
//         </SectionBreak>
//         <SectionBreak
//           header="Other Contact"
//           body="Add as many other contacts as you want."
//         >
//           <OtherContacts
//             contacts={this.state.contacts}
//             onSelect={contacts => this.setState({contacts})}
//           />
//         </SectionBreak>

//         <SectionBreak
//           header="Additional Info"
//           body="Provide general information about this business."
//         >
//           <Row gutter={20}>
//             <Col xs={12}>
//               <FormItem label="Phone">
//                 <PhoneInput
//                   value={this.state.phone}
//                   onChange={phone => this.setState({phone})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={12}>
//               {' '}
//               <FormItem label="Website">
//                 <TextInput
//                   value={this.state.website}
//                   onChange={website => this.setState({website})}
//                 />
//               </FormItem>{' '}
//             </Col>
//             <Col xs={8}>
//               {' '}
//               <FormItem label="Fax">
//                 <TextInput
//                   value={this.state.fax}
//                   onChange={fax => this.setState({fax})}
//                 />
//               </FormItem>{' '}
//             </Col>
//             <Col xs={4} />
//             <Col xs={8}>
//               {' '}
//               <FormItem label="Employee Count">
//                 <NumberInput
//                   value={this.state.employeeCount}
//                   onChange={employeeCount => this.setState({employeeCount})}
//                 />
//               </FormItem>{' '}
//             </Col>{' '}
//             <Col xs={4} />
//             <Col xs={12}>
//               {' '}
//               <FormItem label="Opened on">
//                 <DateInput
//                   value={this.state.opened}
//                   onChange={opened => this.setState({opened})}
//                 />
//               </FormItem>{' '}
//             </Col>
//             <Col xs={12}>
//               {' '}
//               <FormItem label="Closed on">
//                 <DateInput
//                   value={this.state.closed}
//                   onChange={closed => this.setState({closed})}
//                 />
//               </FormItem>{' '}
//             </Col>
//             <Col xs={16} />
//           </Row>
//         </SectionBreak>
//         <SectionBreak
//           header="Additional Info"
//           body="Provide general information about this business."
//         >
//           <Row gutter={20}>
//             <Col xs={24}>
//               <FormItem
//                 label="What stage is this business?"
//                 hint={
//                   !this.state.businessStage
//                     ? 'Is it an existing business?'
//                     : null
//                 }
//               >
//                 <BusinessStagesSelect
//                   value={this.state.businessStage}
//                   onChange={businessStage => this.setState({businessStage})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={12}>
//               {' '}
//               <FormItem
//                 label="Business Status"
//                 hint="A prospect? Current? Former?"
//               >
//                 <BusinessStatusInput
//                   value={this.state.status}
//                   onChange={status => this.setState({status})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={12} />
//             <Col xs={24}>
//               <FormItem
//                 label="Naics code"
//                 hint="Search an apply as many NAICS codes as you see fit..."
//               >
//                 <NaicsInput
//                   value={this.state.naics}
//                   onChange={naics => this.setState({naics})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={24}>
//               <FormItem
//                 label="Clusters"
//                 hint="Clusters are an alternative categorization method to NAICS"
//               >
//                 <ClustersMultiselectInput
//                   value={this.state.clusters}
//                   onChange={clusters => this.setState({clusters})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={24}>
//               <FormItem
//                 label="Tags"
//                 hint="Apply your own custom tags created in the tag manager"
//               >
//                 <CustomTagsInput
//                   value={this.state.tagIds}
//                   onChange={tagIds => this.setState({tagIds})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={24}>
//               <FormItem
//                 label="Diversity tags"
//                 hint="Tag a business by diversity measure- helpful for filtering later on"
//               >
//                 <DiversityInput
//                   value={this.state.diversity}
//                   onChange={diversity => this.setState({diversity})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={24}>
//               <FormItem
//                 label="SupportTypeSelect"
//                 hint="Tag a business by diversity measure- helpful for filtering later on"
//               >
//                 <SupportTypeSelect
//                   value={this.state.serviceNeeds}
//                   onChange={serviceNeeds => this.setState({serviceNeeds})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={24}>
//               <FormItem
//                 label="FundingTypesSelect"
//                 hint="Tag a business by diversity measure- helpful for filtering later on"
//               >
//                 <FundingTypesSelect
//                   value={this.state.serviceNeeds}
//                   onChange={serviceNeeds => this.setState({serviceNeeds})}
//                 />
//               </FormItem>
//             </Col>
//           </Row>
//         </SectionBreak>

//         <Row justify="end" style={{marginTop: 110}}>
//           <div
//             style={{
//               width: 130,
//               marginRight: 10,
//             }}
//           >
//             <FormItem>
//               <Button grey style={{width: 130}} onClick={this.onCancel}>
//                 Cancel
//               </Button>
//             </FormItem>
//           </div>
//           <div
//             style={{
//               width: 170,
//               marginLeft: 10,
//             }}
//           >
//             <FormItem>
//               <Button disabled={this.props.loading} onClick={this.onSubmit}>
//                 {!this.props.loading ? 'Save Business' : '...'}
//               </Button>
//             </FormItem>
//           </div>
//         </Row>
//       </FormContainer>
//     );
//   }
// }

// BusinessForm.propTypes = {
//   mode: PropTypes.string, // either 'add' or 'save'... determines if we show intermediate "save buttons" in each form section
// };

// BusinessForm.defaultProps = {
//   mode: 'add',
//   showImages: true,
// };

// export default BusinessForm;
