// TOP LEVEL IMPORTS
import React from "react";
// ANTD
import Checkbox from "antd/lib/checkbox";
import Row from "components/common/Row";
import Col from "components/common/Col";
import FormLabel from "components/text/FormLabel";

// EXPORTED COMPONENT
// ===================================
export class CheckboxInput extends React.Component {
  render() {
    return (
      <div
        style={{
          display: "flex",
          width: this.props.width || 200,
          maxWidth: "100%",
        }}
      >
        <Checkbox
          style={{ marginRight: 8 }}
          {...this.props}
          size="large"
          checked={this.props.checked}
          onChange={(e) => this.props.onChange(e.target.checked)}
        />

        <FormLabel style={{ marginTop: 4 }}>{this.props.label}</FormLabel>
      </div>
    );
  }
}

export default CheckboxInput;
