import RecentProjects from "./RecentProjects";
import YourWorkTable from "./YourWorkTable";
import helpers from "lib/helpers/GeneralHelpers";
import { CustomerFeatureSettingKeyEnum } from "generated/graphql";
import styled from "styled-components";

const getRecentlyViewed = (currentUser) => {
  let key = `${currentUser.id}-recentlyViewed`;
  let currentValueString = window.localStorage.getItem(key) || "[]";
  let parsedvalue = JSON.parse(currentValueString);
  return parsedvalue;
};
const PageContainer = styled.div`
  display: flex;
  align-self: stretch !important;
  align-items: stretch !important;
  justify-content: space-between;
  word-break: break-word;
  overflow-y: hidden;
  flex-grow: 1;
  width: 100%;
  z-index: 1;
  justify-content: center;
`;
export default function AppYourWork({ currentUser, windowWidth }) {
  // let recentlyViewedIds = getRecentlyViewed(currentUser);
  return (
    <PageContainer>
      <div style={{ maxWidth: "100%" }}>
        {/* {helpers.featureIsOn({
          customer: currentUser.customer,
          feature: CustomerFeatureSettingKeyEnum.Projects,
        }) && <RecentProjects recentlyViewedIds={recentlyViewedIds} />} */}
        <YourWorkTable currentUser={currentUser} windowWidth={windowWidth} />
      </div>
    </PageContainer>
  );
}
