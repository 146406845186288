import { useState, useReducer, useEffect } from "react";
import queryString from "query-string";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
// COMPONENTS
import Button from "components/common/Button";
// import BulkUpload from '../BulkUpload';
// import Modal from 'components/common/Modal';
import TablePrimaryCol from "components/text/TablePrimaryCol";
import Tag from "components/common/Tag";
import BusinessLogo from "components/common/BusinessLogo";
import Table, {
  formatDateColumn,
  renderBusinessNameColumn,
  renderDefaultColumn,
  TableCol,
} from "components/common/Table";
import FiltersButton from "components/common/FiltersButton";
import Filters from "./Filters";
import { CloseOutlined, DownloadOutlined } from "@ant-design/icons";

// LIB
import helpers from "lib/helpers/GeneralHelpers";
import useDownloadCSV from "lib/hooks/useDownloadCSV";
import theme from "lib/theme";
import {
  Business,
  BusinessesDocument,
  BusinessesQuery,
  BusinessesQueryVariables,
  BusinessShortFragmentFragment,
  SortByInput,
  useBusinessesQuery,
} from "generated/graphql";
import { useApolloClient } from "@apollo/client";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import {
  PageContainer,
  MiddleContent,
  LeftCol,
} from "components/common/PageContainer";
import useUrlChange from "hooks/useUrlChange";
import ColumnSelectorModal from "components/common/ColumnSelectorModal";

const columns: TableCol[] = [
  {
    title: "Name",
    field: "title",
    sorter: true,
    render: renderBusinessNameColumn,
  },

  {
    title: "Created",
    field: "createdAt",
    dataIndex: "createdAt",
    sorter: true,
    render: formatDateColumn,
  },

  {
    title: "Street",
    field: "address1",
    dataIndex: "address1",
    render: renderDefaultColumn,
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    field: "status",
    sorter: true,
    render: renderDefaultColumn,
  },
  {
    title: "City",
    key: "city",
    field: "city",
    dataIndex: "city",
    render: renderDefaultColumn,
  },
  {
    title: "Country",
    key: "country",
    field: "country",
    dataIndex: "country",
    render: renderDefaultColumn,
  },
  {
    title: "Industry",
    key: "industry",
    field: "industry",
    dataIndex: "industry",
    render: renderDefaultColumn,
  },
  {
    title: "Employee Count",
    key: "employeeCount",
    dataIndex: "employeeCount",
    field: "employeeCount",
    sorter: true,
    render: renderDefaultColumn,
  },
  {
    title: "Website",
    key: "website",
    dataIndex: "website",
    field: "website",
    render: renderDefaultColumn,
  },
  {
    title: "Closed",
    dataIndex: "closed",
    key: "closed",
    field: "closed",
    render: formatDateColumn,
  },
  {
    title: "Opened",
    dataIndex: "opened",
    key: "opened",
    field: "opened",
    render: formatDateColumn,
  },
  {
    title: "Type",
    key: "businessType",
    dataIndex: "businessType",
    field: "businessType",
    sorter: true,
    render: renderDefaultColumn,
  },
  {
    title: "Tags",
    field: "tags",
    dataIndex: "tags",
    render: ({
      naics,
      clusters,
      tags,
      diversity,
      businessSector,
    }: Business) => (
      <div>
        {naics?.map((item) => (
          <Tag key={item.code} grey>
            {item.title} ({item.code})
          </Tag>
        ))}
        {clusters?.map((item) => (
          <Tag key={item} grey>
            {item}
          </Tag>
        ))}
        {diversity?.map((item) => (
          <Tag key={item} grey>
            {helpers.getDiversityLabel(item)}
          </Tag>
        ))}
        {businessSector && (
          <Tag key={businessSector?.id} grey>
            {businessSector?.title}
          </Tag>
        )}
        {tags?.map((tag) => <Tag key={tag?.id}>{tag?.title}</Tag>)}
      </div>
    ),
  },
];

const MOBILE_COLS: TableCol[] = [
  {
    title: "Name",
    key: "id",
    field: "title",
    dataIndex: "title",
    // render: ({ title, logo, id }) => {
    //   return (
    //     <NameContainer key={id} data-testid={`business-table-${title}`}>
    //       <div style={{ marginRight: 6 }}>
    //         <BusinessLogo
    //           key={id}
    //           src={logo && logo.url}
    //           alt={logo && logo.filename}
    //           width="32"
    //         />
    //       </div>
    //       <TablePrimaryCol>{title}</TablePrimaryCol>
    //     </NameContainer>
    //   );
    // },
    render: renderBusinessNameColumn,
  },
];

const TopRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: flex-end;
`;

export const FILTER_ACTIONS = {
  DIVERSITY_TYPES: "DIVERSITY_TYPES",
  BUSINESS_STAGES: "BUSINESS_STAGES",
  CLUSTERS: "CLUSTERS",
  NAICS: "NAICS",
  SECTORS: "SECTORS",
  TAGS: "TAGS",
  STATUSES: "STATUSES",
};

const filterReducer = (state, action) => {
  // Assuming all actions update the state in the same manner for now,
  // but structured to allow easy addition of unique action handling.
  switch (action.type) {
    case FILTER_ACTIONS.DIVERSITY_TYPES:
    case FILTER_ACTIONS.BUSINESS_STAGES:
    case FILTER_ACTIONS.CLUSTERS:
    case FILTER_ACTIONS.NAICS:
    case FILTER_ACTIONS.STATUSES:
      // If you want to perform any specific logic for an action, do it here.
      return { ...state, ...action.payload };
    default:
      // Handle unknown actions or default case
      return state;
  }
};
const DEFAULT_FILTERS = {
  diversity: undefined,
  businessStages: undefined,
  clusters: undefined,
  tagIds: undefined,
  statuses: [], // DEFAULT_FILTERS
  opened: undefined,
  closed: undefined,
  naicsIds: undefined,
  businessSectorIds: undefined,
};

const CloseIcon = styled(CloseOutlined)`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  color: ${(p) => p.theme.colors.neutral6};
`;

const cleanDataForCSV = (businesses: BusinessShortFragmentFragment[]) => {
  return businesses.map((business) => {
    return {
      ID: business.id,
      Name: business.title,
      Description: business.description,
      "Business Sector": business?.businessSector?.title,
      Phone: business.phone,
      Fax: business.fax,
      Industry: business.industry,
      Website: business.website,
      Twitter: business.twitter,
      Instagram: business.instagram,
      LinkedIn: business.linkedin,
      Facebook: business.facebook,
      "Primary Contact Email": business?.primaryContact?.email,
      "Primary Contact First Name": business?.primaryContact?.firstName,
      "Primary Contact Last Name": business?.primaryContact?.lastName,
      // needs: business?.serviceNeeds?.map((need) => need.title),
      // industries: business?.industries?.map((industry) => industry),
      clusters: business?.clusters?.map((cluster) => cluster),
      naics: business?.naics?.map((naic) => naic.code),
      Classification: business?.diversity?.map((diversityItem) =>
        helpers.getDiversityLabel(diversityItem)
      ),
      street: business.street,
      postal: business.postal,
      city: business.city,
      state: business.state,
      country: business.country,
      lat: business.lat,
      lng: business.lng,
      "Employee Count": business.employeeCount,
      "Business Stage": business.businessStage,
      tags: business?.tags?.map((tag) => tag.title),
    };
  });
};

const DEFAULT_SELECTED_COLUMNS = [
  { field: "title", label: "Name" },
  { field: "createdAt", label: "Created" },
  { field: "address1", label: "Street" },
];

// [
//   { field: "title", label: "Name" },
//   { field: "createdAt", label: "Created At" },
//   { field: "optResult", label: "Opt Result" },
//   { field: "tradeName", label: "Trade Name" },
//   { field: "businessSector", label: "Business Sector" },
//   { field: "logo", label: "Logo" },
//   { field: "description", label: "Description" },
//   { field: "phone", label: "Phone" },
//   { field: "fax", label: "Fax" },
//   { field: "industry", label: "Industry" },
//   { field: "website", label: "Website" },
//   { field: "twitter", label: "Twitter" },
//   { field: "instagram", label: "Instagram" },
//   { field: "linkedin", label: "LinkedIn" },
//   { field: "facebook", label: "Facebook" },
//   { field: "numberOfRetailLocations", label: "Retail Locations" },
//   { field: "serviceNeeds", label: "Service Needs" },
//   { field: "industries", label: "Industries" },
//   { field: "clusters", label: "Clusters" },
//   { field: "surveyPermission", label: "Survey Permission" },
//   { field: "naics", label: "NAICS Codes" },
//   { field: "diversity", label: "Diversity" },
//   { field: "amenities", label: "Amenities" },
//   { field: "employeeCount", label: "Employee Count" },
//   { field: "tags", label: "Tags" },
//   { field: "businessStage", label: "Business Stage" },
//   { field: "businessType", label: "Business Type" },
//   { field: "primaryContact", label: "Primary Contact" },
//   { field: "status", label: "Status" },
//   { field: "opened", label: "Opened On" },
//   { field: "closed", label: "Closed On" },
//   { field: "annualRevenue", label: "Annual Revenue" },
//   { field: "fullAddress", label: "Full Address" },
//   { field: "street", label: "Street" },
//   { field: "street2", label: "Street 2" },
//   { field: "postal", label: "Postal Code" },
//   { field: "country", label: "Country" },
//   { field: "state", label: "State" },
//   { field: "city", label: "City" },
//   // Add any other fields from your GraphQL type that you want to display in the table.
// ];

export default function BusinessesTable({ currentUser, windowWidth }) {
  const client = useApolloClient();
  const [downloading, setDownloading] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState(
    DEFAULT_SELECTED_COLUMNS
  );
  const navigate = useNavigate();
  const location = useLocation();
  const [showFilters, setShowFilters] = useState(false);
  const [filters, onChangeFilters] = useReducer(filterReducer, DEFAULT_FILTERS);
  const { query } = useUrlChange();
  const [sortParams, setSortParams] = useState<SortByInput[] | undefined>(
    undefined
  );
  const [downloadFile, downloadingCSV] = useDownloadCSV();
  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();

  const businessQueryParams = {
    statuses: filters.statuses,
    diversity: filters.diversity,
    businessStages: filters.businessStages,
    clusters: filters.clusters,
    naicsIds: filters.naicsIds,
    businessSectorIds: filters.businessSectorIds,
    tagIds: filters?.tagIds,
    searchText: query?.searchText as string,
    opened:
      filters?.start && filters?.end
        ? ([filters?.start, filters?.end] as string[])
        : [],
    closed:
      filters?.startClosed && filters?.endClosed
        ? ([filters?.startClosed, filters?.endClosed] as string[])
        : [],
  };

  useEffect(() => {
    resetTableParamsToDefault();
  }, []);

  const { data, loading } = useBusinessesQuery({
    variables: {
      params: businessQueryParams,
      limit,
      skip,
      sort: sortParams?.[0] ? sortParams : undefined,
    },
    fetchPolicy: "cache-and-network",
  });

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => {
        if (record.id) {
          const url = `/app/businesses/${record.id}`;

          navigate(url);
        }
      }, // click row
      onDoubleClick: (event) => {}, // double click row
      onContextMenu: (event) => {}, // right button click row
      onMouseEnter: (event) => {}, // mouse enter row
      onMouseLeave: (event) => {}, // mouse leave row
    };
  };

  let currentParams = queryString.parse(location.search);

  // for some reason parsing an array with a single string turns it into a single value instead of an array
  if (typeof currentParams.statuses === "string") {
    currentParams.statuses = [currentParams.statuses];
  }
  if (typeof currentParams.tagIds === "string") {
    currentParams.tagIds = [currentParams.tagIds];
  }
  if (typeof currentParams.clusters === "string") {
    currentParams.clusters = [currentParams.clusters];
  }

  const onDownloadBusinesses = async () => {
    try {
      setDownloading(true);
      let result = await client.query<
        BusinessesQuery,
        BusinessesQueryVariables
      >({
        query: BusinessesDocument,
        variables: {
          params: businessQueryParams,
          limit: 5000,
        },
      });
      if (result.data.businesses?.businesses?.[0]) {
        downloadFile(
          cleanDataForCSV(result.data.businesses?.businesses),
          "businesses.csv"
        );
      }

      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.log(err);
    }
  };

  const isMobile = parseInt(theme.breakpoints.medium) > windowWidth;
  const cols = !isMobile ? columns : MOBILE_COLS;

  return (
    <PageContainer>
      {showFilters && (
        <LeftCol>
          <CloseIcon onClick={() => setShowFilters(!showFilters)} />
          <Filters filters={filters} onChangeFilters={onChangeFilters} />
        </LeftCol>
      )}
      <MiddleContent>
        <TopRow>
          {/* <div style={{width: 400, marginRight: 16}}>
            <BusinessSearchInput />
          </div> */}
          {!isMobile && (
            <>
              <ColumnSelectorModal
                options={columns?.map((item) => {
                  return {
                    field: item?.field,
                    label: item?.title,
                  };
                })}
                selectedColumns={selectedColumns}
                onChange={(newColumns) => setSelectedColumns(newColumns)}
                buttonStyle={{ marginRight: 8 }}
              />

              <FiltersButton
                onClick={() => setShowFilters(!showFilters)}
                style={{ marginRight: 16 }}
                text={showFilters ? "Close" : "Filters"}
              />
              {/* {helpers.hasPermission(currentUser, [
                UserPermissionEnum.Administrator,
                UserPermissionEnum.Member,
              ]) && (
                <Button
                  onClick={() =>
                    onUrlChange({ drawerVisible: formTypes.BUSINESSES })
                  }
                  data-testid="business-new-business-btn"
                >
                  + New Business
                </Button>
              )} */}
            </>
          )}
        </TopRow>
        <div>
          <Table
            dataSource={
              (data && data.businesses && data.businesses.businesses) || []
            }
            scroll={{ x: "max-content" }}
            pagination={{
              pageSize: limit,
              total: data && data.businesses ? data.businesses.count : 0,
              onChange: (page, pageSize) => {
                onTablePaginationChange(page, pageSize);
              },
              showSizeChanger: true,
              current: currentPage,
            }}
            loading={loading}
            columns={cols?.filter(
              (col) => selectedColumns?.some((c) => c.label === col.title)
            )}
            onSortChange={(newSortParams) => setSortParams(newSortParams)}
            rowKey={(record) => record.id}
            onRow={onRow}
          />
        </div>
        {!loading && !isMobile && (
          <Button
            onClick={onDownloadBusinesses}
            disabled={downloading || downloadingCSV}
            loading={downloading || downloadingCSV}
            style={{ position: "relative", top: -35, width: 220, height: 48 }}
            grey
          >
            <DownloadOutlined /> Download CSV
          </Button>
        )}
      </MiddleContent>
      {/* <Modal visible={true} footer={null}>
        <BulkUpload
          onComplete={() => console.log('test')}
          onCancel={() => console.log('test')}
        />
      </Modal> */}
    </PageContainer>
  );
}

// import React from 'react';
// // COMPONENTS
// import BusinessesTable from './BusinessesTable';

// export default function BusinessTab({location, navigate currentUser}) {
//   return <BusinessesTable currentUser={currentUser} />;
// }
