// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import todoFragment from "ApolloClient/Fragments/todoFragment";

export default gql`
  query todos(
    $businessId: ID
    $contactId: ID
    $done: Boolean
    $assignedToId: ID
    $projectId: ID
    $caseId: ID
    $siteId: ID
    $limit: Int
    $skip: Int
  ) {
    todos(
      businessId: $businessId
      contactId: $contactId
      done: $done
      siteId: $siteId
      caseId: $caseId
      assignedToId: $assignedToId
      projectId: $projectId
      limit: $limit
      skip: $skip
    ) {
      count
      todos {
        ...todoFragment
        project {
          id
          title
          image {
            id
            url
          }
        }
      }
    }
  }
  ${todoFragment}
`;
