import React from 'react';
import styled from 'styled-components';
// COMPONENTS
import ClustersMultiselectInput from 'components/inputs/ClustersMultiselectInput';
import SupportTypeSelect from 'components/inputs/SupportTypeSelect';
import BusinessStagesMultiSelect from 'components/inputs/BusinessStagesMultiSelect';
import DiversityInput from 'components/inputs/DiversityInput';
import OrganizationInput from 'components/inputs/OrganizationInput';
import FormItem from 'components/common/FormItem';
import {FILTER_ACTIONS} from '../index';

const Container = styled.div`
  padding: 8px;
`;
// DIVERSITY_TYPES: 'DIVERSITY_TYPES',
// SUPPORT_TYPES: 'SUPPORT_TYPES',
// BUSINESS_STAGES: 'BUSINESS_STAGES',
export default function FiltersContent({filters, onChangeFilters}) {
  return (
    <Container>
      <h2>Filters</h2>
      <FormItem label="Diversity Types">
        <DiversityInput
          value={filters.diversityTypes || []}
          onChange={(diversityTypes) =>
            onChangeFilters({
              type: FILTER_ACTIONS.DIVERSITY_TYPES,
              payload: {diversityTypes},
            })
          }
        />
      </FormItem>
      <FormItem label="Business Stages">
        <BusinessStagesMultiSelect
          value={filters.businessStages || []}
          onChange={(businessStages) =>
            onChangeFilters({
              type: FILTER_ACTIONS.BUSINESS_STAGES,
              payload: {businessStages},
            })
          }
        />
      </FormItem>
    </Container>
  );
}
