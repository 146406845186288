import React from "react";
import styled from "styled-components";
// COMPONENTS
import { DeleteOutlined } from "@ant-design/icons";
import Popconfirm from "components/common/Popconfirm";

// todoId={this.props.todo.id}
// attachments={this.state.todo.attachments}
// onSave={this.onSave}

const Label = styled.div`
  width: 118px;
  margin-left: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: ${(p) => p.theme.colors.neutral2};
`;

const ItemContainer = styled.div`
  display: flex;
  margin-top: 8px;
  height: 24px;
  align-items: center;
  .hover-delete {
    display: none;
  }
  &:hover {
    .hover-delete {
      display: inherit;
    }
  }
`;

const DeleteBtn = styled.button`
  background: transparent;
  border-radius: 5px;
  border: 1px solid #fff;
  color: ${(p) => p.theme.colors.neutral6} !important;
  cursor: pointer;

  &:hover {
    border: 1px solid ${(p) => p.theme.colors.neutral10};
    color: ${(p) => p.theme.colors.neutral5} !important;
  }
  &:focus {
    outline: 0;
  }
`;

const AttachmentItem = ({ attachment, onRemove }) => {
  const getTitle = () => {
    return attachment?.filename?.length > 30
      ? `${attachment?.filename?.slice(0, 30)}...`
      : attachment?.filename;
  };
  return (
    <ItemContainer>
      <div style={{ marginRight: 16 }}>
        <a href={attachment.url}>{getTitle()}</a>
      </div>
      <div>
        <div className="hover-delete">
          <Popconfirm
            title={`Are you sure you want to delete "${getTitle()}"?`}
            okText="Yes"
            onConfirm={onRemove}
          >
            <DeleteBtn>
              <DeleteOutlined />
            </DeleteBtn>
          </Popconfirm>
        </div>
      </div>
    </ItemContainer>
  );
};

export default function AttachmentsList({ attachments, onSave }) {
  const onRemove = (attachment) => {
    onSave({
      attachments: attachments.filter((item) => item.id !== attachment.id),
    });
  };

  if (!attachments || attachments.length === 0) {
    return null;
  }

  return (
    <>
      <Label>Atttachments</Label>
      <div style={{ paddingLeft: 8 }}>
        {attachments.map((attachment) => (
          <AttachmentItem
            key={attachment.id}
            attachment={attachment}
            onRemove={() => onRemove(attachment)}
          />
        ))}
      </div>
    </>
  );
}
