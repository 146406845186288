// import { Row } from 'react-styled-flexboxgrid';

// export default Row;

import Row from 'antd/lib/row';
import 'antd/lib/row/style/css';

Row.defaultProps = {
  type: 'flex',
  align: 'middle',
  justify: 'center',
};

export default Row;
