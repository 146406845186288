import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import numeral from "numeral";
// COMPONENTS
import Row from "components/common/Row";
import Col from "components/common/Col";
import Tag from "components/common/Tag";
import Tooltip from "components/common/Tooltip";
import InfoCircleFilled from "components/icons/InfoCircleFilled";
import { EditOutlined } from "@ant-design/icons";
import CustomField from "components/common/CustomField";
import checkSVG from "./sm-check.svg";
import cancelSVG from "./sm-x.svg";
// LIB
import helpers from "lib/helpers/GeneralHelpers";
import theme from "lib/theme";
import { toString } from "lodash";
import { SiteExtraFieldTypeEnum } from "generated/graphql";

const TabWrapper = styled.div`
  top: -33px;
  left: 35%;
  position: absolute;
  .ant-tabs-nav .ant-tabs-tab {
    font-weight: 600;
    font-size: 16px;
  }
`;
const TabText = styled.div<{ active?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-bottom: 7px;
  color: ${(p) =>
    p.active ? p.theme.colors.neutral1 : p.theme.colors.neutral4};
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
`;

const Activebar = styled.div`
  height: 3px;
  background: ${(p) => p.theme.colors.primary5};
  width: 75%;
  margin: auto;
`;

const Tab = ({ label, active, onClick }) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }}>
      <TabText active={active}>{label}</TabText>
      {active ? <Activebar /> : <div style={{ height: 3 }} />}
    </div>
  );
};

const DEFAULT_TABS = [
  {
    label: "Timeline",
    id: "1",
  },
  {
    label: "Notes",
    id: "2",
  },
  {
    label: "Tasks",
    id: "3",
  },
  {
    label: "Files",
    id: "4",
  },
];

export const Tabs = ({ active, onClick, tabs = DEFAULT_TABS }) => {
  return (
    <TabWrapper>
      <Row style={{ width: 500 }}>
        {tabs.map((item) => (
          <Col xs={6} key={item.id}>
            <Tab
              onClick={() => onClick(item.id)}
              label={item.label}
              active={active === item.id}
            />
          </Col>
        ))}
      </Row>
    </TabWrapper>
  );
};

export const ProjectTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 22px;
`;

const ItemLabel = styled.label`
  margin: 0px;
  margin-bottom: 4px;
  font-weight: 600;
  font-size: 13px;
  position: relative;
  color: ${(p) => p.theme.colors.neutral5};
`;

const ItemValue = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral1};
  font-weight: 500;
  font-size: 15px;
`;

const DetailItemContainer = styled.div<{ editable?: boolean }>`
  .anticon {
    display: none;
  }
  padding: 4px;
  &:hover {
    background: ${(p) => (p.editable ? p.theme.colors.neutral10 : null)};
    cursor: ${(p) => (p.editable ? "text" : "inherit")};
    i {
      display: ${(p) => (p.editable ? "inline" : "none")};
    }
  }
`;

const SaveBtn = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: -32px;
  background: ${(p) => p.theme.colors.neutral10};
  border: 0px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${(p) => p.theme.colors.neutral9};
  }
`;

const CancelBtn = styled(SaveBtn)`
  right: 40px;
`;

interface DetailItemProps {
  label?: any;
  value?: any;
  editable?: boolean;
  fieldType?: DetailitemFieldTypeEnum | SiteExtraFieldTypeEnum;
  fieldName?: string;
  onSave?: any;
  displayValue?: any;
  tooltip?: any;
}

export enum DetailitemFieldTypeEnum {
  naics = "naics",
  tags = "tags",
  date = "date",
  number = "number",
  diversity = "diversity",
  currency = "currency",
  textarea = "textarea",
  text = "text",
  clusters = "clusters",
  businessSector = "businessSector",
  texteditor = "texteditor",
  contact = "contact",
  siteType = "siteType",
  fullName = "fullName",
  /** will be user/growlab team member select dropdown */
  team = "team",
  phone = "phone",
  businessStatus = "businessStatus",
}

const EMPTY_STRING = "n/a";

export default function DetailItem({
  label,
  value,
  editable,
  fieldType,
  fieldName,
  onSave,
  displayValue,
  tooltip,
}: DetailItemProps) {
  const [editing, setEditing] = useState(false);
  const [localValue, setLocalValue] = useState(value); // this is the actual value we want to pass into the input

  // cleanValue is the value we display when NOT in edit mode
  let cleanValue = value || EMPTY_STRING;

  if (fieldType === DetailitemFieldTypeEnum.naics) {
    cleanValue = typeof value === "string" ? JSON.parse(value) : value;
  }

  // // for naics field type, it requires some extra work
  // if (fieldType === DetailitemFieldTypeEnum.tags) {
  //   //let obj = value && value.length > 0 ? JSON.parse(value) : [];
  //   let values = value.map((item) => <Tag key={item.title}>{item.title}</Tag>);
  //   cleanValue = values;
  // }

  // for date types, we need to pass the input a moment object
  if (fieldType === DetailitemFieldTypeEnum.date && value) {
    cleanValue = moment(parseInt(value)).format("MM/DD/YYYY");
  }

  // for number inputs, we need to make sure it's a number
  if (fieldType === DetailitemFieldTypeEnum.number) {
    cleanValue = numeral(parseInt(value)).format("0,0");
  }

  if (fieldType === DetailitemFieldTypeEnum.diversity) {
    cleanValue =
      value && value.length > 0
        ? value.map((item) => (
            <Tag key={item}>{helpers.getDiversityLabel(item)}</Tag>
          ))
        : EMPTY_STRING;
  }

  if (fieldType === DetailitemFieldTypeEnum.currency) {
    cleanValue = `$${numeral(parseInt(value)).format("0,0")}`;
  }

  if (fieldType === DetailitemFieldTypeEnum.textarea) {
    const TRUNCATE_AT = 150;
    cleanValue =
      cleanValue?.length > TRUNCATE_AT
        ? `${cleanValue.slice(0, TRUNCATE_AT)}...`
        : cleanValue;
  }

  const onSaveChange = () => {
    onSave({ [toString(fieldName)]: localValue });
    setEditing(false);
  };

  const onCancel = () => {
    setLocalValue(value);
    setEditing(false);
  };

  if (editing) {
    return (
      <div style={{ minHeight: 43, marginTop: 24, position: "relative" }}>
        <ItemLabel>{label}</ItemLabel>
        <CustomField
          key={fieldName}
          value={localValue}
          fieldType={fieldType}
          onChange={(value) => setLocalValue(value)}
          style={{ backgroundColor: "#fff" }}
        />
        <CancelBtn onClick={onCancel}>
          <img src={cancelSVG} alt="cancel" height="24" />
        </CancelBtn>
        <SaveBtn
          onClick={onSaveChange}
          data-testid="editable-detail-item-save-btn"
        >
          <img src={checkSVG} alt="save" height="24" />
        </SaveBtn>
      </div>
    );
  }

  return (
    <DetailItemContainer
      editable={editable}
      data-testid={`detail-item-container-${fieldType}`}
      onClick={() => {
        if (editable) {
          setEditing(true);
        }
      }}
      style={{ marginTop: 24 }}
    >
      <ItemLabel>
        {label}{" "}
        {tooltip && (
          <Tooltip title={tooltip}>
            <InfoCircleFilled
              height={16}
              width={16}
              fill={theme.colors.neutral7}
            />
          </Tooltip>
        )}
        <EditOutlined style={{ marginLeft: 8 }} />
      </ItemLabel>
      {fieldType === DetailitemFieldTypeEnum.texteditor && (
        <ItemValue
          className="mt-1 max-w-prose text-sm text-gray-900 space-y-5"
          dangerouslySetInnerHTML={{ __html: cleanValue }}
        />
      )}
      {fieldType !== DetailitemFieldTypeEnum.texteditor && (
        <ItemValue data-testid={`detail-item-container-value-${label}`}>
          {displayValue || cleanValue}
        </ItemValue>
      )}
    </DetailItemContainer>
  );
}
