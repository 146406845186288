import { useState, useReducer } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import supportTypes from "lib/constants/supportTypes";
// COMPONENTS
import Button from "components/common/Button";
import Tag from "components/common/Tag";
import EditProgram from "./EditProgram";
import FiltersContent from "./FiltersContent";
import message from "components/common/message";
import Table from "components/common/Table";
import Drawer from "components/common/Drawer";
import PageHeader from "components/text/PageHeader";
import Popconfirm from "components/common/Popconfirm";
import FiltersButton from "components/common/FiltersButton";
import ProgramForm from "../../../../components/forms/ProgramForm";
import {
  useDeleteResourceMutation,
  useProgramsQuery,
  useSaveProgramMutation,
} from "generated/graphql";

const TopRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  align-items: center;
`;

const FormContainer = styled.div`
  background: #fff;
  padding: 16px;
  width: 550px;
  max-width: 100%;
  padding-top: 0px;
`;

const Title = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.neutral1};
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
`;

const Text = styled(Title)`
  color: ${(p) => p.theme.colors.neutral4};
  font-weight: 400;
`;

export const FILTER_ACTIONS = {
  DIVERSITY_TYPES: "DIVERSITY_TYPES",
  SUPPORT_TYPES: "SUPPORT_TYPES",
  BUSINESS_STAGES: "BUSINESS_STAGES",
};

const filterReducer = (state, action) => {
  switch (action.type) {
    case FILTER_ACTIONS.DIVERSITY_TYPES:
      return { ...state, ...action.payload };
    case FILTER_ACTIONS.SUPPORT_TYPES:
      return { ...state, ...action.payload };
    case FILTER_ACTIONS.BUSINESS_STAGES:
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const DEFAULT_FILTERS = {
  diversityTypes: undefined,
  supportTypes: undefined,
  businessStages: undefined,
};

export default function ProgramsTab() {
  const [state, setState] = useState({
    searchText: null,
    finalSearchText: null,
    eventForm: false,
    addNew: false,
  });
  const [filters, onChangeFilters] = useReducer(filterReducer, DEFAULT_FILTERS);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [editId, setEditId] = useState(false);
  const [saveProgram, { loading: creating }] = useSaveProgramMutation();
  const [deleteResource, { loading: deleting }] = useDeleteResourceMutation();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [current, setCurrent] = useState(1);
  const { data, loading } = useProgramsQuery({
    variables: {
      searchText: state.finalSearchText,
      diversityTypes: filters.diversityTypes,
      supportTypes: filters.supportTypes,
      businessStages: filters.businessStages,
      skip,
      limit,
    },
  });

  const closeDrawer = () => {
    setState({ ...state, addNew: false });
    setEditId(false);
  };

  const handleCreateNew = async (values) => {
    try {
      await saveProgram({
        variables: {
          params: {
            title: values.title,
            description: values.description,
            organizationId: values.organizationId,
            businessStages: values.businessStages,
            clusters: values.clusters,
            supportType: values.supportType,
            diversityTypes: values.diversityTypes,
            tagIds: values.tagIds,
          },
        },
        refetchQueries: ["programs"],
      });
      closeDrawer();
      message.success(`New support program created!`);
    } catch (err) {}
  };

  const handleDelete = async (programId) => {
    try {
      if (deleting) return null; // dont rerun mutation if were in the process of deleting
      await deleteResource({
        variables: {
          id: programId,
        },
        refetchQueries: ["programs"],
      });
      message.success(`Program deleted`);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "title",
      key: "id",
      render: ({ title }) => <div>{title}</div>,
    },
    {
      title: "Organization",
      render: ({ organization }) => {
        if (!organization) return null;
        return (
          <Link
            id="organization-click"
            target="_blank"
            to={`/app/organizations/${organization.id}`}
          >
            {organization.title}
          </Link>
        );
      },
    },
    {
      title: "Type",
      render: ({ supportType }) => (
        <div>
          {supportType &&
            supportTypes.filter((item) => item.value === supportType)[0].label}
        </div>
      ),
    },
    {
      title: "Diversity",
      render: ({ diversityTypes }) =>
        diversityTypes &&
        diversityTypes.length > 0 && (
          <div>
            {diversityTypes.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </div>
        ),
    },
    {
      title: "Stages",
      render: ({ businessStages }) =>
        businessStages &&
        businessStages.length > 0 && (
          <div>
            {businessStages.map((tag) => (
              <Tag key={tag}>{tag}</Tag>
            ))}
          </div>
        ),
    },
    {
      title: "",
      width: "7.5%",
      render: (record) => (
        <Text onClick={() => setEditId(record.id)}>Edit</Text>
      ),
    },
    {
      title: "",
      width: "7.5%",
      render: (record) => (
        <Popconfirm
          title="Are you sure you want to delete this program?"
          okText="Yes"
          onConfirm={() => handleDelete(record.id)}
        >
          <Text>{!deleting ? "Delete" : "..."}</Text>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <TopRow>
        <PageHeader style={{ margin: 0 }}>Support Programs</PageHeader>
        <div style={{ justifyContent: "flex-end", display: "flex" }}>
          <FiltersButton onClick={() => setFiltersVisible(true)} />
          <Button
            style={{ marginLeft: 8 }}
            onClick={() => setState({ ...state, addNew: true })}
          >
            + New Program
          </Button>
        </div>
      </TopRow>
      <Table
        dataSource={data?.programs?.programs || []}
        columns={columns}
        loading={loading}
        rowKey={(record) => record.id}
        onChange={(page: number, pageSize: number) => {
          setLimit(pageSize);
          setSkip(pageSize * (page - 1));
          setCurrent(page);
        }}
        pagination={{
          total: data?.programs?.count || 0,
          pageSize: limit,
          current,
          showSizeChanger: true,
        }}
      />
      <Drawer
        onClose={closeDrawer}
        width="40%"
        title={!editId ? "Add New Program" : "Edit Program"}
        visible={editId || state.addNew}
      >
        {editId && <EditProgram programId={editId} onClose={closeDrawer} />}
        {state.addNew && (
          <FormContainer>
            <ProgramForm
              onSubmit={handleCreateNew}
              loading={creating}
              onCancel={closeDrawer}
            />
          </FormContainer>
        )}
      </Drawer>
      <Drawer
        width="17%"
        mask={false}
        placement="left"
        onClose={() => setFiltersVisible(false)}
        visible={filtersVisible}
      >
        <FiltersContent filters={filters} onChangeFilters={onChangeFilters} />
      </Drawer>
    </div>
  );
}

// import React from 'react';
// import NewProgramForm from './NewProgramForm';
// import ResourcesTable from '../ResourceTable';
// import columns from './columns';
// import programsQuery from 'ApolloClient/Queries/programs';
// import Filters from './Filters';

// class ProgramsTab extends React.PureComponent {
//   state = {
//     addNew: false,
//     supportTypes: [],
//     businessStages: [],
//     diversityTypes: [],
//   };
//   onRow = (record, rowIndex) => {
//     return {
//       onClick: e => {
//         let element = e.srcElement || e.target;
//         // only cancel if the user clicked the Link to business detail page
//         if (element.id === 'organization-click') {
//           return null;
//         }
//         this.props.navigate(`/app/support/${record.id}`);
//       },
//       onDoubleClick: event => {}, // double click e
//       onContextMenu: event => {}, // right button click row
//       onMouseEnter: event => {}, // mouse enter row
//       onMouseLeave: event => {}, // mouse leave row
//     };
//   };
//   render() {
//     let variables = {
//       supportTypes: this.state.supportTypes,
//       businessStages: this.state.businessStages,
//       diversityTypes: this.state.diversityTypes,
//     };

//     if (this.state.addNew) {
//       return (
//         <div>
//           <NewProgramForm onCancel={() => this.setState({addNew: null})} />
//         </div>
//       );
//     }
//     return (
//       <div>
//         <ResourcesTable
//           columns={columns}
//           onRow={this.onRow}
//           pagination={{pageSize: 5}}
//           filters={<Filters />}
//           query={programsQuery}
//           variables={variables}
//           dataField="programs"
//           dataName="Program"
//           onNew={() => this.setState({addNew: true})}
//         />
//       </div>
//     );
//   }
// }

// export default ProgramsTab;
