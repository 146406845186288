import styled from "styled-components";
// COMMON
import GeneralHelpers from "lib/helpers/GeneralHelpers";
import StatsBoard from "./StatsBoard";
import OrgActivity from "./OrgActivity";
import {
  PageContainer,
  MiddleContent,
  RightCol,
} from "components/detail-components";
import {
  CurrentUserFragmentFragment,
  UserPermissionEnum,
} from "generated/graphql";
// LIB

export const SectionHeader = styled.h1`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
  font-size: 20px;
  margin-top: 16px;
  margin-bottom: 8px;
`;

export default function AppDashboard({
  currentUser,
}: {
  currentUser: CurrentUserFragmentFragment;
}) {
  return (
    <PageContainer style={{ minHeight: "93vh" }}>
      <MiddleContent>
        <StatsBoard customer={currentUser.customer} />
      </MiddleContent>
      {GeneralHelpers.hasPermission(currentUser, [
        UserPermissionEnum.Administrator,
      ]) && (
        <RightCol>
          <OrgActivity />
        </RightCol>
      )}
    </PageContainer>
  );
}
