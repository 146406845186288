// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  query naicsSearch($searchText: String) {
    naicsSearch(searchText: $searchText) {
      id
      title
      code
    }
  }
`;
