const config = {
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  NODE_ENV: process.env.NODE_ENV,
  appName: "Growlab",
  FULLSTORY_ORG: "o-1HTW6G-na1",
  SITE_URL: window.location.origin,
  GRAPHQL_API: process.env.REACT_APP_API_HOST || process.env.GRAPHQL_API,
  authTokenName: process.env.AUTH_TOKEN_NAME || "growlab_access_token",
  refreshTokenName: process.env.REFRESH_TOKEN_NAME || "growlab_refresh_token",
};

console.log({
  config,
});

export default config;
