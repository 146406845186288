export default function removeTypename(obj) {
  if (Array.isArray(obj)) {
    return obj.map(removeTypename); // recursively remove __typename from each element in the array
  } else if (typeof obj === "object" && obj !== null) {
    const newObj = {};
    for (const [key, value] of Object.entries(obj)) {
      if (key !== "__typename") {
        newObj[key] = removeTypename(value); // recursively remove __typename from the object value
      }
    }
    return newObj;
  } else {
    return obj; // base case: return non-object values (e.g. string, number)
  }
}
