import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
// COMPONENTS
import TablePrimaryCol from "components/text/TablePrimaryCol";
import UserFilled from "components/icons/UserFilled";
// LIB
import theme from "lib/theme";

const DarkLink = styled(Link)`
  width: 175px;
  height: 18px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  color: ${(p) => p.theme.colors.neutral3} !important;
  display: block;
`;

const Title = styled.div`
  height: 16px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  color: ${(p) => p.theme.colors.neutral5};
`;

const EmptyLogo = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.neutral10};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 5px;
  border: 1px solid ${(p) => p.theme.colors.neutral10};
`;

const BusinessItem = ({ business }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 8 }}>
      {business && business.logo && business.logo.url && (
        <div style={{ marginRight: 5 }}>
          <Avatar src={business && business.logo && business.logo.url} />
        </div>
      )}

      {business && (
        <DarkLink id="business-click" to={`/app/businesses/${business.id}`}>
          {business.title}
        </DarkLink>
      )}
    </div>
  );
};

const columns = [
  {
    title: "Name",
    key: "id",

    render: ({ firstName, avatar, id, lastName, title }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 5 }}>
          {avatar && avatar.url ? (
            <Avatar
              key={id}
              src={avatar.url}
              alt={avatar.filename}
              width="32"
            />
          ) : (
            <EmptyLogo>
              <UserFilled height={24} width={24} fill={theme.colors.neutral7} />
            </EmptyLogo>
          )}
        </div>
        <div>
          <TablePrimaryCol
            data-testid={`contact-table-col-name-${firstName}-${lastName}`}
          >
            {firstName} {lastName}
          </TablePrimaryCol>
          {title && <Title>{title}</Title>}
        </div>
      </div>
    ),
  },
  {
    title: "Email",

    render: ({ email }) => email,
  },
  {
    title: "Phone",

    render: ({ workPhone, cellPhone, homePhone }) => {
      const phone = workPhone || cellPhone || homePhone; 

      return phone && (
        <a href={`tel:+${phone.replace(/-/g, "")}`}>{phone}</a>
      )
    }
  },
  {
    title: "Organization",

    render: ({ businesses }) => {
      if (businesses) {
        return businesses.map((business) => {
          return <BusinessItem key={business.id} business={business} />;
        });
      }
    },
  },
];

export default columns;
