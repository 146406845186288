import {
  CaseTemplateFragmentFragment,
  useCreateOneCaseTemplateMutation,
  useDeleteOneCaseTemplateMutation,
  useGetManyCaseTemplatesQuery,
} from "generated/graphql";
import DocumentTitle from "react-document-title";
import constants from "lib/constants";
import Table from "components/common/Table";
import Button from "components/common/Button";
import styled from "styled-components";
import { MiddleContent } from "components/common/PageContainer";
import SectionHeader from "components/text/SectionHeader";
import { useState } from "react";
import CaseTemplateForm from "components/forms/CaseTemplateForm";
import message from "components/common/message";
import { DeleteOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";

const TopRow = styled.div`
  margin: 32px 0px 16px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PageContainer = styled.div`
  max-width: 100%;
  width: 1100px;
  margin: auto;
`;

export default function AppCaseTemplates() {
  const [showForm, setShowForm] = useState(false);
  const { data, loading } = useGetManyCaseTemplatesQuery();
  const [deleteOneCaseTemplateMutation] = useDeleteOneCaseTemplateMutation();
  const [createOneCaseTemplateMutation, { loading: creating }] =
    useCreateOneCaseTemplateMutation();

  if (showForm) {
    return (
      <DocumentTitle title={`${constants.appName} | Case Templates`}>
        <PageContainer>
          <MiddleContent
            style={{ marginLeft: "auto", width: 600, marginRight: "auto" }}
          >
            <TopRow>
              <SectionHeader>Create a Case Template</SectionHeader>
              <Button onClick={() => setShowForm(false)} grey>
                Cancel
              </Button>{" "}
            </TopRow>
            <CaseTemplateForm
              loading={creating}
              onSubmit={async (values) => {
                try {
                  await createOneCaseTemplateMutation({
                    variables: {
                      params: values,
                    },
                    refetchQueries: ["getManyCaseTemplates"],
                  });
                  setShowForm(false);
                  message.success("template created");
                } catch (err) {
                  console.log(err);
                }
              }}
            />{" "}
          </MiddleContent>
        </PageContainer>
      </DocumentTitle>
    );
  }
  return (
    <DocumentTitle title={`${constants.appName} | Case Templates`}>
      <PageContainer>
        <TopRow>
          <SectionHeader>Case Templates</SectionHeader>
          <Button onClick={() => setShowForm(true)} secondary>
            + New Templates
          </Button>{" "}
        </TopRow>
        <Table
          dataSource={data?.getManyCaseTemplates || []}
          loading={loading}
          columns={[
            {
              key: "id",
              title: "Name",
              render: (template: CaseTemplateFragmentFragment) =>
                template?.templateName,
            },
            {
              title: "Actions",
              width: 30,
              render: (template: CaseTemplateFragmentFragment) => (
                <Popconfirm
                  title="Are you sure you want to delete this template?"
                  okText="Yes"
                  onConfirm={async () => {
                    try {
                      await deleteOneCaseTemplateMutation({
                        variables: {
                          id: template?.id,
                        },
                        refetchQueries: ["getManyCaseTemplates"],
                      });
                    } catch (err) {}
                  }}
                >
                  <DeleteOutlined />
                </Popconfirm>
              ),
            },
          ]}
          rowKey={(record) => record.id}
        />
      </PageContainer>
    </DocumentTitle>
  );
}
