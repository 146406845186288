// TOP LEVEL IMPORTS
import styled from 'styled-components';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
// COMPONENTS

const Container = styled(Row)`
  border-top: 1px solid ${(p) => p.theme.colors.neutral10};
  padding-top: 16px;
  margin-top: 32px;
  margin-bottom: 25px;
`;

const FullWidthContainer = styled.div`
  margin-top: 32px;
  padding-top: 16px;
  margin-bottom: 25px;
  border-top: 1px solid ${(p) => p.theme.colors.neutral9};
  display: flex;
`;

const TextContainer = styled.div<{width?: string}>`
  width: ${(p) => (p.width ? p.width : '319px')};
  max-width: 70%;
`;

const Header = styled.div`
  height: 28px;
  font-size: 18px;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.neutral2};
`;

const Body = styled.div<{width?: string}>`
  width: ${(p) => (p.width ? p.width : '389px')};
  font-size: 15px;
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.56;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.neutral5};
`;

const PageSection = ({header, body}) => {
  return (
    <>
      <TextContainer>
        <Header>{header}</Header>
        <div />
        <Body>{body}</Body>
      </TextContainer>
    </>
  );
};

interface SectionBreakProps {
  body?: string | JSX.Element;
  header?: string | JSX.Element;
  children?: any;
  right?: any;
}

export default function SectionBreak({
  body,
  header,
  children,
  right,
}: SectionBreakProps) {
  if (!children) {
    return (
      <FullWidthContainer style={{paddingTop: 16}}>
        <TextContainer width="100%">
          <Header>{header}</Header>
          <div />
          <Body width="100%">{body}</Body>
        </TextContainer>
        {right && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            {right}
          </div>
        )}
      </FullWidthContainer>
    );
  }

  return (
    <div>
      <Container align="top">
        <Col xs={24} md={12}>
          <PageSection header={header} body={body} />
        </Col>
        <Col xs={24} md={12}>
          {children}
        </Col>
      </Container>
    </div>
  );
}
