// TOP LEVEL IMPORTS
import React from 'react';
// ANTD
import TextInput from 'components/inputs/TextInput';

// EXPORTED COMPONENT
// ===================================
export default function PasswordInput(props) {
  return (
    <TextInput
      style={{
        height: 50,
        width: '100%',
      }}
      {...props}
      type="password"
      onChange={(value) => props.onChange(value)}
    />
  );
}
