import {
  useGetManyAnswersQuery,
  useGetOneSurveyResultQuery,
} from "generated/graphql";
import { Link, useLocation } from "react-router-dom";
import Loading from "../Loading";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import styled from "styled-components";
import { useEffect } from "react";
import { HeaderOuter } from "components/layout/AuthenticatedLayout/Header";
import { HeaderContainer } from "components/detail-components";
import logo from "lib/media/logo-icon.svg";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";
import moment from "moment";

const Container = styled.div`
  background: #f3f3f3;
  min-height: 100vh;
  /**  remove the SurveyJS header component  */
  .sd-title.sd-container-modern__title {
    display: none !important;
  }
`;

const Logo = styled.img`
  height: 32px;
  margin-top: 8px;
`;

const HeaderContainerEdited = styled(HeaderContainer)`
  height: 64px;
`;

const HeaderOuterEdited = styled(HeaderOuter)`
  height: 64px;
`;

const Card = styled.div`
  margin: auto;
  background: #fff;
  padding: 24px;
  padding-bottom: 8px;
  width: 640px;
  max-width: 100%;
  margin-top: 48px;
  box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
  border-radius: 4px;
`;

const DataBlock = ({ label, value }) => {
  if (!value) return null;
  return (
    <div style={{ marginBottom: 16 }}>
      {label}: <strong>{value}</strong>
    </div>
  );
};

const DetailsBlock = ({ surveyResult }: any) => {
  return (
    <Card>
      <div>
        <DataBlock
          label="Survey Template"
          value={surveyResult?.template?.title}
        />
        <DataBlock label="Business" value={surveyResult?.business?.title} />
        <DataBlock
          label="Contact"
          value={
            surveyResult?.contact?.id
              ? getFullName(surveyResult?.contact)
              : null
          }
        />
        <DataBlock
          label="Answered"
          value={
            surveyResult?.createdAt
              ? moment(parseInt(surveyResult?.createdAt))?.format(
                  "MMM D, YYYY hh:mma"
                )
              : null
          }
        />
      </div>
    </Card>
  );
};

export default function SurveyFromAnswerView() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const surveyResponseId = queryParams.get("surveyResponseId");
  const { data, loading } = useGetOneSurveyResultQuery({
    variables: { answerId: surveyResponseId || "" },
    skip: !surveyResponseId,
  });

  // remove the disabled className from the inputs so users can read the results easier but the inputs themselves are still disabled from editing
  useEffect(() => {
    const allElements = document.querySelectorAll("*"); // Select all elements

    allElements.forEach((element) => {
      Array.from(element.classList).forEach((className) => {
        if (className.endsWith("--disabled")) {
          element.classList.remove(className); // Remove class if it ends with '--disabled'
        }
      });
    });
  }, [data]); // Dependency on 'data'

  if (loading) return <Loading />;
  const surveyAnswer = data?.getManyAnswers[0];
  const survey = new Model(surveyAnswer?.template);

  survey.data = JSON.parse(surveyAnswer?.stringifiedAnswers || "{}");
  survey.mode = "display";

  return (
    <Container>
      <HeaderContainerEdited>
        <HeaderOuterEdited>
          <div>
            <Link to="/app/dashboard">
              <Logo src={logo} alt="realy-logo" />
            </Link>
          </div>

          {/* <Popover
            content={<Content onLogout={onLogout} navigate={navigate} />}
          >
            <Avatar
              data-testid="header-avatar-dropdown"
              src={currentUser && currentUser.avatar && currentUser.avatar.url}
              style={{
                cursor: "pointer",
                border: `1px solid ${theme.colors.neutral10}`,
              }}
              size="large"
            />
          </Popover> 
        </div>*/}
        </HeaderOuterEdited>
      </HeaderContainerEdited>
      <DetailsBlock surveyResult={surveyAnswer} />
      <Survey model={survey} />
    </Container>
  );
}
