import { ShortCard, CardTitle, BigValue } from "../index";
import Skeleton from "components/common/Skeleton";
// APOLLO
import { useReports_NumberOfBusinessesQuery } from "generated/graphql";

export default function NumberOfBusinesses() {
  const { data, loading } = useReports_NumberOfBusinessesQuery();
  if (loading) {
    return (
      <ShortCard>
        <Skeleton round paragraph={{ rows: 1 }} />
      </ShortCard>
    );
  }

  return (
    <ShortCard>
      <CardTitle>Number of Businesses</CardTitle>
      <BigValue style={{ marginTop: 0 }}>
        {data?.reports_NumberOfBusinesses}
      </BigValue>
    </ShortCard>
  );
}
