import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: ${({theme}) => theme.colors.neutral5};
`;

export default function EmptyData() {
  return <Container>No data...</Container>;
}
