import React from 'react';
import styled from 'styled-components';
// COMPONENTS
import TodoForm from 'components/forms/TodoForm';
import message from 'components/common/message';
// APOLLO
import SAVE_TODO from 'ApolloClient/Mutations/saveTodo';
import todoListFragment from 'ApolloClient/Fragments/todoListFragment';
import {useMutation} from '@apollo/client';

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  background: #fff;
  padding: 16px;
  max-width: 100%;
`;

export default function NewTodo({listId, onCancel, variables}) {
  const [saveTodo, {loading}] = useMutation(SAVE_TODO);
  const onSubmit = async (params) => {
    try {
      await saveTodo({
        variables: {
          params: {
            listId,
            ...params,
          },
        },
        update: (proxy, {data}) => {
          let current = proxy.readFragment({
            id: `TodoList:${listId}`,
            fragment: todoListFragment,
            fragmentName: 'todoListFragment',
            variables,
          });
          proxy.writeFragment({
            id: `TodoList:${listId}`,
            fragment: todoListFragment,
            fragmentName: 'todoListFragment',
            variables,
            data: {
              ...current,
              todos: [data.saveTodo, ...current.todos],
            },
          });
        },
      });
      message.success(`Todo successfully added`);
      onCancel();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormContainer>
      <TodoForm onSubmit={onSubmit} loading={loading} onCancel={onCancel} />
    </FormContainer>
  );
}
