import message from "components/common/message";
import { Todo, TodoParams, useSaveTodoMutation } from "generated/graphql";

const getTodoValues = (todo) => {
  const {
    title,
    businessId,
    dueDate,
    assignedToId,
    attachments,
    priority,
    description,
    contact,
    projectId,
    contactId,
    siteId,
    caseId,
    done,
  } = todo;
  let shapedTodo: TodoParams = {
    title: title || null,
    description: description || null,
    contactId: contactId ? contactId : contact?.id,
    dueDate: dueDate ? dueDate.valueOf().toString() : null,
    priority: priority,
    attachments: attachments,
    assignedToId,
    businessId,
    siteId,
    done,
    projectId,
    caseId,
  };

  return shapedTodo;
};

export default function useSaveTodo() {
  const [saveTodo, { loading: savingTodo }] = useSaveTodoMutation();

  const onSaveTodo = async (params): Promise<Todo | any> => {
    try {
      // throw an error if not filled in
      if (!params.title) {
        return message.error("Title is required");
      }

      // call the mutation
      const result = await saveTodo({
        variables: {
          params: getTodoValues(params),
        },
        refetchQueries: ["todos", "todoById"],
      });

      return result?.data?.saveTodo;
    } catch (err) {
      console.log("saveTodo", err);
      console.log(err);
    }
  };

  return {
    onSaveTodo,
    savingTodo,
  };
}
