import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DocumentTitle from "react-document-title";
// lib
import constants from "lib/constants";
// COMPONENTS
import PageContainer from "components/common/PageContainer";
import FormItem from "components/common/FormItem";
import Button from "components/common/Button";
import PageHeader from "components/text/PageHeader";
import TextInput from "components/inputs/TextInput";
import TextAreaInput from "components/inputs/TextAreaInput";
import SuccessState from "components/common/SuccessState/index";
// GRAPHQL
import { useContactSupportMutation } from "generated/graphql";

const Container = styled.div`
  background: #fff;
  padding: 24px;
  border-radius: ${(p) => p.theme.borderRadius}px;
  width: 500px;
  max-width: 100%;
`;

const Form = styled.form``;

export default function AppHelp() {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();
  const [values, setValues] = useState({
    subject: "",
    message: "",
  });
  const [contactSupport, { loading }] = useContactSupportMutation();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log(values);
      await contactSupport({
        variables: {
          ...values,
        },
      });
      setSuccess(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onChange = (newValues) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  if (success) {
    return (
      <DocumentTitle title={`${constants.appName} | Help`}>
        <PageContainer>
          <SuccessState
            title="Successfully submitted"
            subtitle="We recieved your message. Somebody will be in touch shortly."
            onOk={() => navigate(`/app/businesses`)}
          />
        </PageContainer>
      </DocumentTitle>
    );
  }

  return (
    <DocumentTitle title={`${constants.appName} | Help`}>
      <PageContainer>
        <Container>
          <PageHeader>Get Help</PageHeader>
          <Form onSubmit={handleSubmit}>
            <FormItem label="Subject">
              <TextInput onChange={(subject) => onChange({ subject })} />
            </FormItem>
            <FormItem label="How can we help?" required>
              <TextAreaInput
                onChange={(message) => onChange({ message })}
                rows="5"
              />
            </FormItem>
            <FormItem>
              <Button
                disabled={
                  loading || !values?.message || !values?.message?.[2]
                    ? true
                    : false
                }
                loading={loading}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </FormItem>
          </Form>
        </Container>
      </PageContainer>
    </DocumentTitle>
  );
}
