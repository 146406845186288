import React from "react";
// COMPONENTS
import {
  TodoText,
  getDueIn,
  Tag,
  getPriorityLabel,
  TodoContainer,
} from "components/common/TodoItem";
import Tooltip from "components/common/Tooltip";
import Avatar from "components/common/Avatar";
import { useSaveTodoMutation } from "generated/graphql";
import {
  cleanTodoMutationInputParams,
  TodoStatusInput,
} from "components/common/TodoDetail/TodoDetailContent";
import alerts from "lib/helpers/alerts";
import Row from "components/common/Row";
import Col from "components/common/Col";

export default function TodoItem({ todo, onClick }) {
  const [saveTodoMutation] = useSaveTodoMutation();

  const onSave = async (newValues) => {
    try {
      await saveTodoMutation({
        variables: {
          id: todo.id,
          params: {
            ...cleanTodoMutationInputParams(newValues),
          },
        },
        optimisticResponse: {
          __typename: "Mutation",
          saveTodo: {
            __typename: "Todo",
            id: todo.id,
            ...todo,
            ...cleanTodoMutationInputParams(newValues),
          },
        },
      });

      alerts.success("Todo saved");
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <TodoContainer>
      <Row align="middle" style={{ width: "100%" }}>
        <Col xs={24} sm={12} onClick={onClick}>
          <TodoText data-testid="todo-item-title">
            {todo.title && todo.title.length > 36
              ? `${todo.title.slice(0, 36)}...`
              : todo.title}
          </TodoText>
          <TodoText>{getDueIn(todo.dueDate)}</TodoText>
        </Col>
        <Col xs={8} sm={4}>
          {todo.priority && (
            <Tag priority={todo.priority}>
              {getPriorityLabel(todo.priority)}
            </Tag>
          )}
        </Col>
        <Col
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
          xs={4}
          sm={2}
        >
          {todo.assignedTo && (
            <Tooltip
              title={`${todo.assignedTo.firstName} ${todo.assignedTo.lastName}`}
            >
              <Avatar
                src={todo.assignedTo.avatar && todo.assignedTo.avatar.url}
              />
            </Tooltip>
          )}
        </Col>

        <Col
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TodoStatusInput todo={todo} onSave={onSave} />
        </Col>
      </Row>
    </TodoContainer>
  );
}
