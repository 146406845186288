import styled from "styled-components";
import { CardTypeEnum } from "../../../routes/app-search";
import { useNavigate } from "react-router-dom";
import useUrlChange from "hooks/useUrlChange";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";

const CardTitle = styled.h3`
  font-size: 18px;
  line-height: 24px;
  letter-spacing: normal;
  margin: 0 0 20px;
  display: block;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral1};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Card = styled.div`
  position: relative;
  box-shadow: 0 2px 0 rgb(0 0 0 / 6%);
  border-radius: 5px 5px 4px 4px;
  display: block;
  min-height: 120px;
  padding: 30px;
  background: #fff;
  margin-bottom: 16px;
  &:hover {
    cursor: pointer;
  }
  &:active {
    transform: translatey(3px);
  }
`;

const CardType = styled.strong`
  display: block;
  margin: 0 0 7px;
  line-height: 12px;
  font-size: 13px;
  color: ${({ theme }) => theme.colors.neutral5};
  font-weight: 500;
`;

const getTitle = (item: any, type: CardTypeEnum): string => {
  if (type === CardTypeEnum.Business) return item.title;
  if (type === CardTypeEnum.Case) return item.title;
  if (type === CardTypeEnum.Todo) return item.title;
  if (type === CardTypeEnum.Organization) return item.title;
  if (type === CardTypeEnum.Resource) return item.title;
  if (type === CardTypeEnum.Project) return item.title;
  if (type === CardTypeEnum.Site) return item.title || item.fullAddress;
  if (type === CardTypeEnum.Contact) return getFullName(item) || "";
  return "";
};

const goToLink = (
  item: any,
  type: CardTypeEnum,
  navigate,
  onUrlChange
): string | null => {
  if (type === CardTypeEnum.Business)
    return navigate(`/app/businesses/${item.id}`);
  if (type === CardTypeEnum.Case) {
    return onUrlChange({ selectedCase: item.id });
  }
  if (type === CardTypeEnum.Todo) {
    return onUrlChange({ selectedTodo: item.id });
  }
  if (type === CardTypeEnum.Organization) {
    return null; // navigate(`/app/organizations/${item.id}`);
  }
  if (type === CardTypeEnum.Resource) return null; // navigate(`/app/businesses/${item.id}`);
  if (type === CardTypeEnum.Project)
    return navigate(`/app/projects/${item.id}`);
  if (type === CardTypeEnum.Site) return navigate(`/app/sites/${item.id}`);
  if (type === CardTypeEnum.Contact)
    return navigate(`/app/contacts/${item.id}`);
  return "";
};

export default function SearchCard({
  item,
  type,
}: {
  item: any;
  type: CardTypeEnum;
}) {
  const { onUrlChange }: any = useUrlChange();
  const navigate = useNavigate();
  return (
    <Card onClick={() => goToLink(item, type, navigate, onUrlChange)}>
      <CardType>{type}</CardType>
      <CardTitle>{getTitle(item, type)}</CardTitle>
    </Card>
  );
}
