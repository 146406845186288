import { useState } from "react";
// COMPONENTS
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import BusinessInput from "components/inputs/BusinessInput";
import Button from "components/common/Button";
import { validate } from "email-validator";
import SideFormButtonContainer from "components/common/SideFormButtonContainer";
import FormBreak from "components/common/FormBreak";
import Col from "components/common/Col";
import Row from "components/common/Row";
import OrganizationInput from "components/inputs/OrganizationInput";

export default function ContactForm({
  parentType,
  defaultValues,
  onSubmit,
  onCancel,
  loading,
}) {
  const [errors, setErrors] = useState<any>({});
  const [values, setValues] = useState(defaultValues || {});

  const {
    email,
    firstName,
    department,
    businessId,
    organizationId,
    lastName,
    workPhone,
    title,
    cellPhone,
    homePhone,
  } = values;

  const handleOnSubmit = () => {
    try {
      setErrors({});

      if (email?.[0] && !validate(email as any)) {
        return setErrors({
          email: "Please provide a valid email",
        });
      }

      onSubmit(values);
    } catch (err) {}
  };

  const onChange = (newValues) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const disabled = !firstName || !lastName || loading;
  console.log(values);

  return (
    <div style={{ paddingBottom: 64 }}>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <FormItem label="First name" required error={errors.firstName}>
            <TextInput
              value={firstName}
              onChange={(firstName) => onChange({ firstName })}
              data-testid="contact-form-firstName"
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem label="Last name" required error={errors.lastName}>
            <TextInput
              value={lastName}
              onChange={(lastName) => onChange({ lastName })}
              data-testid="contact-form-lastName"
            />
          </FormItem>
        </Col>
      </Row>
      {parentType === "organization" ? (
        <>
          <FormBreak title="Organization Details" topBorder />
          <FormItem label="Organization" error={errors.organizationId}>
            <OrganizationInput
              value={organizationId}
              defaultOrganizationId={values.organizationId}
              onChange={(organizationId) => onChange({ organizationId })}
            />
          </FormItem>
        </>
      ) : (
        <>
          <FormBreak title="Business Details" topBorder />
          <FormItem label="Business" error={errors.businessId}>
            <BusinessInput
              value={businessId}
              defaultBusinessId={values.businessId}
              onChange={(businessId) => onChange({ businessId })}
            />
          </FormItem>
        </>
      )}

      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <FormItem label="Job title" error={errors.title}>
            <TextInput
              value={title}
              onChange={(title) => onChange({ title })}
              data-testid="contact-form-title"
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem label="Department" error={errors.department}>
            <TextInput
              value={department}
              onChange={(department) => onChange({ department })}
              data-testid="contact-form-department"
            />
          </FormItem>
        </Col>
      </Row>

      <FormBreak title="Contact Info" topBorder />

      <FormItem label="Email" error={errors.email}>
        <TextInput
          data-testid="contact-form-email"
          value={email}
          onChange={(email) => onChange({ email })}
        />
      </FormItem>
      <Row gutter={8}>
        <Col xs={24} sm={12}>
          <FormItem label="Work Phone" error={errors.workPhone}>
            <TextInput
              value={workPhone}
              onChange={(workPhone) => onChange({ workPhone })}
              data-testid="contact-form-workPhone"
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem label="Cell Phone" error={errors.cellPhone}>
            <TextInput
              value={cellPhone}
              onChange={(cellPhone) => onChange({ cellPhone })}
              data-testid="contact-form-cellPhone"
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12}>
          <FormItem label="Home Phone" error={errors.homePhone}>
            <TextInput
              value={homePhone}
              onChange={(homePhone) => onChange({ homePhone })}
              data-testid="contact-form-homePhone"
            />
          </FormItem>
        </Col>
        <Col xs={24} sm={12} />
      </Row>

      <SideFormButtonContainer>
        <Button
          disabled={disabled}
          loading={loading}
          onClick={handleOnSubmit}
          style={{ width: 150, marginLeft: 16 }}
          data-testid="contact-form-submit-btn"
        >
          Save Person
        </Button>
        <Button
          disabled={loading}
          onClick={onCancel}
          grey
          style={{ width: 100, marginLeft: 8 }}
        >
          Cancel
        </Button>
      </SideFormButtonContainer>
    </div>
  );
}
