import {createGlobalStyle} from 'styled-components';
import antdStyle from './antdStyle';
import theme from './theme';

export const GlobalStyle = createGlobalStyle`

  div, a, h1, h2, h3, h4, h5, p, span, a {
    font-family: ${theme.fontFamily};
  }
  ${antdStyle}
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    box-shadow: 0 0 0px 1000px ${theme.colors.neutral11} inset !important;
  }

  a {
    font-weight: 600;
    color: ${theme.colors.primary3} !important;
  }
  a:hover {    
    font-weight: 600;
    color: ${theme.colors.primary2} !important;
  }

`;

export default theme;
