import React from "react";
import styled from "styled-components";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";
import logo from "lib/media/logo2.svg";
import { useParams } from "react-router-dom";

export const PageHeader = styled.h1`
  text-align: center;
  color: ${(p) => p.theme.colors.neutral1};
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 32px;
`;

export const SubHeader = styled.h2`
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 20px;
  margin-top: 32px;
  font-weight: 600;
`;

export const Text = styled.p`
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 16px;
`;

const Container = styled.div`
  background: ${(p) => p.theme.colors.neutral10};
  min-height: 100vh;
`;

const Innter = styled.div`
  margin: auto;
  width: 800px;
  max-width: 100%;
  padding: 48px 0px 88px 0px;
`;

const HeaderContainer = styled.header`
  background: #fff;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral9};
`;

const Header = () => (
  <HeaderContainer>
    <img src={logo} alt="GrowLab Logo" />
  </HeaderContainer>
);

export default function PublicLegal({ match }) {
  const params = useParams();
  return (
    <Container>
      <Header />
      <Innter>
        {params?.page === "privacy" && <PrivacyPolicy />}
        {params?.page === "terms" && <TermsOfService />}
      </Innter>
    </Container>
  );
}
