import Loading from "components/common/Loading";
import {
  GetManyDefaultSurveyTemplatesDocument,
  useDeleteOneDefaultSurveyTemplateMutation,
  useGetManyDefaultSurveyTemplatesQuery,
} from "generated/graphql";
import TemplatesTable from "./TemplatesTable";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/Button";

export default function AdminDefaultSurveyForm() {
  const { data, loading } = useGetManyDefaultSurveyTemplatesQuery();
  const [deleteOneDefaultSurveyTemplate] =
    useDeleteOneDefaultSurveyTemplateMutation();
  const navigate = useNavigate();
  if (loading) return <Loading />;

  return (
    <>
      <div style={{ padding: 32 }}>
        <Button
          style={{ margin: 32 }}
          onClick={() => navigate("/admin/defaultSurveyTemplates/create")}
        >
          + Create New Default Survey Template
        </Button>
        <TemplatesTable
          onSelect={(_id) => navigate(`create?defaultTemplateId=${_id}`)}
          onDelete={(_id) =>
            deleteOneDefaultSurveyTemplate({
              variables: { id: _id },
              refetchQueries: [GetManyDefaultSurveyTemplatesDocument],
            })
          }
          dataSource={data?.getManyDefaultSurveyTemplates || []}
        />
      </div>
    </>
  );
}
