import { useEffect, useState } from "react";
import styled from "styled-components";

// COMPONENTS
import Row from "components/common/Row";
import Col from "components/common/Col";
import { COLUMNS } from "./Todo";
// import Modal from 'components/common/Modal';
import Table from "components/common/Table";
// HOOKs
import useUrlChange from "hooks/useUrlChange";
import {
  CurrentUserFragmentFragment,
  UserPermissionEnum,
  useTeamQuery,
  useYourWorkQuery,
  YourWorkTypeEnum,
} from "generated/graphql";

import SelectInput from "components/inputs/SelectInput";

import getFullName from "lib/helpers/GeneralHelpers/getFullName";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import theme from "lib/theme";

const Container = styled.div`
  margin-top: 32px;
  padding-bottom: 64px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    margin-top: 8px;
    padding-bottom: 24px;
    max-width: 100%;
  }
`;

const TabWrapper = styled.div`
  z-index: 1;
  .ant-tabs-nav .ant-tabs-tab {
    font-weight: 600;
    font-size: 16px;
  }
`;
const TabText = styled.div<{ active?: boolean }>`
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-bottom: 7px;
  color: ${(p) =>
    p.active ? p.theme.colors.neutral1 : p.theme.colors.neutral4};
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
`;

const Activebar = styled.div`
  height: 3px;
  background: ${(p) => p.theme.colors.neutral3};
  width: 90%;
  margin: auto;
`;

const Tab = ({ label, active, onClick }) => {
  return (
    <div onClick={onClick} style={{ cursor: "pointer" }} data-testid={label}>
      <TabText active={active}>{label}</TabText>
      {active ? <Activebar /> : <div style={{ height: 3 }} />}
    </div>
  );
};

export const Tabs = ({ active, onClick, tabs }) => {
  return (
    <TabWrapper>
      <Row style={{ width: 500 }} justify="start">
        {tabs.map((item) => (
          <Col xs={6} key={item.id}>
            <Tab
              onClick={() => onClick(item.id)}
              label={item.label}
              active={active === item.id}
            />
          </Col>
        ))}
      </Row>
    </TabWrapper>
  );
};

const TabContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  z-index: 0;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    flex-direction: column;
    height: 100px;
  }
`;

export default function YourWorkTable({
  currentUser,
  windowWidth,
}: {
  currentUser: CurrentUserFragmentFragment;
  windowWidth: number;
}) {
  const [todoFilter, setTodoFilter] = useState<YourWorkTypeEnum>(
    YourWorkTypeEnum.AssignedToMe
  );
  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();

  const [assignedToId, setAssignedToId] = useState<string | undefined>(
    undefined
  );
  const { onUrlChange } = useUrlChange();

  const { data, loading, error } = useYourWorkQuery({
    variables: {
      type: todoFilter,
      assignedToIds: assignedToId?.[0] ? [assignedToId] : undefined,
      pagination: {
        skip,
        limit,
      },
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });
  // useEffect(() => {
  //   resetTableParamsToDefault();
  // }, []);
  const { data: teamQuery } = useTeamQuery();

  if (error) return <Container>Error...</Container>;

  const DEFAULT_TABS = [
    {
      label: "Assigned to Me",
      id: YourWorkTypeEnum.AssignedToMe,
    },
    {
      label: "Created by Me",
      id: YourWorkTypeEnum.CreatedByMe,
    },
    currentUser?.permission?.includes(UserPermissionEnum.Administrator)
      ? {
          label: "Team's Work",
          id: YourWorkTypeEnum.TeamsWork,
        }
      : {},
    // {
    //   label: "All",
    //   id: "3",
    // },
  ];

  const items = data?.yourWork?.results;
  const count = data?.yourWork?.count;

  const onSelect = (record) => {
    if (record.__typename === "Todo") {
      onUrlChange({ selectedTodo: record?.id });
    }
    if (record.__typename === "Case") {
      onUrlChange({ selectedCase: record?.id });
    }
  };

  const isMobile = parseInt(theme.breakpoints.medium) > windowWidth;

  const MOBILE_COLS = [COLUMNS?.[0]];

  return (
    <>
      <Container id="your-work-container">
        <TabContainer>
          <Tabs
            active={todoFilter}
            tabs={DEFAULT_TABS}
            onClick={(value) => {
              setTodoFilter(value);
              resetTableParamsToDefault(); // sets skip/limit/currentPage back to default as you switch to different tables
            }}
          />
          {[YourWorkTypeEnum.TeamsWork, YourWorkTypeEnum.CreatedByMe]?.includes(
            todoFilter
          ) && (
            <div style={{ maxWidth: "100%" }}>
              <SelectInput
                value={assignedToId}
                containerStyle={{
                  maxWidth: "100%",
                  width: 300,
                  backgroundColor: "#fff",
                  position: "relative",
                  top: -4,
                }}
                placeholder="Filter by team member"
                allowClear
                options={
                  teamQuery?.team?.map((user) => ({
                    id: user?.id,
                    label: getFullName(user) || "",
                  })) || []
                }
                onChange={(assignedToId) => setAssignedToId(assignedToId)}
              />
            </div>
          )}
        </TabContainer>
        <div style={{ paddingTop: 16, maxWidth: "100%" }}>
          <Table
            dataSource={items}
            loading={loading}
            columns={!isMobile ? COLUMNS : MOBILE_COLS}
            scroll={!isMobile ? true : undefined}
            rowClassName="work-table-row"
            pagination={{
              pageSize: limit,
              total: count,
              onChange: (page, pageSize) => {
                onTablePaginationChange(page, pageSize);
              },
              showSizeChanger: true,
              current: currentPage,
            }}
            key="id"
            onRow={(record) => {
              return {
                onClick: () => onSelect(record),
              };
            }}
          />
        </div>
      </Container>
    </>
  );
}
