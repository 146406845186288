import SelectInput from "components/inputs/SelectInput";
import { useTeamQuery } from "generated/graphql";
import theme from "lib/theme";

import {
  RemoveActionBtn,
  ActionContainer,
  LeftCol,
  Rightcol,
} from "../../shared";

export default function ActionSendNotificationToTeamMember({
  onRemove,
  onChange,
}) {
  const { loading, data } = useTeamQuery();
  return (
    <ActionContainer>
      <LeftCol>
        Send a notification to a team member
        <SelectInput
          placeholder="Select a team member"
          options={
            data?.team?.[0]
              ? data?.team?.map((member) => {
                  return {
                    id: member?.id,
                    label: `${member?.firstName || ""} ${
                      member?.lastName || ""
                    }`,
                  };
                })
              : []
          }
          value={undefined}
          loading={loading}
          onChange={(teamMemberId) => {
            onChange({
              teamMemberId,
            });
          }}
          containerStyle={{
            marginLeft: 6,
            marginRight: 4,
            maxWidth: "100%",
            width: 270,
            display: "inline-flex",
            height: 40,
            backgroundColor: theme.colors.neutral11,
          }}
        />
      </LeftCol>
      <Rightcol>
        <RemoveActionBtn onClick={onRemove} />
      </Rightcol>
    </ActionContainer>
  );
}
