// TOP LEVEL IMPORTS
import { useEffect } from "react";
import AppRoutes from "routes/index";
// APOLLO
import client from "ApolloClient";
import theme, { GlobalStyle } from "lib/theme";
import { ApolloProvider } from "@apollo/client";
import { ThemeProvider } from "styled-components";
import ErrorBoundary from "components/common/ErrorBoundaryProps";
import config from "lib/config";
import * as Sentry from "@sentry/browser";
import { setTwoToneColor } from "@ant-design/icons";
import { QueryParamProvider } from "use-query-params";
// @ts-ignore
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import { parse, stringify } from "query-string";
import { BrowserRouter } from "react-router-dom";
import { setLicenseKey } from "survey-core";

setTwoToneColor(theme.colors.neutral7);

export default function App() {
  useEffect(() => {
    setLicenseKey(
      "NDY3YTRkNTEtZDU4Ny00NjY3LTgzZjEtZjQxNGJlODEyYjE3OzE9MjAyNC0xMC0wMSwyPTIwMjQtMTAtMDEsND0yMDI0LTEwLTAx"
    );
  });

  useEffect(() => {
    if (config.NODE_ENV !== "development") {
      Sentry.init({
        dsn: config.SENTRY_DSN,
        environment: config.NODE_ENV,

        integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,
        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        // release: 'growlab@' + process.env.COMMIT_REF,
        tracesSampleRate: 1.0,
        ignoreErrors: ["ResizeObserver loop limit exceeded"],
      });
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <>
            <GlobalStyle />
            <BrowserRouter>
              <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                  searchStringToObject: parse,
                  objectToSearchString: stringify,
                }}
              >
                <AppRoutes />
              </QueryParamProvider>
            </BrowserRouter>
          </>
        </ApolloProvider>
      </ErrorBoundary>{" "}
    </ThemeProvider>
  );
}
