// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import todoFragment from 'ApolloClient/Fragments/todoFragment';

export default gql`
  fragment todoListFragment on TodoList {
    id
    __typename
    title
    description
    archived
    todos(sortBy: $sortBy, searchText: $searchText) {
      ...todoFragment
    }
  }
  ${todoFragment}
`;
