import styled from "styled-components";
import DocumentTitle from "react-document-title";
import constants from "lib/constants";
import BusinessesTab from "./BusinessesTab";
import ContactsTab from "./ContactsTab";
import ResourcesTab from "./ResourcesTab";
import TodosTab from "./TodosTab";
import ProjectsTab from "./ProjectsTab/index";
import CasesTab from "./CasesTab";
import { useLocation } from "react-router-dom";

const PageContainer = styled.div`
  width: 1400px;
  margin: auto;
  padding-top: 24px;
  max-width: 100%;
  height: calc(100vh - 61px);
`;

export default function AppHome({ currentUser, windowWidth, navigate }) {
  const location = useLocation();
  const getPageTitle = () => {
    let title;
    switch (location?.pathname) {
      case "/app/businesses":
        title = "Businesses";
        break;
      case "/app/contacts":
        title = "Contacts";
        break;
      case "/app/resources":
        title = "Resources";
        break;
      case "/app/projects":
        title = "Projects";
        break;
      case "/app/todos":
        title = "Todos";
        break;
      case "/app/cases":
        title = "Cases";
        break;
      case "/app/communication":
        title = "Communication";
        break;
      default:
        return "Home";
    }
    return title;
  };

  let defaultProps = {
    currentUser,
    location,
    navigate,
    windowWidth,
  };

  return (
    <DocumentTitle title={`${constants.appName} | ${getPageTitle()}`}>
      <>
        {" "}
        {(() => {
          switch (location?.pathname) {
            case "/app/businesses":
              return <BusinessesTab {...defaultProps} />;
            case "/app/projects":
              return (
                <PageContainer>
                  <ProjectsTab />
                </PageContainer>
              );
            case "/app/todos":
              return (
                <PageContainer>
                  <TodosTab {...defaultProps} />
                </PageContainer>
              );
            case "/app/contacts":
              return <ContactsTab {...defaultProps} />;
            case "/app/resources":
              return (
                <PageContainer>
                  <ResourcesTab {...defaultProps} />
                </PageContainer>
              );
            case "/app/cases":
              return <CasesTab {...defaultProps} />;
            default:
              return <BusinessesTab {...defaultProps} />;
          }
        })()}
      </>
    </DocumentTitle>
  );
}
