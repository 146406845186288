import React from "react";
import styled from "styled-components";
// APOLLO
import { useState } from "react";
import EditTitleInput from "../EditTitleInput";

const BlockContainer = styled.div`
  max-height: 500px;
  background: #e7edf4;
  width: 300px;
  padding: 8px;
  /* height: 100%; */
  border-radius: 5px;
`;

export default function TempCol({ colIndex = 1, onSave, onCancel }) {
  const [title, setTitle] = useState(`Stage ${colIndex + 1}`);
  return (
    <BlockContainer>
      <EditTitleInput
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        onCancel={onCancel}
        onClick={() => onSave(title)}
      />
      {/* <div style={{position: 'relative'}}>
        <TextInput value={title} onChange={(e) => setTitle(e.target.value)} />
        <CancelBtn onClick={onCancel}>X</CancelBtn>
        <SaveBtn onClick={() => onSave(title)}>S</SaveBtn>
      </div> */}
    </BlockContainer>
  );
}
