import { Card } from "../index";
import Skeleton from "components/common/Skeleton";
import { ResponsivePie } from "@nivo/pie";
// import TopNumberDropdown from '../TopNumberDropdown';
// APOLLO
import { useReports_BusinessAssistedByServiceQuery } from "generated/graphql";
import { toNumber } from "lodash";

export default function ResolvedCasesByService() {
  //   const [top, setTop] = useState(10);
  const { data, loading } = useReports_BusinessAssistedByServiceQuery({
    variables: {
      // top,
    },
    fetchPolicy: "cache-and-network",
  });

  if (loading) {
    return (
      <Card>
        <Skeleton round />
      </Card>
    );
  }

  const overallTotals = data?.reports_BusinessAssistedByService
    ?.map((item) => item?.total)
    .reduce((a, b) => toNumber(a) + toNumber(b), 0);

  const getPercent = (value) => {
    return `${((value / toNumber(overallTotals)) * 100).toFixed(0)}%`;
  };

  const chartData = data?.reports_BusinessAssistedByService?.map((item) => ({
    id: `${item?.title} (${getPercent(item?.total)})`,
    value: item?.total,
  }));

  return (
    <Card
      title="Resolved Cases by Service Requested"
      empty={!chartData || chartData.length === 0}
    >
      {/* <TopNumberDropdown value={top} onChange={(top) => setTop(top)} /> */}
      <ResponsivePie
        data={chartData || []}
        sliceLabel={(d) => getPercent(d.value)}
        innerRadius={0.45}
        colors={{ scheme: "greens" }}
        margin={{ top: 32, right: 88, bottom: 24, left: 88 }}
      />
    </Card>
  );
}
