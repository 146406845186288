// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  fragment contactShortFragment on Contact {
    id
    email
    department
    firstName
    lastName
    title
    status
    website
    workPhone
    cellPhone
    homePhone
    avatar {
      id
      filename
      mimetype
      encoding
      url
      key
    }
  }
`;
