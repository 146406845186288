// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  query caseServices($ids: [ID!], $caseCategoryId: ID) {
    caseServices(ids: $ids, caseCategoryId: $caseCategoryId) {
      id
      title
      description
    }
  }
`;
