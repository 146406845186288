import React from 'react';
import styled from 'styled-components';
import Avatar from 'components/common/Avatar';
// APOLLO
import {useMutation} from '@apollo/client';
import SAVE_TODO from 'ApolloClient/Mutations/saveTodo';
//COMPONENTS
import Col from 'components/common/Col';
import Row from 'components/common/Row';
import Tooltip from 'components/common/Tooltip';
import theme from 'lib/theme';
import constants from 'lib/constants';
import moment from 'moment';

const Unchecked = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.neutral9};
  margin-right: 16px;
  transition: color 0.3s ease, background-color 0.3s ease;
  &:hover {
    background-color: ${(p) => p.theme.colors.neutral8};
    cursor: pointer;
  }
`;

const TodoContainer = styled(Row)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-top: 1px solid ${(p) => (p.isFirst ? 'transparent' : '#e4e7eb')};
  padding-top: 16px;
  padding-bottom: 16px;
`;

const TodoText = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  font-style: normal;
  font-stretch: normal;
  display: flex;
  letter-spacing: 1.25px;
  align-items: center;
  color: ${(p) => (p.done ? p.theme.colors.neutral6 : p.theme.colors.neutral3)};
  cursor: pointer;
  text-decoration: ${(p) => (p.done ? 'line-through' : 'none')};
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
`;

const TagContainer = styled.div`
  padding: 2px 20px;
  background: ${(p) =>
    p.backgroundColor ? p.backgroundColor : p.theme.colors.neutral10};
  border-radius: 25px;
  display: inline-flex;
  min-width: 80px;
  align-items: center;
  justify-content: center;
`;
const TagText = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 500;
  font-size: 14px;
  color: ${(p) => p.color};
`;

const Tag = ({children, priority}) => {
  let obj = constants.priorities.filter((item) => item.value === priority)[0];
  return (
    <TagContainer backgroundColor={obj.backgroundColor}>
      <TagText color={obj.color}>{children}</TagText>
    </TagContainer>
  );
};

const DueTomorrow = styled.div`
  font-weight: 700;
  color: ${(p) => p.theme.colors.yellow3};
`;

const DueToday = styled.div`
  font-weight: 700;
  color: ${(p) => p.theme.colors.red4};
`;

const DueInXDays = styled.div`
  font-weight: 700;
  color: ${(p) => p.theme.colors.neutral3};
`;

export default function Todo({todo, onClick, isFirst}) {
  const [saveTodo] = useMutation(SAVE_TODO);

  const onCheck = async () => {
    try {
      await saveTodo({
        variables: {
          id: todo.id,
          params: {
            done: !todo.done,
          },
        },
        optimisticResponse: {
          __typename: 'Mutation',
          saveTodo: {
            id: todo.id,
            __typename: 'Todo',
            done: !todo.done,
          },
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getDueIn = () => {
    const a = moment();
    const b = moment(parseInt(todo.dueDate));
    const diffInDays = a.diff(b, 'days'); // 1 day
    if (diffInDays === 0) return <DueToday> Today</DueToday>;
    if (diffInDays === -1) return <DueTomorrow> Tomorrow</DueTomorrow>;
    // if not past the due date yet
    if (diffInDays < 0) {
      return <DueInXDays>{`Due in ${Math.abs(diffInDays)} Days`}</DueInXDays>;
    }
    // if passed the due date, show this
    return `Due ${Math.abs(diffInDays)} Days Ago`;
  };

  const {priority, assignedTo, title, done} = todo;
  return (
    <TodoContainer isFirst={isFirst}>
      <Col xs={2} md={1}>
        <Unchecked onClick={onCheck} checked={done}>
          <div
            style={{
              background: done ? theme.colors.primary4 : theme.colors.neutral9,
              borderRadius: 2,
              width: 10,
              height: 10,
            }}
          />
        </Unchecked>
      </Col>
      <Col xs={14} md={13}>
        <TodoText done={done} onClick={onClick}>
          {title.length > 42 ? (
            <Tooltip title={title}>{title.substring(0, 42)}...</Tooltip>
          ) : (
            title
          )}
        </TodoText>
      </Col>

      <Col
        xs={0}
        md={4}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {priority && (
          <Tag priority={priority}>
            {
              constants.priorities.filter((item) => item.value === priority)[0]
                .label
            }
          </Tag>
        )}
      </Col>
      <Col xs={4} md={4}>
        {getDueIn()}
      </Col>
      <Col xs={4} md={2}>
        {assignedTo && (
          <Tooltip title={`${assignedTo.firstName} ${assignedTo.lastName}`}>
            <Avatar src={assignedTo.image && assignedTo.image.url} />
          </Tooltip>
        )}
      </Col>
    </TodoContainer>
  );
}
