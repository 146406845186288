import React from "react";
import styled from "styled-components";
// import
import emptySVG from "./empty.svg";
import Button from "components/common/Button";
// LIB
import formTypes from "lib/constants/formTypes";
import useUrlChange from "hooks/useUrlChange";

const Container = styled.div`
  padding-top: 40px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  text-align: center;
`;

const Image = styled.img`
  height: 150px;
  margin: auto;
  opacity: 0.35;
  margin: 24px;
`;

const Title = styled.h1`
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral1};
  font-weight: 600;
  margin-bottom: 4px;
`;

const Caption = styled.p`
  font-size: 16px;
  color: ${(p) => p.theme.colors.neutral4};
  margin-bottom: 24px;
`;

export default function TodosEmptyState({ project, site, business, contact }) {
  const { onUrlChange } = useUrlChange();
  return (
    <Container>
      <div>
        <Image src={emptySVG} alt="empty" />
        <Title data-testid="todos-empty-state-title">
          You're all caught up
        </Title>
        <Caption>If you get assigned something, we'll ping you...</Caption>
        <div>
          <Button
            style={{ width: 150 }}
            onClick={() => {
              let otherValues = {};
              if (site) {
                otherValues.siteId = site.id;
              }
              if (business) {
                otherValues.businessId = business.id;
              }
              if (project) {
                otherValues.projectId = project.id;
              }
              if (contact) {
                otherValues.contactId = contact.id;
              }
              onUrlChange({
                drawerVisible: formTypes.TODOS,
                ...otherValues,
              });
            }}
          >
            Create a Todo
          </Button>
        </div>
      </div>
    </Container>
  );
}
