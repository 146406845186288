import SAVE_BUSINESS from "ApolloClient/Mutations/saveBusiness";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toString, toNumber } from "lodash";

const getBusinessValues = (business) => {
  const { employeeCount, annualRevenue } = business;
  let shaped = {
    ...business,
    annualRevenue: toString(annualRevenue),
    employeeCount:
      typeof employeeCount === "string" ? toNumber(employeeCount) : null, // employeeCount needs to be an Int before being passed to the mutation
  };

  return shaped;
};

export default function useSaveBusiness() {
  const [saveBusiness, { loading: savingBusiness }] =
    useMutation(SAVE_BUSINESS);
  const navigate = useNavigate();
  const onSubmitBusiness = async (params, refetchQueries = []) => {
    console.log("=====> this thing ran");
    try {
      let res = await saveBusiness({
        variables: {
          params: getBusinessValues(params),
        },
        refetchQueries,
      });
      navigate(`/app/businesses/${res.data.saveBusiness.id}`);
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSubmitBusiness,
    savingBusiness,
  };
}
