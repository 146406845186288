import styled from "styled-components";
import { FormQuestionTypeEnum } from "generated/graphql";
import Popover from "components/common/Popover";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Title from "components/text/Title";
import Tooltip from "components/common/Tooltip";
import { useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import LinkButton from "components/common/LinkButton";
import { useQueryParam, BooleanParam } from "use-query-params";

export const SURVEY_OPTIONS = [
  {
    id: FormQuestionTypeEnum.Text,
    label: "Long Text",
    color: "#FF6363",
  },
  {
    id: FormQuestionTypeEnum.Number,
    label: "Number",
    color: "#FFAB76",
  },
  {
    id: FormQuestionTypeEnum.Percent,
    label: "Percentage",
    color: "#1572A1",
  },
  {
    id: FormQuestionTypeEnum.SingleChoice,
    label: "Single Choice",
    color: "#24A19C",
  },
  // {
  //   id: SurveyQuestionTypeEnum.Date,
  //   label: "Date",
  // },
  {
    id: FormQuestionTypeEnum.MultipleChoice,
    label: "Multiple Choice",
    color: "#655D8A",
  },
  {
    id: FormQuestionTypeEnum.Currency,
    label: "Currency",
    color: "#20FC8F",
  },
  // {
  //   id: FormQuestionTypeEnum.Matrix,
  //   label: "Matrix",
  //   color: "#7897AB",
  // },
  // {
  //   id: SurveyQuestionTypeEnum.YesNo,
  //   label: "Yes/No Choice",
  // },
  {
    id: FormQuestionTypeEnum.SectionHeader,
    label: "Section Header",
    color: "#D885A3",
  },
];

const NewContentBtn = styled.button`
  border: 0px;
  border-radius: 5px;
  height: 32px;
  width: 32px;
  font-size: 18px;
  background: ${({ theme }) => theme.colors.neutral9};
  color: ${({ theme }) => theme.colors.neutral2};
  &:hover {
    background: ${({ theme }) => theme.colors.neutral8};
    cursor: pointer;
  }
`;
const NewContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutral1};
  font-weight: 600;
  padding: 16px;
  margin-bottom: 16px;
`;

const ContentConatiner = styled.div`
  width: 700px;
  height: 250px;
  border-radius: 8px;
  padding: 16px 24px;
  position: relative;
`;

const Option = styled.button`
  margin: 8px 16px;
  padding: 8px;
  line-height: 23px;
  margin-left: 0px;
  font-size: 16px;
  background: #fff;
  border-radius: 5px;
  border: 0px;
  min-width: 200px;
  text-align: left;
  display: flex;
  align-items: center;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.neutral10};
  }
`;

export const QuestionOptionIconBlock = ({
  questionTypeId,
  children,
}: {
  questionTypeId: string;
  children?: any;
}) => {
  const background = SURVEY_OPTIONS?.find(
    (item) => item.id === questionTypeId
  )?.color;

  return (
    <div
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: 24,
        height: 24,
        marginRight: 8,
        borderRadius: 5,
        background,
        color: "#fff",
      }}
    >
      {children}
    </div>
  );
};

const PopoverContent = ({ onSelectNew, setShowPopover }) => {
  const [_, setDefaultQuestionModal] = useQueryParam(
    "DEFAULT_QUESTIONS_MODAL",
    BooleanParam
  );
  return (
    <ContentConatiner>
      <NewContentBtn
        style={{ position: "absolute", top: 8, right: 8 }}
        onClick={() => setShowPopover(false)}
      >
        <CloseOutlined />
      </NewContentBtn>
      <Title style={{ marginBottom: 8 }}>All question types</Title>
      <Row>
        {SURVEY_OPTIONS?.map((option) => {
          return (
            <Col key={option.label} xs={8}>
              <Option onClick={() => onSelectNew(option)}>
                <QuestionOptionIconBlock questionTypeId={option.id} />
                {option.label}
              </Option>
            </Col>
          );
        })}
        <Col xs={16}></Col>
      </Row>
      <LinkButton
        style={{
          position: "absolute",
          right: 16,
          bottom: 8,
        }}
        onClick={() => setDefaultQuestionModal(true)}
      >
        Or, choose from default questions
      </LinkButton>
    </ContentConatiner>
  );
};

const NewContent = ({ onSelectNew }) => {
  const [showPopover, setShowPopover] = useQueryParam(
    "showPopover",
    BooleanParam
  );
  return (
    <NewContentContainer>
      Form Questions
      <div>
        <Popover
          trigger="click"
          visible={showPopover ? true : false}
          placement="rightBottom"
          content={
            <PopoverContent
              setShowPopover={setShowPopover}
              onSelectNew={(newValue) => {
                onSelectNew(newValue);
                setShowPopover(false);
              }}
            />
          }
        >
          <Tooltip title="Add new question">
            <NewContentBtn onClick={() => setShowPopover(true)}>
              +
            </NewContentBtn>
          </Tooltip>
        </Popover>
      </div>
    </NewContentContainer>
  );
};

export default NewContent;
