import { BusinessStatusEnum } from "generated/graphql";

const businessStatuses = [
  {
    value: BusinessStatusEnum.Current,
    label: "Current Business",
  },
  {
    value: BusinessStatusEnum.Former,
    label: "Former Business",
  },
  {
    value: BusinessStatusEnum.Prospect,
    label: "Prospective Business",
  },
  {
    value: BusinessStatusEnum.Closed,
    label: "Closed Business",
  },
];

export default businessStatuses;
