import * as React from 'react';

function BriefcaseFilled(props) {
  return (
    <svg
      width={33}
      height={33}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9 9.9V8.25a4.95 4.95 0 014.95-4.95h3.3a4.95 4.95 0 014.95 4.95V9.9h3.3a3.3 3.3 0 013.3 3.3v5.89a37.867 37.867 0 01-13.2 2.36 37.865 37.865 0 01-13.2-2.36V13.2a3.3 3.3 0 013.3-3.3h3.3zm3.3-1.65a1.65 1.65 0 011.65-1.65h3.3a1.65 1.65 0 011.65 1.65V9.9h-6.6V8.25zm1.65 8.25a1.65 1.65 0 011.65-1.65h.017a1.65 1.65 0 010 3.3H16.5a1.65 1.65 0 01-1.65-1.65z"
        fill={props.fill || '#000'}
      />
      <path
        d="M3.3 22.592V26.4a3.3 3.3 0 003.3 3.3h19.8a3.3 3.3 0 003.3-3.3v-3.808a41.204 41.204 0 01-13.2 2.158c-4.613 0-9.054-.759-13.2-2.158z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default BriefcaseFilled;
