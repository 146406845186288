import React from 'react';
import styled from 'styled-components';

const Input = styled.textarea`
  min-height: 50px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.neutral11};
  color: ${(p) => p.theme.colors.neutral1};
  width: 100%;
  border: 2px solid ${(p) => p.theme.colors.neutral11};
  padding-left: 8px;
  &:focus {
    outline: 0;
    background: #fff;
    color: ${(p) => p.theme.colors.activeFieldText};
    border: 2px solid ${(p) => p.theme.colors.neutral9};
  }
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral7};
  }
`;

export default function TextAreaInput(props) {
  return <Input {...props} onChange={(e) => props.onChange(e.target.value)} />;
}

// // TOP LEVEL IMPORTS
// import React from 'react';
// import PropTypes from 'prop-types';
// // ANTD
// import Input from 'antd/lib/input';

// const { TextArea } = Input;

// // EXPORTED COMPONENT
// // ===================================
// export class TextAreaInput extends React.Component {
//   render() {
//     return (
//       <TextArea
//         {...this.props}
//         onChange={e => this.props.onChange(e.target.value)}
//       />
//     );
//   }
// }

// // PROPS
// // ===================================
// TextAreaInput.propTypes = {
//   size: PropTypes.string,
//   style: PropTypes.object,
// };

// // Specifies the default values for props:
// TextAreaInput.defaultProps = {
//   size: 'large',
//   style: {
//     width: '100%',
//     minWidth: '170px',
//     maxWidth: '100%',
//   },
// };

// // EXPORT
// // ===================================
// export default TextAreaInput;
