import React, { useState } from "react";
import styled from "styled-components";
// // APOLLO
import SAVE_PIPELINE from "ApolloClient/Mutations/savePipeline";
import PIPELINES_BY_CUSTOMER from "ApolloClient/Queries/pipelinesByCustomer";
import { useMutation } from "@apollo/client";
// COMPONENTS
import Button from "components/common/Button";
import message from "components/common/message";
import PipelineForm from "../PiplineForm";
import emptySVG from "lib/media/empty-state-vendors.svg";
import { useNavigate } from "react-router-dom";

const Container = styled.div`
  width: 900px;
  margin: auto;
  max-width: 100%;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral1};
  font-weight: 600;
  margin-bottom: 4px;
`;

const Caption = styled.p`
  font-size: 16px;
  color: ${(p) => p.theme.colors.neutral4};
  margin-bottom: 24px;
`;

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
  background: #fff;
  padding: 16px;
`;

export default function EmptyState() {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [savePipeline, { loading }] = useMutation(SAVE_PIPELINE);

  const onSubmit = async (params) => {
    try {
      let result = await savePipeline({
        variables: {
          params,
        },
        refetchQueries: [
          {
            query: PIPELINES_BY_CUSTOMER,
          },
        ],
      });
      message.success("Pipeline successfully created!");
      navigate(`/app/projects?selectedPipeline=${result.data.savePipeline.id}`);
      setShowForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (showForm) {
    return (
      <FormContainer>
        <PipelineForm
          onSubmit={onSubmit}
          loading={loading}
          onCancel={() => setShowForm(false)}
        />
      </FormContainer>
    );
  }
  return (
    <Container>
      <img
        src={emptySVG}
        alt="empty-state"
        style={{ margin: 24, height: 150 }}
      />
      <Title>You have no piplines yet...</Title>
      <Caption>
        Create your first pipeline to start tracking project status
      </Caption>
      <div>
        <Button style={{ width: 180 }} onClick={() => setShowForm(true)}>
          Create a Pipeline
        </Button>
      </div>
    </Container>
  );
}
