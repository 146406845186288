import SiteForm from "components/forms/SiteForm";
import { get } from "lodash";
// COMPONENTS
import Loading from "components/common/Loading";
import message from "components/common/message";
import useSaveSite from "hooks/useSaveSite";
import { useSiteByIdQuery } from "generated/graphql";

export default function EditSite({ siteId, onClose }) {
  const { data, loading } = useSiteByIdQuery({
    variables: {
      id: siteId,
    },
  });
  const [saveSite, { loading: saving }] = useSaveSite();

  const site = get(data, "siteById");

  if (loading) return <Loading />;

  const onSave = async (values) => {
    try {
      await saveSite(values, siteId);
      message.success(`Your changes were saved`);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <SiteForm
        defaultValue={site}
        loading={saving}
        onSubmit={onSave}
        onCancel={onClose}
      />
    </>
  );
}
