// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import businessFragment from 'ApolloClient/Fragments/businessFragment';

export default gql`
  mutation saveBusiness($id: ID, $params: BusinessParams) {
    saveBusiness(id: $id, params: $params) {
      ...businessFragment
    }
  }
  ${businessFragment}
`;
