import React, { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import message from "components/common/message";
import { LoadingOutlined, CloudUploadOutlined } from "@ant-design/icons";
// APOLLO
import useUploadFile from "lib/hooks/useUploadFile";

const Image = styled.img`
  width: 125px;
  height: 125px;
  border: 1px solid ${(p) => p.theme.colors.neutral10};
  border-radius: 5px;
`;

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Label = styled.label`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary2};
  padding: 8px 16px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.primary9};
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) => p.theme.colors.primary8};
    color: ${(p) => p.theme.colors.primary1};
  }
`;

export default function ImageInput({ value, onChange, label }) {
  const [uploadFile] = useUploadFile();
  const [uploading, setUploading] = useState(false);
  const handleUpload = async (event) => {
    try {
      // if already uploading, return
      if (uploading) return;
      let file = event?.target?.files[0];
      if (!file) return;
      // set loader
      setUploading(true);
      // upload file to s3
      let uploadResult = await uploadFile(file);
      onChange(uploadResult);
      message.success("Upload successful!");
      setUploading(false);
    } catch (err) {
      setUploading(false);
      console.log(err);
    }
  };

  return (
    <>
      {value ? (
        <div>
          <label htmlFor="file-input">
            <Image src={value.url} alt={value.url} />
            {uploading && <LoadingOutlined style={{ marginRight: 6 }} />}
          </label>
          <UploadButton
            id="file-input"
            name="file-input"
            type="file"
            onChange={handleUpload}
            disabled={uploading}
          />
        </div>
      ) : (
        <div style={{ marginBottom: 32 }}>
          <UploadButton
            name="compay-upload"
            type="file"
            id="compay-upload"
            onChange={handleUpload}
            disabled={uploading}
          />
          <Label htmlFor="compay-upload">
            {!uploading ? (
              <>
                <CloudUploadOutlined style={{ marginRight: 6 }} />
                {label || "Upload New File"}
              </>
            ) : (
              <>
                <LoadingOutlined style={{ marginRight: 6 }} />
                Uploading...
              </>
            )}
          </Label>
        </div>
      )}
    </>
  );
}
