import { useEffect } from "react";
// COMPONENTS
import SectionBreak from "components/common/SectionBreak";
import FormItem from "components/common/FormItem";
import Row from "components/common/Row";
import { Link } from "react-router-dom";
import Col from "components/common/Col";
import CustomField from "components/common/CustomField";
import TextInput from "components/inputs/TextInput";
import TextAreaInput from "components/inputs/TextAreaInput";
import CurrencyInput from "components/inputs/CurrencyInput";
import Caption from "components/text/Caption";
import theme from "lib/theme";
import Title from "components/text/Title";
import Select from "antd/lib/select";
import { ProjectTwoTone } from "@ant-design/icons";
import CustomTagsInput from "components/inputs/CustomTagsInput";
// APOLLO
import client from "ApolloClient/index";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import {
  ProjectTypeByPipelineDocument,
  usePipelinesByCustomerQuery,
} from "generated/graphql";
import { DetailitemFieldTypeEnum } from "../DetailItem";

const { Option } = Select;

const useProjectTypeFields = ({ onChange, value }) => {
  useEffect(() => {
    if (value.pipeline) {
      const fetchData = async () => {
        let { data } = await client.query({
          query: ProjectTypeByPipelineDocument,
          variables: {
            id: value.pipeline,
          },
        });
        // Check if pipeline we get back has a projectType field, just in case. It should be a required field.
        if (data.pipelineById && data.pipelineById.projectType) {
          onChange({
            projectType: data.pipelineById.projectType.id,
            extraFields: data.pipelineById.projectType.extraFields,
            customFields: data.pipelineById.projectType.customFields,
          });
        } else {
          // if project has no extra fields, make sure to reset them to empty
          onChange({
            projectType: null,
            extraFields: [],
            customFields: [],
          });
        }
      };
      fetchData();
    }
  }, [value.pipeline]);
};

const PipelinesNotSetup = () => {
  return (
    <div
      style={{
        textAlign: "center",
        width: 450,
        maxWidth: "100%",
        margin: "32px auto 16px auto",
      }}
    >
      <div style={{ marginBottom: 16 }}>
        <ProjectTwoTone
          style={{
            color: theme.colors.neutral5,
            fontSize: 40,
            marginBottom: 16,
          }}
          // fill={theme.colors.neutral5}
        />
        <Title>You need a project pipeline...</Title>
        <Caption>
          You need to setup your first pipeline before you can create a project
        </Caption>
      </div>
      <Link to="/app/projects">Go setup your first pipeline</Link>
    </div>
  );
};

export default function ProjectForm({ value, onChange }) {
  const { data, loading } = usePipelinesByCustomerQuery();

  useProjectTypeFields({ onChange, value });

  const {
    title,
    description,
    pipeline,
    extraFields,
    customFields,
    tagIds,
    amount,
  } = value;

  if (loading) return null;

  let pipelines = data?.pipelinesByCustomer || [];

  const cleanValues = (values) => {
    return values.map(({ fieldName, value, fieldType }) => {
      let cleanValue;
      // just return it as is if the value we're iterating over is naics
      if (fieldType === DetailitemFieldTypeEnum.naics) {
        console.log({ fieldName, value, fieldType });
        return {
          fieldName,
          value: typeof value !== "string" ? JSON.stringify(value) : value,
          fieldType,
        };
      }
      if (typeof value === "string") {
        cleanValue = value;
      }
      if (value && typeof value !== "string") {
        cleanValue = value.toString();
      }
      return {
        fieldName,
        value: cleanValue,
        fieldType,
      };
    });
  };

  const onExtraFieldChange = (value, { fieldName }) => {
    let newFields: any = [];
    extraFields.forEach((item) => {
      if (item.fieldName === fieldName) {
        newFields.push({
          ...item,
          value,
        });
      } else {
        newFields.push(item);
      }
    });
    onChange({
      extraFields: cleanValues(newFields),
    });
  };

  const onCustomFieldChange = (value, { fieldName }) => {
    let newFields: any = [];

    customFields.forEach((item) => {
      if (item.fieldName === fieldName) {
        newFields.push({
          ...item,
          value,
        });
      } else {
        newFields.push(item);
      }
    });

    onChange({
      customFields: cleanValues(newFields),
    });
  };

  if (!loading && data?.pipelinesByCustomer?.length === 0) {
    return <PipelinesNotSetup />;
  }

  return (
    <div style={{ paddingBottom: 72 }}>
      <FormItem label="Project name" required>
        <TextInput value={title} onChange={(title) => onChange({ title })} />
      </FormItem>
      <FormItem label="Description">
        <TextAreaInput
          value={description}
          onChange={(description) => onChange({ description })}
        />
      </FormItem>
      <Row gutter={16}>
        <Col xs={16}>
          <FormItem
            label="Amount"
            tooltip="Is there a monetary value you would give this project?"
          >
            <CurrencyInput
              value={amount}
              onChange={(amount) => onChange({ amount })}
            />
          </FormItem>
        </Col>
        <Col xs={8} />
        <Col xs={12}>
          <FormItem
            label="Pipeline"
            required
            tooltip="Which pipeline do you want this to live in?"
          >
            <DropdownStyleWrapper>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: 200 }}
                onChange={(pipeline) => onChange({ pipeline })}
                value={pipeline}
              >
                {pipelines?.map(({ id, title }) => {
                  if (!id) return null;
                  return (
                    <Option key={id} value={id}>
                      {title}
                    </Option>
                  );
                })}
              </Select>
            </DropdownStyleWrapper>
          </FormItem>
        </Col>
        <Col xs={12} />
        <Col xs={24}>
          <FormItem label="Tags">
            <CustomTagsInput
              value={tagIds}
              onChange={(tagIds) => onChange({ tagIds })}
            />
          </FormItem>{" "}
        </Col>
      </Row>
      {extraFields && extraFields.length > 0 && (
        <>
          <SectionBreak
            header="Extra fields"
            body="These are the fields dictated by your project template"
          />
          {extraFields.map((field) => {
            if (field.fieldType === DetailitemFieldTypeEnum.naics) {
              field = {
                ...field,
                value:
                  typeof field?.value === "string"
                    ? JSON.parse(field.value)
                    : undefined,
              };
            }

            return (
              <FormItem key={field.fieldName} label={field.fieldName}>
                <CustomField
                  key={field.fieldName}
                  value={field.value}
                  fieldType={field.fieldType}
                  onChange={(value) => {
                    onExtraFieldChange(value, field);
                  }}
                />
              </FormItem>
            );
          })}{" "}
        </>
      )}
      {customFields && customFields.length > 0 && (
        <>
          <SectionBreak
            header="Custom fields"
            body="These are custom fields that you built into your project template"
          />
          {customFields.map((field) => (
            <FormItem key={field.fieldName} label={field.fieldName}>
              <CustomField
                key={field.fieldName}
                value={field.value}
                fieldType={field.fieldType}
                onChange={(value) => onCustomFieldChange(value, field)}
              />
            </FormItem>
          ))}
        </>
      )}
    </div>
  );
}
