import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import authLink from "./links/authLink";
import { createUploadLink } from "apollo-upload-client";
import apolloLogger from "apollo-link-logger";
import config from "lib/config";

const uploadLink = createUploadLink({
  uri: config.GRAPHQL_API || "http://localhost:3050/graphql",
  headers: {
    "keep-alive": "true",
  },
});

// ** This will remove all typenames from mutation calls, because otherwise the mutation will error and its really annoying having to remove typenames in the app //
const cleanTypeNameFromMutation = new ApolloLink((operation: any, forward) => {
  const operationType: any = operation.query.definitions[0]?.operation;
  if (operationType === "mutation") {
    const omitTypename = (key, value) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

// for some reason, need to pass uri in via links like https://github.com/apollographql/apollo-link/issues/1246#issuecomment-656092271
let links = [cleanTypeNameFromMutation, authLink, apolloLogger, uploadLink];

if (config.NODE_ENV === "development") {
  links = [cleanTypeNameFromMutation, authLink, apolloLogger, uploadLink];
}

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from(links),
  defaultOptions: {
    // query: {
    //   fetchPolicy: "network-only",
    //   nextFetchPolicy: "cache-only",
    // }
  },
});

export default client;
