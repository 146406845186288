import { useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
// COMPONENTS
import { Label } from "../index";
import Button from "components/common/Button";
import Avatar from "components/common/Avatar";
import Tooltip from "components/common/Tooltip";
import message from "components/common/message";
import Progress from "components/common/Progress";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
// APOLLO
import TODOS from "ApolloClient/Queries/todos";
// HOOKS
import useUrlChange from "hooks/useUrlChange";
import { Todo, useSaveTodoMutation, useTodosQuery } from "generated/graphql";
import { toNumber } from "lodash";

const NewTodoContainer = styled.form`
  margin-top: 16px;
  margin-bottom: 16px;
`;

const NewTodoButton = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: flex-end;
`;

const Input = styled.input`
  background: ${(p) => p.theme.colors.neutral11};
  border: 0px;
  width: 100%;
  height: 50px;
  padding-left: 8px;
  border-radius: 5px;
  border: 2px solid ${(p) => p.theme.colors.neutral11};
  &:focus {
    outline: 0;
    background: #fff;
    border: 2px solid ${(p) => p.theme.colors.primary7};
  }
  ::placeholder {
    color: ${(p) => p.theme.colors.neutral7};
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const TodoContainer = styled.div`
  padding: 8px;
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral10};
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.neutral11};
  }
`;

const TodoItemsContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral9};
  border-radius: 5px;
  margin-top: 8px;
`;

const TodoKey = styled.span`
  color: ${({ theme }) => theme.colors.neutral6};
  display: inline-block;
  margin-right: 8px;
  font-weight: 600;
  width: 80px;
`;

const TodoTitle = styled.span`
  color: ${({ theme }) => theme.colors.neutral2};
  display: inline-block;
  font-weight: 600;
  width: 350px;
`;

const TodoItemEnd = styled.div`
  width: 120px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TodoStatusTag = styled.div<{ done?: boolean }>`
  text-transform: uppercase;
  padding: 4px;
  border-radius: 5px;
  width: 55px;
  height: 24px;
  font-weight: 600;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, done }) => {
    if (done) return theme.colors.primary2;
    return theme.colors.neutral2;
  }};
  background: ${({ theme, done }) => {
    if (done) return theme.colors.primary9;
    return theme.colors.neutral10;
  }};
`;

const TitleContainer = styled.div`
  position: relative;
`;

const AddBtn = styled.button`
  background: transparent;
  border: 0px;
  padding: 0px;
  position: absolute;
  right: 0px;
  top: -4px;
  height: 24px;
  width: 24px;
  &:hover {
    cursor: pointer;
    background: ${({ theme }) => theme.colors.neutral11};
  }
`;

const TODO_TITLE = 45;

const TodoItem = (props) => {
  const { todo, onClick } = props;
  return (
    <TodoContainer onClick={onClick} data-testid={props["data-testid"]}>
      <TodoKey>{todo.key}</TodoKey>
      {todo.title.length > TODO_TITLE ? (
        <Tooltip title={todo.title}>
          <TodoTitle>{`${todo.title.substring(0, TODO_TITLE)}...`}</TodoTitle>
        </Tooltip>
      ) : (
        <TodoTitle>{todo.title}</TodoTitle>
      )}
      <TodoItemEnd>
        {todo.assignedTo && (
          <Tooltip title={`${todo.assignedTo.firstName}`}>
            <Avatar
              style={{ marginRight: 16 }}
              src={
                todo.assignedTo &&
                todo.assignedTo.avatar &&
                todo.assignedTo.avatar.url
              }
            />
          </Tooltip>
        )}
        <TodoStatusTag done={todo.done}>
          {todo.done ? "Done" : "Todo"}
        </TodoStatusTag>
      </TodoItemEnd>
    </TodoContainer>
  );
};

const getPercent = (todos, count): number => {
  let complete: Todo[] = [];
  todos.forEach((todo: Todo) => {
    if (todo.done) {
      complete.push(todo);
    }
  });

  return toNumber(((complete.length / count) * 100).toFixed(0));
};

const ProgressContainer = styled.div`
  margin-top: 8px;
  .ant-progress-bg {
    background-color: ${({ theme }) => theme.colors.blue6};
  }
`;

interface CaseTodosProps {
  caseId?: string;
  businessId?: string;
  onNewTodo: any;
  addNewTodo: any;
  onCancel: any;
}

export const NewTodoInlineForm = ({
  visible,
  onChange,
  onSubmit,
  onCancel,
  loading,
  value,
  submitBtnText,
}: {
  visible: boolean;
  onChange: any;
  onSubmit: any;
  onCancel: any;
  loading?: boolean;
  value?: string;
  submitBtnText?: string;
}) => {
  const textInput = useRef<any>(null);

  useLayoutEffect(() => {
    if (visible && textInput?.current) {
      textInput.current.focus();
    }
  }, [visible]);

  if (!visible) return null;

  return (
    <NewTodoContainer onSubmit={onSubmit}>
      <Input
        // values={newTodoTitle}
        ref={textInput}
        placeholder="What needs to be done?"
        onChange={(e) => onChange(e.target.value)}
        data-testid="case-detail-add-todo-input"
      />
      <NewTodoButton>
        <Button
          style={{ width: 100, marginRight: 8 }}
          disabled={!value || value.length === 0 || loading}
          loading={loading}
          onClick={onSubmit}
          data-testid="case-detail-add-todo-submit-btn"
        >
          {submitBtnText ?? "Create"}
        </Button>
        <Button
          grey
          onClick={onCancel}
          disabled={loading}
          style={{ width: 100 }}
        >
          Cancel
        </Button>
      </NewTodoButton>
    </NewTodoContainer>
  );
};

export default function CaseTodos({
  caseId,
  businessId,
  onNewTodo,
  addNewTodo,
  onCancel,
}: CaseTodosProps) {
  const [newTodoTitle, setNewTodoTitle] = useState<string>("");
  const [saveTodo, { loading: creating }] = useSaveTodoMutation();
  const { onUrlChange } = useUrlChange();
  const { data, loading } = useTodosQuery({
    variables: {
      caseId,
    },
  });

  const handleCreateNewTodo = async (e) => {
    try {
      e && e.preventDefault();

      if (!newTodoTitle?.[1])
        return message.error("Must give the todo a title");

      await saveTodo({
        variables: {
          params: {
            title: newTodoTitle,
            businessId,
            caseId,
          },
        },
        refetchQueries: [
          {
            query: TODOS,
            variables: {
              caseId,
            },
          },
        ],
      });
      onCancel();
      message.success("New todo added");
    } catch (err) {
      console.log(err);
    }
  };

  const todos = data?.todos?.todos;
  const count = data?.todos?.count || 0;

  return (
    <div style={{ marginTop: 24 }}>
      {!loading && count > 0 && (
        <TitleContainer>
          <div>
            <Label>Todos</Label>
            <AddBtn onClick={onNewTodo}>
              <PlusOutlined />
            </AddBtn>
          </div>
          {count >= 2 && (
            <ProgressContainer>
              <Progress percent={getPercent(todos, count)} />
            </ProgressContainer>
          )}
        </TitleContainer>
      )}
      <NewTodoInlineForm
        visible={addNewTodo}
        onSubmit={handleCreateNewTodo}
        onCancel={onCancel}
        loading={creating}
        value={newTodoTitle}
        onChange={(newvalue) => setNewTodoTitle(newvalue)}
      />
      {/* {addNewTodo && (
        <NewTodoContainer onSubmit={handleCreateNewTodo}>
          <Input
            // values={newTodoTitle}
            ref={textInput}
            placeholder="What needs to be done?"
            onChange={(e) => setNewTodoTitle(e.target.value)}
            data-testid="case-detail-add-todo-input"
          />
          <NewTodoButton>
            <Button
              style={{ width: 100, marginRight: 8 }}
              disabled={!newTodoTitle || newTodoTitle.length === 0 || creating}
              loading={creating}
              onClick={handleCreateNewTodo}
              data-testid="case-detail-add-todo-submit-btn"
            >
              Create
            </Button>
            <Button
              grey
              onClick={onCancel}
              disabled={creating}
              style={{ width: 100 }}
            >
              Cancel
            </Button>
          </NewTodoButton>
        </NewTodoContainer>
      )} */}
      {loading && <LoadingOutlined />}
      {todos && todos.length > 0 && (
        <TodoItemsContainer>
          {todos.map((todo) => (
            <TodoItem
              key={todo.id}
              todo={todo}
              onClick={() =>
                onUrlChange({ selectedTodo: todo.id, selectedCase: undefined })
              }
              data-testid="case-detail-add-todo-item"
            />
          ))}
        </TodoItemsContainer>
      )}
    </div>
  );
}
