//     projectsByStage(pipelineId: ID!, stage: Int!): [Project]
import {gql} from '@apollo/client';
import projectFragment from 'ApolloClient/Fragments/projectFragment';

export default gql`
  query projects($searchText: String, $projectIds: [ID]) {
    projects(searchText: $searchText, projectIds: $projectIds) {
      ...projectFragment
      businesses {
        id
        title
        phone
        website
        logo {
          id
          url
        }
      }
    }
  }
  ${projectFragment}
`;
