import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import Popconfirm from "components/common/Popconfirm";
import { Card, CardTitle, CardAvatar, CardActions, RemoveBtn } from "../index";
import { Project } from "generated/graphql";
import Caption from "components/text/Caption";
import Text from "components/text/Text";

export default function ProjectCard({
  item,
  type = "projects",
  onRemove,
}: {
  item: Project;
  onRemove?: any;
  type?: string;
}) {
  return (
    <Card data-testid="project-card">
      <div style={{ display: "flex" }}>
        <CardAvatar type={"projects"} src={item?.image?.url || undefined} />
        <div>
          <CardTitle to={`/app/projects/${item.id}?tab=1`}>
            {item.title}
          </CardTitle>
        </div>
      </div>
      {onRemove && (
        <CardActions className="card-actions">
          <Popconfirm
            title={
              <div style={{ maxWidth: "100%", width: 300 }}>
                <Text>Are you sure you want to remove this project?</Text>
                <Caption>
                  This won't delete the project. It will only remove the
                  project's association to this record.
                </Caption>
              </div>
            }
            onConfirm={onRemove}
            okText="Yes"
          >
            <RemoveBtn>
              <DeleteOutlined />
            </RemoveBtn>
          </Popconfirm>
        </CardActions>
      )}
    </Card>
  );
}
