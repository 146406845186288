import { useState } from "react";

// COMPONENTS

import Row from "components/common/Row";
import Col from "components/common/Col";

import Button from "components/common/Button";
import Table from "components/common/Table";
// APOLLO
import { useQuery } from "@apollo/client";
import PageHeader from "components/text/PageHeader";

interface ResourcesTableProps {
  filters: any;
  dataField: any;
  variables: any;
  onRow: any;
  dataName: string;
  onNew: () => void;
  query: any;
  pagination: any;
  columns?: any[];
}
export default function ResourcesTable({
  filters,
  dataField,
  variables,
  onRow,
  dataName,
  onNew,
  query,
  pagination,
  columns,
}: ResourcesTableProps) {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [current, setCurrent] = useState(1);
  const { data, loading } = useQuery(query, {
    variables: {
      skip,
      limit,
      ...variables,
    },
  });

  return (
    <Row>
      <Col xs={6}>
        <PageHeader style={{ margin: 0 }}>{dataName} Programs</PageHeader>
      </Col>
      <Col xs={12}>{filters && filters}</Col>
      <Col xs={6} style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={() => onNew()}>+ New {dataName}</Button>
      </Col>
      <Col xs={24}>
        <div style={{ height: 8 }} />
        <Table
          dataSource={
            (data && data[dataField] && data[dataField][dataField]) || []
          }
          pagination={{
            pageSize: limit,
            current,
            ...pagination,
            total: (data && data[dataField] && data[dataField].count) || 0,
          }}
          onChange={(current, pageSize) => {
            let skip;

            // skip gets passed to the backend mongo query
            if (current === 1) {
              // if we're on the first page, we don't want to skip any. We want the first X results.
              skip = 0;
            } else {
              // for other pages, we want X items per page * the current page minus 1... i.e. page 2, skip = 5
              skip = (current - 1) * pageSize;
            }
            setCurrent(current);
            setSkip(skip);
            setLimit(pageSize);
          }}
          columns={columns}
          loading={loading}
          rowKey={(record) => record.id}
          onRow={onRow}
        />
      </Col>
    </Row>
  );
}
