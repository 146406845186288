import styled from "styled-components";
import moment from "moment";
import Tooltip from "components/common/Tooltip";
import { Card, CardTitle, CardCaption, CardAvatar } from "../index";
import useUrlChange from "hooks/useUrlChange";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";

const Tag = styled.div`
  padding: 2px 8px;
  border-radius: 25px;
  display: inline-flex;
  font-size: 12px;
  margin-top: 8px;
  font-weight: 600;
  background: ${(p) => {
    if (!p.isFinalStep) {
      return p.theme.colors.yellow9;
    }
    return p.theme.colors.neutral10;
  }};
  color: ${(p) => {
    if (!p.isFinalStep) {
      return p.theme.colors.yellow1;
    }
    return p.theme.colors.neutral1;
  }};
`;

export default function CaseCard({ item, type = "cases", onRemove }) {
  const { onUrlChange } = useUrlChange();
  const onClick = () => {
    return onUrlChange({ selectedCase: item.id });
  };

  return (
    <Card onClick={onClick} data-testid="case-card">
      <div>
        {/* */}
        <div>
          <CardTitle
            to={`${window?.location?.pathname}?selectedCase=${item.id}`}
            data-testid="case-card-title"
          >
            {item.title}
          </CardTitle>
          <CardCaption>
            {moment(parseInt(item.createdAt)).fromNow()}
          </CardCaption>
          <div
            style={{
              marginTop: 24,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Tag isFinalStep={item.caseStatus && item.caseStatus.isFinalStep}>
              {item.caseStatus && item.caseStatus.title}
            </Tag>
            {item && item.assignedTo && (
              <Tooltip title={getFullName(item.assignedTo)}>
                <CardAvatar
                  type={"cases"}
                  src={item?.assignedTo?.avatar?.url}
                />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {/* <CardActions className="card-actions">
        <Popconfirm
          title="Are you sure you want to remove this contact?"
          onConfirm={onRemove}
          okText="Yes"
        >
          <RemoveBtn>
            <DeleteOutlined />
          </RemoveBtn>
        </Popconfirm>
      </CardActions> */}
    </Card>
  );
}
// id: String;
// title: String;
// description: String;
// dueDate: String;
// ('an array of services that this case required');
// serviceIds: [String];
// services: [CaseService];
// businessId: String;
// business: Business;
// contactId: String;
// contact: Contact;
// ('IDs of the users who are assigned tot his case');
// assignedToId: String;
// assignedTo: UserProfile;
// caseCategoryId: String;
// caseCategory: CaseCategory;
// resolutionNotes: String;
// attachments: [Attachment];
// resolvedOn: String;
// createdAt: String;
// updatedAt: String;
