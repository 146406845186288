import React, {useState, useEffect} from 'react';
// APOLLO
import ORGANIZATIONS from 'ApolloClient/Queries/organizations';
import {useQuery} from '@apollo/client';
// COMPONENTS
import {SearchOutlined} from '@ant-design/icons';
import Input from 'antd/lib/input';
import AutoComplete from 'antd/lib/auto-complete';
import 'antd/lib/auto-complete/style/css';

const Option = AutoComplete.Option;

export default function OrganizationsAutocomplete({
  value,
  onChange,
  roles,
  style = {
    width: '100%',
    minWidth: '120px',
    maxWith: '100%',
  },
  size = 'large',
}) {
  const [searchText, setSearchText] = useState((value && value.label) || null);
  const [organizations, setOrganizations] = useState([]);
  let params = {
    searchText,
  };
  if (roles) {
    params.roles = roles;
  }

  const {loading} = useQuery(ORGANIZATIONS, {
    variables: {params},
    onCompleted: (data) => setOrganizations(data.organizations.organizations),
  });

  useEffect(() => {
    setSearchText((value && value.label) || null);
  }, [value]);

  const getOptions = (orgs) => {
    return orgs.map((org) => (
      <Option key={org.id} value={org.id}>
        {`${org.title}`}
      </Option>
    ));
  };

  const handleOnSearch = (searchText) => {
    setSearchText(searchText);
  };

  const handleOnSelect = (selection) => {
    let selectedUser;
    if (organizations && organizations.length) {
      selectedUser = organizations.filter((org) => org.id === selection);
      onChange(selectedUser[0]);
    }
  };

  return (
    <AutoComplete
      dataSource={!loading && organizations ? getOptions(organizations) : []}
      style={style}
      size={size}
      suffix={<SearchOutlined />}
      onSelect={handleOnSelect}
      onSearch={handleOnSearch}
      placeholder="search organizations...."
      value={searchText}
    >
      <Input suffix={<SearchOutlined style={{fontSize: 17}} />} />
    </AutoComplete>
  );
}
