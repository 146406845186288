import { useEffect, useState } from "react";
import queryString from "query-string";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
// COMPONENTS
import Loading from "components/common/Loading";
import message from "components/common/message";
import {
  PageContainer,
  ProjectTitle,
  BreadCrumb,
  BreadCrumbLink,
  DetailImage,
  LeftCol,
  MiddleContent,
  RightCol,
  HeaderContainer,
  HeadLeft,
  HeadMiddle,
  HeadRight,
  Tabs,
  BreadCrumbContainer,
} from "components/detail-components";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import SurveyResponses from "./SurveyResponses";
import Interactions from "components/detail-components/Interactions";
import ActionButtons, {
  ActionButtonTypeEnums,
} from "components/detail-components/ActionButtons";
import FilesAndDocs, {
  FilesAndDocsParentType,
} from "components/detail-components/FilesAndDocs";
import ExpandableList, {
  ExpandableListTypeEnum,
} from "components/detail-components/ExpandableList";
import Notes, { NoteParentTypeEnum } from "components/detail-components/Notes";
import AddEntityDrawer, {
  EntityFormParentTypeEnum,
  ShowEntityFormString,
} from "components/detail-components/AddEntityDrawer";
import ActivityFeed from "components/detail-components/ActivityFeed";
import Todos from "components/detail-components/Todos";
import BusinessDetails from "./BusinessDetails";
// LIB
import FORM_TYPES from "lib/constants/formTypes";
import helpers from "lib/helpers/GeneralHelpers";
// HOOKS
import useSaveBusiness from "hooks/useSaveBusinessHook";
import useOnRemove from "./useOnRemove";
import useUrlChange from "hooks/useUrlChange";
import {
  BusinessByIdDocument,
  CurrentUserFragmentFragment,
  CustomerFeatureSettingKeyEnum,
  EntityTypeEnum,
  useAddCompanyInvestmentMutation,
  useAddEntityToProjectMutation,
  useBusinessByIdQuery,
  UserPermissionEnum,
  useSaveContactMutation,
  useSaveProjectMutation,
} from "generated/graphql";
import theme from "lib/theme";
import Text from "components/text/Text";
import ActionDropdown from "./ActionDropdown";
import EmptyState from "components/common/EmptyState";

interface DetailHeaderProps {
  currentUser: CurrentUserFragmentFragment;
  business: any;
  onSave: any;
  tab: string | string[] | null;
  setTab: any;
  saving?: boolean;
}

const DetailHeader = ({
  currentUser,
  business,
  onSave,
  tab,
  setTab,
  saving,
}: DetailHeaderProps) => {
  const navigate = useNavigate();

  const buttonsToShow = [
    ActionButtonTypeEnums.Call,
    ActionButtonTypeEnums.Email,
    ActionButtonTypeEnums.Note,
    // ActionButtonTypeEnums.Todo,
    ActionButtonTypeEnums.Visit,
  ];

  const tabs = [
    {
      label: "Timeline",
      id: "1",
    },
    {
      label: "Notes",
      id: "2",
    },
    {
      label: "Todos",
      id: "3",
    },
    {
      label: "Files",
      id: "4",
    },
    {
      label: "Interactions",
      id: "5",
    },
  ];

  if (
    helpers.featureIsOn({
      customer: currentUser.customer,
      feature: CustomerFeatureSettingKeyEnum.Surveys,
    })
  ) {
    buttonsToShow.push(ActionButtonTypeEnums.Survey);
    tabs.push({
      label: "Surveys",
      id: "6",
    });
  }

  return (
    <HeaderContainer>
      <HeadLeft>
        <BreadCrumbContainer>
          <BreadCrumb>
            <BreadCrumbLink onClick={() => navigate(`/app/businesses`)}>
              <ArrowLeftOutlined /> Back to Businesses
            </BreadCrumbLink>
          </BreadCrumb>
          {currentUser?.permission?.includes(
            UserPermissionEnum.Administrator
          ) && <ActionDropdown business={business} />}
        </BreadCrumbContainer>
        <div style={{ display: "flex" }}>
          <DetailImage
            src={business?.logo?.url}
            type="businesses"
            onChange={(file) =>
              onSave({
                logo: {
                  ...file,
                },
              })
            }
          />
          <div style={{ marginLeft: 16 }}>
            <ProjectTitle data-testid="business-detail-business-title">
              {business.title}
              {saving && (
                <span
                  style={{
                    fontSize: 12,
                    marginRight: 6,
                    marginLeft: 6,
                    color: theme.colors?.neutral4,
                  }}
                >
                  <LoadingOutlined style={{ fontSize: 10, marginRight: 4 }} />
                  Saving...
                </span>
              )}
            </ProjectTitle>
          </div>
        </div>
      </HeadLeft>
      <HeadMiddle>
        <Tabs active={tab} onClick={(tab) => setTab(tab)} tabs={tabs} />
      </HeadMiddle>
      <HeadRight>
        <ActionButtons business={business} buttonsToShow={buttonsToShow} />
      </HeadRight>
    </HeaderContainer>
  );
};

export default function AppDetailBusiness({ currentUser }) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [showEntityForm, setShowEntityForm] = useState<
    ShowEntityFormString | false
  >(false);
  // const [saveBusiness] = useSaveBusinessMutation();
  const [saveContact, { loading: savingContact }] = useSaveContactMutation();
  const [saveProject] = useSaveProjectMutation();
  const [addCompanyInvestment] = useAddCompanyInvestmentMutation();
  const { onUrlChange } = useUrlChange();
  const [addEntityToProject] = useAddEntityToProjectMutation();
  const { data, loading, error, networkStatus } = useBusinessByIdQuery({
    variables: {
      id: params?.id || "",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    notifyOnNetworkStatusChange: true,
    skip: !params?.id,
  });
  const { onSaveBusiness, loading: savingBusiness } = useSaveBusiness();

  // set the tab in the URL if one does not exist
  useEffect(() => {
    const { tab } = queryString.parse(location.search);
    if (!tab) {
      navigate(`/app/businesses/${params?.id}?tab=1`, { replace: true });
    }
  }, [params?.id, navigate, location]);

  // grab the tab from the URL
  const { tab } = queryString.parse(location.search);
  // change the tab in the URL on tab change
  const setTab = (tab) => {
    navigate(`/app/businesses/${params?.id}?tab=${tab}`, { replace: true });
  };

  let business = data && data.businessById;

  const { onRemove } = useOnRemove({ business });

  // if we get an error, show that
  if (error)
    return (
      <EmptyState
        title="We could not find this record..."
        subtitle="If you think that you reached this by mistake, please fill in a support request."
      />
    );

  if (loading && networkStatus !== 4) return <Loading />;

  if (!business) {
    return (
      <div style={{ marginTop: 32, marginLeft: 32 }}>
        We could not find a business with this ID...{" "}
        <Link to={`/app/businesses`}>go back to businesses</Link>
      </div>
    );
  }

  // onSaveAdd is called when adding a business or other entityt
  const onSaveAdd = async (saveItems) => {
    try {
      let refetchQueries = [
        {
          query: BusinessByIdDocument,
          variables: {
            id: business?.id,
          },
        },
      ];

      if (showEntityForm === ShowEntityFormString.projects) {
        // add it to each project
        let projectIds: any = business?.projects?.map((i) => i?.id) || [];
        let newProjectIds = saveItems
          .filter((i) => !projectIds.includes(i.id))
          ?.map((item) => item.id);
        // first compare the new data to the old data to make sure they are actually adding a new contact
        if (JSON.stringify(projectIds) !== JSON.stringify(newProjectIds)) {
          saveItems
            .filter((i) => !projectIds.includes(i.id))
            .map(async (item) => {
              if (!business?.id) return;
              await addEntityToProject({
                variables: {
                  projectId: item.id,
                  entityId: business?.id,
                  entityType: EntityTypeEnum.Businesses,
                },
                refetchQueries,
              });
            });
          message.success("Business added to project");
        }
      }

      if (showEntityForm === ShowEntityFormString.contacts) {
        console.log(ShowEntityFormString.contacts, "this thing ran");
        // first compare the new data to the old data to make sure they are actually adding a new contact
        const newContactIds = [
          ...(business?.contactIds || []),
          ...saveItems
            .map((item) => item.id)
            ?.filter((id) => !business?.contactIds?.includes(id)),
        ];
        if (
          JSON.stringify(business?.contactIds) !== JSON.stringify(newContactIds)
        ) {
          await onSaveBusiness(
            {
              id: business?.id,
              contactIds: newContactIds,
            },
            data?.businessById
          );
          message.success("Contact added to business");
        }
      }

      setShowEntityForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  const onCreate = async (newEntity) => {
    try {
      let res;

      if (showEntityForm === ShowEntityFormString.companyInvestments) {
        await addCompanyInvestment({
          variables: {
            ...newEntity,
            investedOn:
              newEntity.investedOn &&
              newEntity.investedOn.valueOf &&
              newEntity.investedOn.valueOf().toString(),
            businessId: business?.id,
          },
          refetchQueries: [
            {
              query: BusinessByIdDocument,
              variables: {
                id: business?.id,
              },
            },
          ],
        });
        setShowEntityForm(false);
        return message.success(`Investment created!`);
      }

      if (showEntityForm === ShowEntityFormString.projects) {
        await saveProject({
          variables: {
            params: {
              ...newEntity,
              businessIds: [business?.id],
              stage: 0,
              order: 0,
            },
          },
          refetchQueries: [
            {
              query: BusinessByIdDocument,
              variables: {
                id: business?.id,
              },
            },
          ],
        });
        setShowEntityForm(false);
        return message.success(`Project created!`);
      }

      if (showEntityForm === ShowEntityFormString.contacts) {
        res = await saveContact({
          variables: {
            params: {
              ...newEntity,
            },
          },
        });
        message.success(`Contact created!`);
        onSaveAdd([res.data.saveContact]);
      }
    } catch (err: any) {
      console.log(err);
      message.error(err?.message);
    }
  };

  return (
    <div>
      <DetailHeader
        business={business}
        setTab={setTab}
        currentUser={currentUser}
        tab={tab}
        onSave={(params) => onSaveBusiness(params, data?.businessById)}
        saving={savingBusiness}
      />
      <PageContainer>
        <LeftCol>
          <BusinessDetails
            business={business}
            onSave={(params) => onSaveBusiness(params, data?.businessById)}
          />
        </LeftCol>
        <MiddleContent>
          {tab === "1" && (
            <ActivityFeed
              parentId={business.id}
              parentType={NoteParentTypeEnum.Businesses}
            />
          )}
          {tab === "2" && (
            <Notes
              parentId={business.id || ""}
              parentType={NoteParentTypeEnum.Businesses}
              currentUser={currentUser}
            />
          )}
          {tab === "3" && (
            <Todos
              queryVariables={{ businessId: business.id }}
              business={business}
            />
          )}{" "}
          {tab === "4" && (
            <FilesAndDocs
              parentId={business?.id || ""}
              parentType={FilesAndDocsParentType.Businesses}
            />
          )}
          {tab === "5" && (
            <Interactions
              queryVariables={{
                businessId: business.id,
              }}
            />
          )}
          {tab === "6" &&
            helpers.featureIsOn({
              customer: currentUser.customer,
              feature: CustomerFeatureSettingKeyEnum.Surveys,
            }) && <SurveyResponses businessId={business?.id} />}
        </MiddleContent>{" "}
        <RightCol>
          <ExpandableList
            items={business.contacts || []}
            parent={business}
            title="Contacts"
            empty="View all interactions with this company’s contacts in one place. Add an existing contact from your CRM or create a new one."
            onRemove={(item) => onRemove(item, "contacts")}
            onAdd={() => setShowEntityForm(ShowEntityFormString.contacts)}
            type={ExpandableListTypeEnum.contacts}
            onMakePrimaryContact={async (primaryContact) => {
              try {
                if (primaryContact?.id) {
                  await onSaveBusiness(
                    { primaryContactId: primaryContact?.id },
                    data?.businessById
                  );
                }
              } catch (err) {
                console.log(err);
              }
            }}
          />
          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Cases,
          }) && (
            <ExpandableList
              items={business.cases || []}
              title="Cases"
              empty="This is a place where you can log cases that you have with this business."
              onRemove={(item) => console.log(item, "cases")}
              onAdd={() => {
                onUrlChange({
                  drawerVisible: FORM_TYPES.CASES,
                  proejctId: business?.id,
                });
              }}
              type={ExpandableListTypeEnum.cases}
            />
          )}
          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Projects,
          }) && (
            <ExpandableList
              items={business.projects || []}
              title="Projects"
              empty="Use projects to track all your opportunities."
              onAdd={() => setShowEntityForm(ShowEntityFormString.projects)}
              onRemove={(item) => onRemove(item, "projects")}
              type={ExpandableListTypeEnum.projects}
            />
          )}
          <ExpandableList
            items={business?.companyInvestments || []}
            title="Investments"
            empty="This is a place where you can log investments the company has made for future reference. Things like captial investments or community investments that you would like to have a history of in your CRM."
            onRemove={(item) => onRemove(item, "companyInvestments")}
            onAdd={() =>
              setShowEntityForm(ShowEntityFormString.companyInvestments)
            }
            type={ExpandableListTypeEnum.companyInvestments}
          />
        </RightCol>
      </PageContainer>
      <AddEntityDrawer
        visible={showEntityForm ? true : false}
        type={showEntityForm ? showEntityForm : undefined}
        defaultSelected={business[showEntityForm as any]}
        parentType={EntityFormParentTypeEnum.business}
        parentId={business.id}
        loading={savingBusiness || savingContact}
        onSave={onSaveAdd}
        onCreate={onCreate}
        onRemove={onRemove}
        onClose={() => setShowEntityForm(false)}
      />
    </div>
  );
}
