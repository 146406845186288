import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Modal from "components/common/Modal";
import { ObjectTypeEnum, useDeleteOneContactMutation } from "generated/graphql";
import { Popover } from "antd";
import ObjectPermissionsManager from "components/common/ObjectPermissionsManager";

const TransparentBtn = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
  font-weight: 500;
  border-radius: 5px;
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral4};
  padding: 4px 8px;
  &:hover {
    background: ${({ theme }) => theme.colors.neutral9};
  }
`;

const ActionDropdownContainer = styled.div`
  font-size: 14px;
  display: flex;
  justify-content: space-between;
`;

const { confirm } = Modal;

export default function ActionDropdown({ contact }) {
  const [deleteOneContactMutation] = useDeleteOneContactMutation();
  const navigate = useNavigate();

  return (
    <ActionDropdownContainer>
      <TransparentBtn
        style={{ marginRight: 16 }}
        onClick={() => {
          confirm({
            className: "growlab-confirm-modal",
            title: "Do you want to delete this contact?",
            icon: <ExclamationCircleOutlined />,
            content:
              "You will not be able to recover it. Are you sure you want to delete this? ",
            async onOk() {
              try {
                await deleteOneContactMutation({
                  variables: {
                    id: contact.id,
                  },
                  refetchQueries: ["contacts"],
                });
                navigate("/app/contacts");
              } catch (err) {
                console.log(err);
              }
            },
            okText: "Yes I'm sure.",
            onCancel() {
              console.log("Cancel");
            },
          });
        }}
      >
        Delete
      </TransparentBtn>
      <Popover
        destroyTooltipOnHide
        content={
          <ObjectPermissionsManager
            objectType={ObjectTypeEnum?.Contact}
            id={contact?.id}
          />
        }
        placement="bottom"
      >
        <TransparentBtn
          onClick={() => console.log("permissions")}
          data-testid="sharing-group-permissions-btn"
        >
          Share
        </TransparentBtn>
      </Popover>
    </ActionDropdownContainer>
  );
}
