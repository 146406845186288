import {
  AutomationInput,
  AutomationTriggerType,
  useCreateOneAutomationMutation,
} from "generated/graphql";
import { DefaultPageProps } from "lib/@types/DefaultPageProps";
import useUrlChange from "hooks/useUrlChange";
import ProjectStatusChangeForm from "./ProjectStatusChangeForm";
import SurveySubmissionForm from "./SurveySubmissionForm";
import SelectAutomation from "./SelectAutomation";
import { useNavigate } from "react-router-dom";
import message from "components/common/message";

export const formatAutomatinInput = (automation: any): AutomationInput => {
  return {
    ...automation,
    name: automation?.name,
    events: JSON.stringify(automation?.events),
    conditions: JSON.stringify(automation?.conditions),
    trigger: automation?.trigger,
  };
};

export default function AutomationsCreate({ currentUser }: DefaultPageProps) {
  const [createOneAutomationMutation, { loading }] =
    useCreateOneAutomationMutation();
  const navigate = useNavigate();
  const handleCreate = async (automation: AutomationInput) => {
    try {
      await createOneAutomationMutation({
        variables: {
          params: automation,
        },
      });
      message.success("Automation created!");
      navigate("/app/settings?sidenav=automations");
    } catch (err) {}
  };

  const { query } = useUrlChange();

  return (
    <div>
      {(() => {
        switch (query?.actionTriggerType) {
          case AutomationTriggerType?.ProjectStatusChange?.toString():
            return (
              <ProjectStatusChangeForm
                onSubmit={handleCreate}
                loading={loading}
                currentUser={currentUser}
              />
            );
          case AutomationTriggerType?.FormSurveyResponse?.toString():
            return (
              <SurveySubmissionForm
                onSubmit={handleCreate}
                loading={loading}
                currentUser={currentUser}
              />
            );

          default:
            return <SelectAutomation currentUser={currentUser} />;
        }
      })()}
    </div>
  );
}
