import Select from "antd/lib/select";
import useUrlChange from "hooks/useUrlChange";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { useCaseStatusesQuery } from "generated/graphql";
const { Option } = Select;

interface CaseStatusInputProps {
  value?: string | string[] | null;
  disabled?: boolean;
  placeholder?: string;
  onChange: any;
  style?: React.CSSProperties;
  /** input should show "final/closable" statuses onlly */
  finalStepsOnly?: boolean;
  onBlur?: any;
  mode?: "multiple";
}

export default function CaseStatusInput(props: CaseStatusInputProps) {
  const {
    value,
    disabled,
    finalStepsOnly,
    placeholder,
    onChange,
    onBlur,
    mode,
    style = {},
  } = props;
  const { data, loading } = useCaseStatusesQuery({
    fetchPolicy: "cache-and-network",
  });
  const { onUrlChange } = useUrlChange();

  const statuses = finalStepsOnly
    ? data?.caseStatuses?.filter((status) => status?.isFinalStep)
    : data?.caseStatuses;

  return (
    <DropdownStyleWrapper>
      <Select
        onBlur={onBlur}
        value={value}
        mode={mode}
        disabled={disabled}
        placeholder={placeholder}
        data-testid={props["data-testid"] || "case-status-input"}
        style={{ width: "100%", ...style }}
        filterOption={false}
        notFoundContent={loading ? "searching..." : null}
        onChange={(value) => {
          if (value === "add-new-status") {
            return onUrlChange({ mode: "addNewStatus" }, `/app/cases`);
          }
          onChange(value);
        }}
      >
        {statuses?.map((item, index) => {
          if (!item?.id) return null;
          return (
            <Option
              key={item.id}
              data-testid={`case-form-caseStatusId-option-${index}`}
              value={item.id}
            >
              {item.title}
            </Option>
          );
        })}
        {/* <Option key="add-new-status" value="add-new-status">
          + Add New Status
        </Option> */}
      </Select>
    </DropdownStyleWrapper>
  );
}
