import React from 'react';
import styled from 'styled-components';
// COMPONENTS
import CommentForm from './CommentForm';
import Comment from './Comment';
import Loading from 'components/common/Loading';
import message from 'components/common/message';
// APOLLO CLIENT
import COMMENTS from 'ApolloClient/Queries/comments';
import SAVE_COMMENT from 'ApolloClient/Mutations/saveComment';
import {useQuery, useMutation} from '@apollo/client';

const Label = styled.div`
  width: 118px;
  margin-left: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: ${(p) => p.theme.colors.neutral2};
`;

export default function Comments({todoId, currentUser}) {
  const [saveComment, {loading: saving}] = useMutation(SAVE_COMMENT);
  const {data, loading} = useQuery(COMMENTS, {
    variables: {todoId},
  });
  const onSave = async (newValues, commentId) => {
    try {
      let variables = {
        id: commentId,
        params: {
          ...newValues,
        },
        optimisticResponse: {
          __typename: 'Mutation',
          saveComment: {
            id: commentId,
            __typename: 'Comment',
            ...newValues,
          },
        },
      };

      await saveComment({variables});
      message.success('Your comment was saved');
    } catch (err) {
      console.log(err);
    }
  };

  const onNew = async (newValues) => {
    try {
      let variables = {
        params: {
          todoId: todoId,
          ...newValues,
        },
      };
      await saveComment({
        variables,
        update: (proxy, {data}) => {
          let existing = proxy.readQuery({
            query: COMMENTS,
            variables: {
              todoId: todoId,
            },
          });
          proxy.writeQuery({
            query: COMMENTS,
            variables: {
              todoId: todoId,
            },
            data: {
              ...existing,
              comments: [...existing.comments, data.saveComment],
            },
          });
          message.success('Your comment was added');
          return null;
        },
      });
      console.log(variables);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div style={{marginTop: 80}}>
      <Label>Comments</Label>
      {!loading &&
        data.comments.length > 0 &&
        data.comments.map((comment) => (
          <Comment
            key={comment.id}
            comment={comment}
            onSave={onSave}
            todoId={todoId}
            currentUser={currentUser}
          />
        ))}
      {saving ? (
        <Loading />
      ) : (
        <CommentForm onSubmit={onNew} currentUser={currentUser} />
      )}
    </div>
  );
}
