import { useState, useEffect } from "react";
import { get } from "lodash";
import Select from "antd/lib/select";
import { useQuery } from "@apollo/client";
import NAICS_SEARCH from "ApolloClient/Queries/naicsSearch";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

const { Option } = Select;

export default function NaicsInput({
  value,
  defaultSearch,
  placeholder,
  onChange,
}: // defaultContactId, // in cases where
any) {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { data, loading } = useQuery(NAICS_SEARCH, {
    variables: {
      searchText,
      // contactIds:
      //   !searchText && defaultContactId ? [defaultContactId] : undefined,
    },
  });

  const naics = get(data, "naicsSearch", []);

  useEffect(() => {
    setSearchText(defaultSearch);
  }, [defaultSearch]);

  return (
    <DropdownStyleWrapper>
      <Select
        value={!loading && value}
        placeholder={placeholder}
        onSearch={(value) => setSearchText(value)}
        showSearch
        mode={"multiple"}
        showArrow={false}
        filterOption={false}
        style={{ width: "100%" }}
        notFoundContent={loading ? "searching..." : null}
        onChange={(value) => {
          if (value === "clear") {
            return onChange(undefined);
          }
          onChange(value);
        }}
      >
        {naics &&
          naics.map((item) => {
            return (
              <Option key={item.id} value={item.id}>
                {item.title} ({item.code})
              </Option>
            );
          })}
      </Select>
    </DropdownStyleWrapper>
  );
}

// import React from 'react';
// //ANTD
// import SearchInput from 'components/common/SearchInput';
// import Tag from 'components/common/Tag';

// // EXPORTED COMPONENT
// // ================================================================

// const NaicsInput = ({value = [], onChange}) => {
//   const onRemove = (idToRemove) => {
//     // remove the item and call onChange
//     onChange(value.filter((item) => item.id !== idToRemove));
//   };

//   // sometimes naics come in as a stringified array and need to be parsed...
//   if (typeof value === 'string') {
//     value = JSON.parse(value);
//   }

//   return (
//     <div>
//       <div style={{marginBottom: 16}}>
//         {value &&
//           value.length > 0 &&
//           value.map((item) => {
//             return (
//               <Tag closable onClose={() => onRemove(item.id)} key={item.id}>
//                 {item.title}
//               </Tag>
//             );
//           })}{' '}
//       </div>
//       <SearchInput
//         type="naics"
//         onSelect={(newValue) => {
//           if (value) {
//             onChange([...value, newValue]);
//           } else {
//             onChange([newValue]);
//           }
//         }}
//       />
//     </div>
//   );
// };

// class NaicsInput extends React.PureComponent {
//   state = {
//     searchText: undefined,
//   };
//   render() {
//     return (
//       <Query
//         query={naicsSearch}
//         variables={{searchText: this.state.searchText}}
//       >
//         {({data, loading, error}) => {
//           return (
//             <Select
//               onSearch={(searchText) => this.setState({searchText})}
//               size="large"
//               searchValue={this.state.searchText}
//               onChange={(values) => {
//                 let val1 = [];
//                 values.forEach((val) => {
//                   val1.push(
//                     [...data.naicsSearch, ...this.props.currentValues].filter(
//                       (item) => item.id === val
//                     )[0]
//                   );
//                 });

//                 this.props.onChange(val1);
//                 this.setState({searchText: undefined});
//               }}
//               value={this.props.value}
//               showSearch
//               style={{width: '100%'}}
//               mode="multiple"
//               showArrow={false}
//               optionFilterProp="children"
//             >
//               {loading
//                 ? [<Option key="Searching...">Searching...</Option>]
//                 : data.naicsSearch.map((item) => {
//                     return (
//                       <Option key={item.id} value={item.id}>
//                         {item.title} ({item.code})
//                       </Option>
//                     );
//                   })}
//             </Select>
//           );
//         }}
//       </Query>
//     );
//   }
// }
