import CaseCategoryInput from "components/inputs/CaseCategoryInput";
import CaseServicesInput from "components/inputs/CaseServicesInput";
import CaseStatusInput from "components/inputs/CaseStatusInput";
import DateInput from "components/inputs/DateInput";
import styled from "styled-components";
import { EditFieldEnum } from "../CaseDetailContent";
import moment from "moment";
import { Label } from "../CaseDetailContent/index";
import Tag from "components/common/Tag";
import UserInput from "components/inputs/UserInput";
import Avatar from "components/common/Avatar";
import BusinessInput from "components/inputs/BusinessInput";
import BusinessLogo from "components/common/BusinessLogo";
import ContactInputSimple from "components/inputs/ContactInputSimple";
import CustomTagsInput from "components/inputs/CustomTagsInput";
import ProjectInput from "components/inputs/ProjectInput";
import SelectInput from "components/inputs/SelectInput";
import { useGetManyCaseOutcomesQuery } from "generated/graphql";
import Row from "components/common/Row";
import Col from "components/common/Col";
import { EditOutlined } from "@ant-design/icons";
import Text from "components/text/Text";

const BlockItemContent = styled.div`
  padding: 4px;
  width: 100%;
  border-radius: 5px;
  flex: 1;
  min-height: 48px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  .anticon-edit {
    display: none;
    position: absolute;
    right: 16px;
    cursor: pointer;
  }
  &:hover {
    cursor: text;
    background: ${(p) => p.theme.colors.neutral11};
    .anticon-edit {
      display: inherit;
    }
  }
`;

const None = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
  font-weight: 500;
`;

const ErrorText = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.red3};
`;

const BlockItemRow = styled(Row)`
  min-height: 52px;
  margin-top: 0px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    min-height: 72px;
    margin-top: 24px;
  }
`;

const TransparentBtn = styled.button`
  border: 0px;
  background: transparent;
  box-shadow: none;
  cursor: pointer;
  padding: 8px;
  &:hover {
    background: ${(p) => p.theme.colors.neutral10};
  }
`;

const BlockItem = ({ label, children, editable, editing, setEditing }) => {
  return (
    <BlockItemRow align="middle">
      <Col xs={24} sm={6}>
        <Label style={{ margin: 0 }}>{label}</Label>
      </Col>
      <Col xs={24} sm={18}>
        {!editing ? (
          <BlockItemContent
            onClick={!children ? setEditing : undefined}
            id="todo-block-item"
            data-testid={`block-item-content-${label}`}
          >
            {children || <None>None</None>}
            <EditOutlined
              onClick={setEditing}
              data-testid={`block-item-edit-btn-${label}`}
            />
          </BlockItemContent>
        ) : (
          <>{editable}</>
        )}
      </Col>
    </BlockItemRow>
  );
};

export default function BlockItems({
  getCaseStatus,
  caseStatus,
  editing,
  onSaveCase,
  setConfirmCloseModal,
  values,
  setValues,
  setEditing,
  errors,
}) {
  const { data: caseOutcomesQuery } = useGetManyCaseOutcomesQuery();

  return (
    <div style={{ marginTop: 40 }}>
      <BlockItem
        label="Status"
        editing //</div>={!editing || editing === EditFieldEnum.Status} // slightly different logic here, so that the status dropdown sticks out more visiaully (becuase it'll be active if nothing else is being edited)
        setEditing={() => setEditing(EditFieldEnum.Status)}
        editable={
          <CaseStatusInput
            value={values.caseStatusId}
            data-testid="case-detail-caseStatusId"
            onBlur={() => setEditing(false)}
            onChange={(caseStatusId) => {
              let caseStatus = getCaseStatus(caseStatusId);
              if (caseStatus?.isFinalStep) {
                setEditing(false);
                setValues({
                  ...values,
                });
                return setConfirmCloseModal(true);
              }
              setValues({
                ...values,
                caseStatusId,
                caseStatus: getCaseStatus(caseStatusId),
              });
              onSaveCase({ ...values, caseStatusId });
              setEditing(false);
            }}
          />
        }
      >
        {values.caseStatus ? <>{values.caseStatus.title}</> : <None>None</None>}
      </BlockItem>
      <BlockItem
        label="Category"
        editing={editing === EditFieldEnum.Category}
        setEditing={() => setEditing(EditFieldEnum.Category)}
        editable={
          <CaseCategoryInput
            value={values.caseCategoryId}
            onBlur={() => setEditing(false)}
            onChange={(caseCategoryId) => {
              onSaveCase({ ...values, caseCategoryId });
              setEditing(false);
            }}
          />
        }
      >
        {values.caseCategory ? (
          <>{values.caseCategory.title}</>
        ) : (
          <None>None</None>
        )}
      </BlockItem>

      {/* serviceIds: [String] services: [CaseService] */}
      <BlockItem
        label="Services"
        editing={editing === EditFieldEnum.Services}
        setEditing={() => setEditing(EditFieldEnum.Services)}
        editable={
          <CaseServicesInput
            value={values.serviceIds}
            defaultServiceIds={values.serviceIds}
            onBlur={() => setEditing(false)}
            onChange={(serviceIds) => {
              onSaveCase({ ...values, serviceIds });
              setEditing(false);
            }}
          />
        }
      >
        {values.services && values.services.length > 0 ? (
          <>
            {values.services.map((item) => (
              <Tag key={item.id} style={{ marginBottom: 4 }} grey>
                {item.title}
              </Tag>
            ))}
          </>
        ) : (
          <None>None</None>
        )}
      </BlockItem>

      {caseStatus && caseStatus.isFinalStep && (
        <>
          <BlockItem
            label="Resolved On"
            editing={editing === EditFieldEnum.ResolvedOn}
            setEditing={() => setEditing(EditFieldEnum.ResolvedOn)}
            editable={
              <DateInput
                value={values.resolvedOn}
                showTime={false}
                format="M/D/YY"
                onChange={(resolvedOn) => {
                  setValues({
                    ...values,
                    resolvedOn: resolvedOn.valueOf().toString(),
                  });
                  onSaveCase({ ...values, resolvedOn });
                  setEditing(false);
                }}
              />
            }
          >
            {values.resolvedOn ? (
              <>{moment(parseInt(values.resolvedOn)).format("M/D/YY")}</>
            ) : (
              <None>None</None>
            )}
          </BlockItem>
          {errors.resolvedOn && <ErrorText>{errors.resolvedOn} </ErrorText>}
        </>
      )}
      <BlockItem
        label="Due"
        editing={editing === EditFieldEnum.Due}
        setEditing={() => setEditing(EditFieldEnum.Due)}
        editable={
          <DateInput
            value={values?.dueDate}
            showTime={false}
            format="M/D/YY"
            onChange={(dueDate) => {
              onSaveCase({ ...values, dueDate });
              setEditing(false);
            }}
          />
        }
      >
        {values.dueDate ? (
          <>
            {" "}
            {moment(parseInt(values.dueDate)).fromNow()} (
            {moment(parseInt(values.dueDate)).format("M/D/YY")})
          </>
        ) : (
          <None>None</None>
        )}
      </BlockItem>
      <BlockItem
        label="Assignee"
        editing={editing === EditFieldEnum.Assignee}
        setEditing={() => setEditing(EditFieldEnum.Assignee)}
        editable={
          <UserInput
            value={values.assignedToId}
            defaultUserId={values.assignedToId}
            data-testid={`block-item-input-assignedToId`}
            onBlur={() => setEditing(false)}
            onChange={(assignedToId) => {
              onSaveCase({ ...values, assignedToId });
              setEditing(false);
            }}
          />
        }
      >
        {values.assignedTo ? (
          <>
            <Avatar
              src={
                values.assignedTo &&
                values.assignedTo.avatar &&
                values.assignedTo.avatar.url
              }
              style={{ marginRight: 8 }}
            />
            {values.assignedTo.firstName} {values.assignedTo.lastName}
          </>
        ) : (
          <None>None</None>
        )}
      </BlockItem>
      <BlockItem
        label="Reviewer"
        editing={editing === EditFieldEnum.Reporter}
        setEditing={() => setEditing(EditFieldEnum.Reporter)}
        editable={
          <UserInput
            value={values.reporterId}
            data-testid={`block-item-input-reporter`}
            defaultUserId={values.reporterId}
            onBlur={() => setEditing(false)}
            onChange={(reporterId) => {
              onSaveCase({ ...values, reporterId });
              setEditing(false);
            }}
          />
        }
      >
        {values.reporter ? (
          <>
            <Avatar
              src={
                values.reporter &&
                values.reporter.avatar &&
                values.reporter.avatar.url
              }
              style={{ marginRight: 8 }}
            />
            {values.reporter.firstName} {values.reporter.lastName}
          </>
        ) : null}
      </BlockItem>
      <BlockItem
        label="Business"
        editing={editing === EditFieldEnum.Business}
        setEditing={() => setEditing(EditFieldEnum.Business)}
        editable={
          <BusinessInput
            value={values.businessId}
            defaultBusinessId={values.businessId}
            onBlur={() => setEditing(false)}
            onChange={(businessId) => {
              onSaveCase({ ...values, businessId });
              setEditing(false);
            }}
          />
        }
      >
        {values?.business ? (
          <TransparentBtn
            onClick={() =>
              window.open(
                `/app/businesses/${values?.business?.id}`,
                "_blank",
                "rel=noopener noreferrer"
              )
            }
          >
            <Text>
              {values.business.logo && (
                <BusinessLogo
                  src={values.business.logo.url}
                  alt={values.business.logo.filename}
                  width="32"
                  style={{ marginRight: 4 }}
                />
              )}
              {values?.business?.title}
            </Text>
          </TransparentBtn>
        ) : null}
      </BlockItem>
      <BlockItem
        label="Contact"
        editing={editing === EditFieldEnum.Contact}
        setEditing={(e) => {
          return setEditing(EditFieldEnum.Contact);
        }}
        editable={
          <ContactInputSimple
            value={values.contactId}
            defaultContactId={values.contactId}
            onBlur={() => setEditing(false)}
            onChange={(contactId) => {
              onSaveCase({ ...values, contactId });
              setEditing(false);
            }}
          />
        }
      >
        {values.contact ? (
          <TransparentBtn
            onClick={() =>
              window.open(
                `/app/contacts/${values?.contact?.id}`,
                "_blank",
                "rel=noopener noreferrer"
              )
            }
          >
            <Text>
              <Avatar
                src={
                  values.contact &&
                  values.contact.avatar &&
                  values.contact.avatar.url
                }
                style={{ marginRight: 8 }}
              />
              {values.contact.firstName} {values.contact.lastName}
            </Text>
          </TransparentBtn>
        ) : null}
      </BlockItem>
      <BlockItem
        label="Project"
        editing={editing === EditFieldEnum.Project}
        setEditing={(e) => {
          return setEditing(EditFieldEnum.Project);
        }}
        editable={
          <ProjectInput
            value={values.projectId}
            defaultProjectId={values.projectId}
            onBlur={() => setEditing(false)}
            onChange={(projectId) => {
              onSaveCase({ ...values, projectId });
              setEditing(false);
            }}
          />
        }
      >
        {values.project ? (
          <TransparentBtn
            onClick={() =>
              window.open(
                `/app/projects/${values?.project?.id}`,
                "_blank",
                "rel=noopener noreferrer"
              )
            }
          >
            <p>{values.project.title}</p>
          </TransparentBtn>
        ) : null}
      </BlockItem>
      <BlockItem
        label="Tags"
        editing={editing === EditFieldEnum.Tags}
        setEditing={() => setEditing(EditFieldEnum.Tags)}
        editable={
          <CustomTagsInput
            value={values.tagIds}
            onChange={(tagIds) => {
              onSaveCase({ ...values, tagIds });
              setEditing(false);
            }}
          />
        }
      >
        {values.tags && values.tags.length > 0 ? (
          <>
            {values.tags.map((item) => (
              <Tag key={item.id} style={{ marginBottom: 4 }} grey>
                {item.title}
              </Tag>
            ))}
          </>
        ) : null}
      </BlockItem>

      <BlockItem
        label="Outcomes"
        editing={editing === EditFieldEnum.Outcomes}
        setEditing={() => setEditing(EditFieldEnum.Outcomes)}
        editable={
          <SelectInput
            value={
              values.caseOutcomeIds || values.caseOutcomes?.map((i) => i.id)
            }
            options={
              caseOutcomesQuery?.getManyCaseOutcomes?.map((outcome) => ({
                id: outcome?.id || "",
                label: outcome?.title || "",
              })) || []
            }
            mode="multiple"
            onBlur={() => setEditing(false)}
            onChange={(caseOutcomeIds) => {
              onSaveCase({ ...values, caseOutcomeIds });
              setEditing(false);
            }}
          />
        }
      >
        {values?.caseOutcomes?.[0] ? (
          <>
            {values.caseOutcomes.map((item) => (
              <Tag key={item.id} style={{ marginBottom: 4 }} grey>
                {item.title}
              </Tag>
            ))}
          </>
        ) : null}
      </BlockItem>

      {/* 
      
      
      <BlockItem
        label="Case"
        editing={editing === 'CASE'}
        setEditing={() => setEditing('CASE')}
        editable={
          <CaseInput
            value={todo.caseId}
            defaultCaseId={todo.caseId}
            onChange={(caseId) => {
              onSaveCase({...todo, caseId});
              setEditing(false);
            }}
          />
        }
      >
        {todo.case ? todo.case.title : <None>None</None>}
      </BlockItem>
      

     
      <BlockItem
        label="Project"
        editing={editing === 'Project'}
        setEditing={() => setEditing('Project')}
        editable={
          <ProjectInput
            value={todo.projectId}
            defaultProjectId={todo.projectId}
            onChange={(projectId) => {
              onSaveCase({...todo, projectId});
              setEditing(false);
            }}
          />
        }
      >
        {todo.project ? todo.project.title : <None>None</None>}
      </BlockItem>*/}
    </div>
  );
}
