import { useState } from "react";
import styled from "styled-components";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import { DragDropContext } from "react-beautiful-dnd";
// CCOMPONENTS
import EmptyState from "./EmptyState";
import ProjectTypeForm from "./ProjectTypeForm";
import TableView from "./TableView";
import StageBlock from "./StageBlock";
import PipelineForm from "./PiplineForm";
import TempCol from "./TempCol";
import PipelinesDropdown from "./PipelinesDropdown";
import message from "components/common/message";
import Loading from "components/common/Loading";
import { ProjectOutlined, TableOutlined } from "@ant-design/icons";
import formTypes from "lib/constants/formTypes";
// LIB
import theme from "lib/theme";
// HOOKs
import useOnDragEnd from "./useOnDragEnd";
import {
  PipelinesByCustomerDocument,
  useAddPipelineColMutation,
  usePipelinesByCustomerQuery,
  useSavePipelineMutation,
  useSaveProjectTypeMutation,
} from "generated/graphql";
import Button from "components/common/Button";
import useUrlChange from "hooks/useUrlChange";

const Lists = styled.div`
  display: flex;
  overflow-x: auto;
  > * {
    flex: 0 0 auto;
    margin-right: 8px;
  }
  &::after {
    content: "";
    flex: 0 0 16px;
  }
`;

const NewColBtn = styled.button`
  background: ${(p) => p.theme.colors.neutral9};
  border: 0px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${(p) => p.theme.colors.neutral8};
  }
`;

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
  background: #fff;
  padding: 16px;
`;

const Toggler = styled.div`
  border-radius: 5px;
  background: ${(p) => p.theme.colors.neutral9};
  height: 32px;
  width: 150px;
  display: flex;
  position: absolute;
  right: 16px;
  top: -8px;
`;

const ToggleButton = styled.button<{ active?: boolean }>`
  border-radius: 5px;
  border: 0px;
  color: ${(p) => p.theme.colors.neutral2};
  background: ${(p) => (!p.active ? "transparent" : p.theme.colors.neutral8)};
  flex: 1;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

const ToggleView = ({ toggleView, onChange }) => {
  return (
    <Toggler>
      <ToggleButton
        onClick={() => onChange("board")}
        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
        active={toggleView === "board"}
      >
        <ProjectOutlined
          style={{ color: theme.colors.neutral5, marginRight: 4 }}
        />{" "}
        Board
      </ToggleButton>
      <ToggleButton
        onClick={() => onChange("table")}
        active={toggleView === "table"}
        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
      >
        <TableOutlined
          style={{ color: theme.colors.neutral5, marginRight: 4 }}
        />{" "}
        Table
      </ToggleButton>
    </Toggler>
  );
};

const Sticky = styled.div`
  width: 40px;
  position: absolute;
  top: 54px;
  opacity: 1;
  pointer-events: auto;
  z-index: 0;
  background: ${(p) => p.theme.colors.neutral10};
  transition: opacity 0.2s ease 0s;
  left: 0px;
  margin-left: -40px;
  box-shadow: rgb(255, 255, 255) 0px 80px 0px,
    rgba(9, 30, 66, 0.08) 5px -2px 7px -5px;
`;

export default function ProjectsTab() {
  const location = useLocation();
  const navigate = useNavigate();
  const [toggleView, setToggleView] = useState("board");
  const pipelinesByCustomerQuery = usePipelinesByCustomerQuery();
  const [showNewForm, setShowNewForm] = useState<false | null>(null);
  const [loading, setLoading] = useState(false);
  const [tempCol, setTempCol] = useState(false);
  const [savePipeline] = useSavePipelineMutation();
  const [addPipelineCol] = useAddPipelineColMutation();
  const [showTypeForm, setShowTypeForm] = useState(false);
  const [saveProjectType] = useSaveProjectTypeMutation();

  const setSelectedPipline = (id) => {
    return navigate(`/app/projects?selectedPipeline=${id}`);
  };
  const { selectedPipeline } = queryString.parse(location.search);

  const { onUrlChange } = useUrlChange();

  const pipelines = pipelinesByCustomerQuery?.data?.pipelinesByCustomer || [];

  let currentPipeline =
    pipelines && selectedPipeline
      ? pipelines.filter((item) => item?.id === selectedPipeline)[0]
      : pipelines?.[0];

  const { onDragEnd } = useOnDragEnd({ currentPipeline });

  // show loading
  if (pipelinesByCustomerQuery.loading) return <Loading />;
  // show error
  if (pipelinesByCustomerQuery.error) {
    return <>error loading project...</>;
  }

  // show empty state if no piplines
  if (!pipelines?.[0]) {
    return <EmptyState />;
  }

  const onSubmit = async (params) => {
    try {
      setLoading(true);
      let res = await savePipeline({
        variables: {
          params,
        },
        refetchQueries: [
          {
            query: PipelinesByCustomerDocument,
          },
        ],
      });
      message.success("Pipeline successfully created!");
      setSelectedPipline(res?.data?.savePipeline?.id);
      setLoading(false);
      setShowNewForm(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onTypeSubmit = async (params) => {
    try {
      let res = await saveProjectType({
        variables: {
          params,
        },
      });
      console.log(res?.data?.saveProjectType?.id);
      setShowTypeForm(false);
    } catch (err) {
      console.log(err);
    }
  };

  if (showTypeForm) {
    return (
      <div
        style={{
          background: "#fff",
        }}
      >
        <ProjectTypeForm
          onSubmit={onTypeSubmit}
          loading={loading}
          onCancel={() => setShowTypeForm(false)}
        />
      </div>
    );
  }

  if (showNewForm) {
    return (
      <FormContainer>
        <PipelineForm
          onSubmit={onSubmit}
          loading={loading}
          onCancel={() => setShowNewForm(false)}
        />
      </FormContainer>
    );
  }

  // if (showProjectForm) {
  //   return (
  //     <FormContainer>
  //       {/* <ProjectForm
  //         onSubmit={onProjectSubmit}
  //         currentPipeline={currentPipeline}
  //         loading={loading || submittingProject}
  //         pipelines={pipelines}
  //         onCancel={() => setShowProjectForm(false)}
  //       /> */}
  //       hello world
  //     </FormContainer>
  //   );
  // }

  if (toggleView === "table") {
    return (
      <div style={{ position: "relative" }}>
        <ToggleView
          toggleView={toggleView}
          onChange={(value) => setToggleView(value)}
        />
        <div style={{ paddingTop: 24 }}>
          <TableView />
        </div>
      </div>
    );
    // return (
    //   <DrawerContext.Consumer>
    //     {(context: any) => {
    //       return (
    //         <div style={{ position: "relative" }}>
    //           <ToggleView
    //             toggleView={toggleView}
    //             onChange={(value) => setToggleView(value)}
    //           />
    //           <Button
    //             onClick={() =>
    //               context?.setDrawerVisible(formTypes.PROJECTS, {
    //                 pipeline: selectedPipeline,
    //               })
    //             }
    //             style={{ marginLeft: 16 }}
    //           >
    //             + New Project
    //           </Button>
    //           <TableView />
    //         </div>
    //       );
    //     }}
    //   </DrawerContext.Consumer>
    // );
  }

  // show main component
  return (
    <div style={{ position: "relative" }}>
      <Sticky id="sticky" />
      <ToggleView
        toggleView={toggleView}
        onChange={(value) => setToggleView(value)}
      />
      <div
        style={{
          display: "flex",
          width: "80%",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <div style={{ width: 300 }}>
          <PipelinesDropdown
            setShowNewForm={setShowNewForm}
            pipelines={pipelinesByCustomerQuery?.data?.pipelinesByCustomer}
            currentPipeline={currentPipeline}
            setSelectedPipline={setSelectedPipline}
          />
        </div>
        <Button
          onClick={() => onUrlChange({ drawerVisible: formTypes.PROJECTS })}
          secondary
          style={{ marginLeft: 8 }}
          data-testid="new-project-btn"
        >
          + New Project
        </Button>
      </div>
      <Lists>
        <DragDropContext onDragEnd={onDragEnd}>
          {currentPipeline?.stages?.map((stage) => {
            return (
              <StageBlock
                key={stage?.id}
                stage={stage}
                pipelineId={currentPipeline?.id}
              />
            );
          })}
        </DragDropContext>
        {tempCol ? (
          <TempCol
            onCancel={() => setTempCol(false)}
            onSave={async (label) => {
              try {
                if (!currentPipeline?.id) return;
                await addPipelineCol({
                  variables: {
                    params: {
                      pipelineId: currentPipeline?.id,
                      label,
                      order: currentPipeline?.stages?.length,
                    },
                  },
                  refetchQueries: [
                    {
                      query: PipelinesByCustomerDocument,
                    },
                  ],
                });
                setTempCol(false);
              } catch (err) {
                console.log(err);
              }
            }}
            colIndex={currentPipeline?.stages?.length}
          />
        ) : (
          <NewColBtn
            onClick={() => setTempCol(true)}
            data-testid="add-new-stage"
          >
            +
          </NewColBtn>
        )}
      </Lists>
    </div>
  );
}
