// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  query attachments($parentId: ID!) {
    attachments(parentId: $parentId) {
      id
      filename
      encoding
      mimetype
      url
      key
      createdAt
      createdByName
    }
  }
`;
