import React from 'react';
import styled from 'styled-components';
import Button from 'components/common/Button';
import CheckCircleFilled from 'components/icons/CheckCircleFilled.js'
import theme from 'lib/theme';

const Caption = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral4};
  font-size: 16px;
`;

const Title = styled.h1`
  margin: 0px;
  margin-top: 8px;
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral1};
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 175px;
  flex: 1;
  text-align: center;
  width: 500px;
  margin: auto;
`;

type SuccessStateProps = {
    title: string
    subtitle: string
    onOk?(event: React.MouseEvent<HTMLButtonElement>): void;
    onOkText?: string
    img?: React.ReactNode
}

export default function SuccessState({
    title = "Success!",
    subtitle = "That was successful",
    onOk,
    onOkText,
    img }: SuccessStateProps) {
    return (
        <Container>
            <div>
                {img || <CheckCircleFilled fill={theme.colors.primary4} height="56" width="56" />}
                <Title>{title}</Title>
                <Caption>{subtitle}</Caption>
                {onOk && (
                    <Button style={{ width: 120, marginTop: 16 }} onClick={onOk}>
                        {onOkText || 'Okay'}
                    </Button>
                )}
            </div>
        </Container>
    );
};
