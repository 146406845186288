// COMPONENTS
import message from "components/common/message";
// APOLLO
import BUSINESS_BY_ID from "ApolloClient/Queries/businessById";
import SAVE_BUSINESS from "ApolloClient/Mutations/saveBusiness";
import { useMutation } from "@apollo/client";
import {
  EntityTypeEnum,
  useDeleteCompanyInvestmentMutation,
  useRemoveEntityFromProjectMutation,
} from "generated/graphql";

export default function useOnRemove({
  business,
  onComplete,
}: {
  business?: any;
  onComplete?: any;
}) {
  const [saveBusiness] = useMutation(SAVE_BUSINESS);
  const [deleteCompanyInvestment, { loading: deletingInvestment }] =
    useDeleteCompanyInvestmentMutation();
  const [removeEntityFromProject, { loading: removingEntity }] =
    useRemoveEntityFromProjectMutation();

  const onRemove = async (removeItem, type) => {
    try {
      if (type === "companyInvestments") {
        await deleteCompanyInvestment({
          variables: {
            id: removeItem.id,
          },
          refetchQueries: [
            {
              query: BUSINESS_BY_ID,
              variables: {
                id: business.id,
              },
            },
          ],
        });

        // success popup message
        message.success("Investment removed from business");
      }

      // if the business isn't already selected, we dont want to call the mutation
      if (type === "projects") {
        let projectIds = business.projects.map((i) => i.id);
        if (!projectIds.includes(removeItem.id)) {
          return;
        }
      }
      if (type === "contacts" && !business.contactIds.includes(removeItem.id)) {
        return;
      }
      // call mutation
      if (type === "projects") {
        await removeEntityFromProject({
          variables: {
            projectId: removeItem.id,
            entityId: business.id,
            entityType: EntityTypeEnum.Businesses,
          },
          refetchQueries: [
            {
              query: BUSINESS_BY_ID,
              variables: {
                id: business.id,
              },
            },
          ],
        });

        // success popup message
        message.success("Business removed from business");
      }
      if (type === "contacts") {
        await saveBusiness({
          variables: {
            id: business.id,
            params: {
              contactIds: business.contactIds.filter(
                (id) => id !== removeItem.id
              ),
            },
          },
          refetchQueries: [
            {
              query: BUSINESS_BY_ID,
              variables: {
                id: business.id,
              },
            },
          ],
        });
        // success popup message
        message.success("Contacts removed from business");
      }

      // close the drawer
      onComplete && onComplete();
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onRemove,
    removing: deletingInvestment || removingEntity,
  };
}
