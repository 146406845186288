import {
  CaseInput,
  useCaseStatusesQuery,
  useSaveCaseMutation,
} from "generated/graphql";
import helpers from "lib/helpers/GeneralHelpers";
import caseFragment from "ApolloClient/Fragments/caseFragment";

const cleanParams = (newValues): CaseInput => {
  if (newValues.dueDate) {
    newValues.dueDate = newValues.dueDate.valueOf().toString();
  }

  // we need to strip the __Typename field otherwise the mutation will yell about it being in the args
  if (newValues.attachments) {
    newValues.attachments = helpers.cleanTypenameFromArray(
      newValues.attachments
    );
  }

  if (newValues.resolvedOn) {
    newValues.resolvedOn = newValues.resolvedOn.valueOf().toString();
  }

  return {
    title: newValues.title,
    description: newValues.description,
    contactId: newValues.contactId || null,
    caseStatusId: newValues.caseStatusId || null,
    businessId: newValues.businessId || null,
    serviceIds: newValues.serviceIds,
    caseCategoryId: newValues.caseCategoryId || null,
    resolutionNotes: newValues.resolutionNotes,
    attachments: newValues.attachments,
    dueDate: newValues?.dueDate,
    resolvedOn: newValues.resolvedOn,
    tagIds: newValues?.tagIds,
    projectId: newValues?.projectId,
    caseOutcomeIds: newValues?.caseOutcomeIds,
    reporterId: newValues.reporterId || null,
    assignedToId: newValues.assignedToId || null,
  };
};

export default function useOnSaveCase({ caseId, onSaved, onErrors }) {
  const [saveCase, { loading }] = useSaveCaseMutation();

  const { data: caseStatusQuery, loading: caseStatusesLoading } =
    useCaseStatusesQuery();

  const caseStatuses =
    !caseStatusesLoading && caseStatusQuery?.caseStatuses?.[0]
      ? caseStatusQuery?.caseStatuses
      : [];

  const getCaseStatus = (caseStatusId: any): any => {
    return caseStatuses?.find((status) => status?.id === caseStatusId);
  };

  const onSaveCase = async (newValues) => {
    try {
      const caseStatus = getCaseStatus(newValues.caseStatusId);

      onErrors({});
      /**
       * DO SOME VALIDATION BEFORE SUBMITTING
       */
      if (caseStatus && caseStatus.isFinalStep && !newValues.resolvedOn) {
        return onErrors({
          resolvedOn: "A resolved on date is required for a resolved cases",
        });
      }

      if (caseStatus && caseStatus.isFinalStep && !newValues.resolutionNotes) {
        return onErrors({
          resolutionNotes:
            "Leaving a resolution note is required for a resolved cases",
        });
      }

      const cleanedParams = cleanParams(newValues);

      await saveCase({
        variables: {
          id: caseId,
          params: cleanedParams,
        },
        refetchQueries: ["cases", "caseById"],
        update: (proxy, { data }) => {
          let current: any = proxy.readFragment({
            fragment: caseFragment,
            id: `Case:${caseId}`,
            fragmentName: "caseFragment",
          });
          proxy.writeFragment({
            fragment: caseFragment,
            id: `Case:${caseId}`,
            fragmentName: "caseFragment",
            data: {
              ...current,
              ...cleanedParams,
            },
          });
          onSaved(data?.saveCase);
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  return [onSaveCase, { loading }, getCaseStatus] as const;
}
