import styled from "styled-components";
// Components
import InteractionCard from "components/detail-components/Interactions/InteractionCard";
import EmptyState from "./EmptyState";
import {
  InteractionsQueryVariables,
  useInteractionsQuery,
} from "generated/graphql";

export const Title = styled.h2`
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.neutral1};
  margin-top: 16px;
`;

export const Description = styled.h3`
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.neutral1};
`;

export const Card = styled.div`
  box-shadow: ${({ theme }) => theme.boxShadow};
  padding: 16px;
  border-radius: 7px;
  margin: auto;
  margin-bottom: 16px;
  background: #fff;
  position: relative;
  width: 600px;
  max-width: 100%;
  margin-top: 16px;
`;

export const Name = styled.h3`
  font-weight: 500;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral1};
  font-size: 14px;
  line-height: 20px;
`;

export const CreatedAt = styled.p`
  font-weight: 400;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral5};
  font-size: 14px;
  line-height: 20px;
`;

export default function Interactions({
  queryVariables,
}: {
  queryVariables?: InteractionsQueryVariables;
}) {
  const { data, loading, error } = useInteractionsQuery({
    variables: {
      ...queryVariables,
    },
  });

  if (loading) return null;
  if (error) return null;

  if (!data?.interactions || data?.interactions.count === 0) {
    return <EmptyState />;
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: 60,
        paddingTop: 16,
        maxWidth: "100%",
      }}
    >
      <div style={{ maxWidth: "100%" }}>
        {data?.interactions?.results?.map((interaction) => {
          return (
            <InteractionCard key={interaction.id} interaction={interaction} />
          );
        })}
      </div>
    </div>
  );
}
