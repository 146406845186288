import React from 'react';
import styled from 'styled-components';
// APOLLO
import CASE_STATUS_REPORT from 'ApolloClient/Queries/caseStatusReport';
import {useQuery} from '@apollo/client';

const Container = styled.div`
  margin-bottom: 32px;
  display: flex;
`;

const Card = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 5px;
  flex: 1;
  min-height: 100px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  max-width: 33%;
`;

const CardLabel = styled.h3`
  margin: 0px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  color: ${(p) => p.theme.colors.neutral6};
`;

const CardValue = styled.h2`
  margin: 0px;
  font-size: 24px;
  font-weight: 800;
  color: ${(p) => p.theme.colors.neutral1};
`;

export default function CaseStats() {
  // query to get an array of case statuses and the number of cases in each
  const {data, loading, error} = useQuery(CASE_STATUS_REPORT);
  if (error) return <>something went wrong...</>;
  return (
    <Container>
      {!loading &&
        data.caseStatusReport.map((stat) => {
          return (
            <Card key={stat.id}>
              <div>
                <CardLabel>{stat.status.title}</CardLabel>
                <CardValue>{stat.total} Cases</CardValue>
              </div>
            </Card>
          );
        })}
    </Container>
  );
}
