import React from "react";
import styled from "styled-components";

const Title = styled.p<{ style?: React.CSSProperties }>`
  margin: 0px;
  margin-bottom: 4px;
  color: ${(p) => (p.color ? p.color : p.theme.colors.neutral2)};
  font-size: ${(p) => (p?.style?.fontSize ? p.style.fontSize : "16px")};
  font-weight: 500;
`;

export default Title;
