import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import checkSVG from "./sm-check.svg";
import cancelSVG from "./sm-x.svg";

const CaseTitleContainer = styled.div`
  display: flex;
  align-items: center;
  min-height: 37px;
  margin-bottom: 8px;
  border: 2px solid transparent;
  &:hover {
    background: ${(p) => p.theme.colors.neutral10};
    cursor: text;
  }
`;

const Title = styled.div`
  width: 568px;
  word-break: break-all;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 24px;
  font-weight: 500;

  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.01em;
  color: ${(p) => p.theme.colors.neutral1};
  max-width: 100%;
`;

const CaseTitleInput = styled.textarea`
  width: 568px;
  padding: 0px;
  max-width: 100%;
  min-height: 37px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.01em;
  border-radius: 5px;
  border: 2px solid ${(p) => p.theme.colors.neutral10};
  max-width: 100%;
`;

const SaveBtn = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: -32px;
  background: ${(p) => p.theme.colors.neutral10};
  border: 0px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;

  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${(p) => p.theme.colors.neutral9};
  }
`;

const CancelBtn = styled(SaveBtn)`
  right: 40px;
`;

export default function CaseTitle({
  title,
  onSave,
  ...args
}: {
  title: string;
  onSave: any;
  ["data-testid"]?: string;
}) {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState<string | undefined>(title);
  const textInput = useRef<any>(null);
  const [rows, setRows] = useState(1);
  useEffect(() => {
    if (editing) {
      textInput?.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    const textareaLineHeight = 27; // Set the line height of the textarea
    const initialRows = Math.ceil((title || "").length / 40); // Set the initial number of rows based on the length of the default value
    const currentRows = Math.floor((initialRows * textareaLineHeight) / 24); // Calculate the number of rows based on the line height
    setRows(currentRows);
  }, [title]);

  const handleOnSave = () => {
    // save
    onSave(value);
    setEditing(false);
  };

  function onChange(e) {
    setValue(e.target.value);
    const textareaLineHeight = 27; // Set the line height of the textarea
    const previousRows = e.target.rows;
    e.target.rows = 1; // Reset the number of rows to 1 to calculate the new number of rows needed
    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);
    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    } else {
      setRows(currentRows);
    }
  }

  if (editing) {
    return (
      <div
        style={{
          position: "relative",
          display: "inline-flex",
          maxWidth: "100%",
        }}
      >
        <CaseTitleInput
          value={value}
          onChange={onChange}
          onBlur={handleOnSave}
          ref={textInput}
          rows={rows}
          //rows={value && value?.length <= 34 ? 1 : undefined}
        />
        <CancelBtn
          onClick={() => {
            setEditing(false);
            setValue(undefined);
          }}
        >
          <img src={cancelSVG} alt="cancel" height="24" />
        </CancelBtn>
        <SaveBtn onClick={handleOnSave}>
          <img src={checkSVG} alt="save" height="24" />
        </SaveBtn>
      </div>
    );
  }

  return (
    <CaseTitleContainer onClick={() => setEditing(true)}>
      <Title data-testid={args?.["data-testid"] || "case-detail-title"}>
        {value || title}
      </Title>
    </CaseTitleContainer>
  );
}
