import { useState } from "react";
import styled from "styled-components";
import { Link, Navigate } from "react-router-dom";
import { validate } from "email-validator";
// LIB
import ErrorHelpers from "lib/helpers/ErrorHelpers";
import AuthHelpers from "lib/helpers/AuthHelpers";
import logo from "lib/media/logo2.svg";
// COMPONENST
import PageContainer from "components/common/PageContainer";
import Caption from "components/text/Caption";
import ErrorList from "components/common/ErrorList";
import FormItem from "components/common/FormItem";
import Button from "components/common/Button";
import TextInput from "components/inputs/TextInput";
// APOLLO
import ApolloClient from "ApolloClient";

const Logo = styled.img`
  height: 50px;
  margin: auto;
`;
const TitleContainer = styled.div`
  min-height: 95px;
  text-align: center;
`;

const FormContainer = styled.div`
  width: 500px;
  padding: 16px;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
  background: #fff;
  padding: 32px 64px;
  border-radius: 5px;
  @media only screen and (max-width: 414px) {
    padding: 20px;
  }
`;

const Container = styled(PageContainer)`
  align-items: center;
  padding-top: 20px;
  display: flex;
  height: 100vh;
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 10px;
`;

export default function LoginPage({ currentUser }) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<string[] | false>(false);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState(undefined);

  if (currentUser) {
    return <Navigate to="/app/businesses" />;
  }

  const onSubmit = async (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    // reset errors
    setErrors([]);

    // check that user added an email
    if (typeof email !== "string") {
      return setErrors(["Please provide an email"]);
    }
    // check if its a valid email
    if (email && typeof email === "string" && !validate(email as any)) {
      return setErrors(["That is not a valid email"]);
    }
    // check that they give a password
    if (!password) {
      return setErrors(["Please provide a password"]);
    }
    setLoading(true);
    try {
      await AuthHelpers.handleLogin({
        email,
        password,
      });
    } catch (err: any) {
      let errMessage = err.message.replace("GraphQL", "");
      if (err && err.message.includes("Incorrect password [403]")) {
        errMessage = "You have entered an invalid username or password";
      }
      setLoading(false);
      return setErrors([ErrorHelpers.cleanErrorString(errMessage)]);
    }
    await ApolloClient.resetStore();
  };

  return (
    <div
      style={{
        overflow: "hidden",
        background: "#fff",
      }}
    >
      <Container>
        <FormContainer>
          <TitleContainer>
            <Logo src={logo} />
            <Caption style={{ margin: "16px auto" }}>Welcome back!</Caption>
          </TitleContainer>
          <form onSubmit={onSubmit}>
            <FormItem>
              <TextInput
                value={email}
                placeholder="Email"
                onChange={(email: string) =>
                  setEmail(email?.trim()?.toLowerCase())
                }
                data-testid="login-email-input"
              />
            </FormItem>
            <FormItem>
              <TextInput
                value={password}
                placeholder="Password"
                onChange={(password) => setPassword(password?.trim())}
                type="password"
                data-testid="login-password-input"
              />
            </FormItem>
            <ButtonContainer>
              <Button
                disabled={loading}
                loading={loading}
                type="submit"
                fullWidth
                onClick={onSubmit}
                data-testid="login-submit-btn"
              >
                Login
              </Button>
            </ButtonContainer>
          </form>
          <ErrorList errors={errors} />
          <div>
            <Caption style={{ textAlign: "center" }}>
              <Link to="/forgot-password" data-testid="forgot-password-link">
                Forgot your password
              </Link>
            </Caption>
          </div>
        </FormContainer>
      </Container>
    </div>
  );
}
