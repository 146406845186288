// TOP LEVEL IMPORTS
import { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import FormItem from "components/common/FormItem";
import Row from "components/common/Row";
import CustomTagsInput from "components/inputs/CustomTagsInput";
import Col from "components/common/Col";
import TextAreaInput from "components/inputs/TextAreaInput";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import CurrencyInput from "components/inputs/CurrencyInput";
import { usePipelinesByCustomerQuery } from "generated/graphql";
import Select from "antd/lib/select";
import SideFormButtonContainer from "components/common/SideFormButtonContainer";

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

const { Option } = Select;

interface ProjectFormProps {
  loading?: boolean;
  onCancel: any;
  onSubmit: any;
  defaultValue: any;
}

export default function ProjectForm({
  loading,
  onCancel,
  onSubmit,
  defaultValue = {},
}: ProjectFormProps) {
  const { data } = usePipelinesByCustomerQuery();

  const [values, setValues] = useState({
    title: defaultValue.title || null,
    description: defaultValue.description || null,
    tagIds: defaultValue?.tagsIds || [],
    amount: defaultValue?.amount || 0,
    pipeline: defaultValue?.pipeline || null,
  });

  const [errors, setErrors] = useState<any>({});

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors({});

    // check if type exists
    if (!values.title) {
      return setErrors((errors) => ({
        ...errors,
        title: "This is required",
      }));
    }

    if (loading) return null;

    // pass back up to the parent component
    onSubmit({
      ...values,
    });
  };

  const onChange = (newValues) => {
    setErrors({});
    setValues((oldValues) => ({
      ...oldValues,
      ...newValues,
    }));
  };

  const disabled = !values.title;

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormItem label="Project name" required error={errors.title}>
        <TextInput
          value={values?.title}
          onChange={(title) => onChange({ title })}
          data-testid="project-form-input-title"
        />
      </FormItem>
      <FormItem label="Description">
        <TextAreaInput
          value={values?.description}
          onChange={(description) => onChange({ description })}
          data-testid="project-form-input-description"
        />
      </FormItem>
      <Row gutter={16}>
        <Col xs={16}>
          <FormItem
            label="Pipeline"
            required
            tooltip="Which pipeline do you want this to live in?"
          >
            <DropdownStyleWrapper>
              <Select
                showSearch
                optionFilterProp="children"
                style={{ width: "100%" }}
                onChange={(pipeline) => onChange({ pipeline })}
                value={values?.pipeline}
              >
                {data?.pipelinesByCustomer?.map(({ id, title }) => {
                  if (!id) return null;
                  return (
                    <Option key={id} value={id}>
                      {title}
                    </Option>
                  );
                })}
              </Select>
            </DropdownStyleWrapper>
          </FormItem>
        </Col>
        <Col xs={8} />
        <Col xs={16}>
          <FormItem
            label="Amount"
            tooltip="Is there a monetary value you would give this project?"
          >
            <CurrencyInput
              value={values?.amount}
              onChange={(amount) => onChange({ amount })}
            />
          </FormItem>
        </Col>
        <Col xs={8} />

        <Col xs={24}>
          <FormItem label="Tags">
            <CustomTagsInput
              value={values?.tagIds}
              onChange={(tagIds) => onChange({ tagIds })}
            />
          </FormItem>{" "}
        </Col>
      </Row>
      <SideFormButtonContainer>
        <Button
          disabled={disabled}
          loading={loading}
          onClick={handleSubmit}
          style={{ marginLeft: 16 }}
          data-testid="project-form-submit-btn"
        >
          Save Project
        </Button>
        <Button
          disabled={loading}
          onClick={onCancel}
          grey
          style={{ marginLeft: 8 }}
        >
          Cancel
        </Button>
      </SideFormButtonContainer>
    </FormContainer>
  );
}
