import React from "react";
import styled from "styled-components";
import { DeleteOutlined } from "@ant-design/icons";
import Popconfirm from "components/common/Popconfirm";
import { Card, CardCaption, CardActions, RemoveBtn } from "../index";
import moment from "moment";
import numeral from "numeral";

const Money = styled.h3`
  color: ${(p) => p.theme.colors.primary3};
  margin: 0px;
  font-weight: 600;
  letter-spacing: 0px;
`;

const DateText = styled(CardCaption)`
  color: ${(p) => p.theme.colors.neutral6};
`;

const Description = styled.p`
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 0px;
  color: ${(p) => p.theme.colors.neutral2};
`;

export default function CompanyInvestmentCard({ item, onRemove }) {
  return (
    <Card>
      <div style={{ display: "flex" }}>
        <div>
          <Money>${numeral(item?.amount).format("0,0.00")}</Money>
          {item?.investedOn && (
            <DateText>
              {moment(parseInt(item?.investedOn)).format("MMM D, YYYY")}
            </DateText>
          )}
          <Description>
            {item?.description && item?.description?.length < 80
              ? item?.description
              : `${item?.description?.substring(0, 80)}...`}
          </Description>
        </div>
      </div>
      <CardActions className="card-actions">
        <Popconfirm
          title="Are you sure you want to remove this record about investment?"
          onConfirm={onRemove}
          okText="Yes"
        >
          <RemoveBtn>
            <DeleteOutlined />
          </RemoveBtn>
        </Popconfirm>
      </CardActions>
    </Card>
  );
}
