// TOP LEVEL IMPORTS
import React, { Suspense } from "react";
import { Navigate } from "react-router-dom";
import Loading from "components/common/Loading";

class AdminRoute extends React.Component {
  render() {
    const {
      currentUser,
      path,
      noNav,
      noFooter,
      theme,
      location,
      isAllowed,
      component: Component,
      layout: Layout,
      ...rest
    } = this.props;

    if (
      currentUser &&
      currentUser.roles &&
      !currentUser.roles.includes("appAdmin")
    ) {
      return <Navigate to="/" />;
    }

    return (
      <React.Fragment>
        {currentUser && currentUser.id ? (
          <Layout {...rest} {...this.props}>
            <Suspense fallback={<Loading />}>
              <Component {...rest} {...this.props} />
            </Suspense>
          </Layout>
        ) : (
          <Navigate to="/" />
        )}
      </React.Fragment>
    );
  }
}

export default AdminRoute;
