import DefaultSurveyTemplateCreatorWidget from "components/common/DefaultSurveyTemplateCreatorWidget";
import { useLocation } from "react-router-dom";

export default function AdminDefaultSurveyTemplateCreate() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const defaultTemplateId = queryParams.get("defaultTemplateId");
  console.log(defaultTemplateId);

  return (
    <DefaultSurveyTemplateCreatorWidget defaultTemplateId={defaultTemplateId} />
  );
}
