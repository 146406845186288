import React, { useState } from "react";
import PROJECT_TYPES_BY_CUSTOMER from "ApolloClient/Queries/projectTypesByCustomer";
import { useQuery, useMutation } from "@apollo/client";
import styled from "styled-components";
import ProjectTypeForm from "../ProjectTypeForm";
import SAVE_PROJECT_TYPE from "ApolloClient/Mutations/saveProjectType";
import Select from "antd/lib/select";
import theme from "lib/theme";
import { CloseCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddBtn = styled.button`
  color: ${(p) => p.theme.colors.primary3};
  border: 0px;
  background: transparent;
  font-weight: 600;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(p) => p.theme.colors.primary2};
  }
`;

const ProjectTypeContainer = (props) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: 1000,
        background: "#fff",
        paddingTop: 56,
        overflowY: "scroll",
        paddingBottom: 56,
      }}
    >
      <CloseCircleOutlined
        style={{ position: "absolute", top: 32, right: 32, fontSize: 20 }}
        onClick={props.onCancel}
      />
      <ProjectTypeForm {...props} />
    </div>
  );
};

export default function ProjectTypesInput({ value, onChange }) {
  const [showTypeForm, setShowTypeForm] = useState(false);
  const [saveProjectType] = useMutation(SAVE_PROJECT_TYPE);
  const { data, loading, error } = useQuery(PROJECT_TYPES_BY_CUSTOMER);
  if (loading) return "...";
  if (error) return "...";

  const onTypeSubmit = async (params) => {
    try {
      let res = await saveProjectType({
        variables: {
          params,
        },
        refetchQueries: [
          {
            query: PROJECT_TYPES_BY_CUSTOMER,
          },
        ],
      });
      setShowTypeForm(false);
      onChange(
        res.data.saveProjectType.id,
        res.data.saveProjectType.extraFields,
        res.data.saveProjectType.customFields
      );
    } catch (err) {
      console.log(err);
    }
  };

  if (showTypeForm) {
    return (
      <ProjectTypeContainer
        onSubmit={onTypeSubmit}
        loading={loading}
        onCancel={() => setShowTypeForm(false)}
      />
    );
  }
  if (data.projectTypesByCustomer.length === 0) {
    return (
      <AddBtn
        onClick={() => setShowTypeForm(true)}
        data-testid="add-first-project-type-btn"
      >
        Add your first project type
      </AddBtn>
    );
  }
  return (
    <>
      <Select
        showSearch
        optionFilterProp="children"
        style={{ width: 250 }}
        onChange={(id) => {
          if (id === "createTemplate") {
            return setShowTypeForm(true);
          }
          let type = data.projectTypesByCustomer.filter(
            (item) => item.id === id
          )[0];
          onChange(id, type.extraFields, type.customFields);
        }}
        value={value}
      >
        {data.projectTypesByCustomer.map(({ id, title }) => (
          <Option key={id} value={id}>
            {title}
          </Option>
        ))}
        <Option
          key={"createTemplate"}
          value={"createTemplate"}
          style={{
            paddingBottom: 8,
            fontWeight: 600,
            color: theme.colors.primary5,
          }}
        >
          <div
            style={{
              height: 1,
              background: theme.colors.neutral9,
              marginBottom: 8,
            }}
          />
          + Create New Template
        </Option>
      </Select>
    </>
  );
}
