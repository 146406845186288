import FormItem from "components/common/FormItem";
import CaseStatusInput from "components/inputs/CaseStatusInput";
import Caption from "components/text/Caption";
import FormHeader from "components/text/FormHeader";
import { useGetManyCaseOutcomesQuery } from "generated/graphql";
import theme from "lib/theme";
import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import TextEditor from "components/inputs/TextEditor";
import Button from "components/common/Button";
import SelectInput from "components/inputs/SelectInput";
import DateInput from "components/inputs/DateInput";

const SecondaryOverlay = styled.div`
  /* background: rgba(255, 255, 255, 0.74); */
  background: rgba(9, 30, 66, 0.54);
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 15;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 80px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    padding-top: 10px;
    overflow-y: scroll;
  }
`;

const SecondaryModal = styled.div`
  padding: 32px;
  padding-top: 16px;
  border-radius: 5px;
  width: 600px;
  max-width: 100%;
  min-height: 200px;
  background: #fff;
  position: relative;
`;

export default function ConfirmCloseModal({
  caseStatusId,
  getCaseStatus,
  loading,
  onComplete,
  onCancel,
}) {
  const { data: outcomesQuery } = useGetManyCaseOutcomesQuery();

  const [values, setValues] = useState<{
    resolvedOn: string;
    resolutionNotes?: any;
    caseStatusId?: string;
    caseOutcomeIds?: string;
  }>({
    resolvedOn: moment().valueOf().toString(),
  });

  const handleOnSubmit = () => {
    onComplete({
      ...values,
      resolutionNotes: values?.resolutionNotes,
    });
  };

  const status = getCaseStatus(values?.caseStatusId || caseStatusId);

  const disabled =
    !values.resolvedOn ||
    !values.resolutionNotes ||
    !status.isFinalStep ||
    values.resolutionNotes.toString("html").length < 8; // make sure they typed at least some kind of resolution note

  return (
    <SecondaryOverlay>
      <SecondaryModal>
        <FormHeader>Close this case?</FormHeader>
        <Caption style={{ width: "80%", marginBottom: 32 }}>
          To close this case you must provide the date it was resolved on as
          well as a note about the resolution
        </Caption>
        <FormItem label="Resolution Notes" required>
          <TextEditor
            value={values.resolutionNotes}
            onChange={(resolutionNotes) =>
              setValues({ ...values, resolutionNotes })
            }
          />
        </FormItem>
        <FormItem label="Status" required>
          <CaseStatusInput
            value={values.caseStatusId}
            finalStepsOnly
            //disabled
            style={{ width: "100%" }}
            onChange={(caseStatusId) => {
              setValues({
                ...values,
                caseStatusId,
              });
            }}
          />
        </FormItem>
        <FormItem
          label="Outcomes"
          required
          tooltip="What was the outcome of this case? Resolved successfully? Is it being closed because you lost contact with the business?"
        >
          <SelectInput
            value={values.caseOutcomeIds}
            data-testid={"outcomes-select-input"}
            containerStyle={{
              width: "100%",
              backgroundColor: theme?.colors?.neutral11,
            }}
            options={
              outcomesQuery?.getManyCaseOutcomes?.map((outcome) => ({
                id: outcome?.id || "",
                label: outcome?.title || "",
              })) || []
            }
            mode="multiple"
            onChange={(caseOutcomeIds) => {
              setValues({
                ...values,
                caseOutcomeIds,
              });
            }}
          />
        </FormItem>

        <FormItem label="Resolved On" required>
          <DateInput
            value={values.resolvedOn}
            showTime={false}
            format="M/D/YY"
            onChange={(resolvedOn) => {
              setValues({
                ...values,
                resolvedOn: resolvedOn.valueOf().toString(),
              });
            }}
          />
        </FormItem>

        <div style={{ marginTop: 32 }}>
          <Button
            disabled={loading}
            style={{ width: 120, marginRight: 16 }}
            grey
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            disabled={disabled || loading}
            loading={loading}
            onClick={handleOnSubmit}
            data-testid="close-case-modal-submit-btn"
          >
            Close this Issue
          </Button>
        </div>
      </SecondaryModal>
    </SecondaryOverlay>
  );
}
