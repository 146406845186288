import React from "react";
import styled from "styled-components";
import { ArrowLeftOutlined } from "@ant-design/icons";

const IconContainer = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 8px;
`;

const ButtonContainer = styled.button`
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
  background: transparent;
  border: 0px;
  cursor: pointer;
  :hover {
    div {
      cursor: pointer;
      background-color: ${(p) => p.theme.colors.neutral9};
    }
  }
`;

export default function BackButton({
  style,
  onClick,
  text,
}: {
  style?: React.CSSProperties;
  onClick: any;
  text?: string;
}) {
  return (
    <ButtonContainer onClick={onClick}>
      <IconContainer style={style}>
        <ArrowLeftOutlined style={{ color: "#000" }} />
      </IconContainer>
      {text}
    </ButtonContainer>
  );
}
