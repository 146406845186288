// TOP LEVEL IMPORTS
import React from "react";
// ANTD
import InputNumber from "antd/lib/input-number";
import "antd/lib/input-number/style/css";

// EXPORTED COMPONENT
// ===================================
export class CurrencyInput extends React.Component {
  render() {
    let defaultStyles = {
      width: "100%",
      maxWith: "100%",
      height: 50,
    };
    return (
      <InputNumber
        style={{
          ...defaultStyles,
          ...this.props.style,
        }}
        min={0}
        defaultValue={this.props.defaultValue || 0}
        formatter={(value) =>
          `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
        parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
        {...this.props}
      />
    );
  }
}

export default CurrencyInput;
