import React from "react";
import { Card } from "../index";
import Skeleton from "components/common/Skeleton";
import { ResponsiveBar } from "@nivo/bar";
import EmptyData from "../EmptyData";
// APOLLO
import chartColors from "lib/constants/chartColors";
import { useCaseStatusReportQuery } from "generated/graphql";

export default function CasesByStatus() {
  const { data, loading } = useCaseStatusReportQuery();

  if (loading || !data || !data.caseStatusReport) {
    return (
      <Card title="Cases By Status">
        <Skeleton round />
      </Card>
    );
  }

  let keys = data.caseStatusReport.map((item) => item?.status?.title);
  let chartData = data.caseStatusReport.map((item) => {
    if (item?.status?.title) {
      return {
        [item.status.title as string]: item.total,
        title: item.status.title,
      };
    } else return null;
  });

  if (!loading && chartData.length === 0) {
    return (
      <Card title="Cases By Status">
        <EmptyData />
      </Card>
    );
  }

  return (
    <Card title="Cases By Status">
      <ResponsiveBar
        keys={keys as string[]}
        data={chartData as any}
        colors={chartColors}
        indexBy="title"
        margin={{ top: 24, right: 0, bottom: 50, left: 0 }}
        padding={0.3}
        theme={{
          fontSize: 13,
        }}
        axisBottom={{
          tickRotation: -15,
        }}
      />
    </Card>
  );
}
