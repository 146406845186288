import React, { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import Button from "components/common/Button";
import SectionBreak from "components/common/SectionBreak";
import FormItem from "components/common/FormItem";
import Col from "components/common/Col";
import Row from "components/common/Row";
import { DeleteOutlined } from "@ant-design/icons";
import FormLabel from "components/text/FormLabel";
import TextInput from "components/inputs/TextInput";
import FormHeader from "components/text/FormHeader";
import TextAreaInput from "components/inputs/TextAreaInput";
import CheckboxInput from "components/inputs/CheckboxInput";
import Select from "antd/lib/select";

import checkSVG from "components/common/SaveCancelBtns/sm-check.svg";
import xSVG from "components/common/SaveCancelBtns/sm-x.svg";
import theme from "lib/theme";

const { Option } = Select;

const Form = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

const DEFAULT_FIELDS = [
  {
    fieldName: "State",
    fieldType: "text",
  },
  {
    fieldName: "Square Feet Requested",
    fieldType: "number",
  },
  {
    fieldName: "Acres Requested",
    fieldType: "number",
  },
  {
    fieldName: "Retained Jobs",
    fieldType: "number",
  },
  {
    fieldName: "Average Wage",
    fieldType: "currency",
  },
  {
    fieldName: "New Investment Notes",
    fieldType: "text",
  },
  {
    fieldName: "Site Selector",
    fieldType: "text",
  },
  {
    fieldName: "Public Release",
    fieldType: "text",
  },
  {
    fieldName: "Country",
    fieldType: "text",
  },
  {
    fieldName: "Active Date",
    fieldType: "date",
  },
  // {
  //   fieldName: "Industry Type",
  //   fieldType: "industry-type",
  // },
  // {
  //   fieldName: 'NAICs',
  //   fieldType: 'naics',
  // },
  {
    fieldName: "Square Footage Notes",
    fieldType: "text",
  },
  {
    fieldName: "Acreage Notes",
    fieldType: "textarea",
  },
  {
    fieldName: "New Jobs Notes",
    fieldType: "textarea",
  },
  {
    fieldName: "Wages Notes",
    fieldType: "textarea",
  },
  {
    fieldName: "Total Incentives",
    fieldType: "currency",
  },
  {
    fieldName: "Completion Date",
    fieldType: "date",
  },
  {
    fieldName: "City",
    fieldType: "text",
  },
  {
    fieldName: "New Jobs",
    fieldType: "number",
  },
  {
    fieldName: "Wages",
    fieldType: "currency",
  },
  {
    fieldName: "Net New Investment",
    fieldType: "currency",
  },
  {
    fieldName: "Incentive Notes",
    fieldType: "textarea",
  },
];
// 'text',
// 'number',
// 'currency',
// 'date',
// 'industry-type',
// 'naics',
// 'textarea',
const FIELDTYPE_OPTIONS = [
  {
    id: "text",
    label: "Text",
  },
  {
    id: "number",
    label: "Number",
  },
  {
    id: "currency",
    label: "Currency",
  },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "textarea",
    label: "Text Area",
  },
];

const ExtraBtn = styled.button`
  display: inline-flex;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  right: ${(p) => (p.right ? p.right : "0px")};
  bottom: ${(p) => (p.right ? p.right : "-32px")};
  background: ${(p) => p.theme.colors.neutral10};
  border: 0px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${(p) => p.theme.colors.neutral9};
  }
`;

export default function ProjectTypeForm({ onCancel, loading, onSubmit }) {
  const [title, setTitle] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [extraFields, setExtraFields] = useState([]);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldType, setCustomFieldType] = useState(undefined);
  const [customFieldName, setCustomFieldName] = useState(undefined);
  const beforeSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onSubmit({
      description,
      title,
      extraFields,
      customFields,
    });
  };

  const fields = extraFields.map((item) => item.fieldName);
  return (
    <Form onSubmit={beforeSubmit}>
      <FormHeader>Create a Project Template</FormHeader>
      <SectionBreak
        header="Basics"
        body="Tell us basics about this project template"
      >
        <FormItem label="Template name">
          <TextInput
            data-testid="project-type-form-title"
            value={title}
            onChange={(title) => setTitle(title)}
          />
        </FormItem>
        <FormItem label="Description">
          <TextAreaInput
            value={description}
            data-testid="project-type-form-description"
            onChange={(description) => setDescription(description)}
          />
        </FormItem>
      </SectionBreak>
      <SectionBreak
        header="Extra Fields"
        body="Choose what to include in your template from a list of pre-defined fields"
      >
        <Row>
          {DEFAULT_FIELDS.map((item) => {
            return (
              <Col xs={12} key={item.fieldName}>
                <div style={{ marginTop: 8 }}>
                  <CheckboxInput
                    label={item.fieldName}
                    checked={fields.includes(item.fieldName)}
                    onChange={(value) => {
                      if (value) {
                        return setExtraFields([...extraFields, item]);
                      }
                      return setExtraFields([
                        ...extraFields.filter(
                          (field) => item.fieldName !== field.fieldName
                        ),
                      ]);
                    }}
                  />
                </div>
              </Col>
            );
          })}
          <Col xs={12} />
        </Row>
      </SectionBreak>
      <SectionBreak header="Custom Fields" body="Create your own fields">
        {customFields.map((field) => {
          return (
            <div
              key={field.fieldName}
              style={{
                display: "flex",
                height: 50,
                marginBottom: 16,
                alignItems: "center",
                width: "100%",
                position: "relative",
                border: `1px solid ${theme.colors.neutral9}`,
                borderRadius: 5,
                padding: 8,
              }}
            >
              <div style={{ marginRight: 5, flex: 4 }}>
                <FormLabel>Field Name:</FormLabel>
                <strong> {field.fieldName}</strong>
              </div>
              <div style={{ flex: 4 }}>
                <FormLabel>
                  Type:<strong> {field.fieldType}</strong>{" "}
                </FormLabel>
              </div>
              <div style={{ flex: 1 }}>
                <DeleteOutlined
                  onClick={() => {
                    let newFields = [];
                    customFields.forEach((item) => {
                      if (field.fieldName !== item.fieldName) {
                        newFields.push({
                          item,
                        });
                      }
                    });
                    setCustomFields(newFields);
                  }}
                />
              </div>
            </div>
          );
        })}
        {customFieldName && (
          <div
            style={{
              display: "flex",
              height: 75,
              marginBottom: 16,
              alignItems: "center",
              width: "100%",
              position: "relative",
            }}
          >
            <div style={{ marginRight: 5, flex: 5 }}>
              <FormItem label="Field Name">
                <TextInput
                  value={customFieldName}
                  placeholder=""
                  onChange={(value) => setCustomFieldName(value)}
                />
              </FormItem>
            </div>
            <div style={{ flex: 2 }}>
              <FormItem label="Type">
                <Select
                  showSearch
                  optionFilterProp="children"
                  style={{ width: 100 }}
                  onChange={(value) => setCustomFieldType(value)}
                  value={customFieldType}
                >
                  {FIELDTYPE_OPTIONS.map(({ id, label }) => (
                    <Option key={id} value={id} style={{ fontWeight: 600 }}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            </div>
            <div
              style={{
                flex: 2,
                paddingLeft: 16,
                paddingTop: 24,
              }}
            >
              <ExtraBtn>
                <img
                  src={xSVG}
                  alt="cancel"
                  onClick={() => {
                    setCustomFieldName(undefined);
                    setCustomFieldType(undefined);
                  }}
                />
              </ExtraBtn>
              <ExtraBtn
                style={{
                  marginLeft: 8,
                  backgroundColor: theme.colors.primary9,
                }}
              >
                <img
                  src={checkSVG}
                  alt="save"
                  onClick={() => {
                    setCustomFields([
                      ...customFields,
                      {
                        fieldName: customFieldName,
                        fieldType: customFieldType,
                      },
                    ]);
                    setCustomFieldName(undefined);
                    setCustomFieldType(undefined);
                  }}
                />
              </ExtraBtn>
            </div>
          </div>
        )}
        {!customFieldName && (
          <Button
            secondary
            style={{ width: 200 }}
            onClick={() => {
              setCustomFieldName(" ");
            }}
          >
            + Add New Field
          </Button>
        )}
      </SectionBreak>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <FormItem>
          <Button
            style={{ width: 100, marginRight: 8 }}
            grey
            onClick={onCancel}
          >
            Cancel
          </Button>
          <Button
            style={{ width: 100 }}
            onClick={beforeSubmit}
            disabled={loading}
            data-testid="project-type-form-submit-btn"
          >
            Submit
          </Button>
        </FormItem>
      </div>
    </Form>
  );
}
