// TOP LEVEL IMPORTS
import { Suspense } from "react";
import { Navigate } from "react-router-dom";
import Loading from "components/common/Loading";
import { RoleType } from "generated/graphql";

// EXPORTED COMPONENT
// ==============================
const PublicRoute = (props) => {
  const {
    currentUser,
    redirectOnAuth,
    showHeader,
    showFooter,
    component: Component,
    path,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props;

  if (
    currentUser &&
    currentUser.roles &&
    currentUser.roles.includes(RoleType.AppAdmin)
  ) {
    return <Navigate to="/admin" />;
  }

  if (
    currentUser &&
    currentUser.id &&
    redirectOnAuth &&
    !window.location.href?.includes("/app/")
  ) {
    return <Navigate to="/app/businesses" />;
  }

  return (
    <Layout
      currentUser={currentUser}
      backgroundColor={backgroundColor}
      showHeader={showHeader}
      showFooter={showFooter}
    >
      <Suspense fallback={<Loading />}>
        <Component currentUser={currentUser} {...rest} />
      </Suspense>
    </Layout>
  );
};

// PROPS
// ==============================
PublicRoute.defaultProps = {
  redirectOnAuth: true,
};

// EXPORT
// ==============================
export default PublicRoute;
