import styled from "styled-components";
import { SectionHeader } from "../index";
// STATS
import NumberOfBusinesses from "./NumberOfBusinesses";
import BusinessesByNaics from "./BusinessesByNaics";
import BusinessesByCluster from "./BusinessesByCluster";
import BusinessesBySector from "./BusinessesBySector";
import ProjectsByStage from "./ProjectsByStage";
import NumberOfBusinessesAssisted from "./NumberOfBusinessesAssisted";
import NumberOfClosedCases from "./NumberOfClosedCases";
import CasesByStatus from "./CasesByStatus";
import SupportProgramsByStage from "./SupportProgramsByStage/index";
import SupportProgramsByType from "./SupportProgramsByType";
import OrganizationsByType from "./OrganizationsByType";
import CasesResolvedByMonth from "./CasesResolvedByMonth";
import BusinessAssistedByService from "./BusinessAssistedByService";
import InteractionsByMonth from "./InteractionsByMonth";
import { QuestionCircleFilled } from "@ant-design/icons";
import Tooltip from "components/common/Tooltip";
import EmptyData from "./EmptyData";
import NumberOfOpenCases from "./NumberOfOpenCases";
// LIB
import helpers from "lib/helpers/GeneralHelpers";
import { CustomerFeatureSettingKeyEnum } from "generated/graphql";

export const CardContainer = styled.div`
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  height: 265px;
  background: #fff;
  margin-right: 8px;
  margin-bottom: 8px;
  max-width: 100%;
  width: calc(50% - 10px + 2px);
  border-radius: 10px;
  padding: 16px;
  position: relative;
`;

export const ShortCard = styled.div`
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  height: 235px;
  background: #fff;
  margin-right: 8px;
  margin-bottom: 8px;
  max-width: 100%;
  width: calc(25% - 10px + 2px);
  border-radius: 10px;
  padding: 16px;
  position: relative;
  height: 108px;
  padding: 24px;
`;

export const BigValue = styled.h3`
  font-size: 40px;
  font-weight: 600;
  margin: 0px;
  margin-top: 24px;
  color: ${(p) => p.theme.colors.neutral1};
`;

export const CardTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral3};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1rem;
`;

const QuestionIcon = styled(QuestionCircleFilled)`
  color: ${(p) => p.theme.colors.neutral6};
  margin-left: 8px;
`;

export const Card = ({
  title,
  children,
  action,
  tooltip,
  empty,
}: {
  title?: string;
  children?: any;
  action?: any;
  tooltip?: any;
  empty?: any;
}) => {
  return (
    <CardContainer>
      <CardTitle>
        {title}
        {tooltip && (
          <Tooltip title={tooltip}>
            <QuestionIcon />
          </Tooltip>
        )}
      </CardTitle>
      {action}
      {!empty ? children : <EmptyData />}
    </CardContainer>
  );
};

const StatList = styled.div`
  display: inline-flex;
  max-width: 100%;
  width: 100%;
  flex-wrap: wrap;
`;

export const DASHBOARD_OPTION_TYPE_ENUMS = {
  NUMBER_OF_BUSINESSES: "NUMBER_OF_BUSINESSES",
  NUMBER_OF_ASSISTED_BUSINESSES: "NUMBER_OF_ASSISTED_BUSINESSES",
  SIZE_OF_BUSINESSESS: "SIZE_OF_BUSINESSESS",
  BUSINESSES_BY_NAICS: "BUSINESSES_BY_NAICS",
  BUSINESSES_BY_CLUSTERS: "BUSINESSES_BY_CLUSTERS",
};

export default function StatsBoard({ customer }) {
  return (
    <div>
      <SectionHeader>Dashboard</SectionHeader>
      <StatList>
        <NumberOfBusinesses />
        <NumberOfBusinessesAssisted />
        {helpers.featureIsOn({
          customer,
          feature: CustomerFeatureSettingKeyEnum.Cases,
        }) && (
          <>
            <NumberOfOpenCases />
            <NumberOfClosedCases />
          </>
        )}

        {/* <NumberOfBusinessesAssisted /> */}
      </StatList>

      <StatList>
        <BusinessesByCluster />
        <BusinessesBySector />
        {helpers.featureIsOn({
          customer,
          feature: CustomerFeatureSettingKeyEnum.Cases,
        }) && (
          <>
            <CasesByStatus />
            <CasesResolvedByMonth />
            <BusinessAssistedByService />
          </>
        )}
        <InteractionsByMonth />
        <BusinessesByNaics />

        {helpers.featureIsOn({
          customer,
          feature: CustomerFeatureSettingKeyEnum.Projects,
        }) && <ProjectsByStage />}
        {helpers.featureIsOn({
          customer,
          feature: CustomerFeatureSettingKeyEnum.Resources,
        }) && (
          <>
            <SupportProgramsByStage />
            <SupportProgramsByType />
            <OrganizationsByType />
          </>
        )}
      </StatList>
    </div>
  );
}
