import { Link } from "react-router-dom";
import Tag from "components/common/Tag";
import dTypes from "lib/constants/diversityTypes";

const columns = [
  {
    title: "title",
    render: ({ title }) => <div>{title}</div>,
  },
  {
    title: "Organization",
    render: ({ organization }) => {
      if (!organization) return null;
      return (
        <Link
          id="organization-click"
          target="_blank"
          to={`/app/organizations/${organization.id}`}
        >
          {organization.title}
        </Link>
      );
    },
  },
  {
    title: "Type",
    render: ({ fundingType }) => <div>{fundingType}</div>,
  },
  {
    title: "Diversity",
    render: ({ diversityTypes }) =>
      diversityTypes &&
      diversityTypes.length > 0 && (
        <div>
          {diversityTypes.map((tag) => (
            <Tag key={tag}>
              {dTypes?.find((item) => item.value === tag)?.label}
            </Tag>
          ))}
        </div>
      ),
  },
  {
    title: "Stages",
    render: ({ businessStages }) =>
      businessStages &&
      businessStages.length > 0 && (
        <div>
          {businessStages.map((tag) => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </div>
      ),
  },
];

export default columns;
