// COMPONENTS
import { LoadingOutlined } from "@ant-design/icons";
import Note from "./Note";
// APOLLO
import useUrlChange from "hooks/useUrlChange";
import formTypes from "lib/constants/formTypes";
import EmptyState from "components/common/EmptyState";
import { useNotesQuery } from "generated/graphql";

export enum NoteParentTypeEnum {
  Projects = "Projects",
  Businesses = "Businesses",
  Contacts = "Contacts",
  Sites = "Sites",
}

const getBusinessId = (businessId, parentType, parentId) => {
  if (businessId) return businessId;
  return parentType === NoteParentTypeEnum.Businesses ? parentId : undefined;
};

export default function Notes({
  parentId,
  parentType,
  currentUser,
  businessId,
}: {
  parentId: string;
  parentType: NoteParentTypeEnum;
  currentUser: any;
  businessId?: string;
}) {
  const { onUrlChange } = useUrlChange();
  const notesQuery = useNotesQuery({
    variables: {
      projectId:
        parentType === NoteParentTypeEnum.Projects ? parentId : undefined,
      businessId:
        parentType === NoteParentTypeEnum.Businesses ? parentId : undefined,
      contactId:
        parentType === NoteParentTypeEnum.Contacts ? parentId : undefined,
      siteId: parentType === NoteParentTypeEnum.Sites ? parentId : undefined,
    },
  });

  if (notesQuery.loading) return <LoadingOutlined style={{ fontSize: 24 }} />;

  if (!notesQuery?.data?.notes?.notes?.[0]) {
    return (
      <EmptyState
        title="You have no notes yet..."
        subtitle="Add your first note to let your team know what's going on"
        onOk={() => {
          onUrlChange({
            drawerVisible: formTypes.NOTES,
            projectId:
              parentType === NoteParentTypeEnum.Projects ? parentId : undefined,
            businessId: getBusinessId(businessId, parentType, parentId),
            contactId:
              parentType === NoteParentTypeEnum.Contacts ? parentId : undefined,
            siteId:
              parentType === NoteParentTypeEnum.Sites ? parentId : undefined,
          });
        }}
        onOkText="Create Note"
      />
    );
  }

  return (
    <div style={{ paddingTop: 16 }}>
      <div>
        {notesQuery?.data?.notes?.notes?.map((note) => (
          <Note key={note.id} currentUser={currentUser} note={note} />
        ))}{" "}
      </div>
    </div>
  );
}
