// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import contactFragment from "ApolloClient/Fragments/contactFragment";
import userFragment from "ApolloClient/Fragments/userFragment";

export default gql`
  fragment todoFragment on Todo {
    id
    key
    __typename
    title
    description
    dueDate
    priority
    done
    assignedToId
    assignedTo {
      ...userFragment
    }
    tagIds
    tags {
      id
      title
    }
    contactId
    contact {
      ...contactFragment
    }
    site {
      id
      title
      fullAddress
    }
    businessId
    business {
      id
      title
      logo {
        id
        url
        filename
      }
    }
    caseId
    case {
      id
      title
    }
    projectId
    project {
      id
      title
      image {
        id
        url
      }
    }
    attachments {
      id
      filename
      encoding
      mimetype
      url
      key
      createdAt
      createdByName
    }
    createdAt
    updatedAt
  }
  ${contactFragment}
  ${userFragment}
`;
