import React from "react";
import Select from "antd/lib/select";
import siteTypes from "lib/constants/siteTypes";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const SiteTypeInput = (props) => {
  const { onChange, value = "" } = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        size="large"
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
      >
        {siteTypes.map(({ value, label }) => (
          <Option key={value} value={value} data-testid={`option-${value}`}>
            {label}
          </Option>
        ))}
      </Select>
    </DropdownStyleWrapper>
  );
};

SiteTypeInput.defaultProps = {
  style: {
    width: "100%",
  },
  placeholder: "Select one",
};

// EXPORT
// ================================================================
export default SiteTypeInput;
