import passwordSchema from "lib/helpers/passwordSchema";

export default function useCheckPasswordErrors() {
  const checkPasswordErrors = (password: string): string | null => {
    let errors = passwordSchema.validate(password, { list: true });
    if (errors && typeof errors !== "boolean" && errors.length > 0) {
      if (errors.includes("min")) {
        return "Must be at least 8 characters long";
      }
      if (errors.includes("uppercase")) {
        return "Requires at least one uppercase";
      }
      if (errors.includes("lowercase")) {
        return "Password requires an lowercase letter";
      }
      if (errors.includes("digits")) {
        return "Requires at least one number";
      }
      if (errors.includes("oneOf")) {
        return "Can not be Password123 or Passw0rd";
      }
    }
    if (typeof errors === "boolean" && errors === true) {
      return "This password does not meet the requirements";
    }
    return errors?.[0]; // if we don't find errors that we want to re-write-- at least return whatever error eixsts
  };

  return { checkPasswordErrors, passwordSchema };
}
