import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import FormItem from "components/common/FormItem";
import PrioritySelect from "components/inputs/PrioritySelect";
import DateInput from "components/inputs/DateInput";
import TextEditor from "components/inputs/TextEditor";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Button from "components/common/Button";
import { LoadingOutlined } from "@ant-design/icons";
import Attachments from "components/common/Attachments";
import CaseInput from "components/inputs/CaseInput";
import UserInput from "components/inputs/UserInput";
import ProjectInput from "components/inputs/ProjectInput";
import SiteInput from "components/inputs/SiteInput";
import BusinessInput from "components/inputs/BusinessInput";
import ContactInput from "components/inputs/ContactInputSimple";
import SideFormButtonContainer from "components/common/SideFormButtonContainer";
// Hooks
import useUploadFile from "lib/hooks/useUploadFile";
// LIB
import helpers from "lib/helpers/GeneralHelpers";
import { CustomerFeatureSettingKeyEnum } from "generated/graphql";

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const UploadLabel = styled.label`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary2};
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    color: ${(p) => p.theme.colors.primary1};
  }
`;

export default function TodoForm({
  defaultValues,
  loading,
  onCancel,
  onSubmit,
  customer,
}) {
  const defaultDueDate = moment().add(7, "days");
  const DEFAULT_VALUE = {
    dueDate: defaultDueDate,
    priority: 1,
  };
  const [values, setValues] = useState({ ...DEFAULT_VALUE, ...defaultValues });
  const [errors, setErrors] = useState<any>({});
  // const { uploading, uploadFile } = useSingleUpload();
  const [uploadFile, uploading] = useUploadFile();
  const {
    title,
    businessId,
    dueDate,
    projectId,
    caseId,
    attachments,
    priority,
    description,
    assignedToId,
    siteId,
    contactId,
  } = values;

  const onChange = (newValues) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const handleOnSubmit = () => {
    // reset errors
    setErrors({});
    //
    if (!values.title) {
      return setErrors({
        ...errors,
        title: "Please provide your todo a title",
      });
    }
    if (!values.dueDate) {
      return setErrors({
        ...errors,
        dueDate: "Please provide your todo a title",
      });
    }
    // @todo: assign to the current user if they didnt' assign it to anyone else
    // const valuesToSubmit = {
    //   ...values,
    //   assignedToId: values.assignedToId ? values.assignedToId :
    // }
    onSubmit({
      ...values,
      dueDate: moment(values.dueDate)?.valueOf()?.toString(),
    });
  };

  const disabled = !title || loading;

  return (
    <div>
      <FormItem label="Todo title" required error={errors?.title}>
        <TextInput
          value={title}
          placeholder="Title"
          onChange={(title) => onChange({ title })}
          data-testid="todo-form-title"
        />
      </FormItem>
      <Row>
        <Col xs={12}>
          <FormItem label="Due Date" required error={errors?.dueDate}>
            <DateInput
              style={{ marginRight: 16 }}
              value={dueDate}
              data-testid="todo-form-dueDate"
              onChange={(dueDate) =>
                onChange({
                  dueDate,
                })
              }
            />
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem label="Priority">
            <PrioritySelect
              value={priority}
              onChange={(priority) => onChange({ priority })}
            />
          </FormItem>
        </Col>
      </Row>

      <FormItem label="Notes">
        <TextEditor
          value={description}
          onChange={(description) => onChange({ description })}
        />
      </FormItem>

      <FormItem label="Contact">
        <ContactInput
          value={contactId}
          defaultContactId={contactId as string}
          onChange={(contactId) => {
            onChange({ contactId });
          }}
        />
      </FormItem>

      <FormItem label="Business">
        <BusinessInput
          value={businessId}
          defaultBusinessId={businessId}
          onChange={(businessId) => {
            onChange({ businessId });
          }}
        />
      </FormItem>

      {helpers.featureIsOn({
        customer,
        feature: CustomerFeatureSettingKeyEnum.Cases,
      }) && (
        <FormItem label="Case">
          <CaseInput
            value={caseId}
            onChange={(caseId) => onChange({ caseId })}
          />
        </FormItem>
      )}
      {helpers.featureIsOn({
        customer,
        feature: CustomerFeatureSettingKeyEnum.Projects,
      }) && (
        <FormItem label="Project">
          <ProjectInput
            value={projectId}
            defaultProjectId={projectId}
            onChange={(projectId) => {
              onChange({ projectId });
            }}
          />
        </FormItem>
      )}
      {helpers.featureIsOn({
        customer,
        feature: CustomerFeatureSettingKeyEnum.Resources,
      }) && (
        <FormItem label="Site">
          <SiteInput
            value={siteId}
            defaultSiteId={siteId}
            onChange={(siteId) => {
              onChange({ siteId });
            }}
          />
        </FormItem>
      )}
      <FormItem label="Assigned To">
        <UserInput
          value={assignedToId}
          onChange={(assignedToId) => {
            onChange({ assignedToId });
          }}
        />
      </FormItem>
      <div>
        <div>
          <Attachments
            attachments={attachments || []}
            onSave={(attachments) => onChange({ attachments })}
          />
        </div>
        {/* <FormItem error={errors.attachments}> */}
        <div style={{ paddingTop: 24, paddingBottom: 56 }}>
          {!uploading ? (
            <>
              {" "}
              <UploadLabel htmlFor="todo-attachment-input">
                + Upload an attachment
              </UploadLabel>
              <UploadButton
                id="todo-attachment-input"
                name="todo-attachment-input"
                type="file"
                onChange={async (event) => {
                  try {
                    const file = event?.target?.files?.[0];
                    if (!file) return;
                    let result = await uploadFile(file);
                    onChange({
                      attachments: [...(attachments || []), result],
                    });
                  } catch (err) {
                    console.log(err);
                    setErrors({
                      attachments:
                        "Something went wrong uploading you file. If the issue continues, please reach out to support.",
                    });
                  }
                }}
              />
            </>
          ) : (
            <div style={{ height: 40 }}>
              <LoadingOutlined /> Uploading...
            </div>
          )}
        </div>
        {/* </FormItem> */}
      </div>
      <SideFormButtonContainer>
        <Button
          disabled={disabled}
          loading={loading}
          onClick={handleOnSubmit}
          style={{ width: 150, marginLeft: 16 }}
          data-testid="todo-form-submit-btn"
        >
          Save Todo
        </Button>
        <Button
          disabled={loading}
          onClick={onCancel}
          grey
          style={{ width: 100, marginLeft: 8 }}
        >
          Cancel
        </Button>
      </SideFormButtonContainer>
    </div>
  );
}
