import styled from "styled-components";
import { createRef, useEffect } from "react";
import FormLabel from "components/text/FormLabel";
import { CloseCircleOutlined, QuestionCircleFilled } from "@ant-design/icons";
import Popover from "components/common/Popover";
import theme from "lib/theme";

const Container = styled.div`
  margin-top: 24px;
`;

const HintText = styled(FormLabel)`
  color: ${(p) => p.theme.colors.neutral5};
  margin-top: 4px;
  font-weight: 400;
  font-size: 14px;
`;

const Requred = styled.span`
  color: ${(p) => p.theme.colors.red6};
  font-size: 14px;
`;

const ErrorText = styled(HintText)`
  color: ${(p) => p.theme.colors.red4};
`;

const FormItem = ({
  label,
  colon,
  hint,
  error,
  required,
  children,
  containerProps,
  tooltip,
}: {
  label?: string | JSX.Element;
  colon?: boolean;
  hint?: string | any;
  error?: string;
  required?: boolean;
  children?: any;
  containerProps?: any;
  tooltip?: any;
}) => {
  const input = createRef<HTMLDivElement>();

  useEffect(() => {
    if (error?.[0]) {
      input?.current?.scrollIntoView({
        behavior: "smooth",
        inline: "center",
      });
    }
  }, [error, input]);

  return (
    <Container ref={input} {...containerProps}>
      {label && (
        <FormLabel data-testid={`FormLabel-${label}`}>
          {label}
          {colon ? ":" : null}
          {required ? <Requred> *</Requred> : null}
          {tooltip ? (
            <Popover
              content={
                <div style={{ width: 220, maxWidth: "100%" }}>{tooltip}</div>
              }
            >
              <QuestionCircleFilled
                style={{ marginLeft: 6, color: theme.colors.neutral7 }}
              />
            </Popover>
          ) : null}
        </FormLabel>
      )}
      {children}
      <div style={{ minHeight: 8 }}>
        {hint && <HintText>{hint}</HintText>}
        {error && (
          <ErrorText>
            <CloseCircleOutlined style={{ marginRight: 4, fontSize: 11 }} />
            {error}
          </ErrorText>
        )}
      </div>
    </Container>
  );
};

FormItem.defaultProps = {
  label: null,
  containerProps: {},
  colon: false,
};

export default FormItem;
