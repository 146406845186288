import React from 'react';
import styled from 'styled-components';
import {ApartmentOutlined} from '@ant-design/icons';

const Button = styled.button`
  background: ${({theme}) => theme.colors.neutral10};
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 16px;
  margin-bottom: 16px;
  cursor: pointer;
  position: relative;
  top: 2px;
  border: 0px;
  margin-left: 16px;
  font-weight: 600;
  color: ${({theme}) => theme.colors.neutral3};
  &:hover {
    background: ${({theme}) => theme.colors.neutral9};
  }
`;

const Icon = styled(ApartmentOutlined)`
  color: ${({theme}) => theme.colors.neutral3};
  margin-right: 8px;
`;

export default function AddTodoBtn(props) {
  return (
    <Button onClick={props.onClick} data-testid={props['data-testid']}>
      <Icon /> Add a Todo
    </Button>
  );
}
