import React from "react";
import styled from "styled-components";
// images
import ClipboardFilled from "components/icons/ClipboardFilled";
import useUrlChange from "hooks/useUrlChange";
// LIB
import theme from "lib/theme";
import { AutomationTriggerType } from "generated/graphql";
import AutomationTriggerIcon from "components/common/AutomationTriggerIcon";

const Container = styled.div`
  height: 80%;
  max-width: 100%;
  width: 1100px;
  padding: 32px;
  margin: auto;
`;

// const Title = styled.h1`
//   font-size: 18px;
//   color: ${(p) => p.theme.colors.neutral1};
//   font-weight: 600;
//   margin-bottom: 4px;
// `;

// const Caption = styled.p`
//   font-size: 16px;
//   color: ${(p) => p.theme.colors.neutral4};
//   margin-bottom: 24px;
// `;

const BlockContainer = styled.div`
  width: 375px;
  text-align: center;
  margin-right: 32px;
  height: 225px;
  padding: 16px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 2px solid ${(p) => p.theme.colors.neutral9};
  &:hover {
    border: 2px solid ${(p) => p.theme.colors.neutral8};
    cursor: pointer;
  }
`;

const BlockImg = styled.img`
  height: 65px;
`;

const BlockTitle = styled.h2`
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 18px;
  margin: 0px;
  margin-top: 16px;
`;

const BlockCaption = styled.p`
  color: ${(p) => p.theme.colors.neutral5};
  font-size: 16px;
  margin: 0px;
`;

const ImageContainer = styled.div`
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ReportBlock = ({ img, title, caption, onClick = () => {} }) => {
  return (
    <BlockContainer onClick={onClick}>
      <div>
        <ImageContainer>
          {React.isValidElement(img) && img}
          {!React.isValidElement(img) && <BlockImg src={img} />}
        </ImageContainer>
        <BlockTitle>{title}</BlockTitle>
        <BlockCaption>{caption}</BlockCaption>
      </div>
    </BlockContainer>
  );
};

const PageTitle = styled.h1`
  margin: 0px;
  margin-bottom: 32px;
  font-size: 24px;
  letter-spacing: 1.5;
  color: ${(p) => p.theme.colors.neutral1};
`;

const AUTOMATION_TRIGGERS = [
  {
    id: AutomationTriggerType.ProjectStatusChange,
    title: "Project Stage Change",
    caption: "Run an automation when a project stage changes",
  },
  {
    id: AutomationTriggerType.FormSurveyResponse,
    title: "Form Response",
    caption: "Run an automation when a business responds in a particular way",
  },
];

export default function SelectAutomation({ currentUser }) {
  const { onUrlChange } = useUrlChange();

  return (
    <>
      <Container>
        <PageTitle>Select an Automation to Create</PageTitle>{" "}
        <div style={{ display: "flex" }}>
          {AUTOMATION_TRIGGERS?.map((trigger) => {
            return (
              <ReportBlock
                img={
                  <AutomationTriggerIcon size={72} triggerType={trigger.id} />
                }
                title={trigger.title}
                caption={trigger.caption}
                onClick={() =>
                  onUrlChange(
                    { actionTriggerType: trigger.id },
                    "/app/automations/create"
                  )
                }
              />
            );
          })}

          <BlockContainer>
            <div>
              {/* <BlockImg src={elipSVG} style={{ width: 72 }} /> */}
              <BlockTitle>More reports coming...</BlockTitle>
              <BlockCaption>
                We’re working on developing more out-of-the-box reports. Stay
                tuned!
              </BlockCaption>
            </div>
          </BlockContainer>
        </div>
      </Container>
    </>
  );
}
