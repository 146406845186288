import React from "react";
// COMPONENTS
import FormItem from "components/common/FormItem";
import Button from "components/common/Button";
import TextInput from "components/inputs/TextInput";
import { CurrencyInput } from "../../inputs/CurrencyInput/index";
import DateInput from "../../inputs/DateInput/index";
import styled from "styled-components";

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 550px;
  z-index: 1000;
  height: 65px;
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.neutral10};
`;
export default function CompanyInvestmentForm({
  value,
  onChange,
  onSubmit,
  disabled,
  loading,
  onCancel,
}) {
  const { amount, description, investedOn } = value;

  console.log({
    amount,
    description,
    investedOn,
  });

  return (
    <>
      <div style={{ paddingBottom: 64 }}>
        <FormItem label="Amount" required>
          <CurrencyInput
            value={amount}
            onChange={(amount) => onChange({ amount })}
          />
        </FormItem>
        <FormItem label="Description" required>
          <TextInput
            value={description}
            onChange={(description) => onChange({ description })}
          />
        </FormItem>
        <FormItem label="When was the investment made?" required>
          <DateInput
            value={investedOn}
            onChange={(investedOn) => onChange({ investedOn })}
          />
        </FormItem>
      </div>
      <ButtonContainer>
        <div>
          <Button
            disabled={!amount || !description || !investedOn || disabled}
            loading={loading}
            onClick={onSubmit}
            style={{ marginLeft: 16 }}
          >
            Add Investment
          </Button>
          <Button onClick={onCancel} grey style={{ width: 100, marginLeft: 8 }}>
            Cancel
          </Button>
        </div>
      </ButtonContainer>
    </>
  );
}
