import { ShortCard, CardTitle, BigValue } from "../index";
import Skeleton from "components/common/Skeleton";
// APOLLO
import { useCaseStatusReportQuery } from "generated/graphql";

export default function NumberOfOpenCases() {
  const { data, loading } = useCaseStatusReportQuery();
  if (loading) {
    return (
      <ShortCard>
        <Skeleton round paragraph={{ rows: 1 }} />
      </ShortCard>
    );
  }

  return (
    <ShortCard>
      <CardTitle>Number of Open Cases</CardTitle>
      <BigValue style={{ marginTop: 0 }}>
        {data?.caseStatusReport
          ?.filter((item) => !item?.status?.isFinalStep)
          ?.map((item) => item?.total)
          .reduce((a: any, b: any) => a + b, 0)}
      </BigValue>
    </ShortCard>
  );
}
