import { useSaveFundingProgramMutation } from "generated/graphql";

const getValues = (fundingProgram) => {
  let shaped = {
    ...fundingProgram,
  };
  return shaped;
};

export default function useSaveFundingProgram() {
  const [saveFundingProgramMutation, { loading }] =
    useSaveFundingProgramMutation();

  const onSubmitFundingProgram = async (params, onComplete?: any) => {
    try {
      // set loading
      let res = await saveFundingProgramMutation({
        variables: {
          params: getValues(params),
        },
        refetchQueries: ["fundingPrograms"],
      });

      onComplete && onComplete();
      return res?.data?.saveFundingProgram;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSubmitFundingProgram,
    saveingFundingProgram: loading,
  };
}
