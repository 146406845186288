import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
// COMPONENTS
import Button from "components/common/Button";
import Table from "components/common/Table";
// HOOKs
import useUrlChange from "hooks/useUrlChange";
// LIB
import formTypes from "lib/constants/formTypes";
import orgTypes from "lib/constants/orgTypes";
import Tag from "components/common/Tag";
import Text from "components/text/Text";
import PageHeader from "components/text/PageHeader";
import { Organization, useOrganizationsQuery } from "generated/graphql";

const TableWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  .ant-table-thead {
    background: #fbfafa !important;
    border-bottom-color: transparent !important;
  }
  .ant-table-column-title {
    text-transform: uppercase;
    width: 143px;
    height: 22px;
    font-family: ${(p) => p.theme.fontFamily};
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.04px;
    color: #9fb3c8 !important;
  }
`;

const TopRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
`;

export default function OrganizationsTable() {
  const navigate = useNavigate();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(5);
  const [current, setCurrent] = useState(1);
  const { onUrlChange } = useUrlChange();
  const { data, loading } = useOrganizationsQuery({
    variables: {
      skip,
      limit,
    },
  });

  const onRow = (record, rowIndex) => {
    return {
      onClick: () => navigate(`/app/organizations/${record.id}`), // click row
      onDoubleClick: (event) => {}, // double click e
      onContextMenu: (event) => {}, // right button click row
      onMouseEnter: (event) => {}, // mouse enter row
      onMouseLeave: (event) => {}, // mouse leave row
    };
  };

  const columns = [
    {
      title: "title",
      render: ({ title, serviceTypes }: Organization) => (
        <div>
          <Text>{title}</Text>{" "}
          {serviceTypes?.map((item) => (
            <Tag grey key={item}>
              {item}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Type",
      render: ({ orgType }: Organization) => (
        <div>
          {orgType &&
            orgTypes.filter((item) => item.value === orgType)[0].label}
        </div>
      ),
    },
    {
      title: "Stages",
      render: ({ businessStages }: Organization) => (
        <div>
          {businessStages?.map((item) => (
            <Tag grey key={item}>
              {item}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Diversity",
      render: ({ diversity }: Organization) => (
        <div>
          {diversity?.map((item) => (
            <Tag grey key={item}>
              {item}
            </Tag>
          ))}
        </div>
      ),
    },
    {
      title: "Clusters/Industries",
      render: ({ clusters }: Organization) => (
        <div>
          {clusters?.map((item) => (
            <Tag grey key={item}>
              {item}
            </Tag>
          ))}
        </div>
      ),
    },
    // {
    //   title: '',
    //   width: '7.5%',
    //   render: (record) => (
    //     <Text onClick={() => setSiteId(record.id)}>Edit</Text>
    //   ),
    // },
    // {
    //   title: '',
    //   width: '7.5%',
    //   render: (record) => (
    //     <Popconfirm
    //       title="Are you sure you want to delete this site?"
    //       okText="Yes"
    //       onConfirm={() => handleDeleteSite(record.id)}
    //     >
    //       <Text>{!deleting ? 'Delete' : '...'}</Text>
    //     </Popconfirm>
    //   ),
    // },
  ];

  return (
    <div>
      <TopRow>
        <PageHeader style={{ margin: 0 }}>Ecosystem Organizations</PageHeader>
        <Button
          secondary
          onClick={() =>
            onUrlChange({ drawerVisible: formTypes.ORGANIZATIONS })
          }
          style={{ marginLeft: 16 }}
        >
          + New Organization
        </Button>
      </TopRow>
      <TableWrapper>
        <Table
          dataSource={data?.organizations?.organizations || []}
          columns={columns}
          loading={loading}
          onChange={(page: number, pageSize: number) => {
            setLimit(pageSize);
            setSkip(pageSize * (page - 1));
            setCurrent(page);
          }}
          pagination={{
            total: data?.organizations?.count || 0,
            pageSize: limit,
            current,
            showSizeChanger: true,
          }}
          rowKey="id"
          onRow={onRow}
        />
      </TableWrapper>
    </div>
  );
}
