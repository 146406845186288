import useClipboard from "../../../lib/hooks/useClipboard";
import Button from "components/common/Button";
import TextInput from "components/inputs/TextInput";
import message from "components/common/message";
import { CopyOutlined } from "@ant-design/icons";

export const generateSurveyUrl = ({
  answeredBy,
  businessId,
  templateId,
  referrerId,
  surveyCampaignId,
  surveyCampaignItemId,
  contactId,
}: {
  answeredBy?: string;
  businessId?: string;
  templateId: string;
  referrerId?: string;
  surveyCampaignId?: string;
  surveyCampaignItemId?: string;
  contactId?: string;
}) => {
  const queryParams: string[] = [];

  if (surveyCampaignId)
    queryParams.push(
      `surveyCampaignId=${encodeURIComponent(surveyCampaignId)}`
    );

  if (referrerId) {
    queryParams.push(`referrerId=${encodeURIComponent(referrerId)}`);
  }

  if (businessId) {
    queryParams.push(`businessId=${encodeURIComponent(businessId)}`);
    queryParams.push(`answeredBy=${encodeURIComponent(businessId)}`);
  }

  if (contactId) {
    queryParams.push(`contactId=${encodeURIComponent(contactId)}`);
    queryParams.push(`answeredBy=${encodeURIComponent(contactId)}`); // if we know the contact, then we will use their contactId
  }

  if (templateId) {
    queryParams.push(`templateId=${encodeURIComponent(templateId)}`);
  }

  if (surveyCampaignItemId) {
    queryParams.push(
      `surveyCampaignItemId=${encodeURIComponent(surveyCampaignItemId)}`
    );
  }

  if (answeredBy) {
    queryParams.push(`answeredBy=${encodeURIComponent(answeredBy)}`);
  }

  return `/app/survey/view?${queryParams.join("&")}`;
};
///${window.location.hostname}

export default function ShareLink({
  templateId,
  contactId,
  businessId,
  currentUser,
}) {
  const [copyToClipboard] = useClipboard();

  let SHARE_LINK = `${window.location.hostname}${generateSurveyUrl({
    templateId,
    businessId,
    contactId,
    referrerId: currentUser?.id,
  })}`;

  return (
    <>
      {/* <Title>Or, want to share this survey directly?</Title>
      <p>
        Copy paste the link below and send it to the business so they can fill
        it out
      </p> */}
      <div style={{ display: "flex" }}>
        <TextInput value={SHARE_LINK} disabled />
        <Button
          onClick={() => {
            message.success("Link copied to clipboard");
            copyToClipboard(SHARE_LINK);
          }}
          style={{ width: 150, height: 50 }}
        >
          <CopyOutlined /> Copy
        </Button>
      </div>
    </>
  );
}
