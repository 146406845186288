import React from "react";
import styled from "styled-components";
import GlobalAdd from "./GlobalAdd";
import Header from "./Header";

const Container = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: ${(p) => p.theme.colors.neutral10} !important;
`;

// EXPORT
// ===================================
export default function MobileAuthenticatedLayout({
  children,
  currentUser,
  hideActionBtn,
}) {
  return (
    <Container id="mobile-authenticated-layout">
      <Header currentUser={currentUser} />
      <>
        <>{children}</>
      </>
      {!hideActionBtn && <GlobalAdd currentUser={currentUser} />}
    </Container>
  );
}
