import styled from "styled-components";

export default styled.div`
  height: 100%;
  min-height: 100vh;

  width: 1100px;
  max-width: 100%;
  margin: auto;
  padding: 20px;
  padding-bottom: 140px;
  @media only screen and (max-width: 414px) {
    padding: 20px;
  }
`;

export const PageContainer = styled.div`
  height: calc(100vh - 56px);
  display: flex;
  align-self: stretch !important;
  align-items: stretch !important;
  justify-content: space-between;
  word-break: break-word;
  overflow-y: hidden;
  flex-grow: 1;
  width: 100%;
  z-index: 1;
`;

export const MiddleContent = styled.div`
  overflow-y: auto !important;
  display: flex;
  position: relative;
  align-self: stretch !important;
  z-index: 1;
  flex-shrink: 100000;
  width: 100%;
  overflow-x: auto;
  flex-direction: column;
  overflow-y: scroll;
  padding: 16px;
  white-space: normal;
`;

export const LeftCol = styled.div`
  flex-basis: 20%;
  max-width: 500px;
  min-width: 280px;
  background: #fff;
  overflow-y: scroll;
  z-index: 1;
  padding: 24px;
  overflow-y: scroll;
  border-right: 1px solid ${(p) => p.theme.colors.neutral9};
  position: relative;
`;
