import React from 'react';
import TodoListForm from 'components/forms/TodoListForm';
// APOLLO
import {useMutation} from '@apollo/client';
import SAVE_TODO_LIST from 'ApolloClient/Mutations/saveTodoList';
import TODO_LIST_QUERY from 'ApolloClient/Queries/todoLists';
import message from 'components/common/message';
import PageHeader from 'components/text/PageHeader';

export default function NewTodoListForm({
  variables,
  onSubmitComplete,
  onCancel,
}) {
  const [saveTodoList, {loading}] = useMutation(SAVE_TODO_LIST);

  const updateCache = (proxy, {data}) => {
    let localData = proxy.readQuery({
      query: TODO_LIST_QUERY,
      variables: {
        ...variables,
      },
    });
    proxy.writeQuery({
      query: TODO_LIST_QUERY,
      variables: {
        ...variables,
      },
      data: {
        ...localData,
        todoLists: {
          ...localData.todoLists,
          todoLists: [...localData.todoLists.todoLists, data.saveTodoList],
          count: [...localData.todoLists.todoLists, data.saveTodoList].length,
        },
      },
    });
  };

  const onSubmit = async (values) => {
    try {
      let variables = {
        params: {
          ...values,
        },
      };
      await saveTodoList({
        variables,
        update: updateCache,
      });
      message.success('contact successfully added');
      onSubmitComplete();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div
      style={{
        background: '#fff',
        width: '100%',
        margin: 'auto',
        padding: 16,
      }}
    >
      <PageHeader style={{marginLeft: 32}}>Create a New List</PageHeader>
      <TodoListForm onSubmit={onSubmit} onCancel={onCancel} loading={loading} />
    </div>
  );
}
