import PageContainer from "components/common/PageContainer";
import useUrlChange from "hooks/useUrlChange";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SurveyTemplateOptions from "./SurveyTemplateOptions";
import queryString from "query-string";
import styled from "styled-components";
import Title from "components/text/Title";
import ShareLink, { generateSurveyUrl } from "./ShareLink";
import Button from "components/common/Button";

const Card = styled.div`
  background: #fff;
  padding: 16px;
  border-radius: 5px;
  max-width: 100%;
  margin: auto;
`;

const Subtitle = styled.h3`
  margin: 0px;
  margin-bottom: 32px;
  font-size: 184x;
  color: ${(p) => p.theme.colors.neutral6};
`;

export default function ShareSurvey({ currentUser }) {
  const location = useLocation();
  const navigate = useNavigate();

  const { onUrlChange } = useUrlChange();
  const { businessId, surveyTemplateId, contactId } = queryString.parse(
    location.search
  );

  if (!surveyTemplateId) {
    return (
      <PageContainer style={{ width: 700, maxWidth: "100%" }}>
        <div style={{ width: 700, maxWidth: "100%", margin: "auto" }}>
          <SurveyTemplateOptions
            customerId={currentUser.customerId}
            onChange={(surveyTemplateId) =>
              onUrlChange({ surveyTemplateId }, `/app/survey`)
            }
          />
          <div
            style={{
              marginTop: 16,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <p>
              Or,{" "}
              <Link to="/app/survey/create">create a new survey template</Link>
            </p>
            <p>
              <Link to="/app/surveys/templates">Manage existing templates</Link>
            </p>
          </div>
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer style={{ width: 700, maxWidth: "100%", paddingTop: 32 }}>
      <Title style={{ marginBottom: 32 }}>
        You have two options for initiating this survey...
      </Title>
      <Card>
        <Title>
          1. You can fill it out yourself on behalf of the business.
        </Title>
        <Subtitle>
          This is a good option if you're filling this out as part of a site
          visit or doing a interview over the phone.
        </Subtitle>
        <Subtitle>
          To get started with this option, click the button below:
        </Subtitle>
        <Button
          secondary
          onClick={() => {
            try {
              const url = generateSurveyUrl({
                templateId: surveyTemplateId as string,
                businessId: businessId as string,
                contactId: contactId as string,
                referrerId: currentUser?.id,
                answeredBy: currentUser?.id,
              });
              navigate(url);
            } catch (err) {}
          }}
        >
          Fill it out myself
        </Button>
      </Card>
      <Card style={{ marginTop: 32 }}>
        <Title>2. Or, share the link direclty with the business</Title>
        <Subtitle>
          They can click the link and fill in the survey themselves. You'll get
          an email alert when it's compelted and you'll find the result in the
          business profile page.
        </Subtitle>
        <Subtitle>
          Copy paste the link below and send it to the business so they can fill
          it out:
        </Subtitle>
        <ShareLink
          templateId={surveyTemplateId}
          businessId={businessId}
          currentUser={currentUser}
          contactId={contactId}
        />
      </Card>
      {/* <Card style={{ marginTop: 32 }}>
        <Title>
          3. You share a copy of this 
        </Title>
        <Subtitle>
          This is a good option if you're filling this out as part of a site
          visit or doing a interview over the phone.
        </Subtitle>
      </Card> */}
    </PageContainer>
  );
}
