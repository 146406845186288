import * as React from 'react';

function InfoCircleFilled(props) {
  return (
    <svg
      width={33}
      height={33}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.7 16.5a13.2 13.2 0 11-26.4 0 13.2 13.2 0 0126.4 0zM18.15 9.9a1.65 1.65 0 11-3.3 0 1.65 1.65 0 013.3 0zm-3.3 4.95a1.65 1.65 0 100 3.3v4.95a1.65 1.65 0 001.65 1.65h1.65a1.65 1.65 0 000-3.3V16.5a1.65 1.65 0 00-1.65-1.65h-1.65z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default InfoCircleFilled;
