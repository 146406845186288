import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import checkSVG from "./sm-check.svg";
import cancelSVG from "./sm-x.svg";

const TextInput = styled.input`
  background: #fff !important;
  border: 0px;
  width: 100%;
  padding-left: 8px;
  border-radius: 5px;
  font-size: 14px;
  margin: 0px;
  max-height: 35px !important;
  min-height: 35px !important;
  height: 35px !important;
  font-weight: 600;
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.neutral3};
  letter-spacing: 0.08em;
  &:focus {
    outline: 0;
  }
`;

const SaveBtn = styled.button`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  bottom: -32px;
  background: ${(p) => p.theme.colors.neutral10};
  border: 0px;
  height: 32px;
  width: 32px;
  cursor: pointer;
  font-weight: 700;
  border-radius: 3px;
  font-size: 18px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  &:focus {
    outline: 0;
  }
  &:hover {
    background: ${(p) => p.theme.colors.neutral9};
  }
`;

const CancelBtn = styled(SaveBtn)`
  right: 40px;
`;

export default function EditTitleInput({ value, onChange, onCancel, onClick }) {
  const textInput: any = useRef(null);
  useEffect(() => {
    textInput?.current?.focus();
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <TextInput
        value={value}
        onChange={onChange}
        ref={textInput}
        data-testid="edit-title-input"
      />
      <CancelBtn onClick={onCancel} data-testid="cancel-temp-col-btn">
        <img src={cancelSVG} alt="cancel" height="24" />
      </CancelBtn>
      <SaveBtn onClick={onClick} data-testid="save-temp-col-btn">
        <img src={checkSVG} alt="save" height="24" />
      </SaveBtn>
    </div>
  );
}
