// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import userFragment from 'ApolloClient/Fragments/userFragment';

export default gql`
  mutation saveUser($id: ID, $params: UserParams) {
    saveUser(id: $id, params: $params) {
      ...userFragment
    }
  }
  ${userFragment}
`;
