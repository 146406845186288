import {
  OrganizationParams,
  useSaveOrganizationMutation,
} from "generated/graphql";

export default function useSaveOrganizationHook() {
  const [saveOrganization, { loading: savingOrganization }] =
    useSaveOrganizationMutation();
  const onSaveOrganization = async (
    values: OrganizationParams,
    id?: string
  ) => {
    try {
      await saveOrganization({
        variables: {
          id,
          params: {
            ...values,
          },
        },
        refetchQueries: ["organizations", "organizationById"],
      });
    } catch (err) {
      console.log(err);
    }
  };
  return {
    onSaveOrganization,
    savingOrganization,
  };
}
