import { useEffect, useState } from "react";
import { get } from "lodash";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
// APOLLO
import { useQuery } from "@apollo/client";
import CASES from "ApolloClient/Queries/cases";
import CASE_SERVICES from "ApolloClient/Queries/caseServices";
import CASE_CATEGORIES from "ApolloClient/Queries/caseCategories";
import CASE_STATUSES from "ApolloClient/Queries/caseStatuses";
import CSV_DOWNLOAD_CASES from "ApolloClient/Queries/csvDownload_cases";
import client from "ApolloClient/index";
// COMPONENTS
import { UserOutlined } from "@ant-design/icons";
import Table from "components/common/Table";
import Tooltip from "components/common/Tooltip";
import Avatar from "components/common/Avatar";
import CaseStats from "./CaseStats";
import BusinessLogo from "components/common/BusinessLogo";
import Button from "components/common/Button";
import Tag from "components/common/Tag";
// import CaseServiceForm from './CaseServiceForm';
// import CaseCategoryForm from './CaseCategoryForm';
import Filters from "./Filters";
import message from "components/common/message";
import LinkButton from "components/common/LinkButton";
import useDownloadCSV from "lib/hooks/useDownloadCSV";
import cleanDataForCasesCSV from "./cleanDataForCasesCSV";

import {
  useCasesQuery,
  useSaveCaseCategoryMutation,
  useSaveCaseServiceMutation,
  useSaveCaseStatusMutation,
} from "generated/graphql";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import theme from "lib/theme";
import useUrlChange from "hooks/useUrlChange";

const PageContainer = styled.div`
  height: calc(100vh - 56px);
  display: flex;
  align-self: stretch !important;
  align-items: stretch !important;
  justify-content: space-between;
  word-break: break-word;
  overflow-y: hidden;
  flex-grow: 1;
  width: 100%;
  z-index: 1;
`;

export const MiddleContent = styled.div`
  min-width: 500px;
  overflow-y: auto !important;
  display: flex;
  position: relative;
  align-self: stretch !important;
  z-index: 1;
  flex-shrink: 100000;
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  flex-direction: column;
  overflow-y: scroll;
  white-space: normal;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    min-width: 100%;
  }
`;

const LeftCol = styled.div`
  flex-basis: 20%;
  max-width: 500px;
  min-width: 280px;
  background: #fff;
  overflow-y: scroll;
  z-index: 1;
  padding: 24px;
  /* box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
  overflow-y: scroll;
  border-right: 1px solid ${(p) => p.theme.colors.neutral9};
`;

const TableBottom = styled.div`
  position: relative;
  top: 16px;
  left: 8px;
  display: inline-flex;
  align-items: center;
`;

const NumberOfResults = styled.p`
  font-weight: 600;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral5};
  display: inline;
`;

export default function CasesTab({ windowWidth }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [downloading, setDownloading] = useState(false);
  const [downloadFile] = useDownloadCSV();
  const [saveCaseService] = useSaveCaseServiceMutation();
  const [saveCaseStatus] = useSaveCaseStatusMutation();
  const [saveCaseCategory] = useSaveCaseCategoryMutation();

  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();

  useEffect(() => {
    resetTableParamsToDefault();
  }, []);

  const {
    businessId,
    contactId,
    caseCategoryId,
    caseStatusIds,
    startDate,
    endDate,
    assignedToId,
  } = queryString.parse(location.search);

  const variables = {
    skip,
    limit,
    businessId: businessId as string,
    contactId: contactId as string,
    caseCategoryId: caseCategoryId as string,
    caseStatusIds: caseStatusIds as string[],
    startDate: startDate as string,
    endDate: endDate as string,
    assignedToId: assignedToId as string,
  };
  const { data, loading } = useCasesQuery({
    variables,
  });

  const cases = get(data, "cases.result", []);
  const count = get(data, "cases.count", 0);

  const handleCreateNewCaseService = async (values) => {
    try {
      await saveCaseService({
        variables: {
          params: {
            title: values.title,
            description: values.description,
          },
        },
        refetchQueries: [
          {
            query: CASE_SERVICES,
          },
        ],
      });
      onUrlChange({ mode: undefined });
      message.success(`New case service created!`);
    } catch (err) {}
  };

  const handleCreateNewCaseStatus = async (values) => {
    try {
      await saveCaseStatus({
        variables: {
          params: {
            title: values.title,
            description: values.description,
          },
        },
        refetchQueries: [
          {
            query: CASE_STATUSES,
          },
        ],
      });
      onUrlChange({ mode: undefined });
      message.success(`New case category created!`);
    } catch (err) {}
  };

  const handleCreateNewCaseCategory = async (values) => {
    try {
      await saveCaseCategory({
        variables: {
          params: {
            title: values.title,
            description: values.description,
          },
        },
        refetchQueries: [
          {
            query: CASE_CATEGORIES,
          },
        ],
      });
      onUrlChange({ mode: undefined });
      message.success(`New case category created!`);
    } catch (err) {}
  };

  const { onUrlChange } = useUrlChange();

  const TRUNCATE_TITLE = 30;

  const MOBILE_COLS = [
    {
      title: "Title",
      key: "id",

      render: (record) => (
        <LinkButton
          style={{ fontSize: 16, textAlign: "left" }}
          onClick={() => onUrlChange({ selectedCase: record.id })}
          data-testid="case-table-title"
        >
          {record?.title.length > TRUNCATE_TITLE ? (
            <Tooltip title={record.title}>
              {record?.title?.substring(0, TRUNCATE_TITLE)}...
            </Tooltip>
          ) : (
            record?.title
          )}
        </LinkButton>
      ),
    },
    {
      title: "Status",

      render: (record) => record.caseStatus && record.caseStatus.title,
    },
  ];

  const columns = [
    {
      title: "Title",
      key: "id",
      width: 350,
      render: (record) => (
        <div>
          <LinkButton
            style={{ fontSize: 16, textAlign: "left" }}
            onClick={() => onUrlChange({ selectedCase: record.id })}
            data-testid="case-table-title"
          >
            {record?.title.length > TRUNCATE_TITLE ? (
              <Tooltip title={record.title}>
                {record?.title?.substring(0, TRUNCATE_TITLE)}...
              </Tooltip>
            ) : (
              record?.title
            )}
          </LinkButton>
        </div>
      ),
    },
    {
      title: "Status",
      width: 250,
      render: (record) => record.caseStatus && record.caseStatus.title,
    },
    {
      title: "Todos",
      width: 140,
      render: ({ openTodos, closedTodos }) => {
        if (closedTodos === 0 && openTodos === 0) return;
        return (
          <div>
            {closedTodos}/{openTodos + closedTodos}
          </div>
        );
      },
    },
    {
      title: "Assigned",
      width: 140,
      render: ({ assignedTo }) => (
        <>
          {assignedTo && (
            <Tooltip title={`${assignedTo.firstName} ${assignedTo.lastName}`}>
              <Avatar
                src={assignedTo.avatar && assignedTo.avatar.url}
                icon={<UserOutlined />}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Business",
      width: 300,
      render: (record) => {
        return (
          <div
            style={{
              alignItems: "center",
              display: "flex",
            }}
          >
            {record.business && record.business.logo && (
              <BusinessLogo
                src={record.business.logo.url}
                alt={record.business.logo.filename}
                width="32"
                style={{ marginRight: 4 }}
              />
            )}
            {record.business && record.business.title}
          </div>
        );
      },
    },
    {
      title: "Contact",
      width: 150,
      render: (record) =>
        record.contact &&
        record.contact.firstName && (
          <>
            {record.contact.firstName} {record.contact.lastName}
          </>
        ),
    },

    {
      title: "Category",
      width: 150,
      render: (record) => record.caseCategory && record.caseCategory.title,
    },
    {
      title: "Services",
      width: 150,
      render: (record) =>
        record.services &&
        record.services.map((service) => (
          <Tag key={service.id}>{service.title}</Tag>
        )),
    },
  ];

  const handleDownloadCSV = async () => {
    try {
      setDownloading(true);
      let result = await client.query({
        query: CSV_DOWNLOAD_CASES,
        variables,
      });
      console.log({ result });
      downloadFile(
        cleanDataForCasesCSV(result.data.csvDownload_cases),
        "cases.csv"
      );
      setDownloading(false);
    } catch (err) {
      setDownloading(false);
      console.log(err);
    }
  };

  const isMobile = parseInt(theme.breakpoints.medium) > windowWidth;

  return (
    <PageContainer>
      {!isMobile && (
        <LeftCol>
          <Filters
            handleChange={onUrlChange}
            businessId={businessId}
            contactId={contactId}
            caseCategoryId={caseCategoryId}
            caseStatusIds={caseStatusIds}
            assignedToId={assignedToId}
          />
        </LeftCol>
      )}
      <MiddleContent>
        {/* <PageHeading
          title="Cases"
          action={
            <div style={{ display: "flex" }}>
              <Button
                onClick={() => onUrlChange({ drawerVisible: formTypes.CASES })}
                style={{ marginBottom: 16, width: 135 }}
              >
                + New Case
              </Button>
            </div>
          }
        /> */}

        <div style={{ padding: !isMobile ? 32 : 8 }}>
          {!isMobile && <CaseStats />}

          <Table
            dataSource={cases}
            rowKey="id"
            loading={loading}
            columns={!isMobile ? columns : MOBILE_COLS}
            scroll={!isMobile ? { x: 1300 } : undefined}
            pagination={{
              total: count,
              pageSize: limit,
              onChange: (page, pageSize) => {
                onTablePaginationChange(page, pageSize);
              },
              showSizeChanger: true,
              current: currentPage,
            }}
          />

          {!isMobile && !loading && (
            <TableBottom>
              <NumberOfResults>Results: {count}</NumberOfResults>
              <Button
                onClick={handleDownloadCSV}
                loading={downloading}
                disabled={downloading}
                grey
                style={{ marginLeft: 16 }}
              >
                Download CSV
              </Button>
            </TableBottom>
          )}
        </div>
        {/* <Drawer
          onClose={() =>
            onUrlChange({caseId: undefined, addNewCase: undefined})
          }
          width="40%"
          visible={caseId || addNewCase === 'true'}
        >
          {caseId && <CaseDetailDrawer caseId={caseId} />}
          {addNewCase === 'true' && (
            <FormContainer>
              <FormTitle>Create New Case</FormTitle>
              <CaseForm
                onSubmit={handleCreateNewCase}
                loading={caseLoading}
                onCancel={() => onUrlChange({addNewCase: undefined})}
              />
            </FormContainer>
          )}
        </Drawer> */}
        {/* <Modal
          onCancel={() => onUrlChange({ mode: undefined })}
          visible={modalVisible}
          bodyStyle={{ padding: 0 }}
          footer={null}
        >
          <FormContainer>
            {mode === 'addNewStatus' && (
              <>
                <FormTitle>Create New Case Status</FormTitle>
                <CaseServiceForm
                  onSubmit={handleCreateNewCaseStatus}
                  loading={caseStatusLoading}
                  onCancel={() => onUrlChange({ mode: undefined })}
                />
              </>
            )}
            {mode === 'addNewCategory' && (
              <>
                <FormTitle>Create New Case Category</FormTitle>
                <CaseCategoryForm
                  onSubmit={handleCreateNewCaseCategory}
                  loading={caseCategoryLoading}
                  onCancel={() => onUrlChange({ mode: undefined })}
                />
              </>
            )}
            {mode === 'addNewService' && (
              <>
                <FormTitle>Create New Case Service</FormTitle>
                <CaseServiceForm
                  onSubmit={handleCreateNewCaseService}
                  loading={caseServicesLoading}
                  onCancel={() => onUrlChange({ mode: undefined })}
                />
              </>
            )}
          </FormContainer>
        </Modal> */}
      </MiddleContent>
    </PageContainer>
  );
}
