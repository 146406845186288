import React, { useEffect, useState } from "react";
import styled from "styled-components";
// COMPONENTS
import TextEditor from "components/inputs/TextEditor";
import Button from "components/common/Button";
import setLocalStorage from "lib/helpers/setLocalStorage";
import removeLocalStorage from "lib/helpers/removeLocalStorage";

export const Label = styled.div`
  margin-bottom: 0px;
  margin-top: 16px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.003em;
  color: ${(p) => p.theme.colors.neutral1};
`;

const Description = styled.div`
  padding: 8px;
  padding-left: 4px;
  font-family: ${(p) => p.theme.fontFamily};
  border-radius: 5px;
  &:hover {
    cursor: text;
    background: ${(p) => p.theme.colors.neutral10};
  }
`;

function createMarkup(text = "First &middot; Second") {
  return { __html: text };
}

export default function TextEditorArea({
  value,
  loading,
  label,
  onSubmit,
  textInputId,
}: {
  value?: string;
  loading?: boolean;
  label?: string;
  onSubmit: any;
  textInputId: string;
}) {
  const [editingDescription, setEditingDescription] = useState<boolean>(false);
  const [description, setDescription] = useState<any | undefined>(undefined); // will hold the "editing" desciption text which deson't get "saved" until they hit save at which point it gets updated in the local todo state.

  useEffect(() => {
    const savedValue = localStorage.getItem(textInputId);
    if (savedValue && savedValue !== "undefined") {
      console.log({
        savedValue,
      });
      setDescription(savedValue);
    }
  }, [editingDescription, textInputId]);

  return (
    <>
      {label && <Label>{label}</Label>}

      {!editingDescription ? (
        <Description onClick={() => setEditingDescription(true)}>
          <div
            dangerouslySetInnerHTML={createMarkup(value)}
            data-testid={`text-editor-area-${label}`}
          />
        </Description>
      ) : (
        <>
          <TextEditor
            value={typeof description !== "string" ? value : description}
            onChange={(description) => {
              // setLocalStorage(textInputId, description && description);
              setDescription(description);
            }}
          />
          <div style={{ marginTop: 16 }}>
            <Button
              grey
              disabled={loading}
              onClick={() => {
                setEditingDescription(false); // turn edit mode off
                setDescription(undefined); // reset the description
                removeLocalStorage(textInputId);
              }}
            >
              Cancel
            </Button>
            <Button
              style={{ marginLeft: 8 }}
              loading={loading}
              disabled={loading}
              onClick={() => {
                onSubmit(description && description);
                setEditingDescription(false); // turn edit mode off
                removeLocalStorage(textInputId);
              }}
            >
              Save Changes{" "}
            </Button>
          </div>
        </>
      )}
    </>
  );
}
