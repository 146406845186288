// TOP LEVEL IMPORTS

import styled, { keyframes } from "styled-components";
// COMPONENTS
import logEvent from "lib/helpers/logEvent";
import {
  CloseCircleFilled,
  CloseOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/common/Button";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import { ReactNode, useState } from "react";
import { fadeInUp, fadeIn } from "react-animations";
import theme from "lib/theme";
import { EventLogKeyEnum, useSearchQuery } from "generated/graphql";
import EmptyState from "components/common/EmptyState";
import { debounce } from "lodash";
import useUrlChange from "hooks/useUrlChange";
import SectionHeader from "components/text/SectionHeader";

const animation = keyframes`${fadeInUp}`;

const fadeInAnimation = keyframes`${fadeIn}`;

const ModalOverlay = styled.div`
  background: rgba(9, 30, 66, 0.54);
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 32px 0px;
  overflow-y: scroll;
  animation: 0.25s ${fadeInAnimation};
`;

const CloseBtn = styled(CloseOutlined)`
  font-size: 18px;
  padding: 16px;
  color: ${(p) => p.theme.colors.neutral5};
  position: absolute;
  top: 2px;
  right: 4px;
  cursor: pointer;
`;

const LineBreak = styled.div`
  height: 2px;
  background-color: ${(p) => p.theme.colors.neutral10};
`;

const ModalContent = styled.div`
  border-radius: 5px;
  width: 600px;
  max-width: 100%;
  max-width: 100%;
  min-height: 200px;
  background: #fff;
  position: relative;
  animation: 0.25s ${animation};
`;

const ModalBody = styled.div`
  padding: 16px;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 56px;
  padding-left: 48px;
  border-radius: 5px;
  border: 2px solid ${(p) => p.theme.colors.neutral10};
`;

const SearchContainer = styled.div`
  position: relative;
`;
const ResultItemContainer = styled.div`
  padding: 8px;
  display: flex;
  /* border-bottom: 1px solid ${(p) => p.theme.colors.neutral10}; */
  cursor: pointer;
  align-items: center;
  &:hover {
    background: ${(p) => p.theme.colors.primary10};
  }
`;

const ResultItemTitle = styled.h2`
  font-weight: 600;
  margin: 0px;
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral2};
  line-height: 18px;
`;

const ResultItemCaption = styled.span`
  color: ${(p) => p.theme.colors.neutral6};
  margin: 0px;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  display: block;
`;

const Loading = styled(LoadingOutlined)`
  font-size: 20px;
  margin: 24px;
  color: ${(p) => p.theme.colors.neutral8};
`;

const SeeFullRestulsLink = ({ to }) => {
  return (
    <div style={{ margin: 16, marginBottom: 8 }}>
      <Link to={to} target="_blank">
        See full results...
      </Link>
    </div>
  );
};

const ResultItem = ({
  title,
  image,
  onClick,
  ...args
}: {
  title: string | null | ReactNode;
  image?: { url: string; filename: string } | any;
  onClick: () => void;
}) => {
  return (
    <ResultItemContainer onClick={onClick} data-testid={args["data-testid"]}>
      <ResultItemTitle>
        {image && (
          <img
            height="24"
            src={image.url}
            style={{ marginRight: 8 }}
            alt={image.filename}
          />
        )}
        {title}
      </ResultItemTitle>
    </ResultItemContainer>
  );
};
const SectionTitle = styled.h3`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 11px;
  margin-bottom: 8px;
  letter-spacing: 0.75px;
  color: ${(p) => p.theme.colors.neutral4};
`;

const Section = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
`;

const NoResults = () => {
  return (
    <div
      style={{
        height: 125,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <div>
        <Text data-testid="no-search-results-title">No search results...</Text>
        <Caption>We couldn't find anything for these search terms</Caption>{" "}
      </div>
    </div>
  );
};

const LIMIT = 5;

const SearchModalContent = ({ onClose }) => {
  const navigate = useNavigate();
  const { onUrlChange } = useUrlChange();
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [finalSearchText, setFinalSearchText] = useState<string | undefined>(
    undefined
  );

  const { data, loading } = useSearchQuery({
    variables: {
      searchText: finalSearchText || "",
    },
    onCompleted: (data) => {
      logEvent(EventLogKeyEnum.GlobalSearch, {
        searchTerm: finalSearchText,
        results: Object.keys(data?.search as any)
          ?.filter((key) => key !== "__typename")
          ?.map((key: any) => {
            const result: any = data?.search;
            return { [key]: result[key as any]?.length };
          })
          ?.flat(),
      });
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    skip: !finalSearchText,
  });

  const businesses = data?.search?.businesses || [];
  const todos = data?.search?.todos || [];
  const contacts = data?.search?.contacts || [];
  const projects = data?.search?.projects || [];
  const resources = data?.search?.resources || [];
  const organizations = data?.search?.organizations || [];
  const sites = data?.search?.sites || [];
  const cases = data?.search?.cases || [];

  const noResults =
    !businesses?.[0] &&
    !todos?.[0] &&
    !contacts?.[0] &&
    !resources?.[0] &&
    !organizations?.[0] &&
    !sites?.[0] &&
    !projects?.[0] &&
    !cases?.[0];

  const handleFilter = debounce((finalSearchText) => {
    setFinalSearchText(finalSearchText);
  }, 250);

  return (
    <ModalOverlay>
      <ModalContent>
        <ModalBody>
          <CloseBtn onClick={onClose} data-testid="close-search-modal-button" />
          <SectionHeader style={{ margin: 0 }}>Search Growlab</SectionHeader>
        </ModalBody>
        <LineBreak />
        <ModalBody>
          <SearchContainer>
            <SearchOutlined
              style={{
                position: "absolute",
                left: 15,
                top: 15,
                fontSize: 24,
                color: theme.colors.neutral7,
              }}
            />
            <SearchInput
              placeholder="Type your search here..."
              onChange={(e) => {
                handleFilter(e.target.value);
                setSearchText(e.target.value);
              }}
              value={searchText || ""}
              data-testid="global-search-input"
            />
            {searchText?.[0] && (
              <CloseCircleFilled
                style={{
                  position: "absolute",
                  right: 15,
                  top: 20,
                  fontSize: 16,
                  color: theme.colors.neutral8,
                }}
                onClick={() => {
                  handleFilter(undefined);
                  setSearchText(undefined);
                }}
              />
            )}
          </SearchContainer>
        </ModalBody>
        <ModalBody data-testid="global-search-modal-body">
          {!searchText?.[0] && (
            <EmptyState
              title="Get started by typing in a search term"
              subtitle="You can search through your entire Growlab data set: busineses, contacts, todos, cases, projects and more"
            />
          )}
          {searchText?.[0] && !loading && noResults && <NoResults />}
          {loading && <Loading />}
          {businesses?.[0] && (
            <Section>
              <SectionTitle>Businesses</SectionTitle>
              {businesses.map((item) => {
                if (!item?.id) return null;
                return (
                  <ResultItem
                    key={item.id}
                    image={item?.logo}
                    title={
                      <>
                        <ResultItemTitle
                          data-testid={`search-result-title-${item?.title}`}
                        >
                          {item?.title}
                        </ResultItemTitle>
                        {(item?.street || item?.fullAddress) && (
                          <ResultItemCaption>
                            {item?.fullAddress || item?.street}
                          </ResultItemCaption>
                        )}
                      </>
                    }
                    onClick={() => {
                      navigate(`/app/businesses/${item.id}?tab=1`);
                      onClose();
                    }}
                  />
                );
              })}
              {businesses?.length === LIMIT && (
                <SeeFullRestulsLink
                  to={`/app/businesses?searchText=${searchText}`}
                />
              )}
            </Section>
          )}
          {contacts && contacts.length > 0 && (
            <Section>
              <SectionTitle>Contacts</SectionTitle>
              {contacts.map((contact) => {
                if (!contact?.id) return null;
                return (
                  <ResultItem
                    key={contact?.id}
                    title={
                      <div style={{ display: "flex" }}>
                        {contact?.avatar?.url && (
                          <img
                            height="24"
                            src={contact?.avatar?.url}
                            style={{
                              marginRight: 8,
                              height: 24,
                              width: 24,
                              borderRadius: "50%",
                            }}
                            alt={contact.avatar.filename || ""}
                          />
                        )}
                        <div>
                          <ResultItemTitle>
                            {contact?.firstName} {contact?.lastName}
                            {contact?.title && `, ${contact?.title}`}
                          </ResultItemTitle>
                          {contact?.businesses?.[0] && (
                            <ResultItemCaption>
                              {contact?.businesses?.map((business) => {
                                if (!business) return null;
                                return business.title;
                              })}
                            </ResultItemCaption>
                          )}

                          <ResultItemCaption>{contact.email}</ResultItemCaption>
                        </div>
                      </div>
                    }
                    onClick={() => {
                      navigate(`/app/contacts/${contact.id}?tab=1`);
                      onClose();
                    }}
                  />
                );
              })}
              {contacts?.length === LIMIT && (
                <SeeFullRestulsLink
                  to={`/app/contacts?searchText=${searchText}`}
                />
              )}
            </Section>
          )}
          {todos && todos.length > 0 && (
            <Section>
              <SectionTitle>Todos</SectionTitle>
              {todos.map((item) => (
                <ResultItem
                  key={item.id}
                  title={
                    <>
                      <ResultItemTitle>{item.title}</ResultItemTitle>
                      <ResultItemCaption>{item.key}</ResultItemCaption>
                    </>
                  }
                  onClick={() => {
                    onUrlChange({
                      selectedTodo: item.id,
                    });
                    onClose();
                  }}
                />
              ))}
            </Section>
          )}
          {cases && cases.length > 0 && (
            <Section>
              <SectionTitle>Cases</SectionTitle>
              {cases.map((item) => (
                <ResultItem
                  key={item.id}
                  data-testid={`search-result-item-${item.title}`}
                  title={
                    <>
                      <ResultItemTitle>{item.title}</ResultItemTitle>
                      <ResultItemCaption>{item.key}</ResultItemCaption>
                    </>
                  }
                  onClick={() => {
                    onUrlChange({
                      selectedCase: item.id,
                    });
                    onClose();
                  }}
                />
              ))}
            </Section>
          )}
          {projects?.[0] && (
            <Section>
              <SectionTitle>Projects</SectionTitle>
              {projects.map((item) => (
                <ResultItem
                  key={item.id}
                  title={
                    <>
                      <ResultItemTitle>{item.title}</ResultItemTitle>
                    </>
                  }
                  onClick={() => {
                    navigate(`/app/projects/${item.id}?tab=1`);
                    onClose();
                  }}
                />
              ))}
            </Section>
          )}
          {resources?.[0] && (
            <Section>
              <SectionTitle>Resources</SectionTitle>
              {resources.map((item) => (
                <ResultItem
                  key={item.id}
                  title={
                    <>
                      <ResultItemTitle>{item.title}</ResultItemTitle>
                      <ResultItemCaption>{item.type}</ResultItemCaption>
                    </>
                  }
                  onClick={() => {
                    navigate(`/app/resources/${item.id}?tab=1`);
                    onClose();
                  }}
                />
              ))}
            </Section>
          )}
          {organizations?.[0] && (
            <Section>
              <SectionTitle>Organizations</SectionTitle>
              {organizations.map((item) => {
                if (!item?.id) return null;
                return (
                  <ResultItem
                    key={item.id}
                    title={item?.title}
                    onClick={() => {
                      navigate(`/app/organizations/${item.id}?tab=1`);
                      onClose();
                    }}
                  />
                );
              })}
            </Section>
          )}
          {sites?.[0] && (
            <Section>
              <SectionTitle>Sites</SectionTitle>
              {sites.map((item) => {
                if (!item?.id) return null;
                return (
                  <ResultItem
                    key={item.id}
                    title={
                      <>
                        <ResultItemTitle>{item.title}</ResultItemTitle>
                        <ResultItemCaption>
                          {item.fullAddress}
                        </ResultItemCaption>
                      </>
                    }
                    onClick={() => {
                      navigate(`/app/sites/${item.id}?tab=1`);
                      onClose();
                    }}
                  />
                );
              })}
            </Section>
          )}
        </ModalBody>
      </ModalContent>
    </ModalOverlay>
  );
};

export default function SearchModal() {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      {open && <SearchModalContent onClose={() => setOpen(!open)} />}

      <Button
        grey
        onClick={() => setOpen(!open)}
        style={{ marginLeft: 8 }}
        data-testid="search-button"
      >
        <SearchOutlined style={{ marginRight: 6 }} /> Search
      </Button>
    </>
  );
}
