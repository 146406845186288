import Collapse from 'antd/lib/collapse';
import 'antd/lib/collapse/style/css';

// const Wrapper = styled.div`
//   .ant-collapse-item .ant-collapse-item-active .ant-collapse-no-arrow {
//     border-bottom-color: transparent !important;
//   }
// `;
export default Collapse;
// export default props => <Collapse {...props} />;
