// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  query reports_InteractionsByMonth {
    reports_InteractionsByMonth {
      id
      title
      email
      phone
      visit
    }
  }
`;
