import theme from "lib/theme";

const priorities = [
  {
    value: 1,
    label: "High",
    color: theme.colors.red1,
    backgroundColor: theme.colors.red10,
  },
  {
    value: 2,
    label: "Medium",
    color: theme.colors.yellow1,
    backgroundColor: theme.colors.yellow10,
  },
  {
    value: 3,
    label: "Low",
    color: theme.colors.neutral1,
    backgroundColor: theme.colors.neutral10,
  },
  {
    value: 4,
    label: "Lowest",
    color: theme.colors.neutral1,
    backgroundColor: "#fff",
  },
];

export default priorities;
