import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { useBusinessSectorsQuery } from "generated/graphql";
const { Option } = Select;

export default function BusinessSectorInput({
  value,
  disabled,
  placeholder,
  onChange,
  style = {},
}: any) {
  const { data, loading } = useBusinessSectorsQuery();
  if (loading) return null;
  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        style={{ width: "100%", ...style }}
        filterOption={false}
        notFoundContent={loading ? "searching..." : null}
        onChange={onChange}
      >
        {data?.businessSectors?.map((item) => {
          return (
            <Option key={item.id} value={item.id}>
              {item.title}
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
}
