// TOP LEVEL IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// COMPONENTS
import TextInput from 'components/inputs/TextInput';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import Button from 'components/common/Button';
import FormItem from 'components/common/FormItem';
import SectionBreak from 'components/common/SectionBreak';

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

class TodoListForm extends React.PureComponent {
  state = {
    title: this.props.title || null,
    description: this.props.description || null
  };

  getValues = () => {
    return {
      title: this.state.title || null,
      description: this.state.description || null
    };
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.props.loading) return null;
    // do some logic to verify everything is okay

    // create the product object to pass up to parent
    let values = this.getValues();
    console.log({ values });

    // pass back up to the parent component
    this.props.onSubmit(values);
  };

  onSaveAttacment = async newAttachment => {
    this.setState({ images: [...this.state.images, newAttachment] });
  };

  onRemoveAttacment = async url => {
    this.setState({
      images: [...this.state.images.filter(item => item !== url)]
    });
  };
  render() {
    return (
      <FormContainer>
        <SectionBreak
          header="Create your list"
          body="A todo list will be a place to put your todos. You can create several different lists to help stay organized."
        >
          <Row gutter={8}>
            <Col xs={24}>
              <FormItem
                label="List Title"
                hint="You can always change the title later"
              >
                <TextInput
                  value={this.state.title}
                  onChange={title => this.setState({ title })}
                />
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem label="Description">
                <TextInput
                  value={this.state.description}
                  onChange={description => this.setState({ description })}
                />
              </FormItem>
            </Col>
          </Row>
        </SectionBreak>
        <div>
          <div
            style={{
              width: 270,
              marginTop: 32,
              marginLeft: 'auto'
            }}
          >
            <Button
              style={{
                width: 100,
                marginRight: 8
              }}
              grey
              disabled={this.props.loading}
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>{' '}
            <Button
              style={{
                width: 150
              }}
              disabled={this.props.loading}
              onClick={this.onSubmit}
            >
              {!this.props.loading ? 'Save Todo List' : '...'}
            </Button>
          </div>
        </div>
      </FormContainer>
    );
  }
}

TodoListForm.propTypes = {
  mode: PropTypes.string // either 'add' or 'save'... determines if we show intermediate "save buttons" in each form section
};

TodoListForm.defaultProps = {
  mode: 'add',
  onCancel: () => {},
  onSubmit: () => {}
};

export default TodoListForm;
