import { SupporType } from "generated/graphql";

const serviceTypes = [
  {
    label: "Business Planning",
    value: SupporType.BusinessPlanning,
    description:
      "Do you need help with business plans, pitch decks or other planning work?",
  },
  {
    label: "Financial Management",
    value: SupporType.FinancialManagement,
    description:
      "Do you need help creating or reviewing estimates, spreadsheets or other financial data?",
  },
  {
    label: "Funding",
    value: SupporType.Funding,
    description:
      "Do you need help finding/applying to investors, loans or grants?",
  },
  {
    label: "Legal Assistance",
    value: SupporType.LegalAssistance,
    description:
      "Do you need help creating a business entity or filing a patent?",
  },
  {
    label: "Sales & Marketing",
    value: SupporType.SalesAndMarketing,
  },
  {
    label: "Research & Development",
    value: SupporType.ResearchAndDevelopment,
  },
  {
    label: "Real Estate",
    value: SupporType.RealEstate,
  },
  {
    label: "Workforce",
    value: SupporType.Workforce,
  },
];

export default serviceTypes;
