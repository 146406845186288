import { CurrentUserFragmentFragment } from "generated/graphql";
import { useState } from "react";
import { CaseNumber, EditFieldEnum } from "../CaseDetailContent";
import CaseTitle from "../CaseDetailContent/CaseTitle";
import useOnSaveCase from "../useOnSaveCase";
import message from "components/common/message";
import CaseStatusInput from "components/inputs/CaseStatusInput";
import ConfirmCloseModal from "../ConfirmCloseModal";
import Collapse from "components/common/Collapse";
import moment from "moment";
import styled from "styled-components";
import TextEditorArea from "../CaseDetailContent/TextEditorArea";
import Comments from "../CaseDetailContent/Comments";
import BlockItems from "../BlockItems";

const { Panel } = Collapse;

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const TimeAgo = styled.p`
  margin: 0px;
  margin-top: 8px;
  color: ${(p) => p.theme.colors.neutral4};
  font-size: 13px;
`;

const SectionPadding = styled.div`
  padding-top: 40px;
`;

export default function CaseDetailContentMobile({
  currentUser,
  defaultCase,
}: {
  currentUser: CurrentUserFragmentFragment;
  defaultCase: any;
}) {
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [values, setValues] = useState(defaultCase || {});
  const [editing, setEditing] = useState<EditFieldEnum | false>(false);
  const [errors, setErrors] = useState<{
    resolutionNotes?: string;
    resolvedOn?: string;
  }>({});

  const [onSaveCase, { loading: saving }, getCaseStatus] = useOnSaveCase({
    caseId: defaultCase?.id,
    onSaved: (newCaseValues) => {
      setValues(newCaseValues);
      setConfirmCloseModal(false);
      message.success("Your changes were saved!");
    },
    onErrors: (newErrorsObj) => {
      setErrors({ ...errors, ...newErrorsObj });
    },
  });

  const caseStatus = getCaseStatus(values.caseStatusId);

  return (
    <>
      {confirmCloseModal && (
        <ConfirmCloseModal
          onComplete={(newValues) => {
            onSaveCase({
              ...values,
              ...newValues,
            });
          }}
          getCaseStatus={getCaseStatus}
          loading={saving}
          onCancel={() => setConfirmCloseModal(false)}
          caseStatusId={values.caseStatusId}
        />
      )}
      <div>
        {" "}
        <CaseNumber>{defaultCase.key}</CaseNumber>
        <CaseTitle
          title={values.title}
          onSave={(title) => {
            // only save if the titlel changed
            if (title !== values.title) {
              onSaveCase({
                ...values,
                title,
              });
            }
          }}
        />
        <SectionPadding>
          <CaseStatusInput
            value={values.caseStatusId}
            data-testid="case-detail-caseStatusId"
            onChange={(caseStatusId) => {
              let caseStatus = getCaseStatus(caseStatusId);
              if (caseStatus?.isFinalStep) {
                setValues({
                  ...values,
                });
                return setConfirmCloseModal(true);
              }
              setValues({
                ...values,
                caseStatusId,
                caseStatus: getCaseStatus(caseStatusId),
              });
              onSaveCase({ ...values, caseStatusId });
            }}
          />
        </SectionPadding>
        <SectionPadding>
          <Collapse bordered={false}>
            <Panel header="Description" key="1">
              <TextEditorArea
                value={values.description}
                loading={saving}
                textInputId={`textarea-description-case=${defaultCase?.id}`}
                onSubmit={(newValue) => {
                  onSaveCase({
                    ...values,
                    description: newValue,
                  });
                }}
              />
            </Panel>
          </Collapse>
        </SectionPadding>
        <SectionPadding>
          <Collapse bordered={false}>
            <Panel header="Details" key="1">
              <BlockItems
                getCaseStatus={getCaseStatus}
                caseStatus={caseStatus}
                editing={editing}
                onSaveCase={onSaveCase}
                setConfirmCloseModal={setConfirmCloseModal}
                values={values}
                setValues={setValues}
                setEditing={setEditing}
                errors={errors}
              />
            </Panel>
          </Collapse>
        </SectionPadding>
        <SectionPadding>
          <Collapse bordered={false}>
            <Panel header="More Info" key="1">
              <div>
                <TimeAgo>
                  Created {moment(parseInt(values.createdAt)).fromNow()}
                </TimeAgo>
                <TimeAgo>
                  Updated {moment(parseInt(values.updatedAt)).fromNow()}
                </TimeAgo>
              </div>
            </Panel>
          </Collapse>
        </SectionPadding>
        <Comments caseId={values.id} currentUser={currentUser} />
      </div>
    </>
  );
}
