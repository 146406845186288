import AntdDrawer, { DrawerProps } from "antd/lib/drawer";
import "antd/lib/drawer/style/css";
import { useWindowWidth } from "@react-hook/window-size";

const MOBILE_WINDOW_SIZE = 1000;

export default function Drawer({ visible, ...args }: DrawerProps | any) {
  const windowWidth = useWindowWidth();
  return (
    <AntdDrawer
      placement={args?.placement || "right"}
      width={windowWidth > MOBILE_WINDOW_SIZE ? 550 : "100%"}
      destroyOnClose
      getContainer={false}
      style={{ position: "fixed", top: 0, bottom: 0, overflowY: "hidden" }}
      maskClosable={!args?.onClose ? false : true}
      open={visible || args?.open}
      {...args}
    >
      {args?.children}
    </AntdDrawer>
  );
}
