import React, { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import Button from "components/common/Button";
import SectionBreak from "components/common/SectionBreak";
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import TextAreaInput from "components/inputs/TextAreaInput";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper/index";
import FormHeader from "components/text/FormHeader";
import ProjectTypesInput from "routes/app-home/ProjectsTab/ProjectTypesInput";

const Form = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

export default function PipelineForm({ onCancel, loading, onSubmit }) {
  const [title, setTitle] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [projectTypeId, setProjectTypeId] = useState(undefined);

  const beforeSubmit = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    onSubmit({
      description,
      title,
      projectTypeId,
    });
  };

  const disabled = !title || !projectTypeId;

  return (
    <Form onSubmit={beforeSubmit}>
      <FormHeader>Create a Pipeline</FormHeader>
      <SectionBreak header="Basics" body="Tell us basics about this pipline">
        <FormItem label="Pipline name">
          <TextInput
            value={title}
            onChange={(title) => setTitle(title)}
            data-testid="project-title-input"
          />
        </FormItem>
        <FormItem label="Description">
          <TextAreaInput
            value={description}
            onChange={(description) => setDescription(description)}
            data-testid="project-description-input"
          />
        </FormItem>
        <FormItem label="Project Template">
          <DropdownStyleWrapper>
            <ProjectTypesInput
              value={projectTypeId}
              onChange={(projectType) => {
                setProjectTypeId(projectType);
              }}
              data-testid="project-type-input"
            />
          </DropdownStyleWrapper>
        </FormItem>
        <FormItem>
          <Button
            style={{ width: 100, marginRight: 8 }}
            grey
            onClick={onCancel}
            data-testid="project-form-cancel-btn"
          >
            Cancel
          </Button>
          <Button
            style={{ width: 100 }}
            onClick={beforeSubmit}
            disabled={disabled || loading}
            data-testid="project-form-submit-btn"
          >
            Submit
          </Button>
        </FormItem>
      </SectionBreak>
    </Form>
  );
}
