import styled from "styled-components";
import emptySVG from "lib/media/empty-state-vendors.svg";
import Button from "components/common/Button";

const Caption = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral4};
  font-size: 16px;
  font-weight: 500;
`;

const Title = styled.h1`
  margin: 0px;
  margin-top: 8px;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral1};
`;

const Container = styled.div`
  display: flex;
  min-height: 145px;
  text-align: center;
  width: 500px;
  align-items: center;
  justify-content: center;
  margin: 24px auto;
  max-width: 100%;
`;

type EmptyStateProps = {
  title: string;
  subtitle: string;
  onOk?: Function;
  onOkText?: string;
  img?: string;
  imgHeight?: number;
  imgMargin?: number;
};

export default function EmptyState({
  title,
  subtitle,
  onOk,
  onOkText,
  img = emptySVG,

  imgMargin,
}: EmptyStateProps) {
  return (
    <Container>
      <div>
        <img
          src={img}
          alt="empty-state"
          style={{ margin: imgMargin || 16, height: 150, opacity: 0.8 }}
        />
        <Title>{title}</Title>
        <Caption>{subtitle}</Caption>
        {onOk && (
          <Button style={{ marginTop: 16 }} onClick={onOk}>
            {onOkText || "Okay"}
          </Button>
        )}
      </div>
    </Container>
  );
}
