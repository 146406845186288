const eventTypes = [
  {
    label: 'Educational',
    value: 'educational'
  },
  {
    label: 'Networking',
    value: 'networking'
  },
  {
    label: 'Other',
    value: 'other'
  }
];

export default eventTypes;
