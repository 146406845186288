import priorities from "./priorities";
import clusterTypes from "./clusterTypes";
import config from "lib/config";

const constants = {
  appName: "Growlab",
  supportEmail: "support@growlabcrm.com",
  supportPhone: "855-702-8121",
  priorities,
  clusterTypes,
  authTokenName: config.authTokenName,
  refreshTokenName: config.refreshTokenName,
};

export default constants;
