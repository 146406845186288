import React from 'react';
import AntdAvatar from 'antd/lib/avatar';
import 'antd/lib/avatar/style/css';
import {UserOutlined} from '@ant-design/icons';
// lib
import theme from 'lib/theme';

export default function Avatar(props) {
  return (
    <AntdAvatar
      icon={<UserOutlined />}
      style={{border: `1px solid ${theme.colors.neutral10}`}}
      {...props}
    />
  );
}
