import { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import { DownOutlined, RightOutlined } from "@ant-design/icons";
import BusinessCard from "components/detail-components/BusinessCard";
import ContactCard from "components/detail-components/ContactCard";
import ProjectCard from "components/detail-components/ProjectCard";
import SiteCard from "components/detail-components/SiteCard";
import CaseCard from "components/detail-components/CaseCard";
import CompanyInvestmentCard from "components/detail-components/CompanyInvestmentCard";
// LIB
import theme from "lib/theme";
import formTypes from "lib/constants/formTypes";
import { BusinessFragmentFragment } from "generated/graphql";

const Title = styled.h2`
  color: ${(p) => p.theme.colors.neutral2};
  font-size: 14px;
  font-weight: 600;
  margin-left: 24px;
  cursor: pointer;
`;

const Container = styled.div`
  padding: 24px 16px;
  border-top: 1px solid ${(p) => p.theme.colors.neutral9};
`;

const AddBtn = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.primary3};
  position: absolute;
  font-weight: 600;
  right: 16px;
  font-size: 14px;
  top: 0px;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(p) => p.theme.colors.primary1};
  }
`;

const ICON_STYLE = {
  fontSize: 12,
  color: theme.colors.neutral4,
};

interface ExpandableListProps {
  title?: any;
  type?: ExpandableListTypeEnum;
  items?: any[];
  onAdd?: any;
  empty?: any;
  onRemove?: any;
  parent?: BusinessFragmentFragment | any;
  onMakePrimaryContact?: (contact: any) => void;
}

export enum ExpandableListTypeEnum {
  sites = "sites",
  cases = "cases",
  businesses = "businesses",
  contacts = "contacts",
  projects = "projects",
  companyInvestments = "companyInvestments",
}

export default function ExpandableList({
  title,
  type,
  items,
  onAdd,
  empty,
  onRemove,
  parent,
  onMakePrimaryContact,
}: ExpandableListProps) {
  const [expanded, setExpanded] = useState<boolean>(
    items && items.length > 0 ? true : false
  );
  return (
    <Container>
      <div style={{ position: "relative" }}>
        <div
          style={{ position: "absolute", left: 0, cursor: "pointer" }}
          onClick={() => setExpanded(!expanded)}
          data-testid="expand-btn"
        >
          {!expanded ? (
            <RightOutlined style={ICON_STYLE} />
          ) : (
            <DownOutlined style={ICON_STYLE} />
          )}
        </div>
        <Title
          onClick={() => setExpanded(!expanded)}
          data-testid={`expand-btn-${type}`}
        >
          {title} ( {items?.length} )
        </Title>
        <AddBtn onClick={onAdd} data-testid={`expandable-add-btn-${type}`}>
          {" "}
          + Add
        </AddBtn>
      </div>
      {expanded && (
        <div>
          {items && items.length > 0 ? (
            items.map((item) => {
              if (type === "businesses") {
                return (
                  <BusinessCard
                    key={item.id}
                    item={item}
                    onRemove={() => onRemove(item)}
                  />
                );
              }
              if (type === "sites") {
                return (
                  <SiteCard
                    key={item.id}
                    item={item}
                    onRemove={() => onRemove(item)}
                  />
                );
              }
              if (type === "contacts") {
                return (
                  <ContactCard
                    key={item.id}
                    item={item}
                    onRemove={() => onRemove(item)}
                    onMakePrimaryContact={onMakePrimaryContact}
                    isPrimary={item?.id === parent?.primaryContact?.id}
                  />
                );
              }
              if (type === "cases") {
                return (
                  <CaseCard
                    key={item.id}
                    item={item}
                    onRemove={() => onRemove(item)}
                  />
                );
              }
              if (type === formTypes.COMPANYINVESTMENTS) {
                if (!item?.id) return null;
                return (
                  <CompanyInvestmentCard
                    key={item.id}
                    item={item}
                    onRemove={() => onRemove(item)}
                  />
                );
              }
              if (type === "projects") {
                return (
                  <ProjectCard
                    key={item.id}
                    item={item}
                    onRemove={() => onRemove(item)}
                  />
                );
              }
              return null;
            })
          ) : (
            <div style={{ marginTop: 24 }}>{empty}</div>
          )}
        </div>
      )}
    </Container>
  );
}
