import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SettingOutlined } from "@ant-design/icons";
import theme from "lib/theme";

const Button = styled.button`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0px;
  background: transparent;
  border: 0px;
  &:hover {
    background: ${(p) => p.theme.colors.neutral10};
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
`;

export default function SettingsButton() {
  const navigate = useNavigate();
  return (
    <Button data-testid="settings-nav-button">
      <SettingOutlined
        onClick={() => navigate(`/app/settings`)}
        style={{ fontSize: 24, color: theme.colors.neutral6 }}
      />
    </Button>
  );
}
