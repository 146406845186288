import { useState, useEffect } from "react";
import styled from "styled-components";
import Drawer from "components/common/Drawer";
import Tabs from "components/common/Tabs";
import Button from "components/common/Button";
import BusinessForm from "components/forms/BusinessForm";
import ProjectForm from "components/detail-components/ProjectForm";
import ContactForm from "components/forms/ContactForm";
import CompanyInvestmentForm from "components/detail-components/CompanyInvestmentForm";
import SearchInput from "components/common/SearchInput";
import BusinessCard from "components/detail-components/BusinessCard";
import ContactCard from "components/detail-components/ContactCard";
import ProjectCard from "components/detail-components/ProjectCard";
import SiteCard from "../SiteCard";
import SiteForm from "components/forms/SiteForm";

const { TabPane } = Tabs;

const ButtonContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  width: 550px;
  z-index: 1000;
  height: 65px;
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.neutral10};
`;

const getTitle = (type) => {
  if (type === "contacts") {
    return "Add a Contact";
  }
  if (type === "businesses") {
    return "Add a Business";
  }
  if (type === "projects") {
    return "Add a Project";
  }
  if (type === "sites") {
    return "Add a Site";
  }
  if (type === "companyInvestments") {
    return "Add an Investment";
  }
  return null;
};

const getTab1Title = (type) => {
  if (type === "contacts") {
    return "Add existing contact";
  }
  if (type === "businesses") {
    return "Add existing business";
  }
  if (type === "projects") {
    return "Add an existing project";
  }
  if (type === "sites") {
    return "Add an existing site";
  }
  if (type === "companyInvestments") {
    return "Add an Investment";
  }
  return null;
};

const getTab2Title = (type) => {
  if (type === "contacts") {
    return "Create new contact";
  }
  if (type === "businesses") {
    return "Create new business";
  }
  if (type === "projects") {
    return "Create new project";
  }
  if (type === "sites") {
    return "Create new site";
  }
  return null;
};

export enum ShowEntityFormString {
  companyInvestments = "companyInvestments",
  projects = "projects",
  contacts = "contacts",
  businesses = "businesses",
  sites = "sites",
}

export enum EntityFormParentTypeEnum {
  site = "site",
  project = "project",
  business = "business",
  contact = "contact",
  organization = "organization",
}

const getDrawerCaption = (type: ShowEntityFormString) => {
  if (type === ShowEntityFormString.contacts) {
    return "Keep track of related contacts.";
  }
  if (type === ShowEntityFormString.sites) {
    return "Keep track of related sites.";
  }
  if (type === ShowEntityFormString.businesses) {
    return "Keep track of related businesses.";
  }
  if (type === ShowEntityFormString.projects) {
    return "Keep track of related projects.";
  }

  return null;
};

const getNewBtnText = (type?: ShowEntityFormString) => {
  if (type === "contacts") {
    return "Create Contact";
  }
  if (type === "businesses") {
    return "Create Business";
  }
  if (type === "projects") {
    return "Create Project";
  }
  if (type === "sites") {
    return "Create Site";
  }
  if (type === "companyInvestments") {
    return "Add Investment";
  }
  return null;
};

const getPlaceHolder = (type?: ShowEntityFormString): string => {
  if (type === ShowEntityFormString.contacts) {
    return "Search for a contact";
  }
  if (type === ShowEntityFormString.sites) {
    return "Search for a site";
  }
  if (type === ShowEntityFormString.businesses) {
    return "Search for a business";
  }
  if (type === ShowEntityFormString.projects) {
    return "Search for a project";
  }
  if (type === ShowEntityFormString.companyInvestments) {
    return "Search for an investment";
  }
  return "";
};

export default function AddEntityDrawer({
  visible,
  loading,
  onClose,
  defaultSelected,
  onRemove,
  type,
  onSave,
  onCreate,
  parentType,
  parentId,
}: {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
  defaultSelected: any;
  onRemove: any;
  type: ShowEntityFormString | undefined;
  onSave: (args: any) => void;
  onCreate: (args: any) => void;
  parentType: EntityFormParentTypeEnum;
  parentId: string;
}) {
  const [selected, setSelected] = useState<any>(defaultSelected || []); // ARRAY
  const [newEntity, setNewEntity] = useState<any>({});

  // reset the local state if something changed
  useEffect(() => {
    setSelected(defaultSelected || []);
  }, [defaultSelected]);

  const formProps = {
    value: newEntity,
    defaultValues: {
      ...(parentType === "business" ? { businessId: parentId } : {}),
      ...(parentType === "organization" ? { organizationId: parentId } : {}),
    },
    onSaveExisting: (existing) => onSave([existing]),
    onChange: (newValues) => setNewEntity({ ...newEntity, ...newValues }),
  };
  const url = window.location.href;

  // handle on remove for local selected results
  const handleOnRemove = (item) => {
    let defaultSelectedIds = defaultSelected.map((i) => i.id);
    // if the item that we're trying to remove HAS NOT been saved to server yet, we can jsut remove it from the local state (selected)
    if (!defaultSelectedIds.includes(item.id)) {
      return setSelected(selected.filter((i) => i.id !== item.id));
    }
    onRemove(item);
  };

  return (
    <Drawer
      title={getTitle(type)}
      placement="right"
      width={550}
      onClose={onClose}
      visible={visible}
      height={200}
      getContainer={false}
      destroyOnClose
      style={{
        position: "fixed",
        top: 0,
        bottom: 0,
        overflowY: "hidden",
      }}
    >
      {type === "companyInvestments" && (
        <>
          {" "}
          <CompanyInvestmentForm
            {...formProps}
            disabled={!selected && loading}
            loading={loading}
            onCancel={onClose}
            onSubmit={() => {
              onCreate(newEntity);
              setNewEntity({});
            }}
          />
        </>
      )}
      {type !== "companyInvestments" && (
        <>
          {type && getDrawerCaption(type)}
          <div style={{ position: "relative" }}>
            <Tabs defaultActiveKey="1">
              <TabPane tab={getTab1Title(type)} key="1">
                <SearchInput
                  onSelect={(entity) => {
                    // get ids of our currently selected items
                    let selectedIds = selected.map((i) => i.id);
                    // make sure we're not adding a duplicate
                    if (selectedIds.includes(entity.id)) return null;
                    setSelected([...selected, entity]);
                  }}
                  placeholder={getPlaceHolder(type)}
                  type={type}
                />
                {selected &&
                  selected.length > 0 &&
                  selected.map((item) => {
                    if (type === "contacts") {
                      return <ContactCard key={item.id} item={item} />;
                    }
                    if (type === "projects") {
                      return <ProjectCard key={item.id} item={item} />;
                    }
                    if (type === "sites") {
                      return <SiteCard key={item.id} item={item} />;
                    }
                    if (type === "businesses") {
                      return <BusinessCard key={item.id} item={item} />;
                    }
                    return null;
                  })}
                <ButtonContainer>
                  <div>
                    <Button
                      disabled={!selected || loading}
                      data-testid="AddEntityDrawer-save-btn"
                      onClick={() => {
                        onSave(selected);
                      }}
                      style={{ marginLeft: 16 }}
                    >
                      {!loading ? "Save" : "..."}
                    </Button>
                    <Button onClick={onClose} grey style={{ marginLeft: 8 }}>
                      Cancel
                    </Button>
                  </div>
                </ButtonContainer>
              </TabPane>
              <TabPane tab={getTab2Title(type)} key="2">
                {(() => {
                  switch (type) {
                    case ShowEntityFormString.businesses:
                      return (
                        <BusinessForm
                          {...formProps}
                          loading={!selected || loading}
                          onCancel={() => {}}
                          onSubmit={(newEntity) => {
                            onCreate(newEntity);
                            setNewEntity({});
                          }}
                        />
                      );
                    case ShowEntityFormString.sites:
                      return (
                        <SiteForm
                          {...formProps}
                          loading={!selected || loading}
                          onCancel={() => {}}
                          onSubmit={(newEntity) => {
                            onCreate(newEntity);
                            setNewEntity({});
                          }}
                        />
                      );
                    case ShowEntityFormString.contacts:
                      return (
                        <ContactForm
                          {...formProps}
                          parentType={parentType}
                          loading={!selected || loading}
                          onCancel={() => {}}
                          onSubmit={(newEntity) => {
                            onCreate(newEntity);
                            setNewEntity({});
                          }}
                        />
                      );
                    case ShowEntityFormString.projects:
                      return (
                        <ProjectForm
                          {...formProps}
                          value={{ ...newEntity, stage: 0 }} // when creating a new project, we'll set the stage to 0
                        />
                      );
                    default:
                      return null;
                  }
                })()}
                {type !== "businesses" &&
                  type !== "contacts" &&
                  type !== "sites" && (
                    <ButtonContainer>
                      <div>
                        <Button
                          disabled={
                            !selected ||
                            loading ||
                            (type === "projects" &&
                              (!newEntity?.title || !newEntity?.pipeline))
                          }
                          onClick={() => {
                            onCreate(newEntity);
                            setNewEntity({});
                          }}
                          style={{ marginLeft: 16 }}
                        >
                          {!loading ? getNewBtnText(type) : "..."}
                        </Button>
                        <Button
                          onClick={onClose}
                          grey
                          style={{ marginLeft: 8 }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </ButtonContainer>
                  )}
              </TabPane>
            </Tabs>
          </div>
        </>
      )}
    </Drawer>
  );
}
