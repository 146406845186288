// TOP LEVEL IMPORTS
import { Suspense } from "react";
import { Navigate, useLocation } from "react-router-dom";
import AdminView from "./AdminView";
import Loading from "components/common/Loading";
import { RoleType } from "generated/graphql";

export default function ProtectedRoute(props) {
  const { currentUser, component: Component, layout: Layout } = props;
  const location = useLocation();

  if (
    currentUser?.roles?.includes(RoleType.AppAdmin) &&
    !currentUser.customerId
  ) {
    return (
      <>
        <Navigate to="/admin" />
      </>
    );
  }

  return (
    <div style={{ height: "100%" }}>
      {currentUser && currentUser.id ? (
        <Layout {...props} location={location}>
          <Suspense fallback={<Loading />}>
            <Component {...props} location={location} />
          </Suspense>
        </Layout>
      ) : (
        <Navigate to="/" />
      )}
      {currentUser?.roles?.includes(RoleType.AppAdmin) && <AdminView />}
    </div>
  );
}
