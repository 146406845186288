import styled from "styled-components";
import { DeleteFilled } from "@ant-design/icons";
import Text from "components/text/Text";
import { RightOutlined } from "@ant-design/icons";
import theme from "lib/theme";

export const ActionContainer = styled.div`
  background: ${({ theme }) => {
    return theme?.colors?.neutral9;
  }};
  padding: 16px;
  margin-top: 24px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
`;

export const LeftCol = styled.div`
  display: flex;
  align-items: center;
`;

export const Rightcol = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const RemoveActionBtn = ({ onClick }) => {
  return (
    <DeleteFilled
      onClick={onClick}
      style={{ fontSize: 16, color: theme.colors.neutral4 }}
    />
  );
};

export const PageContainer = styled.div`
  width: 1200px;
  margin: auto;
  padding-top: 24px;
  max-width: 100%;
  padding-bottom: 96px;
`;

export const StepTitle = styled(Text)`
  display: flex;
  margin-top: 32px;
  margin-bottom: 8px;
  align-items: center;
`;

export const LeftColLine = styled.div<{ height?: number }>`
  width: 2px;
  background: ${({ theme }) => theme.colors.neutral10};
  height: ${({ height }) => (height ? height + "px" : "95px")};
`;

const ChevronCircleContainer = styled.div`
  background: ${({ theme }) => theme.colors.neutral9};
  color: ${({ theme }) => theme.colors.neutral4};
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
`;

export const ChevronCircle = () => {
  return (
    <ChevronCircleContainer>
      <RightOutlined style={{ fontSize: 10, color: theme.colors.neutral6 }} />
    </ChevronCircleContainer>
  );
};

export const InlineText = styled(Text)`
  display: inline-flex;
  margin-top: 32px;
  margin-bottom: 8px;
  align-items: center;
  font-weight: 500;
`;

export const StepCircle = styled.div`
  background: ${({ theme }) => theme.colors.primary2};
  height: 16px;
  width: 16px;
  border-radius: 50%;
  color: #fff;
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 6px;
`;

export const PageTitle = styled(Text)`
  font-size: 18px;
  margin-bottom: 64px;
  margin-top: 24px;
  font-weight: 600;
`;

export const Card = styled.div`
  padding: 16px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.neutral9};
  background: #fff;
  max-width: 100%;
  width: 1100px;
`;

export const TagText = styled.div`
  background: ${({ theme }) => theme.colors.blue2};
  border-radius: 5px;
  color: #fff;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  margin: 4px;
  font-size: 11px;
`;
