import React from 'react';
import Table from 'components/common/Table';
import styled from 'styled-components';

const TableWrapper = styled.div`
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  .ant-table-thead {
    background: #fbfafa !important;
    border-bottom-color: transparent !important;
  }
  .ant-table-column-title {
    text-transform: uppercase;
    width: 143px;
    height: 22px;
    font-family: ${(p) => p.theme.fontFamily};
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 1.04px;
    color: #9fb3c8 !important;
  }
`;

class AdminTable extends React.PureComponent {
  render() {
    return (
      <TableWrapper>
        <Table
          loading={this.props.loading}
          onRow={this.props.onRow}
          rowKey={(record) => record.id}
          dataSource={this.props.dataSource}
          pagination={this.props.pagination}
          columns={this.props.columns}
          {...this.props}
        />
      </TableWrapper>
    );
  }
}

export default AdminTable;
