import message from "components/common/message";
import { omit } from "lodash";
import { useSaveProjectMutation } from "generated/graphql";
import { useNavigate } from "react-router-dom";

const getProjectValues = (project) => {
  try {
    //  if (proejct.)

    let shapedProject = {
      ...project,
      order: 0,
      stage: 0,
    };
    if (shapedProject?.extraFields?.[0]) {
      shapedProject.extraFields = shapedProject.extraFields.map((item) => {
        if (item.fieldType === "naics") {
          console.log(item);
          return omit(
            {
              ...item,
              value:
                typeof item.value !== "string"
                  ? JSON.stringify(item.value)
                  : item.value,
            },
            ["__typename"]
          );
        }
        return omit(item, ["__typename"]);
      });
    }
    if (shapedProject.customFields) {
      shapedProject.customFields = shapedProject.customFields.map((item) =>
        omit(item, ["__typename"])
      );
    }
    return shapedProject;
  } catch (err) {
    console.log("error in get projec values");
    console.log(err);
    return err;
  }
};

export default function useSaveProject() {
  const [saveProject, { loading }] = useSaveProjectMutation();
  const navigate = useNavigate();
  const onSubmitProject = async (params: any, onComplete?: any) => {
    try {
      console.log({
        params: getProjectValues(params),
      });
      // check if it has a title
      if (!params.title) {
        return message.error("Project title is required");
      }
      if (!params.pipeline) {
        return message.error("Please choose a pipeline");
      }

      let res = await saveProject({
        variables: {
          params: getProjectValues(params),
        },
        refetchQueries: ["projectsByStage"],
      });

      onComplete();
      if (res?.data?.saveProject?.id) {
        navigate(`/app/projects/${res.data.saveProject.id}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSubmitProject,
    savingProject: loading,
  };
}

// import SAVE_PROJECT from "ApolloClient/Mutations/saveProject";
// import { useMutation } from "@apollo/client";
// import message from "components/common/message";
// import { useNavigate } from "react-router-dom";

// const getProjectValues = (project) => {
//   try {
//     //  if (proejct.)

//     let shapedProject = {
//       ...project,
//       order: 0,
//       stage: 0,
//     };
//     if (shapedProject.extraFields) {
//       shapedProject.extraFields = shapedProject.extraFields.map((item) => {
//         delete item.__typename;
//         if (item.fieldType === "naics") {
//           return {
//             ...item,
//             value: JSON.stringify(item.value),
//           };
//         }
//         return item;
//       });
//     }
//     if (shapedProject.customFields) {
//       shapedProject.customFields = shapedProject.customFields.map((item) => {
//         delete item.__typename;
//         return item;
//       });
//     }
//     return shapedProject;
//   } catch (err) {
//     console.log("error in get projec values");
//     console.log(err);
//   }
// };

// export default function useSaveProject({ setLoading }) {
//   const [saveProject] = useMutation(SAVE_PROJECT);
//   const navigate =useNavigate();
//   const onSubmitProject = async (params, onComplete, refetchQueries = []) => {
//     try {
//       // check if it has a title
//       if (!params.title) {
//         return message.error("Project title is required");
//       }
//       if (!params.pipeline) {
//         return message.error("Please choose a pipeline");
//       }

//       // set loading
//       setLoading(true);
//       let res = await saveProject({
//         variables: {
//           params: getProjectValues(params),
//         },
//         refetchQueries,
//       });
//       // setLoading(false);
//       onComplete();
//       navigate(`/app/projects/${res.data.saveProject.id}`);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   return {
//     onSubmitProject,
//   };
// }
