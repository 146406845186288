// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import pipelineFragment from 'ApolloClient/Fragments/pipelineFragment';

export default gql`
  query pipelinesByCustomer {
    pipelinesByCustomer {
      ...pipelineFragment
    }
  }
  ${pipelineFragment}
`;
