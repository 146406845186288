// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  query caseCategories {
    caseCategories {
      id
      title
      description
    }
  }
`;
