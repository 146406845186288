// import styled from "styled-components";
// import RichTextEditor from "react-rte";

// const getTextForEditor = (str) => {
//   if (str === null) return RichTextEditor.createEmptyValue();

//   // if it's an object, then it's probably the object the text editor expects
//   if (typeof str === "object") return str;

//   // if it's null, use the helper function from RichTextEditor to create the RichTextEditor object
//   if (!str) {
//     return RichTextEditor.createEmptyValue();
//   }

//   // else, create value from the given string
//   return RichTextEditor.createValueFromString(str, "html");
// };

// const TextEditorWrapper = styled.div`
//   .RichTextEditor__root___2QXK- {
//     background: ${(p) => p.theme.colors.neutral11};
//     border: 1px solid ${(p) => p.theme.colors.neutral9};
//   }
//   .DraftEditor-editorContainer {
//     /* min-height: 80px; */
//   }
// `;

// const toolbarConfig = {
//   // Optionally specify the groups to display (displayed in the order listed).
//   display: [
//     "INLINE_STYLE_BUTTONS",
//     "BLOCK_TYPE_BUTTONS",
//     "LINK_BUTTONS",
//     "BLOCK_TYPE_DROPDOWN",
//     "HISTORY_BUTTONS",
//   ],
//   INLINE_STYLE_BUTTONS: [
//     { label: "Bold", style: "BOLD", className: "custom-css-class" },
//     { label: "Italic", style: "ITALIC" },
//     { label: "Underline", style: "UNDERLINE" },
//   ],
//   BLOCK_TYPE_DROPDOWN: [
//     { label: "Normal", style: "unstyled" },
//     { label: "Heading Large", style: "header-one" },
//     { label: "Heading Medium", style: "header-two" },
//     { label: "Heading Small", style: "header-three" },
//   ],
//   BLOCK_TYPE_BUTTONS: [
//     { label: "UL", style: "unordered-list-item" },
//     { label: "OL", style: "ordered-list-item" },
//   ],
// };

// export default function TextEditor(props) {
//   const { value, onChange, onBlur } = props;
//   return (
//     <TextEditorWrapper>
//       <RichTextEditor
//         onBlur={onBlur}
//         toolbarConfig={toolbarConfig}
//         value={getTextForEditor(value)}
//         onChange={(newValue) => onChange(newValue)}
//         data-testid={props["data-testid"] || "rich-test-editor"}
//       />
//     </TextEditorWrapper>
//   );
// }

import { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TextEditor(props: any) {
  const editorRef = useRef<any>(null);

  return (
    <>
      <Editor
        apiKey="kl0u8y0qf6lyxsc95vqhi1rnvexua4ceoi6816k9eqvqrbvr"
        onInit={(evt, editor) => (editorRef.current = editor)}
        value={props?.value || "<p></p>"}
        onEditorChange={(value) => {
          props.onChange(value);
        }}
        id="tiny-mce"
        data-testid="text-editor-input"
        init={{
          id: "tiny-mce",
          autosave_ask_before_unload: true,
          autosave_interval: "30s",
          autosave_prefix: "{path}{query}-{id}-",
          autosave_restore_when_empty: false,
          toolbar_sticky: true,
          height: 300,
          // plugins: [
          //   "advlist",
          //   "autolink",
          //   "lists",
          //   "link",
          //   "image",
          //   "charmap",
          //   "preview",
          //   "anchor",
          //   "searchreplace",
          //   // "visualblocks",
          //   "mentions",
          //   "fullscreen",
          //   "insertdatetime",
          //   "media",
          //   "table",
          //   "code",
          //   "help",
          //   "wordcount",
          //   "textpattern",
          // ],
          // image
          plugins:
            "preview searchreplace autosave save fullscreen link table wordcount emoticons lists help",
          // plugins:
          //   "preview importcss searchreplace autolink autosave save directionality visualchars pagebreak nonbreaking advlist charmap quickbars",
          textpattern_patterns: [
            { start: "1. ", cmd: "InsertOrderedList" },
            { start: "* ", cmd: "InsertUnorderedList" },
            { start: "- ", cmd: "InsertUnorderedList" },
          ],
          menubar: "edit view  format table help", // insert
          skin: "oxide",
          autoreplace: [
            {
              before: "-",
              after: "•",
            },
          ],
          // toolbar:
          //   "undo redo | " +
          //   "bold italic forecolor superscript | alignleft aligncenter " +
          //   "alignright alignjustify | bullist numlist outdent indent | " +
          //   "removeformat | help",
          // image
          toolbar:
            "undo redo | bold italic underline strikethrough  link | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile",
          content_style:
            "body { font-family:Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
  );
}
