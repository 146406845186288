import styled from "styled-components";
// COMPONENTS
import Loading from "components/common/Loading";
import message from "components/common/message";
import EmptyState from "components/common/EmptyState";
import Modal from "components/common/Modal";
import TodoDetailContent from "./TodoDetailContent";
import {
  CloseOutlined,
  DeleteOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
// HOOKs
import useUrlChange from "hooks/useUrlChange";
import useClipboard from "lib/hooks/useClipboard";
import { useDeleteTodoMutation, useTodoByIdQuery } from "generated/graphql";

const { confirm } = Modal;

const Overlay = styled.div`
  background: rgba(9, 30, 66, 0.54);
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 64px;
`;

const ModalContent = styled.div`
  padding: 32px;
  padding-top: 16px;
  border-radius: 5px;
  width: 1000px;
  max-width: 100%;
  min-height: 200px;
  background: #fff;
  position: relative;
`;

const CloseBtn = styled(CloseOutlined)`
  font-size: 22px;
  color: ${(p) => p.theme.colors.neutral5};
`;

const ShareBtn = styled(ShareAltOutlined)`
  font-size: 22px;
  color: ${(p) => p.theme.colors.neutral5};
`;

const DeleteBtn = styled(DeleteOutlined)`
  font-size: 22px;
  color: ${(p) => p.theme.colors.neutral6};
`;

const IconBtn = styled.button`
  height: 32px;
  width: 32px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  background: transparent;
  &:hover {
    background: ${(p) => p.theme.colors.neutral10};
  }
  &:focus {
    background: ${(p) => p.theme.colors.primary10};
    outline: 0;
  }
`;

const ActionBar = styled.div`
  height: 32px;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default function TodoDetail2({ selectedTodo, currentUser }) {
  const { onUrlChange } = useUrlChange();
  const [copyToClipboard] = useClipboard();
  const [deleteTodo, { loading: deleting }] = useDeleteTodoMutation();
  const { data, loading, error } = useTodoByIdQuery({
    variables: {
      id: selectedTodo,
    },
    skip: !selectedTodo,
  });

  if (!selectedTodo) return null;

  const onClose = () => {
    onUrlChange({ selectedTodo: undefined });
  };

  const onDeleteTodo = async () => {
    try {
      if (deleting || !selectedTodo) return;
      await deleteTodo({
        variables: {
          id: selectedTodo,
        },
      });
      onUrlChange({ selectedTodo: undefined });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Overlay
      id="todo-modal-overlay"
      onClick={(e: any) => {
        if (e?.target?.id === "todo-modal-overlay") {
          onClose();
        }
      }}
    >
      <ModalContent>
        {loading && <Loading />}
        {error && (
          <EmptyState
            title="Something went wrong..."
            subtitle="If this continues please contact support"
          />
        )}
        {!loading && !error && data?.todoById && (
          <div>
            <ActionBar>
              <IconBtn
                onClick={() => {
                  confirm({
                    className: "growlab-confirm-modal",
                    title: "Are you sure you want to delete this?",
                    content:
                      "This is permanent and will include deleting attachments and comments",
                    onOk: onDeleteTodo,
                    okText: `Yes, I'm sure`,
                  });
                }}
                style={{ marginRight: 24 }}
              >
                <DeleteBtn data-testid="todo-detail-delete-todo-btn" />
              </IconBtn>
              <IconBtn
                onClick={() => {
                  copyToClipboard(window.location.href);
                  message.success("Link copied to your clipboard");
                }}
                style={{ marginRight: 24 }}
              >
                <ShareBtn />
              </IconBtn>
              <IconBtn onClick={onClose}>
                <CloseBtn />
              </IconBtn>
            </ActionBar>
            <TodoDetailContent
              currentUser={currentUser}
              defaultTodo={data?.todoById}
            />
          </div>
        )}
      </ModalContent>
    </Overlay>
  );
}
