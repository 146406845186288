// TOP LEVEL IMPORTS
import React, { useState } from "react";
// COMPONENTS
import Button from "components/common/Button";
import message from "components/common/message";
import FormItem from "components/common/FormItem";
import PasswordInput from "components/inputs/PasswordInput";
// LIB
import AuthHelpers from "lib/helpers/AuthHelpers";
import {
  useChangePasswordMutation,
  useResetPasswordMutation,
} from "generated/graphql";

const buttonStyle = {
  width: 200,
  marginTop: 20,
  marginLeft: "auto",
  display: "block",
};

export default function ChangePasswordForm() {
  const [oldPassword, setOldPassword] = useState<string | undefined>("");
  const [newPassword, setNewPassword] = useState<string | undefined>("");
  const [confirmNewPassword, setConfirmNewPassword] = useState<
    string | undefined
  >("");
  const [changePasswordMutation, { loading }] = useChangePasswordMutation();

  const handleChangePasswordSubmit = async () => {
    try {
      if (!oldPassword || !newPassword) return;
      await changePasswordMutation({
        variables: {
          oldPassword,
          newPassword,
        },
      });
      message.success("New password saved!");
      setNewPassword("");
      setOldPassword("");
      setConfirmNewPassword("");
    } catch (err: any) {
      return message.error(err.message || "Something went wrong");
    }
  };

  let disabled =
    !oldPassword?.[0] || !newPassword?.[0] || !confirmNewPassword?.[0];

  return (
    <>
      <FormItem label="Old Password">
        <PasswordInput
          onChange={(oldPassword) => setOldPassword(oldPassword)}
          value={oldPassword}
          placeholder="****************"
        />
      </FormItem>
      <FormItem label="New Password">
        <PasswordInput
          onChange={(newPassword) => setNewPassword(newPassword)}
          value={newPassword}
          placeholder="****************"
        />
      </FormItem>
      <FormItem
        label="Confirm New Password"
        error={
          newPassword &&
          confirmNewPassword &&
          newPassword !== confirmNewPassword
            ? "Passwords must match"
            : ""
        }
      >
        <PasswordInput
          onChange={(confirmNewPassword) =>
            setConfirmNewPassword(confirmNewPassword)
          }
          value={confirmNewPassword}
          placeholder="****************"
        />
      </FormItem>
      <Button
        secondary
        onClick={handleChangePasswordSubmit}
        disabled={disabled || loading}
        loading={loading}
        style={buttonStyle}
      >
        Change Password
      </Button>
    </>
  );
}
