// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  fragment projectTypeFragment on ProjectType {
    id
    __typename
    title
    extraFields {
      fieldName
      fieldType
      value
    }
    customFields {
      fieldName
      fieldType
      value
    }
  }
`;
