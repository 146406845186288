// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  query reports_ProjectsByStage($pipelineId: ID!) {
    reports_ProjectsByStage(pipelineId: $pipelineId) {
      title
      total
    }
  }
`;
