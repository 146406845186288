import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
// COMPONENTS
import message from "components/common/message";
import { LoadingOutlined, CloudUploadOutlined } from "@ant-design/icons";
// APOLLO

import ATTACHMENTS from "ApolloClient/Queries/attachments";
import Progress from "components/common/Progress";
// HOOKs
import useUploadFile from "lib/hooks/useUploadFile";
import {
  ObjectTypeEnum,
  UserPermissionEnum,
  useAttachmentsQuery,
  useSaveAttachmentMutation,
} from "generated/graphql";
import { toNumber } from "lodash";
import ObjectPermissionsManager from "components/common/ObjectPermissionsManager";
import { Popover } from "antd";
import useCurrentUser from "lib/hooks/useCurrentUser";

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const Label = styled.label`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary2};
  padding: 8px 16px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.primary9};
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) => p.theme.colors.primary8};
    color: ${(p) => p.theme.colors.primary1};
  }
`;

const ItemContainer = styled.div`
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  border-radius: 5px;
  padding: 8px 16px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
  max-width: 100%;
  position: relative;
  a {
    font-size: 16px;
  }
`;

const Username = styled.p`
  color: ${(p) => p.theme.colors.neutral2};
  margin: 0px;
  font-weight: 600;
  font-size: 14px;
  margin-top: 8px;
`;

const Time = styled.span`
  color: ${(p) => p.theme.colors.neutral6};
  font-size: 14px;
  margin: 0px;
`;

const OptionsArea = styled.div`
  position: absolute;
  right: 16px;
  top: 8px;
  display: flex;
  align-items: center;
`;
const TransparentBtn = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
`;

const AttachmentItem = ({ item, currentUser }) => {
  return (
    <ItemContainer>
      <div>
        {" "}
        <div>
          <a
            href={item.url}
            target="_blank"
            rel="noreferrer"
            data-testid="AttachmentItem-title"
          >
            {item.filename}
          </a>
        </div>
        <Username>
          {item.createdByName}{" "}
          <Time>
            uploaded{" "}
            {moment(parseInt(item.createdAt)).format("MMMM D, YYYY h:mma")}
          </Time>
        </Username>{" "}
      </div>
      <OptionsArea>
        {currentUser?.permission?.includes(
          UserPermissionEnum.Administrator
        ) && (
          <Popover
            destroyTooltipOnHide
            content={
              <ObjectPermissionsManager
                objectType={ObjectTypeEnum?.Attachment}
                id={item?.id}
              />
            }
            placement="bottom"
          >
            <TransparentBtn
              onClick={() => console.log("permissions")}
              data-testid="sharing-group-permissions-btn"
            >
              Share
            </TransparentBtn>
          </Popover>
        )}
      </OptionsArea>
    </ItemContainer>
  );
};

export enum FilesAndDocsParentType {
  Businesses = "Businesses",
  Contacts = "Contacts",
  Projects = "Projects",
  Sites = "Sites",
  Organizations = "Organizations",
}

export default function FilesAndDocs({
  parentId,
}: {
  parentId: string;
  parentType?: FilesAndDocsParentType;
}) {
  const [saveAttachment] = useSaveAttachmentMutation();
  const currentUser = useCurrentUser();
  const [uploadFile, progress] = useUploadFile();
  const [uploading, setUploading] = useState(false);
  const { data, loading } = useAttachmentsQuery({
    variables: {
      parentId,
    },
  });
  // upload
  const onUpload = async (event) => {
    try {
      // if already uploading, return
      if (uploading) return;

      const file = event?.target?.files[0];

      if (!file) return;
      // set loader
      setUploading(true);

      const uploadedFile = await uploadFile(file);

      // // add attachment to parent (business, contact, project, etc)
      await saveAttachment({
        variables: {
          params: {
            parentId,
            ...uploadedFile,
          },
        },
        refetchQueries: [
          {
            query: ATTACHMENTS,
            variables: {
              parentId,
            },
          },
        ],
      });
      message.success("Upload successful!");
      setUploading(false);
    } catch (err) {
      setUploading(false);
      console.log(err);
    }
  };
  return (
    <div style={{ paddingTop: 16 }}>
      <div style={{ marginBottom: 32 }}>
        <UploadButton
          name="compay-upload"
          type="file"
          id="compay-upload"
          data-testid="attachment-upload-btn"
          onChange={onUpload}
          disabled={uploading}
        />

        {uploading && <Progress percent={toNumber(progress)} />}
        <Label htmlFor="compay-upload" data-testid="attachment-upload-label">
          {!uploading ? (
            <>
              <CloudUploadOutlined style={{ marginRight: 6 }} /> Upload New File
            </>
          ) : (
            <>
              <LoadingOutlined style={{ marginRight: 6 }} />
              Uploading...
            </>
          )}
        </Label>
      </div>
      {loading && "Loading..."}
      {!loading &&
        data?.attachments?.map((item) => {
          return (
            <AttachmentItem
              key={item?.id}
              item={item}
              currentUser={currentUser}
            />
          );
        })}
    </div>
  );
}
