// TOP LEVEL IMPORTS
import { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import TextEditor from "components/inputs/TextEditor";
import StatesInput from "components/inputs/StatesInput";
import FormBreak from "components/common/FormBreak";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import FormItem from "components/common/FormItem";
import ContactInput from "components/inputs/ContactInputSimple";
import NumberInput from "components/inputs/NumberInput";
import SiteTypeInput from "../../inputs/SiteTypeInput";

import LocationAutocomplete from "components/inputs/LocationAutocomplete";
// LIB
import helpers from "lib/helpers/GeneralHelpers";
import CustomTagsInput from "components/inputs/CustomTagsInput";

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

// const RemoveLocationIcon = styled(CloseOutlined)`
//   margin-left: 16px;
//   color: ${(p) => p.theme.colors.primary4};
//   cursor: pointer;
//   &:hover {
//     color: ${(p) => p.theme.colors.primary2};
//   }
// `;

export default function SiteForm({
  loading,
  onCancel,
  onSubmit,
  defaultValue = {},
}) {
  const [values, setValues] = useState({
    title: defaultValue.title || null,
    description: defaultValue.description || null,
    type: defaultValue.type || null,
    url: defaultValue.url || null,
    size: defaultValue.size || null,
    contactId: defaultValue.contactId || null,
    lat: defaultValue.lat || null,
    lng: defaultValue.lng || null,
    placeId: defaultValue.placeId || null,
    fullAddress: defaultValue.fullAddress || null,
    street: defaultValue.street || null,
    postal: defaultValue.postal || null,
    country: defaultValue.country || null,
    state: defaultValue.state || null,
    city: defaultValue.city || null,
    attachments: defaultValue.attachments || [],
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();

    // check if type exists
    if (!values.title) {
      return setErrors({
        ...errors,
        title: "This is required",
      });
    }

    if (!values.type) {
      return setErrors({
        ...errors,
        type: "This is required",
      });
    }

    if (loading) return null;

    // pass back up to the parent component
    onSubmit({
      ...values,
      // location
      lat: (values.lat && values.lat.toString()) || null,
      lng: (values.lng && values.lng.toString()) || null,
      description: values.description && values.description.toString("html"),
      attachments: values.attachments
        ? helpers.cleanTypenameFromArray(values.attachments)
        : [],
    });
  };

  const onChange = (newValues) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormItem label="Title" required error={errors.title}>
        <TextInput
          value={values.title}
          onChange={(title) => onChange({ title })}
          data-testid="site-form-title"
        />
      </FormItem>
      <FormItem label="Description">
        <TextEditor
          value={values.description}
          onChange={(description) => onChange({ description })}
          data-testid="site-form-description"
        />
      </FormItem>
      <FormItem label="Site URL" error={errors?.url}>
        <TextInput
          value={values?.url}
          onChange={(url) => onChange({ url })}
          data-testid="site-form-url"
        />
      </FormItem>
      <Row gutter={16}>
        <Col xs={8}>
          <FormItem label="Site Size">
            <NumberInput
              value={values.size}
              onChange={(size) => onChange({ size })}
              data-testid="site-form-size"
            />
          </FormItem>
        </Col>
        <Col xs={16}>
          <FormItem label="Site Type" required error={errors.type}>
            <SiteTypeInput
              value={values.type}
              onChange={(type) => onChange({ type })}
              data-testid="site-form-type"
            />
          </FormItem>
        </Col>
        <Col xs={16}>
          <FormItem label="Contact">
            <ContactInput
              value={values.contactId}
              defaultContactId={values.contactId}
              onChange={(contactId) => onChange({ contactId })}
            />
          </FormItem>
        </Col>
        <Col xs={8} />
        <Col xs={24}>
          {" "}
          <FormItem label="Tags">
            <CustomTagsInput
              value={values.tagIds}
              onChange={(tagIds) => onChange({ tagIds })}
            />
          </FormItem>
        </Col>
      </Row>
      {/* LOCATION INFO */}
      <FormBreak
        title="Location"
        description="Tell about where this site is located"
        topBorder
      />
      <Row gutter={16}>
        <Col xs={24}>
          <FormItem label="Street">
            <LocationAutocomplete
              value={values.street}
              initialValue={values.street}
              onChange={(street) => onChange({ street })}
              onSuggestSelect={(value) => {
                onChange({ ...value });
              }}
            />
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem label="City">
            <TextInput
              value={values.city}
              onChange={(city) => onChange({ city })}
            />
          </FormItem>
        </Col>
        <Col xs={6}>
          <FormItem label="State">
            <StatesInput
              value={values.state}
              onChange={(state) => onChange({ state })}
            />
          </FormItem>
        </Col>
        <Col xs={6}>
          <FormItem label="Postal">
            <TextInput
              value={values.postal}
              onChange={(postal) => onChange({ ...values, postal })}
            />
          </FormItem>
        </Col>
      </Row>
      {/*  ATTACHMENTS */}
      {/* <FormBreak
        title="Attachments"
        description="Add as many attachments as you'd like"
        topBorder
      />
      <FormItem>
        <Attachments
          attachments={values.attachments}
          onSave={(attachments) => onChange({ attachments })}
        />
        <ButtonUploader
          containerStyle={{ marginTop: 32 }}
          name="site-attachment-uploader"
          label="+ Upload an attachment"
          loading={uploading}
          onChange={async (e) => {
            if (!e?.target?.files?.[0]) return;
            let result = await uploadFile(e?.target?.files?.[0]);
            onChange({
              ...values,
              attachments: [...values.attachments, result],
            });
          }}
        />
      </FormItem> */}
      <div style={{ height: 56 }} />
      <FormItem>
        <Button
          style={{
            width: 100,
            marginRight: 8,
          }}
          grey
          disabled={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>{" "}
        <Button
          style={{
            width: 150,
          }}
          disabled={loading}
          onClick={handleSubmit}
          loading={loading}
          data-testid="save-site-btn"
        >
          Save Site
        </Button>
      </FormItem>
    </FormContainer>
  );
}
