import Loading from "components/common/Loading";
import SurveyCreatorWidget from "components/common/SurveyCreatorWidget";
import { useGetOneDefaultSurveyTemplateQuery } from "generated/graphql";
import { useLocation } from "react-router-dom";

export default function AppSurveyCreation() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const editSurveyId = queryParams.get("editSurveyId");
  const defaultTemplateId = queryParams.get("surveyTemplateId");

  const { data: defaultSurveyTemplate, loading } =
    useGetOneDefaultSurveyTemplateQuery({
      variables: { defaultTemplateId: defaultTemplateId || "" },
      skip: !defaultTemplateId,
    });

  if (loading) return <Loading />;

  return (
    <SurveyCreatorWidget
      defaultSurveyTemplate={
        defaultSurveyTemplate?.getOneDefaultSurveyTemplate.template
      }
      templateId={editSurveyId}
    />
  );
}
