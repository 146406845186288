import * as React from 'react';

function ClipboardFilled(props) {
  return (
    <svg
      width={33}
      height={33}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.85 3.3a1.65 1.65 0 100 3.3h3.3a1.65 1.65 0 100-3.3h-3.3z"
        fill={props.fill || '#627D98'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6 8.25a3.3 3.3 0 013.3-3.3 4.95 4.95 0 004.95 4.95h3.3a4.95 4.95 0 004.95-4.95 3.3 3.3 0 013.3 3.3V26.4a3.3 3.3 0 01-3.3 3.3H9.9a3.3 3.3 0 01-3.3-3.3V8.25zm4.95 6.6a1.65 1.65 0 000 3.3h.016a1.65 1.65 0 100-3.3h-.016zm4.95 0a1.65 1.65 0 000 3.3h4.95a1.65 1.65 0 100-3.3H16.5zm-4.95 6.6a1.65 1.65 0 000 3.3h.016a1.65 1.65 0 100-3.3h-.016zm4.95 0a1.65 1.65 0 000 3.3h4.95a1.65 1.65 0 100-3.3H16.5z"
        fill={props.fill || '#627D98'}
      />
    </svg>
  );
}

export default ClipboardFilled;
