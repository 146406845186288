// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import todoFragment from 'ApolloClient/Fragments/todoFragment';
import todoListFragment from 'ApolloClient/Fragments/todoListFragment';

export default gql`
  query todoLists(
    $sortBy: TodoListSortByEnum
    $archived: Boolean
    $searchText: String
  ) {
    todoLists(archived: $archived) {
      count
      todoLists {
        ...todoListFragment
      }
    }
  }
  ${todoListFragment}
  ${todoFragment}
`;
