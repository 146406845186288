import React from "react";
// COMPONENTS
import { DetailsTitle } from "components/detail-components";
import DetailItem, {
  DetailitemFieldTypeEnum,
} from "components/detail-components/DetailItem";
import Tag from "components/common/Tag";
import theme from "lib/theme";
import moment from "moment";
import { BusinessByIdFragmentFragment } from "generated/graphql";
import businessStatuses from "lib/constants/businessStatuses";

export default function BusinessDetails({
  business,
  onSave,
}: {
  business: BusinessByIdFragmentFragment;
  onSave: any;
}) {
  const communication = () => {
    if (business?.optResult === true) {
      return "Yes";
    }
    if (business?.optResult === false) {
      return "No";
    }
    return "Unkown";
  };
  const DETAILS_TO_SHOW = [
    {
      label: "Name",
      editable: true,
      value: business.title,
      fieldName: "title",
      fieldType: DetailitemFieldTypeEnum.text,
    },
    {
      label: "Description",
      editable: true,
      value: business.description,
      fieldName: "description",
      fieldType: DetailitemFieldTypeEnum.textarea,
    },
    {
      label: "Trade Name",
      editable: true,
      value: business.tradeName,
      fieldName: "tradeName",
      fieldType: DetailitemFieldTypeEnum.text,
    },
    {
      label: "Primary Contact",
      tooltip: "This is your main contact at the business",
      editable: true,
      fieldType: DetailitemFieldTypeEnum.contact,
      fieldName: "primaryContactId",
      value:
        business.primaryContact &&
        `${business?.primaryContact?.firstName || ""} ${
          business?.primaryContact?.lastName || ""
        }`,
    },
    {
      label: "Team Lead",
      tooltip:
        "This is the main contact at your organization for this business",
      editable: true,
      fieldType: DetailitemFieldTypeEnum.team,
      fieldName: "ownerId",
      value:
        business.owner &&
        `${business.owner.firstName} ${business.owner.lastName}`,
    },
    {
      label: "Contact Info",
      section: true,
    },
    {
      label: "Phone",
      editable: true,
      value: business.phone,
      fieldName: "phone",
      fieldType: DetailitemFieldTypeEnum.phone,
    },
    {
      label: "Website",
      editable: true,
      value: business.website,
      fieldName: "website",
      fieldType: "text",
    },
    {
      label: "Fax",
      editable: true,
      value: business.fax,
      fieldName: "fax",
      fieldType: "text",
    },
    {
      label: "Open to Communication",
      editable: false,
      value: communication(),
      fieldName: "optResult",
    },
    {
      label: "Location",
      section: true,
    },
    {
      label: "Address",
      editable: true,
      value: business.street,
      fieldName: "street",
      fieldType: "text",
    },
    {
      label: "Address 2",
      editable: true,
      value: business.street2,
      fieldName: "street2",
      fieldType: "text",
    },
    {
      label: "Postal",
      editable: true,
      value: business.postal,
      fieldName: "postal",
      fieldType: "text",
    },
    {
      label: "City",
      editable: true,
      value: business.city,
      fieldName: "city",
      fieldType: "text",
    },
    {
      label: "General Info",
      section: true,
    },
    {
      label: "Status",
      editable: true,
      value: business.status,
      displayValue: business.status
        ? businessStatuses?.find(
            (businessStatus) => businessStatus.value === business.status
          )?.label
        : null,
      fieldName: "status",
      fieldType: DetailitemFieldTypeEnum.businessStatus,
    },
    {
      label: "Sector",
      editable: true,
      value: business.businessSectorId,
      displayValue: business.businessSector
        ? business.businessSector.title
        : "",
      fieldName: "businessSectorId",
      fieldType: DetailitemFieldTypeEnum.businessSector,
    },
    {
      label: "Opened",
      tooltip: "The date/year this business opened",
      editable: true,
      value: business.opened,
      displayValue:
        business.opened &&
        `${moment(parseInt(business.opened)).fromNow()} (${moment(
          parseInt(business.opened)
        ).format("M/DD/YYYY")})`,
      fieldName: "opened",
      fieldType: DetailitemFieldTypeEnum.date,
    },
    {
      label: "Closed",
      tooltip: "The date/year this business closed",
      editable: true,
      value: business?.closed,
      displayValue:
        business.closed &&
        `${moment(parseInt(business.closed)).fromNow()} (${moment(
          parseInt(business.closed)
        ).format("M/DD/YYYY")})`,
      fieldName: "closed",
      fieldType: DetailitemFieldTypeEnum.date,
    },
    {
      label: "Annual Revenue",
      editable: true,
      value: business.annualRevenue,
      fieldName: "annualRevenue",
      fieldType: "currency",
    },
    {
      label: "Employee Count",
      editable: true,
      value: business.employeeCount,
      fieldName: "employeeCount",
      fieldType: "number",
    },

    {
      label: "Classification",
      editable: true,
      value: business.diversity,
      fieldName: "diversity",
      fieldType: "diversity",
    },
    {
      label: "NAICs",
      editable: true,
      value:
        business.naicsIds && business.naicsIds.length > 0
          ? business.naicsIds
          : undefined,
      displayValue:
        business.naics && business.naics.length > 0
          ? business.naics.map((item) => <Tag key={item.id}>{item.title}</Tag>)
          : undefined,
      fieldName: "naicsIds",
      fieldType: "naics",
    },
    {
      label: "Clusters",
      editable: true,
      value:
        business.clusters && business.clusters.length > 0
          ? business.clusters
          : undefined,
      displayValue:
        business.clusters && business.clusters.length > 0
          ? business.clusters.map((item) => <Tag key={item}>{item}</Tag>)
          : undefined,
      fieldName: "clusters",
      fieldType: "clusters",
    },
    {
      label: "Tags",
      editable: true,
      value: business?.tags?.map(({ id }) => id),
      fieldName: "tagIds",
      displayValue:
        business.tags && business.tags.length > 0
          ? business.tags.map(({ title }) => (
              <Tag key={title} data-testid={`tag-${title}`}>
                {title}
              </Tag>
            ))
          : undefined,
      fieldType: "tags",
    },
    {
      label: "Socials",
      section: true,
    },
    {
      label: "Twitter",
      editable: true,
      value: business.twitter,
      fieldName: "twitter",
      fieldType: "text",
    },
    {
      label: "Instagram",
      editable: true,
      value: business.instagram,
      fieldName: "instagram",
      fieldType: "text",
    },
    {
      label: "Linkedin",
      editable: true,
      value: business.linkedin,
      fieldName: "linkedin",
      fieldType: "text",
    },
    {
      label: "Facebook",
      editable: true,
      value: business.facebook,
      fieldName: "facebook",
      fieldType: "text",
    },
  ];
  // state,
  // postal,
  // industry,

  return (
    <>
      <DetailsTitle>Business Details</DetailsTitle>
      {DETAILS_TO_SHOW.map((item, i) => {
        if (item.section) {
          return (
            <div
              key={item.label}
              style={{
                marginTop: 24,
                paddingTop: 8,
                borderTop: `1px solid ${theme.colors.neutral8}`,
              }}
            >
              <h3
                style={{
                  margin: 0,
                  fontSize: 12,
                  fontWeight: 600,
                  textTransform: "uppercase",
                  color: `${theme.colors.neutral5}`,
                }}
              >
                {item.label}
              </h3>
            </div>
          );
        }

        return (
          <DetailItem
            key={item?.fieldName || item.label}
            label={item.label}
            value={item.value}
            editable={item.editable}
            fieldType={item.fieldType as any}
            fieldName={item.fieldName}
            displayValue={item.displayValue}
            tooltip={item.tooltip}
            onSave={onSave}
          />
        );
      })}
    </>
  );
}
