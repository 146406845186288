// TOP LEVEL IMPORTS
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import TextEditor from "components/inputs/TextEditor";
import Button from "components/common/Button";
import FormItem from "components/common/FormItem";
import ClustersMultiselectInput from "components/inputs/ClustersMultiselectInput";
import FundingTypesSelect from "components/inputs/FundingTypesSelect";
import BusinessStagesMultiSelect from "components/inputs/BusinessStagesMultiSelect";
import DiversityInput from "components/inputs/DiversityInput";
import OrganizationInput from "components/inputs/OrganizationInput";
import CustomTagsInput from "components/inputs/CustomTagsInput";

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

interface FundingFormProps {
  loading?: boolean;
  onCancel: any;
  onSubmit: any;
  defaultValue: any;
}

export default function FundingForm({
  loading,
  onCancel,
  onSubmit,
  defaultValue = {},
}: FundingFormProps) {
  const [values, setValues] = useState({
    title: defaultValue.title || null,
    description: defaultValue.description || null,
    attachments: defaultValue.attachments || [],
    organizationId: defaultValue.organizationId || null,
    businessStages: defaultValue.businessStages || [],
    clusters: defaultValue.clusters || [],
    fundingType: defaultValue.fundingType || null,
    diversityTypes: defaultValue.diversityTypes || [],
    tagIds: defaultValue?.tagsIds || [],
  });

  const [errors, setErrors] = useState<any>({});

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrors({});

    if (!values.fundingType) {
      return setErrors((errors) => ({
        ...errors,
        fundingType: "This is required",
      }));
    }

    // check if type exists
    if (!values.title) {
      return setErrors((errors) => ({
        ...errors,
        title: "This is required",
      }));
    }

    if (loading) return null;

    // pass back up to the parent component
    onSubmit({
      ...values,
      description: values.description && values.description.toString("html"), // required by the text editor we're using
    });
  };

  const onChange = (newValues) => {
    setErrors({});
    setValues((oldValues) => ({
      ...oldValues,
      ...newValues,
    }));
  };

  const disabled = !values.title || !values.fundingType;

  return (
    <FormContainer onSubmit={handleSubmit}>
      <FormItem
        label="What type of program is this?"
        required
        error={errors.supportType}
      >
        <FundingTypesSelect
          value={values.fundingType}
          onChange={(fundingType) => onChange({ fundingType })}
        />
      </FormItem>
      <FormItem label="Title" required error={errors.title}>
        <TextInput
          value={values.title}
          onChange={(title) => onChange({ title })}
        />
      </FormItem>
      <FormItem label="Description">
        <TextEditor
          value={values.description}
          onChange={(description) => onChange({ description })}
        />
      </FormItem>
      <FormItem
        label="What business stages is this resources for?"
        hint="Some resources are more suited for businesses at a certain stage."
      >
        <BusinessStagesMultiSelect
          value={values.businessStages}
          onChange={(businessStages) => onChange({ businessStages })}
        />
      </FormItem>
      <FormItem label="What industries/clusters is this resource for? (optional)">
        <ClustersMultiselectInput
          value={values.clusters}
          onChange={(clusters) => onChange({ clusters })}
        />
      </FormItem>{" "}
      <FormItem label="Are there any specific diversity measures?">
        <DiversityInput
          value={values.diversityTypes}
          onChange={(diversityTypes) => onChange({ diversityTypes })}
        />
      </FormItem>
      <FormItem
        label="Organization"
        hint={
          <>
            Is this support program associated with a specific{" "}
            <Link to="/app/resources?sidenav=organizations" target="_blank">
              organization
            </Link>{" "}
            in your ecosystem?
          </>
        }
      >
        <OrganizationInput
          value={values.organizationId}
          onChange={(organizationId) => onChange({ organizationId })}
          defaultOrganizationId={values.organizationId}
        />
      </FormItem>
      <FormItem label="Tags">
        <CustomTagsInput
          value={values.tagIds}
          onChange={(tagIds) => onChange({ tagIds })}
        />
      </FormItem>
      <div style={{ height: 56 }} />
      <FormItem>
        <Button
          style={{
            width: 100,
            marginRight: 8,
          }}
          grey
          disabled={loading}
          onClick={onCancel}
        >
          Cancel
        </Button>{" "}
        <Button
          style={{
            width: 150,
          }}
          disabled={disabled || loading}
          onClick={handleSubmit}
          loading={loading}
        >
          Save Program
        </Button>
      </FormItem>
    </FormContainer>
  );
}
