import styled from "styled-components";
import { useState } from "react";
import {
  AutomationTriggerType,
  AutomationEventType,
  AutomationInput,
  CurrentUserFragmentFragment,
  useGetManyFormTemplatesQuery,
  CustomerFeatureSettingKeyEnum,
} from "generated/graphql";
import helpers from "lib/helpers/GeneralHelpers";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import theme from "lib/theme";
import SelectInput from "components/inputs/SelectInput";
import TextInput from "components/inputs/TextInput";
import AutomationActionsSelectInput from "components/inputs/AutomationActionsSelectInput";
import EventEditor from "../EventEditor";
import { formatAutomatinInput } from "../index";
import {
  StepCircle,
  PageTitle,
  InlineText,
  TagText,
  Card,
  StepTitle,
  ChevronCircle,
  LeftColLine,
  PageContainer,
} from "../shared";

const BtnContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
`;

const LeftCol = styled.div`
  padding-top: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const inputContainerStyles = {
  marginLeft: 4,
  marginRight: 4,
  maxWidth: "100%",
  width: 270,
  display: "inline-flex",
  height: 40,
  backgroundColor: theme.colors.neutral11,
};

export default function SurveySubmissionForm({
  onSubmit,
  loading,
  currentUser,
}: {
  loading?: boolean;
  onSubmit: (values: AutomationInput) => void;
  currentUser: CurrentUserFragmentFragment;
}) {
  const [name, setName] = useState(undefined);
  const [formId, setFormId] = useState(undefined);
  const [questionId, setQuestionId] = useState(undefined);
  const [answerId, setAnswerId] = useState(undefined);
  const [events, setEvents] = useState<any[]>([]);
  const { data, loading: loadingPipelines } = useGetManyFormTemplatesQuery();

  const form = data?.getManyFormTemplates?.find((temp) => temp?.id === formId);
  const questions = form?.questions?.filter((q) =>
    ["MULTIPLE_CHOICE", "SINGLE_CHOICE", "YES_NO"]?.includes(q.questionType)
  );
  const question = questions?.find((q) => q.id === questionId);

  const onHandleSubmit = () => {
    onSubmit(
      formatAutomatinInput({
        name,
        trigger: AutomationTriggerType.FormSurveyResponse,
        formId,
        conditions: {
          any: [
            {
              fact: questionId,
              operator: "equal",
              value: answerId,
            },
            {
              fact: questionId,
              operator: "contains",
              value: answerId,
            },
          ],
        },
        events,
      })
    );
  };

  const ACTIONS_SECTIONS = [
    {
      title: "SEND EMAIL TO",
      options: [
        {
          type: AutomationEventType?.NotifyTeamMember,
          label: "Team Member",
        },
      ],
    },
    ...(currentUser?.customer?.integrations?.constantContact?.exists
      ? [
          {
            title: "NOTIFY INTEGRATION",
            options: [
              {
                type: AutomationEventType?.AddBusinessToConstantContactList,
                label: "Add Business to Constant Contact List",
              },
            ],
          },
        ]
      : []),
    ...(helpers.featureIsOn({
      customer: currentUser.customer,
      feature: CustomerFeatureSettingKeyEnum.Cases,
    })
      ? [
          {
            title: "CREATE THE FOLLOWING",
            options: [
              {
                type: AutomationEventType?.CreateACase,
                label: "Create a Case",
              },
            ],
          },
        ]
      : []),
  ];

  const disabled = !name?.[0] || !formId || !questionId || !events?.[0]?.params;

  return (
    <PageContainer>
      <PageTitle>New “Business Form/Survey Response” Automation Rule</PageTitle>
      <StepTitle>
        <StepCircle>1</StepCircle>Alert Name
      </StepTitle>
      <TextInput
        value={name}
        onChange={(value) => setName(value)}
        style={{
          width: 500,
          background: "#fff",
        }}
        placeholder="Enter alert name..."
      />
      <StepTitle>
        <StepCircle>2</StepCircle>Set Conditions
      </StepTitle>
      <Card>
        <Row align="top">
          <Col xs={1}>
            <LeftCol>
              <ChevronCircle />
              <LeftColLine height={287} />
              <ChevronCircle />
            </LeftCol>
          </Col>
          <Col xs={23}>
            <div style={{ marginBottom: 56 }}>
              <InlineText>
                <TagText>WHEN</TagText>a response is recieved to template
              </InlineText>{" "}
              <SelectInput
                placeholder="Select a form"
                options={
                  data?.getManyFormTemplates?.[0]
                    ? data?.getManyFormTemplates?.map((form) => ({
                        id: form?.id,
                        label: form?.title,
                      }))
                    : []
                }
                value={formId}
                onChange={(newPipelineId) => {
                  setFormId(undefined);
                  setFormId(newPipelineId);
                }}
                loading={loadingPipelines}
                containerStyle={inputContainerStyles}
              />
              <InlineText>where the question</InlineText>{" "}
              <div>
                <SelectInput
                  placeholder="Select a question"
                  options={
                    questions?.[0]
                      ? questions?.map((question) => ({
                          id: question?.id || "",
                          label: question?.label || "",
                        }))
                      : []
                  }
                  value={questionId}
                  onChange={(newQuestionId) => {
                    setQuestionId(newQuestionId);
                  }}
                  loading={loadingPipelines}
                  containerStyle={{
                    ...inputContainerStyles,
                    width: "100%",
                    marginTop: 24,
                  }}
                />
              </div>
              <InlineText>recieves an answer of</InlineText>{" "}
              <div>
                <SelectInput
                  placeholder="Select an answer"
                  options={
                    question?.options?.[0]
                      ? question?.options?.map((option) => ({
                          id: option?.value || "",
                          label: option?.label || "",
                        }))
                      : []
                  }
                  value={answerId}
                  onChange={(newAnswerId) => {
                    setAnswerId(newAnswerId);
                  }}
                  containerStyle={{
                    ...inputContainerStyles,
                    width: "100%",
                    marginTop: 24,
                  }}
                />
              </div>
              {/* <SelectInput
                placeholder="Select a stage"
                options={
                  stages?.[0]
                    ? stages?.map((stage) => ({
                        id: stage?.id,
                        label: stage?.label,
                      }))
                    : []
                }
                value={changedToStageId}
                onChange={(newStageId) => setChangedToStageId(newStageId)}
                loading={false}
                containerStyle={inputContainerStyles}
              /> */}
            </div>
            <InlineText>
              <TagText>THEN</TagText>Perform these actions:
            </InlineText>{" "}
            <AutomationActionsSelectInput
              placeholder="Add an action"
              actions={ACTIONS_SECTIONS?.[0] ? ACTIONS_SECTIONS : []}
              onChange={(newEvent) => {
                setEvents((oldEvents) => [
                  ...oldEvents,
                  {
                    ...newEvent,
                    params: {
                      formId,
                    },
                  },
                ]);
              }}
              loading={false}
              containerStyle={{
                ...inputContainerStyles,
                width: 400,
              }}
            />
            <div>
              {events?.map((event, index) => {
                return (
                  <EventEditor
                    event={event}
                    key={event.id + index}
                    onParamsChange={(newParams) => {
                      setEvents((oldEvents) =>
                        [...oldEvents]?.map((evt) => {
                          if (evt.id === event.id) {
                            return {
                              ...evt,
                              params: {
                                ...evt.params,
                                ...newParams,
                              },
                            };
                          } else {
                            return evt;
                          }
                        })
                      );
                    }}
                    onRemove={() => {
                      setEvents((oldEvents) =>
                        [...oldEvents]?.filter((evt) => evt.type !== event.type)
                      );
                    }}
                  />
                );
              })}
            </div>
          </Col>
        </Row>
      </Card>
      <BtnContainer>
        <Button
          disabled={disabled || loading}
          loading={loading}
          onClick={onHandleSubmit}
        >
          Create Rule
        </Button>
      </BtnContainer>
    </PageContainer>
  );
}
