// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import caseFragment from 'ApolloClient/Fragments/caseFragment';

export default gql`
  query csvDownload_cases {
    csvDownload_cases {
      ...caseFragment
    }
  }
  ${caseFragment}
`;
