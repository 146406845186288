import { CheckCircleTwoTone } from "@ant-design/icons";
import Loading from "components/common/Loading";
import SurveyFormView from "components/common/SurveyFormView";
import Caption from "components/text/Caption";
import Title from "components/text/Title";
import { useGetOneSurveyTemplateNewQuery } from "generated/graphql";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  background: #f3f3f3;
  min-height: 100vh;
`;

const SuccessContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

const SuccessImg = styled.img`
  height: 150px;
  width: auto;
  margin-bottom: 24px;
`;

const SuccessfulSubmission = ({ logo }: { logo?: string }) => {
  return (
    <SuccessContainer>
      <div>
        <div>
          {false ? (
            <SuccessImg src={logo} alt="successful-submission" />
          ) : (
            <CheckCircleTwoTone style={{ fontSize: 40, marginBottom: 8 }} />
          )}
        </div>
        <Title style={{ fontSize: 20, marginBottom: 0 }}>Thank you!</Title>
        <Caption>We appricate your response.</Caption>
      </div>
    </SuccessContainer>
  );
};

export default function PublicSurveyView() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const templateId = queryParams.get("templateId") || "";
  const answeredBy = queryParams.get("answeredBy");
  const referrerId = queryParams.get("referrerId");
  const businessId = queryParams.get("businessId");
  const surveyCampaignId = queryParams.get("surveyCampaignId");

  const surveyCampaignItemId = queryParams.get("surveyCampaignItemId");
  const mode = queryParams.get("mode");

  const { data, loading } = useGetOneSurveyTemplateNewQuery({
    variables: { templateId: templateId || "" },
  });

  if (mode === "success")
    return (
      <SuccessfulSubmission
        logo={data?.getOneSurveyTemplateNew?.template?.logo}
      />
    );

  if (loading) return <Loading />;

  return (
    <Container>
      <SurveyFormView
        businessId={businessId}
        referrerId={referrerId}
        surveyCampaignId={surveyCampaignId}
        templateId={templateId}
        template={data?.getOneSurveyTemplateNew.template}
        surveyCampaignItemId={surveyCampaignItemId}
        answeredBy={answeredBy}
      />
    </Container>
  );
}
