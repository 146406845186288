// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  fragment userFragment on UserProfile {
    id
    __typename
    email
    firstName
    lastName
    customerId
    avatar {
      id
      url
    }
    roles
    permission
  }
`;
