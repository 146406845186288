export default [
  {
    label: 'Venture Capital',
    value: 'venture',
  },
  {
    label: 'Angel Group',
    value: 'angel',
  },
  {
    label: 'Incubator',
    value: 'incubator',
  },
  {
    label: 'Accelerator',
    value: 'accelerator',
  },
  {
    label: 'CDFI',
    value: 'cdfi',
  },
  {
    label: 'SBA',
    value: 'sba',
  },
  {
    label: 'Chamber of Commerce',
    value: 'chamber',
  },
  {
    label: 'SCORE',
    value: 'score',
  },
  {
    label: 'University',
    value: 'university',
  },
  {
    label: 'Other',
    value: 'other',
  },
];
