import React from "react";
import { get } from "lodash";
// APOLLO
import RESOURCE_BY_ID from "ApolloClient/Queries/resourceById";
import SAVE_PROGRAM from "ApolloClient/Mutations/saveProgram";
import PROGRAMS from "ApolloClient/Queries/programs";
import { useQuery, useMutation } from "@apollo/client";
// COMPONENTS
import Loading from "components/common/Loading";
import message from "components/common/message";
import ProgramForm from "../../../../../components/forms/ProgramForm";

export default function EditSite({ programId, onClose }) {
  const { data, loading } = useQuery(RESOURCE_BY_ID, {
    variables: {
      id: programId,
    },
  });

  const [saveProgram, { loading: saving }] = useMutation(SAVE_PROGRAM);

  const program = get(data, "resourceById");

  if (loading) return <Loading />;

  const onSave = async (values) => {
    try {
      await saveProgram({
        variables: {
          id: programId,
          params: {
            ...values,
          },
        },
        refetchQueries: [
          {
            query: PROGRAMS,
          },
        ],
      });
      message.success(`Your changes were saved`);
      onClose();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <ProgramForm
        defaultValue={program}
        loading={saving}
        onSubmit={onSave}
        onCancel={onClose}
      />
    </>
  );
}
