import { useQueryParam, NumberParam } from "use-query-params";

export const TableSkipParam = "TableSkipParam";
export const TableLimitParam = "TableLimitParam";
export const TableCurrentPageParam = "TableCurrentPageParam";

const TableSkipParamDefaultValue = 0;
const TableLimitParamDefaultValue = 100;
const TableCurrentPageParamDefaultValue = 1;

export default function usePaginationQueryParams() {
  const [skip, setSkip] = useQueryParam(TableSkipParam, NumberParam);
  const [limit, setLimit] = useQueryParam(TableLimitParam, NumberParam);
  const [currentPage, setCurrentPage] = useQueryParam(
    TableCurrentPageParam,
    NumberParam
  );

  const resetTableParamsToDefault = () => {
    if (skip !== TableSkipParamDefaultValue) {
      setSkip(TableSkipParamDefaultValue);
    }
    if (limit !== TableLimitParamDefaultValue) {
      setLimit(TableLimitParamDefaultValue);
    }
    if (currentPage !== TableCurrentPageParamDefaultValue) {
      setCurrentPage(TableCurrentPageParamDefaultValue);
    }
  };

  const onTablePaginationChange = (page: number, pageSize: number) => {
    setLimit(pageSize);
    setSkip(page === 1 ? 0 : (page - 1) * pageSize);
    setCurrentPage(page);
  };

  return {
    skip: skip || TableSkipParamDefaultValue,
    limit: limit || TableLimitParamDefaultValue,
    setSkip,
    setLimit,
    currentPage: currentPage || TableCurrentPageParamDefaultValue,
    setCurrentPage,
    resetTableParamsToDefault,
    onTablePaginationChange,
  };
}
