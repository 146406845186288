const businessStages = [
  {
    label: 'Idea Stage',
    value: 'idea',
    description: `Are you just starting to think about building a business?`
  },
  {
    label: 'Startup Stage',
    value: 'startup',
    description: `Are you already started? Maybe you're a new business or pre-revenue?`
  },
  {
    label: 'Existing Stage',
    value: 'existing',
    description: 'Do you have a stable business with existing income?'
  }
];

export default businessStages;
