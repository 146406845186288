// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import currentUserFragment from "../Fragments/currentUserFragment";

export default gql`
  query currentUser {
    currentUser {
      ...currentUserFragment
    }
  }
  ${currentUserFragment}
`;
