// TOP LEVEL IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
// COMPONENTS
import TextInput from 'components/inputs/TextInput';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import Button from 'components/common/Button';
import FormItem from 'components/common/FormItem';
import SectionBreak from 'components/common/SectionBreak';
import DateInput from 'components/inputs/DateInput';
import EventTypeSelect from 'components/inputs/EventTypeSelect';
import TextAreaInput from 'components/inputs/TextAreaInput';
import OrganizationsAutocomplete from 'components/inputs/OrganizationsAutocomplete';
import BusinessStagesMultiSelect from 'components/inputs/BusinessStagesMultiSelect';
import LocationAutocomplete from 'components/inputs/LocationAutocomplete';

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

const OrganizationCardContainer = styled(Row)`
  border-radius: 5px;
  border: 1px solid ${p => p.theme.colors.neutral9};
  height: 70px;
  width: 400px;
  padding: 8px;
`;

const OrganizationCard = ({organization, onRemove}) => (
  <OrganizationCardContainer>
    <Col xs={12}>{organization.title}</Col>
    <Col xs={6} />
    <Col xs={6}>
      <Button style={{width: 85}} secondary onClick={onRemove}>
        remove
      </Button>
    </Col>
  </OrganizationCardContainer>
);

class EventForm extends React.PureComponent {
  state = {
    title: this.props.title || null,
    description: this.props.description || null,
    type: this.props.type || null,
    organization: this.props.organization || null,
    date: this.props.date || moment(),
    businessStages: this.props.businessStages || [],
    lat: this.props.lat || null,
    lng: this.props.lng || null,
    placeId: this.props.placeId || null,
    fullAddress: this.props.fullAddress || null,
    street: this.props.street || null,
    postal: this.props.postal || null,
    country: this.props.country || null,
    state: this.props.state || null,
    city: this.props.city || null,
  };

  getValues = () => {
    return {
      title: this.state.title || null,
      description: this.state.description || null,
      type: this.state.type || null,
      date: this.state.date.valueOf().toString() || null,
      businessStages: this.state.businessStages || [],
      // location
      lat: (this.state.lat && this.state.lat.toString()) || null,
      lng: (this.state.lng && this.state.lng.toString()) || null,
      placeId: this.state.placeId || null,
      fullAddress: this.state.fullAddress || null,
      street: this.state.street || null,
      postal: this.state.postal || null,
      country: this.state.country || null,
      state: this.state.state || null,
      city: this.state.city || null,
      organizationId:
        (this.state.organization && this.state.organization.id) || null,
    };
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.props.loading) return null;
    // do some logic to verify everything is okay

    // create the product object to pass up to parent
    let values = this.getValues();
    console.log({values});

    // pass back up to the parent component
    this.props.onSubmit(values);
  };

  onSaveAttacment = async newAttachment => {
    this.setState({images: [...this.state.images, newAttachment]});
  };

  onRemoveAttacment = async url => {
    this.setState({
      images: [...this.state.images.filter(item => item !== url)],
    });
  };
  render() {
    return (
      <FormContainer>
        <SectionBreak
          header="General"
          body="Provide general information about this resource."
        >
          <Row gutter={8}>
            <Col xs={24}>
              <FormItem label="Title">
                <TextInput
                  value={this.state.title}
                  onChange={title => this.setState({title})}
                />
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem label="Description">
                <TextAreaInput
                  rows="4"
                  value={this.state.description}
                  onChange={description => this.setState({description})}
                />
              </FormItem>
            </Col>
          </Row>
        </SectionBreak>
        <SectionBreak header="Additional info" body="Lorem ipsum">
          <Row gutter={8}>
            <Col xs={12}>
              <FormItem label="Date">
                <DateInput
                  value={this.state.date}
                  onChange={date => this.setState({date})}
                />
              </FormItem>
            </Col>{' '}
            <Col xs={12}>
              <FormItem label="What type of event is this?">
                <EventTypeSelect
                  value={this.state.type}
                  onChange={type => this.setState({type})}
                />
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem label="Location">
                {this.state.lat && this.state.lng ? (
                  <div>{this.state.fullAddress}</div>
                ) : (
                  <LocationAutocomplete
                    onSuggestSelect={value => this.setState({...value})}
                  />
                )}
              </FormItem>{' '}
            </Col>
            <Col xs={24}>
              <FormItem label="What business stages is this event for?">
                <BusinessStagesMultiSelect
                  value={this.state.businessStages}
                  onChange={businessStages => this.setState({businessStages})}
                />
              </FormItem>
            </Col>
            <Col xs={24}>
              <FormItem label="What organization is handling this?">
                {this.state.organization ? (
                  <OrganizationCard
                    organization={this.state.organization}
                    onRemove={() => this.setState({organization: null})}
                  />
                ) : (
                  <OrganizationsAutocomplete
                    value={this.state.organization}
                    onChange={organization => this.setState({organization})}
                  />
                )}
              </FormItem>
            </Col>
          </Row>
        </SectionBreak>
        <div>
          <div
            style={{
              width: 270,
              marginTop: 32,
              marginLeft: 'auto',
            }}
          >
            <Button
              style={{
                width: 100,
                marginRight: 8,
              }}
              grey
              disabled={this.props.loading}
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>{' '}
            <Button
              style={{
                width: 150,
              }}
              disabled={this.props.loading}
              onClick={this.onSubmit}
            >
              {!this.props.loading ? 'Save Event' : '...'}
            </Button>
          </div>
        </div>
      </FormContainer>
    );
  }
}

EventForm.propTypes = {
  mode: PropTypes.string, // either 'add' or 'save'... determines if we show intermediate "save buttons" in each form section
};

EventForm.defaultProps = {
  mode: 'add',
  onCancel: () => {},
  onSubmit: () => {},
};

export default EventForm;
