import { get } from "lodash";
import styled from "styled-components";
// COMPONENTS
import { ActivityFeedContent } from "components/detail-components/ActivityFeed";
import Button from "components/common/Button";
import { useOrganizationAcitvitiesQuery } from "generated/graphql";

const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.neutral9};
  border-radius: 5px;
  background: #fff;
  /* height: 500px;
  width: 400px;
  overflow-y: scroll; */
  width: 100%;
  padding: 16px;
  position: relative;
`;

export default function OrgActivity() {
  const variables = {
    limit: 10,
    skip: 0,
  };
  const { data, loading, networkStatus, error, fetchMore } =
    useOrganizationAcitvitiesQuery({
      variables,
    });
  if (loading) return null;
  if (error) return null;

  const items = get(data, "organizationAcitvities.result");
  const count = get(data, "organizationAcitvities.count");

  const handleFetchMore = () => {
    fetchMore({
      variables: {
        ...variables,
        skip: data?.organizationAcitvities?.result?.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        return {
          ...prev,
          organizationAcitvities: {
            ...prev.organizationAcitvities,
            result: [
              ...(prev?.organizationAcitvities?.result || []),
              ...(fetchMoreResult?.organizationAcitvities?.result || []),
            ],
          },
        };
      },
    });
  };

  return (
    <>
      {/* <SectionHeader>Organization Activity</SectionHeader> */}
      <Container>
        <ActivityFeedContent activityItems={items} linkToParent />
        <div
          style={{ marginBottom: 16, display: "flex", alignItems: "center" }}
        >
          {count > items.length && (
            <Button
              style={{ margin: "auto" }}
              loading={loading || networkStatus === 3}
              onClick={handleFetchMore}
            >
              Load More
            </Button>
          )}
        </div>
      </Container>
    </>
  );
}
