// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import projectTypeFragment from 'ApolloClient/Fragments/projectTypeFragment';

export default gql`
  query projectTypesByCustomer {
    projectTypesByCustomer {
      ...projectTypeFragment
    }
  }
  ${projectTypeFragment}
`;
