import { useState } from "react";
import moment from "moment";
import styled from "styled-components";
// COMPONENTS
import Row from "components/common/Row";
import Col from "components/common/Col";
import Avatar from "components/common/Avatar";
import Popconfirm from "components/common/Popconfirm";
import CommentForm from "../CommentForm";
import message from "components/common/message";
// APOLLO
import { useDeleteCommentMutation } from "generated/graphql";

const Container = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
`;

const Name = styled.span`
  font-weight: 600;
  color: ${(p) => p.theme.colors.neutral4};
`;

const Content = styled.div`
  font-weight: 500;
  color: ${(p) => p.theme.colors.neutral1};
`;

const CreatedAt = styled.span`
  font-weight: 400;

  color: ${(p) => p.theme.colors.neutral6};
`;

const BtnText = styled.span`
  color: ${(p) => p.theme.colors.neutral6};
  margin-right: 16px;
  display: inline-block;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.neutral5};
  }
`;

export default function Comment({ onSave, comment, caseId, currentUser }) {
  const [editing, setEditing] = useState(false);
  const [deleteComment] = useDeleteCommentMutation();

  const onSubmit = (values) => {
    setEditing(false);
    onSave(values, comment.id);
  };

  const onDelete = async () => {
    try {
      await deleteComment({
        variables: {
          id: comment.id,
        },
        refetchQueries: ["comments"],
        update: () => message.success("Your comment was deleted"),
      });
    } catch (err) {
      console.log(err);
    }
  };

  if (editing) {
    return (
      <CommentForm
        onSubmit={onSubmit}
        comment={comment}
        currentUser={currentUser}
        onCancel={() => setEditing(false)}
      />
    );
  }

  return (
    <Container>
      <Row justify="start" align="top">
        <Col xs={3} sm={2}>
          <Avatar src={comment?.createdByUser?.avatar?.url} />
        </Col>
        <Col xs={21} sm={22}>
          <Name data-testid="comment-name">
            {comment?.createdByUser?.firstName}{" "}
            {comment?.createdByUser?.lastName?.[0]}.
          </Name>{" "}
          <CreatedAt>
            {moment(parseInt(comment.createdAt)).format("MMM D, YYYY h:mm A")}
            {comment.updatedAt &&
              comment.createdAt !== comment.updatedAt &&
              " (Edited)"}
          </CreatedAt>
        </Col>
        <Col xs={3} sm={2} />
        <Col xs={21} sm={22}>
          <Content data-testid="comment-content">{comment.content}</Content>
          <div style={{ marginTop: 8 }}>
            {" "}
            <BtnText onClick={() => setEditing(true)}>Edit</BtnText>{" "}
            <Popconfirm
              okText="Yes"
              onConfirm={onDelete}
              title="Are you sure you want to delete this comment?"
            >
              <BtnText data-testid="comment-delete-btn">Delete</BtnText>
            </Popconfirm>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
