// import styled from "styled-components";
// import filterIcon from "lib/media/filter-icon.svg";
// import { useState } from "react";
// import Title from "components/text/Title";
// import {
//   ButtonArea,
//   ModalBody,
//   Modal,
//   ModalOverlay,
// } from "routes/app-survey-create/NewView2/DefaultQuestionsModal";
// import Button from "components/common/Button";
// import { Checkbox } from "antd";

// const Text = styled.div`
//   color: ${(p) => p.theme.colors.neutral4};
//   font-size: 16px;
//   font-weight: 600;
// `;

// const Btn = styled.button`
//   background: ${(p) => p.theme.colors.neutral9};
//   width: 130px;
//   height: 40px;
//   border-radius: 5px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   border: 0px;
//   transition: background-color 0.3s ease;
//   &:hover {
//     cursor: pointer;
//     background: ${(p) => p.theme.colors.neutral8};
//   }
//   &:focus {
//     outline: 0;
//   }
// `;

// interface ColumnSelectorModalProps {
//   text?: string;
//   onChange: (args: { field: string; label: string }[]) => void;
//   selectedColumns: { field: string; label: string }[];
//   options: { field: string; label: string }[];
//   buttonStyle?: React.CSSProperties;
// }

// export default function ColumnSelectorModal({
//   options,
//   selectedColumns,
//   onChange,
//   text = "Table",
//   buttonStyle,
// }: ColumnSelectorModalProps) {
//   const [open, setOpen] = useState<boolean>(false);
//   const onCloseModal = () => {
//     setOpen(false);
//   };
//   const [selected, setSelected] = useState(selectedColumns);
//   return (
//     <>
//       <Btn
//         onClick={() => setOpen(true)}
//         style={buttonStyle}
//         data-testid="filters-button"
//       >
//         <img src={filterIcon} alt="icon" style={{ marginRight: 6 }} />
//         <Text>{text}</Text>
//       </Btn>
//       {open && (
//         <ModalOverlay>
//           <Modal>
//             <ModalBody style={{ minHeight: 320 }}>
//               <Title>Select columns to display in your table</Title>
//               {options?.map((item) => {
//                 return (
//                   <div key={item.label} style={{ marginTop: 12 }}>
//                     <Checkbox
//                       checked={
//                         selected?.find((i) => i.field === item?.field)?.field
//                           ? true
//                           : false
//                       }
//                     >
//                       {item.label}
//                     </Checkbox>
//                   </div>
//                 );
//               })}
//             </ModalBody>
//             <ButtonArea>
//               <Button grey onClick={() => onCloseModal()}>
//                 Cancel
//               </Button>
//               <Button>Save changes</Button>
//             </ButtonArea>
//           </Modal>
//         </ModalOverlay>
//       )}
//     </>
//   );
// }
import React, { useState } from "react";
import styled from "styled-components";
import filterIcon from "lib/media/filter-icon.svg";
import Title from "components/text/Title";
import {
  ButtonArea,
  ModalBody,
  Modal,
  ModalOverlay,
} from "routes/app-survey-create/NewView2/DefaultQuestionsModal";
import Button from "components/common/Button";
import { Checkbox } from "antd";
import { TableOutlined } from "@ant-design/icons";
import theme from "lib/theme";

const Text = styled.div`
  color: ${(p) => p.theme.colors.neutral4};
  font-size: 16px;
  font-weight: 600;
`;

const Btn = styled.button`
  background: ${(p) => p.theme.colors.neutral9};
  width: 130px;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  transition: background-color 0.3s ease;
  &:hover {
    cursor: pointer;
    background: ${(p) => p.theme.colors.neutral8};
  }
  &:focus {
    outline: 0;
  }
`;

interface ColumnSelectorModalProps {
  text?: string;
  onChange: (args: { field: string; label: string }[]) => void;
  selectedColumns: { field: string; label: string }[];
  options: { field: string; label: string }[];
  buttonStyle?: React.CSSProperties;
}

export default function ColumnSelectorModal({
  options,
  selectedColumns,
  onChange,
  text = "Columns",
  buttonStyle,
}: ColumnSelectorModalProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [selected, setSelected] =
    useState<{ field: string; label: string }[]>(selectedColumns);

  const onCloseModal = () => {
    setOpen(false);
  };

  const handleSelect = (item: { field: string; label: string }) => {
    const isSelected = selected.find((i) => i.field === item.field);
    if (isSelected) {
      setSelected(selected.filter((i) => i.field !== item.field));
    } else {
      setSelected([...selected, item]);
    }
  };

  const handleSaveChanges = () => {
    onChange(selected);
    onCloseModal();
  };

  return (
    <>
      <Btn
        onClick={() => setOpen(true)}
        style={buttonStyle}
        data-testid="filters-button"
      >
        <TableOutlined
          style={{ marginRight: 6, color: theme.colors.neutral4 }}
        />
        <Text>{text}</Text>
      </Btn>
      {open && (
        <ModalOverlay>
          <Modal>
            <ModalBody>
              <Title>Select columns to display in your table</Title>
              <div
                style={{
                  overflowY: "scroll",
                  height: 400,
                  border: "1px solid lightgrey",
                  padding: 8,
                  marginTop: 16,
                }}
              >
                {options?.map((item) => (
                  <div key={item.label} style={{ marginTop: 12 }}>
                    <Checkbox
                      checked={selected.some((i) => i.field === item.field)}
                      onChange={() => handleSelect(item)}
                    >
                      {item.label}
                    </Checkbox>
                  </div>
                ))}
              </div>
            </ModalBody>
            <ButtonArea>
              <Button grey onClick={onCloseModal}>
                Cancel
              </Button>
              <Button onClick={handleSaveChanges}>Save changes</Button>
            </ButtonArea>
          </Modal>
        </ModalOverlay>
      )}
    </>
  );
}
