import React from 'react';
import styled from 'styled-components';
import Select from 'antd/lib/select';
import Row from 'components/common/Row';
import priorities from 'lib/constants/priorities';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

const Option = Select.Option;

const Text = styled.span`
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: -0.154375px;
  color: ${(p) => p.color};
`;
// EXPORTED COMPONENT
// ================================================================
const PrioritySelect = (props) => {
  const {onChange, value = ''} = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        size="large"
        onChange={onChange}
        value={value || undefined}
        optionLabelProp="label"
      >
        {priorities.map(({value, label, color, backgroundColor}) => (
          <Option key={value} value={value} label={label}>
            <Row
              justify="start"
              align="middle"
              style={{marginBottom: 2, padding: 5}}
            >
              <div
                style={{
                  width: 10,
                  height: 10,
                  borderRadius: 5,
                  background: backgroundColor,
                  marginRight: 10,
                }}
              />
              <Text color={color}> {label} </Text>
            </Row>
          </Option>
        ))}
      </Select>
    </DropdownStyleWrapper>
  );
};

PrioritySelect.defaultProps = {
  style: {
    width: '100%',
  },
};

// EXPORT
// ================================================================
export default PrioritySelect;
