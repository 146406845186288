// TOP LEVEL IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// COMPONENTS
import TextInput from 'components/inputs/TextInput';
import Button from 'components/common/Button';
import FormItem from 'components/common/FormItem';
import SectionBreak from 'components/common/SectionBreak';
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import ImageInput from 'components/inputs/ImageInput';

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

class UserForm extends React.PureComponent {
  state = {
    firstName: this.props.firstName || null,
    lastName: this.props.lastName || null,
    email: this.props.email || null,
    title: this.props.title || null,
    avatar: this.props.avatar || null,
  };
  componentWillReceiveProps(newProps) {
    if (
      newProps.avatar &&
      newProps.avatar.url &&
      this.state.avatar &&
      newProps.avatar.url !== this.state.avatar.url
    ) {
      this.setState({
        avatar: newProps.avatar,
      });
    }
  }
  getValues = () => {
    return {
      firstName: this.state.firstName || null,
      lastName: this.state.lastName || null,
      title: this.state.title || null,
      email: this.state.email || null,
    };
  };
  onSubmit = (e) => {
    e.preventDefault();

    if (this.props.loading) return null;
    // do some logic to verify everything is okay

    // create the product object to pass up to parent
    let values = this.getValues();

    // pass back up to the parent component
    this.props.onSubmit(values);
  };
  onAvatarChange = (avatar) => {
    this.props.onSubmit({
      avatar,
    });
  };
  render() {
    return (
      <>
        <SectionBreak
          header="General"
          body="Provide general information about this venue."
        >
          <Row gutter={20}>
            <Col xs={24} md={12}>
              <ImageInput
                onChange={this.onAvatarChange}
                value={this.state.avatar}
                label="Upload an Avatar"
              />
            </Col>{' '}
            <Col xs={24} md={12} />
            <Col xs={24} md={12}>
              <FormItem label="First Name">
                <TextInput
                  value={this.state.firstName}
                  onChange={(firstName) => this.setState({firstName})}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem label="Last Name">
                <TextInput
                  value={this.state.lastName}
                  onChange={(lastName) => this.setState({lastName})}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={12}>
              <FormItem label="Email">
                <TextInput
                  value={this.state.email}
                  onChange={(email) => this.setState({email})}
                />
              </FormItem>
            </Col>
            {/* <Col xs={12}>
              <FormItem label="Roles">
                <RolesInput
                  value={this.state.roles}
                  onChange={roles => this.setState({ roles })}
                />
              </FormItem>
            </Col> */}
            <Col xs={24} md={12}>
              <FormItem label="Title">
                <TextInput
                  value={this.state.title}
                  onChange={(title) => this.setState({title})}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={12} />
          </Row>
        </SectionBreak>
        <div>
          <div
            style={{
              width: 170,
              marginLeft: 'auto',
            }}
          >
            <FormItem>
              <Button disabled={this.props.loading} onClick={this.onSubmit}>
                {!this.props.loading ? 'Save User' : '...'}
              </Button>
            </FormItem>
          </div>
        </div>
      </>
    );
  }
}

UserForm.propTypes = {
  mode: PropTypes.string, // either 'add' or 'save'... determines if we show intermediate "save buttons" in each form section
};

UserForm.defaultProps = {
  mode: 'add',
};

export default UserForm;
