import * as React from 'react';

// See example: https://www.figma.com/file/MkthAGPBHnhWJOL858tPoY/Greenside-Ecommerce?node-id=1299%3A1411

export default function CheckCircleFilled(props) {
  return (
    <svg
      width={15}
      height={15}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 15a7.5 7.5 0 100-15 7.5 7.5 0 000 15zm3.475-8.712A.937.937 0 009.65 4.962L6.562 8.05 5.35 6.837a.937.937 0 00-1.325 1.326L5.9 10.038a.937.937 0 001.325 0l3.75-3.75z"
        fill={props.fill || '#27AB83'}
      />
    </svg>
  );
}
