import {
  CurrentUserFragmentFragment,
  useCurrentUserQuery,
} from "generated/graphql";

export default function useCurrentUser():
  | CurrentUserFragmentFragment
  | undefined {
  const { data, loading } = useCurrentUserQuery({
    fetchPolicy: "cache-first",
  });
  return !loading && data?.currentUser ? data?.currentUser : undefined;
}
