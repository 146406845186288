// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import organizationFragment from "ApolloClient/Fragments/organizationFragment";

export default gql`
  query organizations(
    $searchText: String
    $includesAtleastOrganizationIds: [String!]
    $organiationIds: [String!]
    $skip: Int
    $limit: Int
  ) {
    organizations(
      searchText: $searchText
      includesAtleastOrganizationIds: $includesAtleastOrganizationIds
      organiationIds: $organiationIds
      skip: $skip
      limit: $limit
    ) {
      count
      organizations {
        ...organizationFragment
      }
    }
  }
  ${organizationFragment}
`;
