import React from "react";
import moment from "moment";
import styled from "styled-components";
import constants from "lib/constants";

export const TodoContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  width: 670px;
  margin: auto;
  margin-top: 16px;
  height: 72px;
  align-items: center;
  cursor: pointer;
  &:hover {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    height: 120px;
  }
`;

export const getPriorityLabel = (priority) => {
  return constants.priorities.filter((item) => item.value === priority)[0]
    .label;
};

export const TodoText = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  letter-spacing: 1px;
  align-items: center;
  color: ${(p) => (p.done ? p.theme.colors.neutral6 : p.theme.colors.neutral3)};
  cursor: pointer;
  text-decoration: ${(p) => (p.done ? "line-through" : "none")};
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
`;

export const Col = styled.div`
  margin-right: 8px;
`;

const DueTomorrow = styled.div`
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0px;
  color: ${(p) => p.theme.colors.yellow3};
`;

const DueToday = styled(DueTomorrow)`
  color: ${(p) => p.theme.colors.red4};
`;

const DueInXDays = styled(DueTomorrow)`
  color: ${(p) => p.theme.colors.neutral3};
`;

export const getDueIn = (dueDate) => {
  if (!dueDate) return <DueInXDays>No due date</DueInXDays>;
  const a = moment();
  const b = moment(parseInt(dueDate));
  const diffInDays = a.diff(b, "days"); // 1 day

  if (a.format("MM/DD/YY") === b.format("MM/DD/YY")) {
    return <DueToday>Due Today</DueToday>;
  }
  if (diffInDays === -1 || diffInDays === 0) {
    return <DueTomorrow>Due Tomorrow</DueTomorrow>;
  }
  // if not past the due date yet
  if (diffInDays < 0) {
    return <DueInXDays>{`Due in ${Math.abs(diffInDays)} Days`}</DueInXDays>;
  }
  // if passed the due date, show this
  return (
    <DueToday>{`Due ${Math.abs(diffInDays)} ${
      Math.abs(diffInDays) > 1 ? "Days" : "Day"
    } Ago`}</DueToday>
  );
};

const TagContainer = styled.div`
  padding: 2px 6px;
  background: ${(p) =>
    p.backgroundColor ? p.backgroundColor : p.theme.colors.neutral10};
  border-radius: 25px;
  display: inline-flex;
  min-width: 75px;
  align-items: center;
  justify-content: center;
`;

const TagText = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  font-weight: 500;
  font-size: 12px;
  color: ${(p) => p.color};
`;

export const ProjectName = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${(p) => p.theme.colors.neutral2};
  display: flex;
  align-items: center;
`;

export const Tag = ({ children, priority }) => {
  let obj = constants.priorities.filter((item) => item.value === priority)[0];
  return (
    <TagContainer backgroundColor={obj.backgroundColor}>
      <TagText color={obj.color}>{children}</TagText>
    </TagContainer>
  );
};

export const AttachmentNumber = styled.span`
  color: ${(p) => p.theme.colors.neutral6};
  font-size: 16px;
`;
