import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";

export default function useUrlChange() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const onUrlChange = (newValues: any, baseUrl?: string | null) => {
    const existing = queryString.parse(searchParams.toString());
    const newUrl = {
      ...existing,
      ...newValues, // object
    };

    // the user of onUrlChange can pass in a baseUrl, or it will default to the current base url
    navigate(
      `${baseUrl || location.pathname}?${queryString.stringify(newUrl)}`
    );
  };

  return {
    onUrlChange,
    query: queryString.parse(searchParams.toString()),
  };
}
