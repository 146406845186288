import { LockOutlined, UsergroupAddOutlined } from "@ant-design/icons";

import styled from "styled-components";

import Text from "components/text/Text";
import Col from "components/common/Col";
import Row from "components/common/Row";

import {
  ObjectPermissionAccessEnum,
  ObjectPermissionFragmentFragment,
  ObjectPermissionSubjectTypeEnum,
  ObjectTypeEnum,
  UpdateArrayActionEnum,
  UserGroupFragmentFragment,
  useGetManyObjectPermissionsQuery,
  useGetManyUserGroupsQuery,
  useUpdateOneObjectsPermissionsMutation,
} from "generated/graphql";
import Loading from "components/common/Loading";
import Avatar from "components/common/Avatar";
import Caption from "components/text/Caption";
import LinkButton from "components/common/LinkButton";
import SelectInput from "components/inputs/SelectInput";
import message from "components/common/message";
import theme from "lib/theme";

const ObjectPermissionsRow = ({
  objectPermission,
  onRemove,
}: {
  objectPermission: ObjectPermissionFragmentFragment;
  onRemove: any;
}) => {
  return (
    <div
      style={{
        padding: 8,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Avatar
          shape="square"
          icon={<UsergroupAddOutlined />}
          style={{ marginRight: 8, marginTop: 4 }}
        />
        <div>
          {" "}
          <Text style={{ fontWeight: 600 }}>
            {objectPermission?.subject?.title}
          </Text>{" "}
          <Caption>{objectPermission?.subject?.users?.length} people</Caption>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <LinkButton
          danger
          onClick={onRemove}
          data-testid="remove-group-permissions-btn"
        >
          Remove
        </LinkButton>
      </div>
    </div>
  );
};

const ObjectPermissionsModalContainer = styled.div`
  width: 500px;
  min-height: 100px;
  max-width: 100%;
`;

export default function ObjectPermissionsManager({
  objectType,
  id,
}: {
  id: string;
  objectType: ObjectTypeEnum;
}) {
  const { data, loading } = useGetManyObjectPermissionsQuery({
    variables: {
      type: objectType,
      id,
    },
  });

  const { data: userGroupsQuery } = useGetManyUserGroupsQuery({
    variables: {
      not: data?.getManyObjectPermissions?.map((perm) => perm?.subject?.id),
      includeCustomerDefault: true,
    },
    skip: loading && !data?.getManyObjectPermissions?.[0],
  });

  const [updateOneObjectsPermissionsMutation] =
    useUpdateOneObjectsPermissionsMutation();

  if (loading)
    return (
      <ObjectPermissionsModalContainer>
        <Loading height={100} />
      </ObjectPermissionsModalContainer>
    );

  const onRemove = async (permission: ObjectPermissionFragmentFragment) => {
    try {
      await updateOneObjectsPermissionsMutation({
        variables: {
          type: objectType,
          id: id,
          action: UpdateArrayActionEnum.Remove,
          permission: {
            subjectId: permission?.subjectId,
          },
        },
        refetchQueries: ["getManyObjectPermissions"],
      });
      message.success(`${permission?.subject?.title} Removed`);
    } catch (err) {
      console.log(err);
    }
  };

  const onAdd = async (group: UserGroupFragmentFragment) => {
    try {
      await updateOneObjectsPermissionsMutation({
        variables: {
          type: objectType,
          id: id,
          action: UpdateArrayActionEnum.Add,
          permission: {
            subjectId: group?.id,
            subjectType: ObjectPermissionSubjectTypeEnum.Group,
            access: [
              ObjectPermissionAccessEnum.Edit,
              ObjectPermissionAccessEnum.View,
            ],
          },
        },
        refetchQueries: ["getManyObjectPermissions"],
      });
      message.success(`${group?.title} Added`);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <ObjectPermissionsModalContainer>
      <Caption style={{ marginBottom: 16 }}>
        <LockOutlined style={{ marginRight: 4 }} />
        Restrict which groups have access to this record
      </Caption>
      {data?.getManyObjectPermissions?.map((permission) => (
        <ObjectPermissionsRow
          key={permission.id}
          objectPermission={permission}
          onRemove={() => onRemove(permission)}
        />
      ))}
      {data?.getManyObjectPermissions?.length === 0 && (
        <Text style={{ fontWeight: 600, marginTop: 16 }}>
          Only the creator and organization admins will be able to see this
          record
        </Text>
      )}
      {userGroupsQuery?.getManyUserGroups?.length !== 0 && (
        <Row gutter={12} style={{ marginTop: 16, marginBottom: 16 }}>
          <Col xs={20}>
            {" "}
            <SelectInput
              data-testid="groups-select-input"
              options={userGroupsQuery?.getManyUserGroups?.map((group) => ({
                id: group?.id || "",
                label: group.title || "",
              }))}
              placeholder="Search for groups to share this record with"
              containerStyle={{
                width: "100%",
                backgroundColor: theme.colors?.neutral11,
              }}
              value={undefined}
              onChange={(groupId) => {
                const groupToAdd = userGroupsQuery?.getManyUserGroups?.find(
                  (group) => group.id === groupId
                );
                groupToAdd && onAdd(groupToAdd);
              }}
            />
          </Col>
          <Col xs={4}>
            {" "}
            {/* <Button
            secondary
            loading={false}
            disabled={false}
            onClick={() => onAdd("add")}
          >
            Add
          </Button> */}
          </Col>
        </Row>
      )}
    </ObjectPermissionsModalContainer>
  );
}
