import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
import Tag from "components/common/Tag";
// COMPONENTS
import TextEditor from "components/inputs/TextEditor";
import Button from "components/common/Button";
import message from "components/common/message";
import Comments from "./Comments";
import AttachmentsList from "./AttachmentsList";
import Popover from "components/common/Popover";
import CaseInput from "components/inputs/CaseInput";
import UserInput from "components/inputs/UserInput";
import BusinessInput from "components/inputs/BusinessInput";
import Avatar from "components/common/Avatar";
import PriorityInput from "components/inputs/PrioritySelect";
import DateInput from "components/inputs/DateInput";
import ProjectInput from "components/inputs/ProjectInput";
import ContactInput from "components/inputs/ContactInputSimple";
import BusinessLogo from "components/common/BusinessLogo";
import { DownOutlined } from "@ant-design/icons";
import AttachBtn from "./AttachBtn";
import uuid from "lib/helpers/uuid";
// LIB
import helpers from "lib/helpers/GeneralHelpers";
import constants from "lib/constants";
import {
  CustomerFeatureSettingKeyEnum,
  useSaveTodoMutation,
} from "generated/graphql";
import SiteInput from "components/inputs/SiteInput";
import CustomTagsInput from "components/inputs/CustomTagsInput";
import CaseTitle from "components/common/CaseDetail/CaseDetailContent/CaseTitle";
import TextEditorArea from "components/common/CaseDetail/CaseDetailContent/TextEditorArea";

const Left = styled.div`
  width: 60%;
  margin-right: 5%;
  max-height: 70vh;
  overflow-y: scroll;
`;

const Right = styled.div`
  width: 35%;
`;

const Container = styled.div`
  display: flex;
  margin-top: 16px;
  position: relative;
`;

const Label = styled.div`
  margin-bottom: 0px;
  margin-top: 16px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.003em;
  color: ${(p) => p.theme.colors.neutral1};
`;

const TodoNumber = styled(Label)`
  color: ${(p) => p.theme.colors.neutral5};
  top: -24px;
  position: absolute;
  margin: 0px;
  font-size: 14px;
  font-weight: 700;
  a {
    font-size: 14px !important;
    position: relative;
    top: -1px;
  }
`;

const Description = styled.div`
  padding: 8px;
  padding-left: 4px;
  font-family: ${(p) => p.theme.fontFamily};
  border-radius: 5px;
  min-height: 100px;
  &:hover {
    cursor: text;
    background: ${(p) => p.theme.colors.neutral10};
  }
`;

function createMarkup(description = "First &middot; Second") {
  return { __html: description };
}

const LineBreak = styled.div`
  height: 1px;
  background: ${(p) => p.theme.colors.neutral8};
  margin-top: 24px;
  margin-bottom: 16px;
`;

const TimeAgo = styled.p`
  margin: 0px;
  margin-top: 8px;
  color: ${(p) => p.theme.colors.neutral4};
  font-size: 13px;
`;

const StatusOptionText = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  color: ${(p) => p.theme.colors.neutral4};
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.neutral1};
  }
`;

const HoverBtn = styled.button<{ complete?: boolean }>`
  border: 0px;
  border-radius: 5px;
  height: 40px;
  text-align: left;
  font-size: 16px;
  min-width: 124px;
  position: relative;
  /* margin-right: 16px; */
  font-weight: 600;
  padding-right: 40px;
  padding-left: 8px;
  font-family: ${(p) => p.theme.fontFamily};
  background: ${(p) => {
    if (p.complete) {
      return p.theme.colors.primary10;
    }
    return p.theme.colors.neutral10;
  }};
  color: ${(p) => {
    if (p.complete) {
      return p.theme.colors.primary1;
    }
    return p.theme.colors.neutral1;
  }};
  &:hover {
    cursor: pointer;
    background: ${(p) => {
      if (p.complete) {
        return p.theme.colors.primary10;
      }
      return p.theme.colors.neutral9;
    }};
  }
  &:focus {
    outline: 0;
  }
`;

const ActiveDot = styled.span<{ active?: boolean }>`
  height: 7px;
  width: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 2px;
  background: ${(p) => (p.active ? p.theme.colors.primary5 : "inherit")};
`;

export const cleanTodoMutationInputParams = (newValues) => {
  // we need to strip the __Typename field otherwise the mutation will yell about it being in the args
  if (newValues.attachments) {
    newValues.attachments = helpers.cleanTypenameFromArray(
      newValues.attachments
    );
  }

  if (newValues.dueDate) {
    newValues.dueDate = newValues.dueDate.valueOf().toString();
  } else {
    newValues.dueDate = moment().valueOf().toString();
  }

  // delete fields we can't pass to the mutation
  delete newValues.id;
  delete newValues.__typename;
  delete newValues.createdAt;
  delete newValues.updatedAt;
  delete newValues.contact;
  delete newValues.business;
  delete newValues.project;
  delete newValues.assignedTo;
  delete newValues.case;
  delete newValues.key;
  delete newValues.site;
  delete newValues.tags;

  if (newValues.attachments) {
    newValues.attachments = helpers.cleanTypenameFromArray(
      newValues.attachments
    );
  }

  const valuesToSubmit = {
    ...newValues,
    contactId: newValues.contactId || null,
    businessId: newValues.businessId || null,
    assignedToId: newValues.assignedToId || null,
  };

  return valuesToSubmit;
};

const BlockItemContent = styled.div`
  padding: 4px;
  width: 100%;
  border-radius: 5px;
  flex: 1;
  height: 48px;
  display: flex;
  align-items: center;
  &:hover {
    cursor: text;
    background: ${(p) => p.theme.colors.neutral10};
  }
`;

const BlockItem = ({ label, children, editable, editing, setEditing }) => {
  return (
    <div style={{ marginTop: 0, display: "flex", alignItems: "center" }}>
      <div style={{ width: "30%", maxWidth: "100%" }}>
        <Label style={{ margin: 0 }}>{label}</Label>
      </div>
      <div style={{ width: "70%", maxWidth: "100%" }}>
        {!editing ? (
          <BlockItemContent id="todo-block-item" onClick={setEditing}>
            {children}
          </BlockItemContent>
        ) : (
          editable
        )}
      </div>
    </div>
  );
};

const PriorityBlock = ({ onClick, priority }) => {
  if (!priority) return null;
  return (
    <div onClick={onClick}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: 8 }}>
          <div
            style={{
              height: 10,
              width: 10,
              borderRadius: "50%",
              background: constants.priorities.filter(
                (item) => item.value === priority
              )[0].color,
            }}
          />
        </div>
        <div>
          {
            constants.priorities.filter((item) => item.value === priority)[0]
              .label
          }
        </div>
      </div>
    </div>
  );
};

const None = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral5};
  font-weight: 500;
`;

export const TodoStatusInput = ({ todo, onSave }) => {
  return (
    <Popover
      placement="bottom"
      data-testid={`block-item-input-TodoStatusInput`}
      content={
        <div>
          <StatusOptionText
            onClick={() => onSave({ ...todo, done: false })}
            data-testid={`block-item-input-Todo`}
          >
            <ActiveDot active={!todo.done} />
            Todo
          </StatusOptionText>
          <StatusOptionText
            onClick={() => onSave({ ...todo, done: true })}
            data-testid={`block-item-input-Complete`}
          >
            <ActiveDot active={todo.done} />
            Complete
          </StatusOptionText>
        </div>
      }
    >
      <HoverBtn complete={todo.done} data-testid={`block-item-input-HoverBtn`}>
        {todo.done ? "Complete" : "Todo"}
        <DownOutlined
          style={{
            position: "absolute",
            right: 12,
            fontSize: 10,
            top: 16,
          }}
        />
      </HoverBtn>
    </Popover>
  );
};

export default function TodoDetailContent({
  defaultTodo,
  currentUser,
  rerunQueries = [],
}) {
  const [todo, setTodo] = useState(defaultTodo || {});
  const [editingDescription, setEditingDescription] = useState(false);
  const [description, setDescription] = useState<any>(undefined); // will hold the "editing" desciption text which deson't get "saved" until they hit save at which point it gets updated in the local todo state.
  const [saveTodo, { loading: saving }] = useSaveTodoMutation();
  const [editing, setEditing] = useState<string | false>(false);
  const [randomId] = useState(uuid());

  const onSave = async (newValues) => {
    try {
      await saveTodo({
        variables: {
          id: todo.id,
          params: cleanTodoMutationInputParams(newValues),
        },
        optimisticResponse: {
          __typename: "Mutation",
          saveTodo: {
            __typename: "Todo",
            id: todo.id,
            ...defaultTodo,
            ...cleanTodoMutationInputParams(newValues),
          },
        },
        refetchQueries: [...rerunQueries],
        update: async (proxy, { data }: any) => {
          // let current: any = await proxy.readFragment({
          //   fragment: todoFragment,
          //   id: `Todo:${todo.id}`,
          //   fragmentName: "todoFragment",
          // });
          // proxy.writeFragment({
          //   fragment: todoFragment,
          //   id: `Todo:${todo.id}`,
          //   fragmentName: "todoFragment",
          //   data: {
          //     ...current,
          //     ...data.saveTodo,
          //   },
          // });
          setTodo(data.saveTodo);
        },
      });
      message.success("Your changes were saved!");
      setEditingDescription(false);
      setDescription(undefined); // reset the description
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Left>
        <TodoNumber>
          {todo.case && (
            <>
              <Link to={`/app/cases?selectedCase=${todo.case.id}`}>
                {todo.case.title}
              </Link>{" "}
              /{" "}
            </>
          )}
          {todo.key}
        </TodoNumber>
        <CaseTitle
          data-testid="todo-detail-title"
          title={todo.title}
          onSave={(title) => {
            if (title !== todo?.title) {
              onSave({ ...todo, title });
            }
          }}
        />
        <AttachBtn
          onChange={(attachment) =>
            onSave({ ...todo, attachments: [...todo.attachments, attachment] })
          }
        />

        <TextEditorArea
          value={description || todo.description}
          loading={saving}
          label="Description"
          textInputId={`textarea-description-todo=${todo?.id || randomId}`}
          onSubmit={(newValue) => {
            onSave({
              ...todo,
              description: newValue,
            });
          }}
        />
        <AttachmentsList
          attachments={todo.attachments}
          onSave={({ attachments }) =>
            onSave({
              ...todo,
              attachments,
            })
          }
        />
        <Comments todoId={todo.id} currentUser={currentUser} />
      </Left>{" "}
      <Right>
        <TodoStatusInput todo={todo} onSave={onSave} />
        <div style={{ marginTop: 40 }}>
          <BlockItem
            label="Priority"
            editing={editing === "Priority"}
            setEditing={() => setEditing("Priority")}
            editable={
              <PriorityInput
                value={todo.priority}
                onBlur={() => setEditing(false)}
                onChange={(priority) => {
                  onSave({ ...todo, priority });
                  setEditing(false);
                }}
              />
            }
          >
            <PriorityBlock
              onClick={() => setEditing("Priority")}
              priority={todo.priority}
            />
          </BlockItem>
          <BlockItem
            label="Due"
            editing={editing === "Due"}
            setEditing={() => setEditing("Due")}
            editable={
              <DateInput
                value={todo.dueDate}
                showTime={false}
                format="M/D/YY"
                onChange={(dueDate) => {
                  onSave({ ...todo, dueDate });
                  setEditing(false);
                }}
              />
            }
          >
            {todo.dueDate ? (
              <>
                {" "}
                {moment(parseInt(todo.dueDate)).fromNow()} (
                {moment(parseInt(todo.dueDate)).format("M/D/YY")})
              </>
            ) : (
              <None>None</None>
            )}
          </BlockItem>
          <BlockItem
            label="Assignee"
            editing={editing === "Assignee"}
            setEditing={() => setEditing("Assignee")}
            editable={
              <UserInput
                value={todo.assignedToId}
                defaultUserId={todo?.assignedToId}
                data-testid={`block-item-input-assignedToId`}
                onBlur={() => setEditing(false)}
                onChange={(assignedToId) => {
                  onSave({ ...todo, assignedToId });
                  setEditing(false);
                }}
              />
            }
          >
            {todo.assignedTo ? (
              <>
                <Avatar
                  src={
                    todo.assignedTo &&
                    todo.assignedTo.avatar &&
                    todo.assignedTo.avatar.url
                  }
                  style={{ marginRight: 8 }}
                />
                {todo.assignedTo.firstName} {todo.assignedTo.lastName}
              </>
            ) : (
              <None>None</None>
            )}
          </BlockItem>

          {helpers.featureIsOn({
            customer: currentUser?.customer,
            feature: CustomerFeatureSettingKeyEnum.Cases,
          }) && (
            <BlockItem
              label="Case"
              editing={editing === "CASE"}
              setEditing={() => setEditing("CASE")}
              editable={
                <CaseInput
                  value={todo.caseId}
                  defaultCaseId={todo.caseId}
                  onChange={(caseId) => {
                    onSave({ ...todo, caseId });
                    setEditing(false);
                  }}
                />
              }
            >
              {todo.case ? todo.case.title : <None>None</None>}
            </BlockItem>
          )}
          <BlockItem
            label="Business"
            editing={editing === "Business"}
            setEditing={() => setEditing("Business")}
            editable={
              <BusinessInput
                value={todo.businessId}
                defaultBusinessId={todo.businessId}
                onChange={(businessId) => {
                  onSave({ ...todo, businessId });
                  setEditing(false);
                }}
              />
            }
          >
            {todo.business ? (
              <>
                {todo.business.logo && (
                  <BusinessLogo
                    src={todo.business.logo.url}
                    alt={todo.business.logo.filename}
                    width="32"
                    style={{ marginRight: 4 }}
                  />
                )}
                {todo.business.title}
              </>
            ) : (
              <None>None</None>
            )}
          </BlockItem>

          <BlockItem
            label="Contact"
            editing={editing === "Contact"}
            setEditing={(e) => {
              console.log(e.target);
              if (e.target.id === "todo-block-item") {
                return setEditing("Contact");
              }
            }}
            editable={
              <ContactInput
                value={todo.contactId}
                defaultContactId={todo.contactId}
                onChange={(contactId) => {
                  onSave({ ...todo, contactId });
                  setEditing(false);
                }}
              />
            }
          >
            {todo.contact ? (
              <>
                <Avatar
                  src={
                    todo.contact &&
                    todo.contact.avatar &&
                    todo.contact.avatar.url
                  }
                  style={{ marginRight: 8 }}
                />
                <Link target="_blank" to={`/app/contacts/${todo.contact.id}`}>
                  {todo.contact.firstName} {todo.contact.lastName}
                </Link>
              </>
            ) : (
              <None>None</None>
            )}
          </BlockItem>

          {helpers.featureIsOn({
            customer: currentUser?.customer,
            feature: CustomerFeatureSettingKeyEnum.Projects,
          }) && (
            <BlockItem
              label="Project"
              editing={editing === "Project"}
              setEditing={() => setEditing("Project")}
              editable={
                <ProjectInput
                  value={todo.projectId}
                  defaultProjectId={todo.projectId}
                  onChange={(projectId) => {
                    onSave({ ...todo, projectId });
                    setEditing(false);
                  }}
                />
              }
            >
              {todo.project ? todo.project.title : <None>None</None>}
            </BlockItem>
          )}

          {helpers.featureIsOn({
            customer: currentUser?.customer,
            feature: CustomerFeatureSettingKeyEnum.Resources,
          }) && (
            <BlockItem
              label="Site"
              editing={editing === "Site"}
              setEditing={() => setEditing("Site")}
              editable={
                <SiteInput
                  value={todo.siteId}
                  defaultSiteId={todo.siteId}
                  onChange={(siteId) => {
                    onSave({ ...todo, siteId });
                    setEditing(false);
                  }}
                />
              }
            >
              {todo.site ? <>{todo.site.title}</> : <None>None</None>}
            </BlockItem>
          )}
          <BlockItem
            label="Tags"
            editing={editing === "Tags" ? true : false}
            setEditing={() => setEditing("Tags")}
            editable={
              <CustomTagsInput
                value={todo.tagIds}
                onChange={(tagIds) => {
                  onSave({ ...todo, tagIds });
                  setEditing(false);
                }}
              />
            }
          >
            {todo.tags && todo.tags.length > 0 ? (
              <>
                {todo.tags.map((item) => (
                  <Tag key={item.id} style={{ marginBottom: 4 }} grey>
                    {item.title}
                  </Tag>
                ))}
              </>
            ) : (
              <None>None</None>
            )}
          </BlockItem>
        </div>
        <LineBreak />
        <TimeAgo>Created {moment(parseInt(todo.createdAt)).fromNow()}</TimeAgo>
        <TimeAgo>Updated {moment(parseInt(todo.updatedAt)).fromNow()}</TimeAgo>
      </Right>
    </Container>
  );
}
