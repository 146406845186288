const siteTypes = [
  {
    label: 'Land',
    value: 'land',
  },
  {
    label: 'Building',
    value: 'building',
  },
];

export default siteTypes;
