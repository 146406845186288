import styled, { keyframes } from "styled-components";
// COMPONENTS
import Loading from "components/common/Loading";
import message from "components/common/message";
import EmptyState from "components/common/EmptyState";
import Modal from "components/common/Modal";
import CaseDetailContent from "./CaseDetailContent";
import {
  CloseOutlined,
  DeleteOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
// HOOKs
import useUrlChange from "hooks/useUrlChange";
import useClipboard from "lib/hooks/useClipboard";
import { fadeInUp } from "react-animations";
import { useCaseByIdQuery, useDeleteCaseMutation } from "generated/graphql";
import { useWindowWidth } from "@react-hook/window-size";
import theme from "lib/theme";
import CaseDetailContentMobile from "./CaseDetailContentMobile";
import Drawer from "components/common/Drawer";

const animation = keyframes`${fadeInUp}`;

const { confirm } = Modal;

const Overlay = styled.div`
  background: rgba(9, 30, 66, 0.54);
  position: fixed;
  bottom: 0px;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 64px;
`;

const ModalContent = styled.div`
  padding: 32px;
  padding-top: 16px;
  border-radius: 5px;
  width: 1200px;
  max-width: 100%;
  min-height: 200px;
  background: #fff;
  position: relative;
  animation: 0.75s ${animation};
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    max-width: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    z-index: 900;
    padding: 16px;
  }
`;

const CloseBtn = styled(CloseOutlined)`
  font-size: 22px;
  color: ${(p) => p.theme.colors.neutral5};
`;

const ShareBtn = styled(ShareAltOutlined)`
  font-size: 22px;
  color: ${(p) => p.theme.colors.neutral5};
`;

const DeleteBtn = styled(DeleteOutlined)`
  font-size: 22px;
  color: ${(p) => p.theme.colors.neutral6};
`;

const IconBtn = styled.button`
  height: 32px;
  width: 32px;
  padding: 0px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0px;
  background: transparent;
  &:hover {
    background: ${(p) => p.theme.colors.neutral10};
  }
  &:focus {
    background: ${(p) => p.theme.colors.primary10};
    outline: 0;
  }
`;

const ActionBar = styled.div`
  height: 32px;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default function CaseDetail({ selectedCase, currentUser }) {
  const { onUrlChange } = useUrlChange();
  const [copyToClipboard] = useClipboard();
  const windowWidth = useWindowWidth();
  const [deleteCase, { loading: deleting }] = useDeleteCaseMutation();
  const { data, loading, error } = useCaseByIdQuery({
    variables: {
      id: selectedCase,
    },
    skip: !selectedCase,
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });

  if (!selectedCase) return null;

  const onClose = () => {
    onUrlChange({ selectedCase: undefined });
  };

  const onDelete = async () => {
    try {
      if (deleting || !selectedCase) return;
      await deleteCase({
        variables: {
          id: selectedCase,
        },
      });
      onUrlChange({ selectedCase: undefined });
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  // we render a completely different application on mobile
  if (windowWidth <= parseInt(theme.breakpoints.medium)) {
    return (
      <ModalContent>
        {" "}
        {loading && <Loading />}
        {error && (
          <EmptyState
            title="Something went wrong..."
            subtitle="If this continues please contact support"
          />
        )}
        <Drawer
          open={!loading && !error && data?.caseById ? true : false}
          placement="bottom"
          onClose={() => console.log(false)}
          height="100%"
          headerStyle={{ display: "none" }}
        >
          {data?.caseById && (
            <div>
              <ActionBar>
                <IconBtn
                  onClick={() => {
                    confirm({
                      className: "growlab-confirm-modal",
                      title: "Are you sure you want to delete this?",
                      content:
                        "This is permanent and will include deleting attachments and comments",
                      onOk: onDelete,
                      okText: `Yes, I'm sure`,
                    });
                  }}
                  style={{ marginRight: 24 }}
                >
                  <DeleteBtn />
                </IconBtn>
                <IconBtn
                  onClick={() => {
                    copyToClipboard(window.location.href);
                    message.success("Link copied to your clipboard");
                  }}
                  style={{ marginRight: 24 }}
                >
                  <ShareBtn />
                </IconBtn>
                <IconBtn onClick={onClose}>
                  <CloseBtn />
                </IconBtn>
              </ActionBar>
              <CaseDetailContentMobile
                currentUser={currentUser}
                defaultCase={data.caseById}
              />
            </div>
          )}
        </Drawer>
        {/* {!loading && !error && data?.caseById && (
          <div>
            <ActionBar>
              <IconBtn
                onClick={() => {
                  confirm({
                    className: "growlab-confirm-modal",
                    title: "Are you sure you want to delete this?",
                    content:
                      "This is permanent and will include deleting attachments and comments",
                    onOk: onDelete,
                    okText: `Yes, I'm sure`,
                  });
                }}
                style={{ marginRight: 24 }}
              >
                <DeleteBtn />
              </IconBtn>
              <IconBtn
                onClick={() => {
                  copyToClipboard(window.location.href);
                  message.success("Link copied to your clipboard");
                }}
                style={{ marginRight: 24 }}
              >
                <ShareBtn />
              </IconBtn>
              <IconBtn onClick={onClose}>
                <CloseBtn />
              </IconBtn>
            </ActionBar>
            <CaseDetailContentMobile
              currentUser={currentUser}
              defaultCase={data.caseById}
            />
          </div>
        )} */}
      </ModalContent>
    );
  }

  return (
    <>
      <Overlay
        id="case-modal-overlay"
        onClick={(e: any) => {
          if (e?.target?.id === "case-modal-overlay") {
            onClose();
          }
        }}
      >
        <ModalContent>
          {loading && <Loading />}
          {error && (
            <EmptyState
              title="Something went wrong..."
              subtitle="If this continues please contact support"
            />
          )}
          {!loading && !error && data?.caseById && (
            <div>
              <ActionBar>
                <IconBtn
                  onClick={() => {
                    confirm({
                      className: "growlab-confirm-modal",
                      title: "Are you sure you want to delete this?",
                      content:
                        "This is permanent and will include deleting attachments and comments",
                      onOk: onDelete,
                      okText: `Yes, I'm sure`,
                    });
                  }}
                  style={{ marginRight: 24 }}
                >
                  <DeleteBtn />
                </IconBtn>
                <IconBtn
                  onClick={() => {
                    copyToClipboard(window.location.href);
                    message.success("Link copied to your clipboard");
                  }}
                  style={{ marginRight: 24 }}
                >
                  <ShareBtn />
                </IconBtn>
                <IconBtn onClick={onClose}>
                  <CloseBtn />
                </IconBtn>
              </ActionBar>
              <CaseDetailContent
                currentUser={currentUser}
                defaultCase={data.caseById}
              />
            </div>
          )}
        </ModalContent>
      </Overlay>
    </>
  );
}
