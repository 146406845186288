// TOP LEVEL IMPORTS
import React from "react";
import styled from "styled-components";
import DocumentTitle from "react-document-title";
// COMPONENTS
import PageContainer from "components/common/PageContainer";
import SectionBreak from "components/common/SectionBreak";
import PageHeader from "components/text/PageHeader";
import message from "components/common/message";
import UserForm from "components/forms/UserForm";
import ChangePasswordForm from "components/forms/ChangePasswordForm";
// lib
import constants from "lib/constants";
import helpers from "lib/helpers/GeneralHelpers";
// APOLLO
import SAVE_USER from "ApolloClient/Mutations/saveUser";
import { useMutation } from "@apollo/client";

const Container = styled.div`
  background: #fff;
  padding: 24px;
  border-radius: ${(p) => p.theme.borderRadius}px;
`;

const Label = styled.label`
  display: block;
  font-weight: 600;
  color: ${(p) => p.theme.colors.neutral4};
`;

const OptionLabel = styled.p`
  display: block;
  font-weight: 500;
  color: ${(p) => p.theme.colors.neutral2};
  font-size: 16px;
  line-height: 1.2;
  margin: 0px;
`;

const mapNotificationTypeToLabel = (notificationType) => {
  if (notificationType === "SURVEY_SUBMITTED") {
    return "When a business submits a survey";
  }
  if (notificationType === "TODO_COMMENTED_ON") {
    return `When somebody comments on a todo I'm assigned`;
  }
  if (notificationType === "CASE_ASSIGNED_TO") {
    return `When a case is assigned to me`;
  }
  return notificationType;
};

const NotificationOptionContainer = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
  display: flex;
  width: 100%;
  padding: 16px;
`;

const NotificationOption = ({
  notificationType,
  values = { email: false },
  onChange,
}) => {
  return (
    <NotificationOptionContainer>
      <div style={{ width: 220, marginRight: 32 }}>
        <OptionLabel>
          {mapNotificationTypeToLabel(notificationType)}
        </OptionLabel>
      </div>
      <div style={{ marginRight: 56 }}>
        <Label>Email Me</Label>
        <input
          type="checkbox"
          checked={values?.email}
          onChange={(e) => onChange({ ...values, email: e.target.checked })}
        />
      </div>
      {/* <div>
        <Label>SMS/Text</Label>
        <input
          type="checkbox"
          checked={values.sms}
          onChange={(e) => onChange({...values, sms: e.target.checked})}
        />
      </div> */}
    </NotificationOptionContainer>
  );
};

export default function AppProfile({ currentUser }) {
  const [saveUser, { loading }] = useMutation(SAVE_USER);
  const onUserSave = async (userData) => {
    try {
      await saveUser({
        variables: {
          id: currentUser.id,
          params: userData,
        },
      });

      message.success("Profile saved!");
    } catch (err) {
      console.log({ err });
    }
  };

  const onUpdateSetting = (key, newValues) => {
    let newSettings: any[] = [];

    currentUser.notificationSettings.forEach((setting) => {
      if (setting.notificationType === key) {
        newSettings.push({
          notificationType: key,
          ...newValues,
        });
      } else {
        newSettings.push(setting);
      }
    });

    console.log({ key, newValues });
    onUserSave({
      notificationSettings: helpers.cleanTypenameFromArray(newSettings),
    });
  };

  return (
    <DocumentTitle title={`${constants.appName} | Profile`}>
      <PageContainer>
        <Container>
          <PageHeader>Account Settings</PageHeader>
          <UserForm {...currentUser} onSubmit={onUserSave} loading={loading} />
          {/* {currentUser.notificationSettings && (
            <SectionBreak
              header="Notifications"
              body="Decide what actions you would like to trigger a notification"
            >
              {currentUser.notificationSettings.map((setting) => {
                return (
                  <NotificationOption
                    key={setting.notificationType}
                    onChange={(values) => {
                      onUpdateSetting(setting.notificationType, values);
                    }}
                    notificationType={setting.notificationType}
                    values={setting}
                  />
                );
              })}
            </SectionBreak>
          )} */}
          <SectionBreak
            header="Security"
            body="Make changes to your account here."
          >
            <ChangePasswordForm />
          </SectionBreak>
        </Container>
      </PageContainer>
    </DocumentTitle>
  );
}
