import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import mailSVG from "./mail.svg";
import phoneSVG from "./phone.svg";
import pencilSVG from "./pencil.svg";
import checkSVG from "./check.svg";
import meetingSVG from "./meeting.svg";
// LIB
import formTypes from "lib/constants/formTypes";
import useUrlChange from "hooks/useUrlChange";
// ICONs
import ClipboardFilled from "components/icons/ClipboardFilled";

const BtnsContainer = styled.div`
  display: flex;
  padding-top: 32px;
  padding-left: 40px;
  justify-content: flex-end;
`;

const BtnContainer = styled.div`
  margin-right: 8px;
  text-align: center;
  width: 50px;
  max-width: 100%;
`;

const Btn = styled.button`
  border-radius: 50%;
  height: 32px;
  width: 32px;
  transition:
    color 0.3s ease,
    background-color 0.3s ease,
    border-color 0.3s ease,
    width 0.3s ease,
    opacity 0.3s ease;
  border: 1px solid ${(p) => p.theme.colors.neutral7};
  background: ${(p) => p.theme.colors.neutral9};
  cursor: pointer;
  &:hover {
    border: 1px solid ${(p) => p.theme.colors.neutral8};
    background: ${(p) => p.theme.colors.neutral10};
  }
  &:focus {
    outline: 0;
  }
`;

const Label = styled.label`
  font-size: 12px;
  margin-top: 4px;
  display: block;
`;

const ActionBtn = (props) => {
  const { label, icon, onClick } = props;
  return (
    <BtnContainer>
      <Btn onClick={onClick} data-testid={props["data-testid"]}>
        {React.isValidElement(icon) && icon}
        {icon && typeof icon === "string" && (
          <img style={{ width: 16 }} src={icon} alt={`${label}-icon`} />
        )}
      </Btn>
      <Label>{label}</Label>
    </BtnContainer>
  );
};

export enum ActionButtonTypeEnums {
  Note = "Note",
  Email = "Email",
  Call = "Call",
  Visit = "Visit",
  Survey = "Survey",
  Todo = "Todo",
}

interface ActionButtonsProps {
  project?: any;
  business?: any;
  contact?: any;
  site?: any;
  organization?: any;
  buttonsToShow?: ActionButtonTypeEnums[];
}

const getOtherValues = ({ business, organization, project, contact, site }) => {
  let otherValues: any = {};
  if (business) {
    otherValues.businessId = business.id;
  }

  if (organization) {
    otherValues.organizationId = organization.id;
  }
  if (project) {
    otherValues.projectId = project.id;
  }
  if (contact) {
    otherValues.contactId = contact.id;
  }
  if (site) {
    otherValues.siteId = site.id;
  }
  return otherValues;
};

export default function ActionButtons({
  project,
  business,
  contact,
  organization,
  site,
  buttonsToShow = Object.values(ActionButtonTypeEnums),
}: ActionButtonsProps) {
  const navigate = useNavigate();
  const { onUrlChange } = useUrlChange();
  return (
    <BtnsContainer>
      {buttonsToShow?.includes(ActionButtonTypeEnums.Note) && (
        <ActionBtn
          label="Note"
          data-testid="action-btn-add-note"
          icon={pencilSVG}
          onClick={() => {
            let otherValues = getOtherValues({
              business,
              project,
              contact,
              site,
              organization,
            });
            onUrlChange({
              drawerVisible: formTypes.NOTES,
              ...otherValues,
            });
          }}
        />
      )}

      {buttonsToShow?.includes(ActionButtonTypeEnums.Email) && (
        <ActionBtn
          label="Email"
          data-testid="action-btn-add-email"
          icon={mailSVG}
          onClick={() => {
            let otherValues = getOtherValues({
              business,
              project,
              contact,
              site,
              organization,
            });
            onUrlChange({
              drawerVisible: formTypes.EMAIL,
              ...otherValues,
            });
          }}
        />
      )}

      {buttonsToShow?.includes(ActionButtonTypeEnums.Call) && (
        <ActionBtn
          label="Call"
          data-testid="action-btn-add-call"
          icon={phoneSVG}
          onClick={() => {
            let otherValues = getOtherValues({
              business,
              project,
              contact,
              site,
              organization,
            });
            onUrlChange({
              drawerVisible: formTypes.PHONE,
              ...otherValues,
            });
          }}
        />
      )}

      {buttonsToShow?.includes(ActionButtonTypeEnums.Visit) && (
        <ActionBtn
          label="Visit"
          data-testid="action-btn-add-visit"
          icon={meetingSVG}
          onClick={() => {
            let otherValues = getOtherValues({
              business,
              project,
              contact,
              site,
              organization,
            });
            onUrlChange({
              drawerVisible: formTypes.VISIT,
              ...otherValues,
            });
          }}
        />
      )}

      {buttonsToShow?.includes(ActionButtonTypeEnums.Survey) && (
        <ActionBtn
          label="Survey"
          data-testid="action-btn-add-survey"
          icon={
            <ClipboardFilled
              width={20}
              height={20}
              style={{ position: "relative", top: 3, left: -1 }}
            />
          }
          onClick={() => {
            let url = `/app/survey?`;
            if (business?.id || contact?.businesses?.[0]?.id) {
              url =
                url +
                `businessId=${business?.id || contact?.businesses?.[0]?.id}`;
            }
            if (contact?.id) {
              url = url + `&contactId=${contact.id}`;
            }
            navigate(url);
          }}
        />
      )}
      {buttonsToShow?.includes(ActionButtonTypeEnums.Todo) && (
        <ActionBtn
          label="Todo"
          key="action-btn-add-todo"
          data-testid="action-btn-add-todo"
          icon={checkSVG}
          onClick={() => {
            let otherValues = getOtherValues({
              business,
              project,
              contact,
              site,
              organization,
            });
            onUrlChange({
              drawerVisible: formTypes.TODOS,
              ...otherValues,
            });
          }}
        />
      )}
    </BtnsContainer>
  );
}
