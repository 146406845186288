// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  mutation authenticate($params: AuthenticateParamsInput!) {
    authenticate(serviceName: "password", params: $params) {
      sessionId
      tokens {
        refreshToken
        accessToken
      }
    }
  }
`;
