// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import projectTypeFragment from 'ApolloClient/Fragments/projectTypeFragment';

export default gql`
  mutation saveProjectType($id: ID, $params: ProjectTypeParams) {
    saveProjectType(id: $id, params: $params) {
      ...projectTypeFragment
    }
  }
  ${projectTypeFragment}
`;
