import styled from "styled-components";
import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { CardAvatar } from "components/detail-components";
import { useSearchUsersQuery, useTeamQuery } from "generated/graphql";

const { Option } = Select;

const OptText = styled.div`
  color: ${(p) => p.theme.colors.neutral1};
  font-size: 15px;
  font-weight: 600;
`;

const OptContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral10};
  padding-bottom: 8px;
  padding-top: 8px;
`;

export default function UserInput({
  value,
  placeholder,
  onChange,
  onBlur,
  ...args
}: {
  value?: string;
  placeholder?: string;
  onChange: any;
  defaultUserId?: string;
  onBlur?: any;
}) {
  const { data, loading } = useTeamQuery({
    //variables: {},
    fetchPolicy: "cache-and-network",
  });
  const users = data?.team || [];
  return (
    <DropdownStyleWrapper>
      <Select
        onBlur={onBlur}
        value={!loading && value ? value : undefined}
        placeholder={placeholder}
        style={{ width: "100%" }}
        onChange={onChange}
        optionLabelProp="label"
        allowClear
        data-testid={args?.["data-testid"]}
      >
        {!loading &&
          users?.map((item, index) => {
            if (!item?.id) return null;
            return (
              <Option
                key={item.id}
                label={`${item.firstName} ${item.lastName}`}
                value={item.id}
                data-testid={`user-input-option-${item.firstName} ${item.lastName}`}
              >
                <OptContainer>
                  <div style={{ width: 40 }}>
                    <CardAvatar
                      type="contacts"
                      src={item?.avatar?.url || undefined}
                    />
                  </div>
                  <div>
                    {" "}
                    <OptText>
                      {item.firstName} {item.lastName}
                    </OptText>
                    <span> {item.email}</span>
                  </div>
                </OptContainer>
              </Option>
            );
          })}
      </Select>
    </DropdownStyleWrapper>
  );
}
