import styled from "styled-components";
import { LoadingOutlined, PaperClipOutlined } from "@ant-design/icons";
import useUploadFile from "lib/hooks/useUploadFile";
import { Attachment } from "generated/graphql";

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const UploadLabel = styled.label`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral3};
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.neutral2};
  }
`;

const Container = styled.div`
  background: ${({ theme }) => theme.colors.neutral10};
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  padding: 0px 16px;
  margin-bottom: 16px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.colors.neutral9};
  }
`;

interface AttachBtnProps {
  name?: string;
  onChange: (attachment: Attachment) => void;
  loading?: boolean;
  containerStyle?: React.CSSProperties;
}

export default function AttachBtn({
  name = "upload-input",
  onChange,
  containerStyle,
}: AttachBtnProps) {
  const [uploadFile, uploading] = useUploadFile();

  return (
    <Container style={containerStyle}>
      {uploading && <LoadingOutlined />}
      {!uploading && (
        <>
          <UploadLabel htmlFor={name}>
            <PaperClipOutlined /> Attach
          </UploadLabel>
          <UploadButton
            id={name}
            name={name}
            type="file"
            onChange={async (e) => {
              if (!e?.target?.files?.[0]) return;
              let result = await uploadFile(e?.target?.files?.[0]);
              onChange(result);
            }}
          />
        </>
      )}
    </Container>
  );
}
