import styled from 'styled-components';

const SectionHeader = styled.span`
  height: 24px;
  font-family: ${p => p.theme.fontFamily};
  font-size: 16px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${p => p.theme.colors.neutral4};
`;

SectionHeader.defaultProps = {
  style: {},
  size: null
};

export default SectionHeader;
