// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import organizationFragment from "ApolloClient/Fragments/organizationFragment";

export default gql`
  fragment resourceFragment on Resource {
    id
    title
    description
    businessStages
    type
    clusters
    supportType
    fundingType
    diversityTypes
    tagIds
    organization {
      ...organizationFragment
    }
  }
  ${organizationFragment}
`;
