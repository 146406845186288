import React from "react";
import Select from "antd/lib/select";
import clusters from "lib/constants/clusterTypes";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

const Option = Select.Option;

const ClustersMultiselectInput = (props) => {
  const { onChange, value = "" } = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        size="large"
        mode="multiple"
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
      >
        {clusters.map(({ value, label }) => (
          <Option key={value?.trim()} value={value?.trim()}>
            {label?.trim()}
          </Option>
        ))}
      </Select>
    </DropdownStyleWrapper>
  );
};

ClustersMultiselectInput.defaultProps = {
  style: {
    width: "100%",
  },
  placeholder: "Select all that apply",
};

// EXPORT
// ================================================================
export default ClustersMultiselectInput;
