import React from "react";
import { DeleteOutlined } from "@ant-design/icons";
import Popconfirm from "components/common/Popconfirm";
import styled from "styled-components";
// LIB
import helpers from "lib/helpers/GeneralHelpers";
import { Attachment } from "generated/graphql";

const Label = styled.div`
  width: 118px;
  margin-left: 8px;
  margin-bottom: 8px;
  margin-top: 16px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.28px;
  color: ${(p) => p.theme.colors.neutral2};
`;

const FileItem = styled.div`
  padding: 8px;
  display: flex;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  border-radius: 5px;
  margin-bottom: 8px;
  position: relative;
`;

const FileTitle = styled.a`
  font-size: 14px;
  color: ${(p) => p.theme.colors.neutral4} !important;
`;

const FileType = styled.p`
  margin: 0px;
  color: ${(p) => p.theme.colors.neutral6};
`;

// const IMAGE = {
//   encoding: "7bit",
//   filename:
//     "09D01146D49607DEB26294BC012EACAF2AB7A6808CF7C765A4575170C26E42CC_sk_17_cid_1.jpeg",
//   id: "https://growlab-test.s3.amazonaws.com/09D01146D49607DEB26294BC012EACAF2AB7A6808CF7C765A4575170C26E42CC_sk_17_cid_1.jpeg",
//   key: "09D01146D49607DEB26294BC012EACAF2AB7A6808CF7C765A4575170C26E42CC_sk_17_cid_1.jpeg",
//   mimetype: "image/jpeg",
//   url: "https://growlab-test.s3.amazonaws.com/09D01146D49607DEB26294BC012EACAF2AB7A6808CF7C765A4575170C26E42CC_sk_17_cid_1%20%281%29.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAQCWHC2GT5IJ4DK7U%2F20200529%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20200529T151728Z&X-Amz-Expires=600&X-Amz-Signature=cab89faa08bd8cdc94f8190906126073a95254a3f8cb283dfe05ff495dcb70cb&X-Amz-SignedHeaders=host",
// };

const TRUNCATE = 25;

export default function Attachments({
  attachments,
  onSave,
}: {
  attachments?: Attachment[];
  onSave: any;
}) {
  const onDeleteAttachment = async (value) => {
    let existingAttachments =
      attachments?.[0] && helpers.cleanTypenameFromArray(attachments);
    onSave({
      attachments: existingAttachments.filter((item) => item.id !== value.id),
    });
  };

  return (
    <>
      {attachments?.[0] && <Label>Attachments</Label>}
      {attachments?.[0] &&
        attachments?.map((attachment, i) => {
          if (!attachment) return null;
          return (
            <FileItem key={attachment?.id || attachment?.url}>
              <div>
                <FileTitle target="_blank" href={attachment?.url || ""}>
                  {attachment?.filename &&
                  attachment?.filename?.length > TRUNCATE
                    ? `${attachment?.filename?.substring(0, TRUNCATE)}...`
                    : attachment?.filename}
                </FileTitle>{" "}
                <FileType>{attachment?.mimetype}</FileType>
              </div>
              <div style={{ marginLeft: 16 }}>
                <Popconfirm
                  title="Are you sure you want to delete this attachment?"
                  onConfirm={() => onDeleteAttachment(attachment)}
                >
                  <DeleteOutlined
                    type="delete"
                    style={{
                      fontSize: 16,
                      position: "absolute",
                      right: 8,
                      top: 10,
                      cursor: "pointer",
                    }}
                  />
                </Popconfirm>
              </div>
            </FileItem>
          );
        })}
    </>
  );
}
