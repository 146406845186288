import { useEffect, useState } from "react";
import styled from "styled-components";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import FormItem from "components/common/FormItem";
import DateInput from "components/inputs/DateInput";
import TextEditor from "components/inputs/TextEditor";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Text from "components/text/Text";
import Caption from "components/text/Caption";
import ContactInput from "components/inputs/ContactInputSimple";
import Button from "components/common/Button";
import BusinessInput from "components/inputs/BusinessInput";
import ProjectInput from "components/inputs/ProjectInput";
import { LoadingOutlined } from "@ant-design/icons";
import Attachments from "components/common/Attachments";
import SideFormButtonContainer from "components/common/SideFormButtonContainer";
import moment from "moment";
// HOOKs
import useUploadFile from "lib/hooks/useUploadFile";
// LIB
import helpers from "lib/helpers/GeneralHelpers";
import {
  CustomerFeatureSettingKeyEnum,
  InteractionDirectionEnum,
  InteractionParams,
  useGetManyInteractionCategoriesQuery,
} from "generated/graphql";
import getTextEditorValueLength from "lib/helpers/getTextEditorValueLength";
import SelectInput from "components/inputs/SelectInput";

import theme from "lib/theme";
import OrganizationInput from "components/inputs/OrganizationInput";

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const UploadLabel = styled.label`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary2};
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    color: ${(p) => p.theme.colors.primary1};
  }
`;

export default function InteractionForm({
  defaultValues,
  loading,
  customer,
  onCancel,
  onSubmit,
}: {
  defaultValues: any;
  loading: boolean;
  customer: any;
  onCancel: any;
  onSubmit: (values: InteractionParams) => void;
}) {
  const [errors, setErrors] = useState<{
    title?: string;
    description?: string;
    date?: string;
    direction?: string;
    categoryId?: string;
  }>({});
  const [values, setValues] = useState({
    date: moment()?.valueOf(),
    ...defaultValues,
  });
  const [uploadFile, uploading] = useUploadFile();

  useEffect(() => {
    setValues((oldValues) => ({ ...oldValues, ...defaultValues }));
  }, [defaultValues]);

  const { data: categoryQuery } = useGetManyInteractionCategoriesQuery();

  const onChange = (newValues) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const handleOnSubmit = () => {
    setErrors({});
    if (!values.title) {
      return setErrors({ title: "A title is required" });
    }
    if (!values.description) {
      return setErrors({ description: "A note is required" });
    }
    if (!values.date) {
      return setErrors({ date: "A date of the interaction is required" });
    }

    onSubmit({
      title: values?.title,
      date: values?.date,
      contactId: values?.contactId,
      businessId: values?.businessId,
      organizationId: values?.organizationId,
      projectId: values?.projectId,
      categoryId: values?.categoryId,
      direction: values?.direction,
      siteId: values?.siteId,
      attachments: values?.attachments,
      description: values?.description,
    });
  };

  const disabled =
    !values?.title || getTextEditorValueLength(values?.description) < 1;

  return (
    <div style={{ paddingBottom: "30%" }}>
      <FormItem label="Title" required error={errors.title}>
        <TextInput
          value={values.title}
          placeholder="Title"
          onChange={(title) => onChange({ title })}
          data-testid="interaction-form-title-input"
        />
      </FormItem>
      <Row>
        {" "}
        <Col xs={12}>
          {" "}
          <FormItem label="Date" required error={errors.date}>
            <DateInput
              style={{ marginRight: 16 }}
              value={values.date}
              onChange={(date) =>
                onChange({
                  date,
                })
              }
            />
          </FormItem>
        </Col>
        <Col xs={12}></Col>
      </Row>
      <FormItem label="Notes" required error={errors?.description}>
        <TextEditor
          value={values.description}
          onChange={(description) => onChange({ description })}
        />
      </FormItem>
      <FormItem
        label="Direction"
        tooltip={
          <>
            <Text>What is direction?</Text>
            <Caption>
              {" "}
              Track if this interaction was "inbound" or "outbound"-- i.e. Did
              this business reach out to you (inbound) or did you reach out to
              them (outbound)?{" "}
            </Caption>
            <Text style={{ marginTop: 8 }}>Why would I care?</Text>
            <Caption>
              {" "}
              Tracking if an interaction was inbound vs outbound can be helpful
              if you want to report on how many businesses{" "}
              <em>reached out to you </em>per month vs how many businesses
              <em> you reached out to</em> per month .
            </Caption>
          </>
        }
        error={errors.direction}
      >
        <SelectInput
          placeholder="Inbound or outbound?"
          options={
            Object.values(InteractionDirectionEnum)?.map((dir) => ({
              id: dir,
              valueToShow: helpers.capitalize(dir),
              label: (
                <>
                  <Text>{helpers.capitalize(dir)}</Text>
                  <Caption>
                    {dir === "inbound"
                      ? "They reached out to me"
                      : "I reached out to them"}
                  </Caption>
                </>
              ),
            })) || []
          }
          value={values?.direction}
          optionLabelProp="id"
          containerStyle={{
            backgroundColor: theme.colors?.neutral11,
            width: "100%",
          }}
          onChange={(direction) => onChange({ direction })}
        />
      </FormItem>
      <FormItem
        label="Category"
        tooltip={
          <>
            <Text>What is category?</Text>
            <Caption>
              An optional field for grouping interactions into categories
            </Caption>
            <Text style={{ marginTop: 8 }}>Why would I care?</Text>
            <Caption>
              For instance, you may group by BRE cateogry so you can report on
              how many interactions were for "Retention" vs "Expansion" vs
              "Attraction".
            </Caption>
          </>
        }
        error={errors.categoryId}
        //tooltip=""
      >
        <SelectInput
          placeholder="Category"
          options={
            categoryQuery?.getManyInteractionCategories?.map((item) => {
              return {
                id: item?.id || "",
                label: item?.title || "",
              };
            }) || []
          }
          value={values?.categoryId}
          containerStyle={{
            backgroundColor: theme.colors?.neutral11,
            width: "100%",
          }}
          onChange={(categoryId) => onChange({ categoryId })}
        />
      </FormItem>
      <FormItem label="Business">
        <BusinessInput
          value={values.businessId}
          defaultBusinessId={values.businessId}
          canCreateNew
          onChange={(businessId) => {
            onChange({ businessId });
          }}
        />
      </FormItem>
      <FormItem label="Contact">
        <ContactInput
          value={values.contactId}
          defaultContactId={values.contactId}
          onChange={(contactId) => {
            onChange({ contactId });
          }}
        />
      </FormItem>
      {helpers.featureIsOn({
        customer,
        feature: CustomerFeatureSettingKeyEnum.Projects,
      }) && (
        <FormItem label="Project">
          <ProjectInput
            value={values.projectId}
            defaultProjectId={values.projectId}
            onChange={(projectId) => {
              onChange({ projectId });
            }}
          />
        </FormItem>
      )}{" "}
      {helpers.featureIsOn({
        customer,
        feature: CustomerFeatureSettingKeyEnum.Resources,
      }) && (
        <FormItem label="Organization">
          <OrganizationInput
            value={values.organizationId}
            defaultOrganizationId={values.organizationId}
            canCreateNew
            onChange={(organizationId) => {
              onChange({ organizationId });
            }}
          />
        </FormItem>
      )}{" "}
      <div style={{ marginTop: 24 }}>
        <div>
          <Attachments
            attachments={values.attachments || []}
            onSave={(attachments) => onChange({ attachments })}
          />
        </div>

        <div style={{ paddingTop: 24, paddingBottom: 56 }}>
          {!uploading ? (
            <>
              {" "}
              <UploadLabel htmlFor="file-input">
                + Upload an attachment
              </UploadLabel>
              <UploadButton
                id="file-input"
                name="file-input"
                type="file"
                onChange={async (e) => {
                  try {
                    const file = e?.target?.files?.[0];
                    if (!file) return;
                    const result = await uploadFile(file);
                    onChange({
                      attachments: [...(values.attachments || []), result],
                    });
                  } catch (err) {
                    console.log(err);
                  }
                }}
              />
            </>
          ) : (
            <div style={{ height: 40 }}>
              <LoadingOutlined style={{ marginRight: 8 }} /> Uploading...
            </div>
          )}
        </div>
      </div>
      <SideFormButtonContainer>
        <Button
          disabled={disabled || loading}
          loading={loading}
          onClick={handleOnSubmit}
          style={{ width: 150, marginLeft: 16 }}
          data-testid="interaction-form-save-btn"
        >
          Save
        </Button>
        <Button
          disabled={loading}
          onClick={onCancel}
          grey
          style={{ width: 100, marginLeft: 8 }}
        >
          Cancel
        </Button>
      </SideFormButtonContainer>
    </div>
  );
}
