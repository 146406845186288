// TOP LEVEL IMPORTS
import React from "react";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const getBackgroundColor = ({
  grey,
  disabled,
  secondary,
  theme,
  yellow,
  danger,
}) => {
  if (disabled) {
    return theme.colors.neutral8;
  }
  if (danger) {
    return theme.colors.red5;
  }
  if (grey) {
    return theme.colors.neutral10;
  }
  if (secondary) {
    return theme.colors.primary9;
  }
  if (yellow) {
    return theme.colors.yellow10;
  }

  return theme.colors.primary2;
};

const getTextColor = ({ grey, disabled, yellow, secondary, theme }) => {
  if (disabled) {
    return theme.colors.neutral7;
  }
  if (grey) {
    return theme.colors.neutral4;
  }
  if (secondary) {
    return theme.colors.primary1;
  }
  if (yellow) {
    return theme.colors.yellow1;
  }
  return "#fff";
};

const getHoverBackgroundColor = ({
  grey,
  disabled,
  secondary,
  theme,
  danger,
  yellow,
}) => {
  if (disabled) {
    return theme.colors.neutral8;
  }
  if (danger) {
    return theme.colors.red3;
  }
  if (grey) {
    return theme.colors.neutral9;
  }
  if (secondary) {
    return theme.colors.primary8;
  }
  if (yellow) {
    return theme.colors.yellow9;
  }
  return theme.colors.primary1;
};

const PrimaryButtonContainer = styled.button`
  width: ${(p) => {
    if (p.fullWidth) return "100%";
    if (p?.style?.width) return p.style.width;
    return "auto";
  }};
  height: 40px;
  border-radius: 4px;
  background-color: ${(props) => getBackgroundColor(props)};
  border: 0px;
  line-height: 40px;
  padding: 0px 16px;
  letter-spacing: 0.025em;
  align-items: center;
  /* box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); */
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  :hover {
    cursor: ${(p) => {
      if (p.disabled) {
        return "not-allowed";
      }
      return "pointer";
    }};
    background-color: ${(props) => getHoverBackgroundColor(props)};
  }
  :focus {
    outline: 0;
  }
  &:active {
    transform: translatey(1px);
  }
`;

const ButtonText = styled.div`
  height: 20px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  text-align: center;
  color: ${(props) => getTextColor(props)};
`;

const PrimaryButton = (props) => (
  <PrimaryButtonContainer
    style={props.style}
    disabled={props.disabled}
    fullWidth={props.fullWidth}
    onClick={props.onClick}
    secondary={props.secondary}
    danger={props.danger}
    grey={props.grey}
    yellow={props.yellow}
    aria-label={props["aria-label"] || "Button"}
    data-testid={props["data-testid"] || "Button"}
  >
    <ButtonText
      disabled={props.disabled}
      secondary={props.secondary}
      danger={props.danger}
      grey={props.grey}
      yellow={props.yellow}
    >
      {!props.loading ? props.children : <LoadingOutlined />}
    </ButtonText>
  </PrimaryButtonContainer>
);

PrimaryButton.defaultProps = {
  fullWidth: false,
  disabled: false,
  type: "button",
};

export default PrimaryButton;
// // TOP LEVEL IMPORTS
// import React from 'react';
// import styled from 'styled-components';
// import {LoadingOutlined} from '@ant-design/icons';

// const getBackgroundColor = ({grey, disabled, secondary, theme, danger}) => {
//   if (disabled) {
//     return theme.colors.neutral8;
//   }
//   if (danger) {
//     return theme.colors.red5;
//   }
//   if (grey) {
//     return theme.colors.neutral10;
//   }
//   if (secondary) {
//     return theme.colors.primary10;
//   }

//   return theme.colors.primary2;
// };

// const getTextColor = ({grey, disabled, secondary, theme}) => {
//   if (disabled) {
//     return theme.colors.neutral5;
//   }
//   if (grey) {
//     return theme.colors.neutral4;
//   }
//   if (secondary) {
//     return theme.colors.primary1;
//   }
//   return '#fff';
// };

// const getHoverBackgroundColor = ({
//   grey,
//   disabled,
//   secondary,
//   theme,
//   danger,
// }) => {
//   if (disabled) {
//     return theme.colors.neutral8;
//   }
//   if (danger) {
//     return theme.colors.red3;
//   }
//   if (grey) {
//     return theme.colors.neutral9;
//   }
//   if (secondary) {
//     return theme.colors.primary9;
//   }
//   return theme.colors.primary1;
// };

// const PrimaryButtonContainer = styled.button`
//   width: ${(p) => {
//     if (p.fullWidth) return '100%';
//     if (p.style.width) return p.style.width;
//     return '100%';
//   }};
//   height: 36px;
//   border-radius: 4px;
//   background-color: ${(props) => getBackgroundColor(props)};
//   border: 0px;
//   line-height: 40px;
//   /* text-transform: uppercase; */
//   letter-spacing: 0.025em;
//   align-items: center;
//   /* box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08); */
//   transition: color 0.3s ease, background-color 0.3s ease,
//     border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
//   :hover {
//     cursor: pointer;
//     background-color: ${(props) => getHoverBackgroundColor(props)};
//   }
//   :focus {
//     outline: 0;
//   }
// `;

// const ButtonText = styled.div`
//   height: 14px;
//   font-family: ${(p) => p.theme.fontFamily};
//   font-size: 16px;
//   font-weight: 600;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 16px;
//   letter-spacing: 0.8px;
//   text-align: center;
//   color: ${(props) => getTextColor(props)};
// `;

// const PrimaryButton = (props) => (
//   <PrimaryButtonContainer {...props}>
//     <ButtonText
//       disabled={props.disabled}
//       secondary={props.secondary}
//       danger={props.danger}
//       grey={props.grey}
//     >
//       {props.icon && props.icon}
//       {!props.loading ? props.children : <LoadingOutlined />}
//     </ButtonText>
//   </PrimaryButtonContainer>
// );

// PrimaryButton.defaultProps = {
//   fullWidth: true,
//   disabled: false,
//   type: 'button',
//   style: {
//     minWidth: 162,
//   },
// };

// export default PrimaryButton;
