// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import attachmentFragment from "ApolloClient/Fragments/attachmentFragment";
import contactFragment from "ApolloClient/Fragments/contactFragment";
import projectFragmentShort from "./projectFragmentShort";

export default gql`
  fragment siteFragment on Site {
    id
    title
    description
    type
    size
    lat
    lng
    url
    placeId
    fullAddress
    street
    postal
    country
    archived
    tagIds
    state
    city
    projects {
      ...projectFragmentShort
    }
    attachments {
      ...attachmentFragment
    }
    contactId
    contacts {
      ...contactFragment
    }
    contact {
      ...contactFragment
    }
    extraFields {
      fieldName
      value
      fieldType
    }
  }
  ${attachmentFragment}
  ${contactFragment}
  ${projectFragmentShort}
`;
