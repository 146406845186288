// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  fragment caseFragmentShort on Case {
    id
    title
    createdAt
    caseStatus {
      id
      title
      isFinalStep
    }
    assignedTo {
      id
      firstName
      lastName
      avatar {
        id
        url
      }
    }
    caseOutcomes {
      id
      title
    }
    resolutionNotes
    reporter {
      id
      firstName
      lastName
      avatar {
        id
        url
      }
    }
  }
`;
