import { Popconfirm } from "antd";
import LinkButton from "components/common/LinkButton";
import Table from "components/common/Table";

export default function TemplatesTable({ dataSource, onSelect, onDelete }) {
  const columns = [
    {
      title: "title",
      render: ({ title }) => title,
    },
    {
      title: " ",
      render: ({ _id }) => (
        <>
          <LinkButton onClick={() => onSelect(_id)}>Edit</LinkButton>
        </>
      ),
    },
    {
      title: " ",
      render: ({ _id }) => (
        <Popconfirm
          title="Are you sure you want to delete this template"
          onConfirm={() => onDelete(_id)}
          okText="Yes"
        >
          <LinkButton danger>Delete</LinkButton>
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
}
