import { toString, toNumber } from "lodash";
import {
  BusinessSectorsDocument,
  TagsDocument,
  useSaveBusinessMutation,
} from "generated/graphql";
import message from "components/common/message";
import client from "ApolloClient/index";

const cleanParams = (params) => {
  // params.naicsIds = params.naics ? params.naics.map((item) => item.id) : [];
  delete params.naics;
  delete params.tags;
  delete params.businessSector;
  let cleaned = {
    ...params,
  };
  if (params.annualRevenue) {
    cleaned.annualRevenue = toString(params.annualRevenue);
  }
  if (params.employeeCount) {
    cleaned.employeeCount = toNumber(params.employeeCount);
  }
  return cleaned;
};

export default function useSaveBusiness() {
  const [saveBusiness, { loading }] = useSaveBusinessMutation();

  const onSaveBusiness = async (params: any, currentBusiness?: any) => {
    try {
      if ("title" in params && !params?.title?.[0]) {
        return message.error("A name is required for a business");
      }

      let optimisticResponse: any = {
        __typename: "Mutation",
        saveBusiness: {
          id: currentBusiness?.id,
          __typename: "Business",
          ...currentBusiness,
          ...params,
        },
      };

      if (params.business) {
        optimisticResponse.saveBusiness.logo = {
          ...params.logo,
          __typename: "Attachment",
          id: params.logo.url,
        };
      }

      if (params?.businessSectorId) {
        const sectors = await client.query({ query: BusinessSectorsDocument });
        optimisticResponse.saveBusiness.businessSector = {
          ...sectors?.data?.businessSectors?.find(
            (sect) => sect.id === params?.businessSectorId
          ),
          __typename: "BusinessSector",
          id: params?.businessSectorId,
        };
      }

      if (params?.tagIds) {
        const localTagsQuery = await client.query({ query: TagsDocument });

        optimisticResponse.saveBusiness.tags =
          localTagsQuery?.data?.tags?.tags?.filter((tag) =>
            params?.tagIds?.includes?.(tag.id)
          );
      }

      const res = await saveBusiness({
        variables: {
          id: currentBusiness?.id,
          params: cleanParams(params),
        },
        refetchQueries: ["businessById"],
        optimisticResponse,
      });
      return res;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSaveBusiness,
    loading,
  };
}
