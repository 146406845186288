import styled from "styled-components";

const NavItemContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  font-family: ${(p) => p.theme.fontFamily};
  margin-bottom: 8px;
`;

const NavText = styled.span`
  font-weight: 700;
  color: ${(p) =>
    p.active ? p.theme.colors.primary1 : p.theme.colors.neutral4};
  &:hover {
    cursor: pointer;
    color: ${(p) =>
      p.active ? p.theme.colors.primary1 : p.theme.colors.neutral3};
  }
`;

const SideNavItem = ({ label, icon, active, onChange, testId }) => (
  <NavItemContainer onClick={onChange} data-testid={testId}>
    {icon}
    <NavText active={active}>{label}</NavText>
  </NavItemContainer>
);

export default SideNavItem;
