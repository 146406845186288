import Select from "antd/lib/select";
// APOLLO
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { useTagsQuery } from "generated/graphql";
// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

export default function CustomTagsInput({
  onChange,
  value = "",
  placeholder = "Select one",
  style = {},
}) {
  const { data, loading } = useTagsQuery();
  if (loading) return null;
  return (
    <DropdownStyleWrapper>
      <Select
        placeholder={placeholder}
        style={{ width: "100%", ...style }}
        size="large"
        mode="multiple"
        onChange={onChange}
        value={value || undefined}
        showSearch
        optionFilterProp="children"
        data-testid="custom-tags-input"
      >
        {data?.tags?.tags?.map(({ id, title }) => (
          <Option
            key={id}
            value={id}
            data-testid={`custom-tags-input-option-${title}`}
          >
            {title}
          </Option>
        ))}
      </Select>
    </DropdownStyleWrapper>
  );
}
