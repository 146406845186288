import React from "react";
import { Card } from "../index";
import Skeleton from "components/common/Skeleton";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
// APOLLO
import REPORTS_CASES_RESOLVED_BY_MONTH from "ApolloClient/Queries/reports_InteractionsByMonth";
import { useQuery } from "@apollo/client";
import chartColors from "lib/constants/chartColors";

export default function InteractionsByMonth() {
  const { data, loading } = useQuery(REPORTS_CASES_RESOLVED_BY_MONTH);

  if (loading || !data || !data.reports_InteractionsByMonth) {
    return (
      <Card>
        <Skeleton round />
      </Card>
    );
  }

  let chartData = data.reports_InteractionsByMonth.map((item) => {
    return {
      title: moment(item.title, "M/YYYY").format("MMM YYYY"),
      email: item.email,
      phone: item.phone,
      visit: item.visit,
    };
  });

  return (
    <Card
      title="Interactions by Month"
      empty={!chartData || chartData.length === 0}
      tooltip="See your interactions broken down over time by type (phone calls, visits and emails)"
    >
      <ResponsiveBar
        keys={["email", "phone", "visit"]}
        data={chartData}
        colors={chartColors}
        indexBy="title"
        margin={{ top: 24, right: 0, bottom: 50, left: 0 }}
        padding={0.3}
        theme={{
          fontSize: 12,
        }}
        axisBottom={{
          tickRotation: -45,
        }}
      />
    </Card>
  );
}
