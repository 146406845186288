import {
  SurveyTemplateNew,
  useGetOneSurveyTemplateNewQuery,
  useSaveSurveyResultMutation,
} from "generated/graphql";
import { useCallback, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import Loading from "../Loading";
import { message, notification } from "antd";
import { useNavigate } from "react-router-dom";

export default function SurveyFormView({
  templateId,
  answeredBy,
  businessId,
  referrerId,
  surveyCampaignItemId,
  surveyCampaignId,
  template,
}: {
  templateId: string;
  answeredBy: string | null;
  referrerId: string | null;
  businessId: string | null;
  surveyCampaignId: string | null;
  surveyCampaignItemId: string | null;
  template: SurveyTemplateNew;
}) {
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();
  const survey = new Model(template);
  const [saveSurveyResult] = useSaveSurveyResultMutation();
  const surveyComplete = useCallback(async (sender) => {
    try {
      setSubmitting(true);
      await saveSurveyResult({
        variables: {
          params: {
            stringifiedAnswers: JSON.stringify(sender.data),
            templateId: templateId,
            template,
            answeredBy,
            businessId,
            referrerId,
            surveyCampaignId,
            surveyCampaignItemId,
          },
        },
      });
      setSubmitting(false);
      notification.success({
        message: "We recieved your survey!",
        description:
          "Thanks so much for taking the time to submit your response.",
      });
      navigate(`/app/survey/view?templateId=${templateId}&mode=success`);
    } catch (err: any) {
      setSubmitting(false);
      console.log(err);
      message.error(err?.message);
    }
  }, []);

  survey.onComplete.add(surveyComplete);

  if (submitting) return <Loading />;

  return <Survey model={survey} />;
}
