import { useNavigate } from "react-router-dom";
// import NewProgramForm from './NewProgramForm';
import ResourcesTable from "../ResourceTable";
import columns from "./columns";
import fundingProgramsQuery from "ApolloClient/Queries/fundingPrograms";
import useUrlChange from "hooks/useUrlChange";
import formTypes from "lib/constants/formTypes";

export default function FundingTab() {
  const { onUrlChange } = useUrlChange();
  const navigate = useNavigate();
  const onRow = (record, rowIndex) => {
    return {
      onClick: (e) => {
        let element = e.srcElement || e.target;
        // only cancel if the user clicked the Link to business detail page
        if (element.id === "organization-click") {
          return null;
        }
        navigate(`/app/funding/${record.id}`);
      },
      onDoubleClick: (event) => {}, // double click e
      onContextMenu: (event) => {}, // right button click row
      onMouseEnter: (event) => {}, // mouse enter row
      onMouseLeave: (event) => {}, // mouse leave row
    };
  };
  return (
    <div>
      <ResourcesTable
        columns={columns}
        onRow={onRow}
        pagination={{ pageSize: 5 }}
        filters={<div />}
        query={fundingProgramsQuery}
        variables={{}}
        dataField="fundingPrograms"
        dataName="Funding"
        onNew={() => onUrlChange({ drawerVisible: formTypes.FUNDING })}
      />
    </div>
  );
}
