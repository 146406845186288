import Title from "components/text/Title";
import {
  FormQuestion,
  useGetManyDefaultFormTemplateQuestionsQuery,
} from "generated/graphql";
import { useState } from "react";
import styled from "styled-components";
import { useQueryParam, BooleanParam } from "use-query-params";
import Button from "components/common/Button";
import Text from "components/text/Text";
import CheckboxInput from "components/inputs/CheckboxInput";
import { SURVEY_OPTIONS } from "../NewContent";

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  z-index: 10000;
  height: 100vh;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.45);
  .ant-select-dropdown {
    z-index: 10000 !important;
  }
`;

export const Modal = styled.div`
  background-color: #ffffff;
  max-width: 100%;
  width: 580px;
  border-radius: 5px;
  position: relative;
  max-height: 100vh;
  min-height: 400px;
  border-radius: 5px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    max-width: 100%;
    position: fixed;
    top: 0px;
    bottom: 0px;
  }
`;

export const ModalBody = styled.div`
  padding: 48px 32px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    padding: 32px 16px 112px 16px;
  }
`;

export const ButtonArea = styled.div`
  background: ${({ theme }) => theme.colors.neutral11};
  height: 10%;
  bottom: 0px;
  right: 0px;
  left: 0px;
  height: 80px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: ${({ style }) => style?.justifyContent || "space-between"};
  padding: 8px 32px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    position: fixed;
    padding: 8px;
  }
`;

const QuestionOptionBlockContainer = styled.div<{ selected?: boolean }>`
  padding: 24px 16px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral11};
  background-color: ${({ theme, selected }) => {
    if (selected) return theme.colors.primary10;
    return "#fff";
  }};
  &:hover {
    cursor: pointer;
    background-color: ${({ theme, selected }) => {
      if (selected) return theme.colors.primary10;
      return theme.colors.neutral11;
    }};
  }
`;

const QSquare = styled.div`
  height: 20px;
  width: 20px;
  margin: 4px;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.neutral3};
  font-size: 12px;
  background-color: ${({ theme }) => theme.colors.neutral10};
`;

const QuestionOptionBlock = ({
  question,
  onAddQuestion,
  selected,
}: {
  question: FormQuestion;
  onAddQuestion: any;
  selected: boolean;
}) => {
  const alphabet = Array.from(Array(26))
    .map((e, i) => i + 65)
    .map((x) => String.fromCharCode(x));

  const questionType = SURVEY_OPTIONS?.find(
    ({ id }) => id === question?.questionType
  );

  return (
    <QuestionOptionBlockContainer
      selected={selected}
      onClick={() => onAddQuestion(question)}
    >
      <div style={{ width: 32 }}>
        <CheckboxInput checked={selected} />
      </div>{" "}
      <div>
        <Text>
          <strong>
            {" "}
            <QSquare>Q.</QSquare>
            {question?.label}
          </strong>
        </Text>
        {question?.options?.map((option, index) => {
          return (
            <div style={{ marginLeft: 8 }} key={option.value}>
              {alphabet[index]?.toLowerCase()}. {option?.label}
            </div>
          );
        })}
      </div>
    </QuestionOptionBlockContainer>
  );
};

const QuestionsContainer = styled.div`
  height: 600px;
  max-height: 100%;
  margin-top: 16px;
  overflow: scroll;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.neutral10};
  padding-bottom: 24px;
`;

const CategorySection = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral10};
  padding: 8px 16px;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 600;
`;

export default function DefaultQuestionModal({ onAddQuestion }) {
  const [questionsToAdd, setQuestionsToAdd] = useState<any[]>([]);
  const { data } = useGetManyDefaultFormTemplateQuestionsQuery();
  const [defaultQuestionModal, setDefaultQuestionModal] = useQueryParam(
    "DEFAULT_QUESTIONS_MODAL",
    BooleanParam
  );
  const [_, setShowPopover] = useQueryParam("showPopover", BooleanParam);

  if (!defaultQuestionModal) return null;

  return (
    <ModalOverlay>
      <Modal>
        <ModalBody>
          <Title>Select questions ({questionsToAdd?.length} Selected)</Title>
          <QuestionsContainer>
            {data?.getManyDefaultFormTemplateQuestions?.map((category) => {
              return (
                <>
                  <CategorySection>{category?.id}</CategorySection>
                  {category?.questions?.map((question) => {
                    const selected = questionsToAdd
                      ?.map((q: any) => q.id)
                      ?.includes(question.id)
                      ? true
                      : false;
                    return (
                      <QuestionOptionBlock
                        onAddQuestion={(question) => {
                          if (selected) {
                            setQuestionsToAdd((oldvalues) => [
                              ...oldvalues?.filter((q) => q.id !== question.id),
                            ]);
                          } else {
                            setQuestionsToAdd((oldvalues) => [
                              ...oldvalues,
                              question,
                            ]);
                          }
                        }}
                        key={question?.id}
                        question={question}
                        selected={selected}
                      />
                    );
                  })}
                </>
              );
            })}
          </QuestionsContainer>
        </ModalBody>
        <ButtonArea>
          <Button
            grey
            onClick={() => {
              setQuestionsToAdd([]);
              setDefaultQuestionModal(false);
              setShowPopover(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onAddQuestion(questionsToAdd);
              setQuestionsToAdd([]);
              setDefaultQuestionModal(false);
              setShowPopover(false);
            }}
          >
            Add Questions
          </Button>
        </ButtonArea>
      </Modal>
    </ModalOverlay>
  );
}
