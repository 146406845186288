// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  mutation deleteTodoList($id: ID!) {
    deleteTodoList(id: $id) {
      id
    }
  }
`;
