// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import siteFragment from "ApolloClient/Fragments/siteFragment";

export default gql`
  query sites($searchText: String, $siteIds: [ID], $skip: Int, $limit: Int) {
    sites(
      searchText: $searchText
      siteIds: $siteIds
      skip: $skip
      limit: $limit
    ) {
      count
      sites {
        ...siteFragment
      }
    }
  }
  ${siteFragment}
`;
