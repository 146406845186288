import { useSaveProgramMutation } from "generated/graphql";

const getValues = (fundingProgram) => {
  let shaped = {
    ...fundingProgram,
  };
  return shaped;
};

export default function useSaveSupportProgram() {
  const [saveProgram, { loading }] = useSaveProgramMutation();

  const onSubmitSupportProgram = async (params, onComplete?: any) => {
    try {
      // set loading
      let res = await saveProgram({
        variables: {
          params: getValues(params),
        },
        refetchQueries: ["supportPrograms"],
      });

      onComplete && onComplete();
      return res?.data?.saveProgram;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSubmitSupportProgram,
  };
}
