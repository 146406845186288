import React from 'react';
import styled from 'styled-components';
import { LoadingOutlined } from '@ant-design/icons';

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const UploadLabel = styled.label`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.primary2};
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    color: ${({ theme }) => theme.colors.primary1};
  }
`;

type UploadButtonPops = {
  name?: string;
  label?: string;
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void);
  loading?: boolean;
  containerStyle?: React.CSSProperties;
}

export default function ButtonUploader({
  name = 'upload-input',
  label = '+ Upload an attachment',
  onChange,
  loading,
  containerStyle
}: UploadButtonPops) {
  if (loading) return <LoadingOutlined />;
  return (
    <div style={containerStyle}>
      <UploadLabel htmlFor={name}>{label}</UploadLabel>
      <UploadButton id={name} name={name} type="file" onChange={onChange} />
    </div>
  );
}