import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import ms from "ms";
import { BellOutlined } from "@ant-design/icons";
// LIB
import theme from "lib/theme";
// APOLLO
import { useMyNotificationsQuery } from "generated/graphql";

const Button = styled.button`
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  border-radius: 50%;
  padding: 0px;
  background: transparent;
  border: 0px;
  &:hover {
    background: ${(p) => p.theme.colors.neutral10};
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
`;

const Badge = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.red5};
  color: #fff;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  right: 0px;
`;

export default function NotificationsButton({ currentUser }) {
  const { data } = useMyNotificationsQuery({
    pollInterval: ms("15m"),
    skip: !currentUser?.id,
  });
  const navigate = useNavigate();
  const unreadCount = data?.myNotifications?.unreadCount || 0;
  return (
    <Button>
      <BellOutlined
        onClick={() => navigate(`/app/notifications`)}
        style={{ fontSize: 24, color: theme.colors.neutral6 }}
      />
      {unreadCount > 0 && <Badge>{unreadCount}</Badge>}
    </Button>
  );
}
