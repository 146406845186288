// TOP LEVEL IMPORTS
import React, { useState } from "react";
import styled from "styled-components";
// ANTD
import Layout from "antd/lib/layout";
import "antd/lib/layout/style/css";
import Menu from "antd/lib/menu";
import "antd/lib/menu/style/css";
// LIB
import constants from "lib/constants";
import AuthHelpers from "lib/helpers/AuthHelpers/index";
import ApolloClient from "../../../ApolloClient/index";
import logo from "lib/media/logo2.svg";
import useFullStory from "lib/hooks/useFullStory";
import { useLocation, useNavigate } from "react-router-dom";

// CONSTANTS & DESTRCUTURING
// ====================================
const { Content, Footer, Sider } = Layout;
// STYLED-COMPONENTS
// ====================================
const Logo = styled.img`
  height: 26px;
  display: block;
  margin: 20px auto;
  max-width: 100%;
`;

const LogoContainer = styled.div`
  max-width: 100%;
  padding: 10px;
`;

export default function AdminLayout({ children, currentUser }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [collapsed, setCollaped] = useState();
  useFullStory();

  const onCollapse = (collapsed) => {
    setCollaped(collapsed);
  };

  const onMenuSelect = async ({ item, key, selectedKeys }) => {
    if (key === "logout") {
      await AuthHelpers.handleLogout(currentUser.id);
      await ApolloClient.resetStore();
      return navigate("login");
    }
    navigate(key);
  };

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        collapsible
        theme="light"
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        {" "}
        <LogoContainer>
          <Logo src={logo} />
        </LogoContainer>
        <Menu
          onSelect={onMenuSelect}
          theme="light"
          defaultSelectedKeys={[location?.pathname]}
          selectedKeys={[location?.pathname]}
          mode="inline"
        >
          <Menu.Item key="/admin">
            <span>Dashboard</span>
          </Menu.Item>
          <Menu.Item key="/admin/users">
            <span>Users</span>
          </Menu.Item>
          <Menu.Item key="/admin/customers">
            <span>Customers</span>
          </Menu.Item>
          <Menu.Item key="/admin/settings">
            <span>Settings</span>
          </Menu.Item>
          <Menu.Item key="/admin/defaultSurveyTemplates">
            <span>Default Survey Templates</span>
          </Menu.Item>
          <Menu.Item key="/admin/contact/uploader">
            <span>Contact Uploader</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ margin: "16px" }}>
          <div>{children}</div>
        </Content>
        <Footer style={{ textAlign: "center" }}>
          {constants.appName} ©{new Date().getFullYear()}
        </Footer>
      </Layout>
    </Layout>
  );
}

//Unused linter error

// const IconCircle = styled.div`
//   border-radius: 50%;
//   background: ${p => (p.color ? p.color : p.theme.colors.neutral9)};
//   height: 25px;
//   width: 25px;
//   display: inline-flex;
//   align-items: center;
//   justify-content: center;
//   .anticon {
//     margin-right: 0px !important;
//   }
// `;
