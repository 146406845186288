import styled from "styled-components";
import moment from "moment";
import Avatar from "components/common/Avatar";
import {
  EllipsisOutlined,
  ExclamationCircleOutlined,
  UnlockOutlined,
} from "@ant-design/icons";
import Dropdown from "components/common/Dropdown";
import Menu from "components/common/Menu";
import Modal from "components/common/Modal";
// GRAPQHL
import {
  CurrentUserFragmentFragment,
  NoteFragmentFragment,
  ObjectTypeEnum,
  UserPermissionEnum,
  useDeleteNoteMutation,
} from "generated/graphql";
import useUrlChange from "hooks/useUrlChange";
import FORM_TYPES from "lib/constants/formTypes";
import ObjectPermissionsManager from "components/common/ObjectPermissionsManager";
import Popover from "components/common/Popover";
import { Link } from "react-router-dom";

const { confirm } = Modal;

const Title = styled.h2`
  margin: 0px;
  font-size: 16px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fontFamily};
  color: ${({ theme }) => theme.colors.neutral1};
  margin-top: 16px;
`;

const Description = styled.h3`
  margin: 0px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: ${(p) => p.theme.colors.neutral1};
`;

const Card = styled.div`
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 16px;
  border-radius: 7px;
  margin: auto;
  margin-bottom: 16px;
  background: #fff;
  position: relative;
  width: 600px;
  max-width: 100%;
`;

const Name = styled.h3`
  font-weight: 500;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral1};
  font-size: 14px;
  line-height: 20px;
`;

const CreatedAt = styled.p`
  font-weight: 400;
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral5};
  font-size: 14px;
  line-height: 20px;
`;

function createMarkup(text = "First &middot; Second") {
  return { __html: text };
}

const OptionsArea = styled.div`
  position: absolute;
  right: 16px;
  top: 8px;
  display: flex;
  align-items: center;
`;

const OptionsBtn = styled.button`
  font-size: 22px;
  border: 0px;
  cursor: pointer;
  background: transparent;
  margin-left: 16px;
`;

const TransparentBtn = styled.button`
  background: transparent;
  border: 0px;
  cursor: pointer;
`;

export default function Note({
  note,
  currentUser,
}: {
  note: NoteFragmentFragment;
  currentUser: CurrentUserFragmentFragment;
}) {
  const [deleteNote] = useDeleteNoteMutation();
  const { onUrlChange } = useUrlChange();
  const menu = (
    <Menu>
      {currentUser?.id === note?.createdByUser?.id && (
        <Menu.Item>
          <TransparentBtn
            data-testid="notes-card-options-edit"
            onClick={() => {
              onUrlChange({
                editNoteId: note?.id,
                drawerVisible: FORM_TYPES?.NOTES,
              });
            }}
          >
            Edit Note
          </TransparentBtn>
        </Menu.Item>
      )}

      <Menu.Item danger>
        <TransparentBtn
          data-testid="notes-card-options-delete"
          onClick={() => {
            confirm({
              className: "growlab-confirm-modal",
              title: "Do you want to delete this note?",
              icon: <ExclamationCircleOutlined />,
              content:
                "You will not be able to recover it. Are you sure you want to delete this? ",
              async onOk() {
                try {
                  if (note?.id) {
                    await deleteNote({
                      variables: {
                        id: note.id,
                      },
                      refetchQueries: ["notes"],
                    });
                  }
                } catch (err) {
                  console.log(err);
                }
              },
              okText: "Yes I'm sure.",
              onCancel() {
                console.log("Cancel");
              },
            });
          }}
        >
          Delete
        </TransparentBtn>
      </Menu.Item>
    </Menu>
  );

  return (
    <Card data-testid={`note-card-${note.title}`}>
      <OptionsArea>
        {currentUser?.permission?.includes(
          UserPermissionEnum.Administrator
        ) && (
          <Popover
            destroyTooltipOnHide
            content={
              <ObjectPermissionsManager
                objectType={ObjectTypeEnum?.Note}
                id={note?.id}
              />
            }
            placement="bottom"
          >
            <TransparentBtn
              onClick={() => console.log("permissions")}
              data-testid="sharing-group-permissions-btn"
            >
              Share
            </TransparentBtn>
          </Popover>
        )}

        <OptionsBtn data-testid="notes-card-options">
          <Dropdown overlay={menu}>
            <EllipsisOutlined />
          </Dropdown>
        </OptionsBtn>
      </OptionsArea>

      <div style={{ display: "flex" }}>
        <div style={{ marginRight: 8 }}>
          <Avatar size={40} src={note?.createdByUser?.avatar?.url} />
        </div>
        <div>
          <Name>
            {note?.createdByUser?.firstName} {note?.createdByUser?.lastName}
          </Name>
          {note.createdAt && (
            <CreatedAt>
              {moment(parseInt(note.createdAt)).format("MMMM D")} at{" "}
              {moment(parseInt(note.createdAt)).format("h:mm A")}
            </CreatedAt>
          )}
          {note?.project?.id && (
            <CreatedAt>
              <Link to={`/app/projects/${note?.project?.id}`} target="_blank">
                {note?.project?.title}
              </Link>
            </CreatedAt>
          )}
        </div>
      </div>
      <Title data-testid="note-title">{note.title}</Title>
      <div style={{ marginTop: 8 }}>
        <Description>
          {typeof note?.description === "string" && (
            <div
              data-testid="note-description"
              dangerouslySetInnerHTML={createMarkup(note?.description)}
            />
          )}
        </Description>
      </div>
      {/* <Comments noteId={note.id} currentUser={currentUser} /> */}
    </Card>
  );
}
