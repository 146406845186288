import Select from "antd/lib/select";
import fundingTypes from "lib/constants/fundingTypes";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const FundingTypesSelect = (props) => {
  const { onChange, value = "", mode } = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        size="large"
        mode={mode || null}
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
      >
        {fundingTypes.map(({ value, label }) => (
          <Option key={value} value={value}>
            <span
              style={{
                height: 15,
                width: 15,
                display: "inline-block",
                marginRight: 5,
                borderRadius: "50%",
              }}
            />{" "}
            {label}
          </Option>
        ))}
      </Select>
    </DropdownStyleWrapper>
  );
};

FundingTypesSelect.defaultProps = {
  style: {
    width: "100%",
  },
  placeholder: "Select one",
};

// EXPORT
// ================================================================
export default FundingTypesSelect;
