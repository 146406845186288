import { useState } from "react";
// COMPONENTS
import { Card } from "../index";
import Skeleton from "components/common/Skeleton";
import { ResponsivePie } from "@nivo/pie";
import TopNumberDropdown from "../TopNumberDropdown";
import EmptyData from "../EmptyData";
// APOLLO
import { useReports_BusinessesBySectorQuery } from "generated/graphql";

export default function BusinessesByCluster() {
  const [top, setTop] = useState(10);
  const { data, loading } = useReports_BusinessesBySectorQuery({
    variables: {
      top,
    },
    fetchPolicy: "cache-and-network",
  });

  const title = "Businesses by Sector";

  if (loading) {
    return (
      <Card title={title}>
        <Skeleton round />
      </Card>
    );
  }

  const chartData = data?.reports_BusinessesBySector?.map((item) => ({
    id: item?.title,
    label: item?.title,
    value: item?.numberOfBusinesses,
  }));

  if (!chartData?.[0]) {
    return (
      <Card
        title={title}
        action={
          <TopNumberDropdown value={top} onChange={(top) => setTop(top)} />
        }
      >
        <EmptyData />
      </Card>
    );
  }

  return (
    <Card
      title={title}
      action={<TopNumberDropdown value={top} onChange={(top) => setTop(top)} />}
    >
      <ResponsivePie
        data={chartData}
        colors={{ scheme: "set3" }}
        margin={{ top: 32, right: 88, bottom: 24, left: 88 }}
      />
    </Card>
  );
}
