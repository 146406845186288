import SelectInput from "components/inputs/SelectInput";
import { useTeamQuery } from "generated/graphql";
import theme from "lib/theme";
import Row from "components/common/Row";
import Col from "components/common/Col";
import Text from "components/text/Text";
import {
  RemoveActionBtn,
  ActionContainer,
  LeftCol,
  Rightcol,
} from "../../shared";
import TextAreaInput from "components/inputs/TextAreaInput";
import { NewTodoInlineForm } from "components/common/CaseDetail/CaseDetailContent/CaseTodos";
import { useState } from "react";
import styled from "styled-components";
import LinkButton from "components/common/LinkButton";

export default function ActionCreateACase({ values, onRemove, onChange }) {
  const { loading, data } = useTeamQuery();
  const { todos = [] } = values;
  return (
    <ActionContainer>
      <LeftCol style={{ width: "75%" }}>
        <div style={{ width: "100%" }}>
          <Row style={{ marginBottom: 24, width: "100%" }} align="middle">
            <Col xs={8}>Create a new case assigned to</Col>
            <Col xs={16}>
              <SelectInput
                placeholder="Select a team member"
                options={
                  data?.team?.[0]
                    ? data?.team?.map((member) => {
                        return {
                          id: member?.id,
                          label: `${member?.firstName || ""} ${
                            member?.lastName || ""
                          }`,
                        };
                      })
                    : []
                }
                value={undefined}
                loading={loading}
                onChange={(assignedToId) => {
                  onChange({
                    assignedToId,
                  });
                }}
                containerStyle={{
                  maxWidth: "100%",
                  width: 270,
                  display: "inline-flex",
                  height: 40,
                  backgroundColor: theme.colors.neutral11,
                }}
              />
            </Col>{" "}
          </Row>
          <Row style={{ marginBottom: 24, width: "100%" }} align="middle">
            <Col xs={8}>With case description of</Col>
            <Col xs={16}>
              <TextAreaInput
                placeholder="Write a description"
                value={undefined}
                loading={loading}
                onChange={(description) => {
                  onChange({
                    description,
                  });
                }}
              />
            </Col>
          </Row>
          <Row style={{ marginBottom: 24, width: "100%" }} align="top">
            <Col xs={8}>With these subtasks (optional)</Col>
            <Col xs={16}>
              <TodosList
                todos={todos}
                onSubmit={(newTodoTitle) => {
                  onChange({
                    todos: [...todos, { title: newTodoTitle }],
                  });
                }}
              />
            </Col>
          </Row>
        </div>
      </LeftCol>
      <Rightcol>
        <RemoveActionBtn onClick={onRemove} />
      </Rightcol>
    </ActionContainer>
  );
}

const TodoContainer = styled.div`
  padding: 8px 16px;
  border: 1px solid ${({ theme }) => theme.colors.neutral8};
  border-radius: 5px;
  font-size: 14px;
  margin-bottom: 16px;
  display: flex;
`;

const TodoItem = ({ todo }) => {
  return (
    <TodoContainer>
      <Text>{todo?.title}</Text>
      {/* <XMarkIcon /> */}
    </TodoContainer>
  );
};

const TodosList = ({ todos, onSubmit }) => {
  const [showAddTodo, setShowAddTodo] = useState(false);
  const [value, setValue] = useState<string | undefined>(undefined);
  return (
    <>
      {todos?.map((todo, index) => {
        return <TodoItem key={todo.title + index} todo={todo} />;
      })}
      {!showAddTodo && (
        <LinkButton onClick={() => setShowAddTodo(true)}>+ Add task</LinkButton>
      )}
      <NewTodoInlineForm
        visible={showAddTodo}
        onSubmit={(e) => {
          e?.preventDefault();
          onSubmit(value);
          setValue(undefined);
          setShowAddTodo(false);
        }}
        submitBtnText="Add"
        onCancel={(e) => {
          e?.preventDefault();
          setValue(undefined);
          setShowAddTodo(false);
        }}
        loading={false}
        value={value}
        onChange={(newvalue) => setValue(newvalue)}
      />
    </>
  );
};
