import BusinessDetail from "./BusinessDetail";
import ContactDetail from "./ContactDetail";
import styled from "styled-components";
import { useParams } from "react-router-dom";

const PageContainer = styled.div`
  /* padding: 16px; */
  padding-top: 16px;
`;

export const PageTitle = styled.h1`
  margin: 0px;
  font-size: 20px;
  font-weight: 500;
  font-family: ${(p) => p.theme.fontfamily};
  color: ${(p) => p.theme.colors.neutral1};
  margin-bottom: 16px;
`;

export const Card = styled.div`
  padding: 16px;
  background: #fff;
`;

export default function AppDetail(props) {
  const params = useParams();
  return (
    <PageContainer>
      {props.location?.pathname.includes("app/businesses/") && (
        <BusinessDetail {...props} businessId={params.id} />
      )}
      {props.location?.pathname.includes("app/contacts/") && (
        <ContactDetail {...props} contactId={params.id} />
      )}
    </PageContainer>
  );
}
