import React from 'react';
import Select from 'antd/lib/select';
import eventTypes from 'lib/constants/eventTypes';
// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const EventTypesSelect = props => {
  const { onChange, value = '' } = props;

  return (
    <Select
      {...props}
      size="large"
      onChange={onChange}
      value={value || undefined}
      showSearch
      optionFilterProp="children"
    >
      {eventTypes.map(({ value, label }) => (
        <Option key={value} value={value}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

EventTypesSelect.defaultProps = {
  style: {
    width: '100%'
  },
  placeholder: 'Select one'
};

// EXPORT
// ================================================================
export default EventTypesSelect;
