// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  fragment attachmentFragment on Attachment {
    id
    __typename
    filename
    encoding
    mimetype
    url
    key
    createdAt
    createdByName
  }
`;
