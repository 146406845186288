import React from 'react';
import Select from 'antd/lib/select';
import orgTypes from 'lib/constants/orgTypes';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';
// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const OrgTypeSelect = (props) => {
  const {onChange, value = '', mode} = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        size="large"
        mode={mode || null}
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
      >
        {orgTypes.map(({value, label}) => (
          <Option key={value} value={value}>
            <span
              style={{
                height: 15,
                width: 15,
                display: 'inline-block',
                marginRight: 5,
                borderRadius: '50%',
              }}
            />{' '}
            {label}
          </Option>
        ))}
      </Select>
    </DropdownStyleWrapper>
  );
};

OrgTypeSelect.defaultProps = {
  style: {
    width: '100%',
  },
  placeholder: 'Select one',
};

// EXPORT
// ================================================================
export default OrgTypeSelect;
