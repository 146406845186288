import { useState } from "react";
import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { useCasesQuery } from "generated/graphql";

const { Option } = Select;

export default function CaseInput({
  value,
  placeholder,
  onChange,
  defaultCaseId,
}: {
  value?: string;
  placeholder?: string;
  onChange?: any;
  defaultCaseId?: string;
}) {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { data, loading } = useCasesQuery({
    variables: {
      searchText,
      caseIds: !searchText && defaultCaseId ? [defaultCaseId] : undefined,
    },
    fetchPolicy: "cache-and-network",
  });
  const cases = data?.cases?.result || [];

  return (
    <DropdownStyleWrapper>
      <Select
        value={!loading && value ? value : undefined}
        placeholder={placeholder}
        onSearch={(value) => setSearchText(value as string)}
        showSearch
        showArrow={false}
        filterOption={false}
        style={{ width: "100%" }}
        notFoundContent={loading ? "searching..." : null}
        onChange={onChange}
        optionLabelProp="label"
      >
        {cases &&
          cases.map((item) => {
            if (!item?.id) return null;
            return (
              <Option key={item.id} value={item.id} label={item.title}>
                <strong style={{ marginRight: 8, fontSize: 16 }}>
                  {item.title}
                </strong>
              </Option>
            );
          })}
      </Select>
    </DropdownStyleWrapper>
  );
}
