// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  query csvDownload_contacts {
    csvDownload_contacts {
      id
      email
      department
      twitter
      instagram
      linkedin
      facebook
      firstName
      lastName
      title
      status
      website
      workPhone
      cellPhone
      homePhone
      tags {
        id
        title
        description
      }
      lat
      lng
      placeId
      fullAddress
      street
      postal
      country
      state
      city
    }
  }
`;
