// TOP LEVEL IMPORTS
import { Suspense } from "react";
import { Route } from "react-router-dom";
import Loading from "components/common/Loading";

// EXPORTED COMPONENT
// ==============================
// const BlankRoute = (props) => {
//   const {
//     currentUser,
//     showHeader,
//     showFooter,
//     component: Component,
//     path,
//     backgroundColor,
//     layout: Layout,
//     ...rest
//   } = props;

//   return (
//     <Route
//       {...rest}
//       path={path}
//       render={(args) => (
//         <Layout
//           currentUser={currentUser}
//           backgroundColor={backgroundColor}
//           showHeader={showHeader}
//           showFooter={showFooter}
//         >
//           <Suspense fallback={<Loading />}>
//             <Component currentUser={currentUser} {...args} />
//           </Suspense>
//         </Layout>
//       )}
//     />
//   );
// };

const BlankRoute = (props) => {
  const {
    currentUser,
    redirectOnAuth,
    showHeader,
    showFooter,
    component: Component,
    path,
    backgroundColor,
    layout: Layout,
    ...rest
  } = props;

  return (
    <Layout
      currentUser={currentUser}
      backgroundColor={backgroundColor}
      showHeader={showHeader}
      showFooter={showFooter}
    >
      <Suspense fallback={<Loading />}>
        <Component currentUser={currentUser} {...rest} />
      </Suspense>
    </Layout>
  );
};

// EXPORT
// ==============================
export default BlankRoute;
