// import message from 'components/common/message'
import notification from "components/common/notification";

const alerts = {
  success: (message: string) => {
    notification.success({
      message,
      placement: "bottomRight",
    });
  },
};

export default alerts;
