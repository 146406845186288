// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  fragment projectFragment on Project {
    id
    __typename
    title
    description
    projectType
    pipeline
    order
    amount
    stageId
    image {
      id
      filename
      mimetype
      encoding
      url
      key
    }
    updatedAt
    createdAt
    businessIds
    contactIds
    siteIds
    extraFields {
      value
      fieldName
      fieldType
    }
    customFields {
      value
      fieldName
      fieldType
    }
  }
`;
