import {
  CurrentUserCustomerFragmentFragment,
  CustomerFeatureSettingKeyEnum,
} from "generated/graphql";

export default function featureIsOn({
  customer,
  feature,
}: {
  customer: CurrentUserCustomerFragmentFragment | null | undefined;
  feature: CustomerFeatureSettingKeyEnum;
}): boolean {
  let turnedOnFeatures: any = [];
  if (!customer) return false;
  if (!customer.features) return false;
  customer.features.forEach((feat) => {
    if (feat?.active) {
      turnedOnFeatures.push(feat?.key);
    }
  });
  return turnedOnFeatures.includes(feature);
}
