import React from 'react';
import styled from 'styled-components';
import Select from 'antd/lib/select';
import businessStages from 'lib/constants/businessStages';
import DropdownStyleWrapper from 'components/inputs/DropdownStyleWrapper';

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

const Bold = styled.p`
  margin: 0px;
  font-weight: 700;
`;

const Text = styled.p`
  margin: 0px;
  font-weight: 500;
  color: ${(p) => p.theme.colors.neutral4};
`;

const getStageExplainer = (stage) => {
  if (stage === 'idea') {
    return `Business is pre-revenue and/or pre-customers`;
  }
  if (stage === 'startup') {
    return `Business is new but has some revenues and/or customer`;
  }
  if (stage === 'existing') {
    return `Business exists and has established customers and revenues`;
  }

  return stage;
};

// EXPORTED COMPONENT
// ================================================================
const BusinessStagesMultiSelect = (props) => {
  const {onChange, value = ''} = props;

  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        size="large"
        mode="multiple"
        onChange={onChange}
        value={value || undefined}
        showSearch
        optionFilterProp="children"
        optionLabelProp="label"
      >
        {businessStages.map(({value, label}) => (
          <Option key={value} value={value}>
            <Bold>{label}</Bold>
            <Text>{getStageExplainer(value)}</Text>
          </Option>
        ))}
      </Select>
    </DropdownStyleWrapper>
  );
};

BusinessStagesMultiSelect.defaultProps = {
  style: {
    width: '100%',
  },
  placeholder: 'Select all that apply',
};

// EXPORT
// ================================================================
export default BusinessStagesMultiSelect;
