import React from "react";
import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

const { Option } = Select;

export default function SelectInput({
  value,
  options,
  onChange,
  loading,
  containerStyle,
  placeholder,
  mode,
  onBlur,
  optionLabelProp,
  allowClear,
  ...args
}: {
  value?: string;
  options?: { id: string; label: string | JSX.Element | null }[];
  onChange?: any;
  loading?: boolean;
  containerStyle?: React.CSSProperties;
  placeholder?: string;
  mode?: undefined | "multiple";
  onBlur?: any;
  optionLabelProp?: string;
  allowClear?: boolean;
}) {
  // show main component
  return (
    <DropdownStyleWrapper
      background={
        typeof containerStyle?.backgroundColor === "string"
          ? containerStyle?.backgroundColor
          : "#fff"
      }
      style={containerStyle}
    >
      <Select
        optionFilterProp="children"
        data-testid={args?.["data-testid"]}
        mode={mode}
        onBlur={onBlur}
        loading={loading}
        placeholder={placeholder}
        style={{ width: containerStyle?.width || 260 }}
        onChange={(value) => {
          onChange(value);
        }}
        allowClear={allowClear}
        optionLabelProp={optionLabelProp || "children"}
        value={value}
      >
        {!loading &&
          options?.map(
            ({ id, label }) =>
              id && (
                <Option
                  key={id}
                  value={id}
                  style={{ fontWeight: 600 }}
                  data-testid={`select-option-${label}`}
                >
                  {label}
                </Option>
              )
          )}
      </Select>
    </DropdownStyleWrapper>
  );
}
