// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import businessFragment from "ApolloClient/Fragments/businessFragment";
import attachmentFragment from "ApolloClient/Fragments/attachmentFragment";

export default gql`
  fragment contactFragment on Contact {
    id
    email
    department
    optResult
    avatar {
      id
      filename
      mimetype
      encoding
      url
      key
    }
    attachments {
      ...attachmentFragment
    }
    twitter
    instagram
    linkedin
    facebook
    firstName
    lastName
    title
    status
    website
    workPhone
    cellPhone
    homePhone
    businesses {
      ...businessFragment
    }
    tags {
      id
      title
      description
    }
    lat
    lng
    placeId
    fullAddress
    street
    postal
    country
    state
    city
  }
  ${businessFragment}
  ${attachmentFragment}
`;
