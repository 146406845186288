import { useState } from "react";
import styled from "styled-components";
import { useNavigate, Link } from "react-router-dom";
import MenuFilled from "components/icons/MenuFilled";
import SearchModal from "../../AuthenticatedLayout/Header/SearchModal";
// lib
import logo from "lib/media/logo-icon.svg";
import theme from "lib/theme";
import useSignout from "lib/hooks/useSignout";
import helpers from "lib/helpers/GeneralHelpers";
import {
  CustomerFeatureSettingKeyEnum,
  UserPermissionEnum,
} from "generated/graphql";
import Drawer from "components/common/Drawer";

const HeaderOuter = styled.div`
  background-color: #fff;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 10;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
  height: 56px;
`;

const HeaderContainer = styled.div`
  background: #fff;
  display: flex;
  align-items: center;
  height: 100%;
  max-width: 100%;
  font-weight: 600;
  margin: auto;
  padding: 0px 16px;
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const MenuBtn = styled.button`
  border: 0px;
  background: transparent;
  margin: 0px;
  padding: 0px;
  height: 35px;
  display: inline-flex;
`;

const Overlay = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  top: 0px;
  background: rgba(0, 0, 0, 0.25);
  z-index: 10;
`;

const MenuOption = styled.button`
  border: 0px;
  background: transparent;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
  height: 64px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
`;

const Menu = styled.div`
  background: #fff;
  width: 55%;
  position: absolute;
  bottom: 0px;
  top: 0px;
  left: 0px;
  z-index: 1000;
  padding-top: 8px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const HeaderRight = styled.div`
  display: flex;
  justify-content: space-between;
  width: 190px;
`;

export default function Header({ currentUser }) {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const [signOut] = useSignout();
  const onLogout = async () => {
    try {
      // run logout function
      await signOut(currentUser);
    } catch (err) {
      return;
    }
  };

  const onPathChange = (path) => {
    navigate(path);
    setShowMenu(false);
  };

  const OPTIONS = [
    {
      label: "Businesses",
      onClick: () => onPathChange("/app/businesses"),
      "data-testid": "mobile-nav-link-businesses",
      show: true,
    },
    {
      label: "Contacts",
      onClick: () => onPathChange("/app/contacts"),
      "data-testid": "mobile-nav-link-contacts",
      show: true,
    },
    {
      label: "Cases",
      onClick: () => onPathChange("/app/cases"),
      "data-testid": "mobile-nav-link-cases",
      show:
        helpers.featureIsOn({
          customer: currentUser.customer,
          feature: CustomerFeatureSettingKeyEnum.Cases,
        }) &&
        helpers.hasPermission(currentUser, [UserPermissionEnum.Administrator]),
    },
    {
      label: "Your Work",
      onClick: () => onPathChange("/app/your-work"),
      "data-testid": "mobile-nav-link-your-work",
      show: true,
    },
  ];

  return (
    <>
      <HeaderOuter>
        <HeaderContainer>
          <MenuBtn
            onClick={() => setShowMenu(true)}
            data-testid="mobile-open-btn"
          >
            <MenuFilled />
          </MenuBtn>
          <HeaderRight>
            <SearchModal />
            <Link to="/app/dashboard">
              <img src={logo} alt="logo" />
            </Link>
          </HeaderRight>
        </HeaderContainer>
      </HeaderOuter>

      <Drawer
        open={showMenu}
        placement="left"
        onClose={() => setShowMenu(false)}
        headerStyle={{ display: "none" }}
        width="80%"
      >
        <>
          <>
            {OPTIONS?.map((item) => {
              if (!item.show) return null;
              return (
                <MenuOption
                  onClick={item.onClick}
                  data-testid={item?.["data-testid"]}
                >
                  {item.label}
                </MenuOption>
              );
            })}
            <MenuOption
              onClick={onLogout}
              style={{ color: theme.colors.red3 }}
              data-testid="mobile-nav-link-logout"
            >
              Logout
            </MenuOption>
          </>
        </>
      </Drawer>
    </>
  );
}
