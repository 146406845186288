import styled from "styled-components";
import moment from "moment";
// COMPONENTS
import AdminTable from "components/common/AdminTable";
import { useNavigate } from "react-router-dom";
import { useProjectsByCustomerQuery } from "generated/graphql";
import Loading from "components/common/Loading";

const Container = styled.div`
  padding: 24px;
`;

const CustomerView = styled.button`
  border: 0px;
  background: transparent;
  color: ${(p) => p.theme.colors.supportB3};
  cursor: pointer;
  &:focus {
    outline: 0;
  }
`;

export default function TableView() {
  const navigate = useNavigate();
  const { data, loading, error } = useProjectsByCustomerQuery();
  if (loading) return <Loading />;
  if (error) return <>error...</>;

  const columns = [
    {
      title: "title",
      key: "id",
      render: ({ id, title }) => (
        <CustomerView onClick={() => navigate(`/app/projects/${id}`)}>
          {title}
        </CustomerView>
      ),
    },
    {
      title: "Pipeline",
      render: ({ id, pipelineName }) => <div>{pipelineName}</div>,
    },
    {
      title: "Stage",
      render: ({ id, pipelineStage }) => (
        <div>{pipelineStage && pipelineStage.label}</div>
      ),
    },
    {
      title: "Last updated",
      render: ({ updatedAt }) => (
        <div>{updatedAt && moment(parseInt(updatedAt)).format("M/DD/YY")}</div>
      ),
    },
  ];
  return (
    <Container>
      <AdminTable
        loading={loading}
        columns={columns}
        dataSource={
          (!loading &&
            data &&
            data.projectsByCustomer &&
            data.projectsByCustomer) ||
          []
        }
        count={
          (data && data.projectsByCustomer && data.projectsByCustomer.length) ||
          0
        }
      />
    </Container>
  );
}
