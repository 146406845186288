import { useEffect, useState } from "react";
import { get } from "lodash";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import numeral from "numeral";
// APOLLO
import { useQuery, useMutation } from "@apollo/client";
import SITES from "ApolloClient/Queries/sites";
import SAVE_SITE from "ApolloClient/Mutations/saveSite";
import DELETE_SITE from "ApolloClient/Mutations/deleteSite";
// COMPONENTS
import Button from "components/common/Button";
import EditSite from "./EditSite";
import message from "components/common/message";
import Table from "components/common/Table";
import PageHeader from "components/text/PageHeader";
import Drawer from "components/common/Drawer";
import Popconfirm from "components/common/Popconfirm";
import SiteForm from "../../../../components/forms/SiteForm";
import {
  useArchiveSiteMutation,
  useDeleteSiteMutation,
  useSaveSiteMutation,
} from "generated/graphql";
import usePaginationQueryParams from "hooks/usePaginationQueryParams";
import { LoadingOutlined } from "@ant-design/icons";

const TopRow = styled.div`
  display: flex;
  margin-bottom: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FormContainer = styled.div`
  background: #fff;
  padding: 16px;
  width: 550px;
  max-width: 100%;
  padding-top: 0px;
`;

const Title = styled.div`
  font-family: ${(p) => p.theme.fontFamily};
  color: ${(p) => p.theme.colors.neutral1};
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  height: 14px;
`;

const Subtitle = styled(Title)`
  color: ${(p) => p.theme.colors.neutral5};
  font-size: 14px;
  margin-top: 4px;
  font-weight: 500;
`;

const Text = styled(Title)`
  color: ${(p) => p.theme.colors.neutral4};
  font-weight: 400;
`;

export default function SitesTab() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    searchText: null,
    finalSearchText: null,
    eventForm: false,
    addNew: false,
  });

  const [siteId, setSiteId] = useState<string | false>(false);
  const [archiveSite] = useArchiveSiteMutation({
    refetchQueries: ["sites"],
  });
  const [saveSite, { loading: creatingSite }] = useSaveSiteMutation();
  const [deleteSite, { loading: deleting }] = useDeleteSiteMutation();
  const [archiving, setArchiving] = useState<string | undefined>(undefined);

  const {
    skip,
    limit,
    currentPage,
    onTablePaginationChange,
    resetTableParamsToDefault,
  } = usePaginationQueryParams();

  useEffect(() => {
    resetTableParamsToDefault();
  }, []);

  const variables = {
    limit,
    searchText: state.finalSearchText,
    skip,
  };
  const { data, loading } = useQuery(SITES, {
    variables,
  });

  // const handleFilter = debounce((finalSearchText) => {
  //   setState({ ...state, finalSearchText });
  // }, 250);

  const closeDrawer = () => {
    setState({ ...state, addNew: false });
    setSiteId(false);
  };

  const handleCreateNewSite = async (values) => {
    try {
      await saveSite({
        variables: {
          params: {
            title: values.title,
            description: values.description,
            type: values.type,
            url: values?.url,
            contactId: values.contactId,
            attachments: values.attachments,
            size: values.size,
            // location
            lat: values.lat,
            lng: values.lng,
            placeId: values.placeId,
            fullAddress: values.fullAddress,
            street: values.street,
            postal: values.postal,
            country: values.country,
            state: values.state,
            city: values.city,
            tagIds: values.tagIds,
          },
        },
        refetchQueries: ["sites"],
      });
      closeDrawer();
      message.success(`New site created!`);
    } catch (err) {}
  };

  const handleDeleteSite = async (siteId) => {
    try {
      if (deleting) return null; // dont rerun mutation if were in the process of deleting
      await deleteSite({
        variables: {
          id: siteId,
        },
        refetchQueries: [
          {
            query: SITES,
            fetchPolicy: "network-only",
            variables,
          },
        ],
      });
      message.success(`Site deleted`);
    } catch (err) {
      console.log(err);
    }
  };

  const columns = [
    {
      title: "title",
      width: "45%",
      render: ({ id, title, fullAddress }) => {
        if (!fullAddress && title)
          return (
            <Title onClick={() => navigate(`/app/sites/${id}`)}>{title}</Title>
          );
        return (
          <div onClick={() => navigate(`/app/sites/${id}`)}>
            <Title>{fullAddress}</Title>
            <Subtitle>{title}</Subtitle>
          </div>
        );
      },
    },
    {
      title: "type",
      width: "10%",
      render: ({ type }) => <Text>{type}</Text>,
    },
    {
      title: "size",
      width: "10%",
      render: ({ size }) => <Text>{numeral(size).format("0,0")}</Text>,
    },
    {
      title: "contact",
      width: "20%",
      render: ({ contact }) =>
        contact && (
          <Link to={`/app/contacts/${contact.id}`}>
            {contact.firstName} {contact.lastName}
          </Link>
        ),
    },
    {
      title: "",
      width: "5%",
      render: (record) => {
        if (archiving === record.id) return <LoadingOutlined />;
        return record.archived ? (
          <Text
            onClick={async () => {
              try {
                setArchiving(record.id);
                await archiveSite({
                  variables: { siteId: record.id, archived: false },
                });
                setArchiving(undefined);
              } catch (err) {
                setArchiving(undefined);
              }
            }}
          >
            Unarchive
          </Text>
        ) : (
          <Text
            onClick={async () => {
              try {
                setArchiving(record.id);
                await archiveSite({
                  variables: { siteId: record.id, archived: true },
                });
                setArchiving(undefined);
              } catch (err) {
                setArchiving(undefined);
              }
            }}
          >
            Archive
          </Text>
        );
      },
    },
    {
      title: "",
      width: "5%",
      render: (record) => (
        <Text onClick={() => setSiteId(record.id)}>Edit</Text>
      ),
    },
    {
      title: "",
      width: "5%",
      render: (record) => (
        <Popconfirm
          title="Are you sure you want to delete this site?"
          okText="Yes"
          onConfirm={() => handleDeleteSite(record.id)}
        >
          <Text>{!deleting ? "Delete" : "..."}</Text>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <TopRow>
        {/* <SearchBar
          onChange={(searchText) => {
            handleFilter(searchText);
          }}
          placeholder="Search sites"
          style={{ width: 400, marginRight: 16 }}
        /> */}
        <PageHeader style={{ margin: 0 }}>Sites</PageHeader>
        <Button
          style={{ width: 160 }}
          onClick={() => setState({ ...state, addNew: true })}
        >
          + New Site
        </Button>
      </TopRow>
      <Table
        dataSource={(data?.sites?.sites) || []}
        columns={columns}
        loading={loading}
        rowKey={(record) => record.id}
        pagination={{
          total: data?.sites?.count || 0,
          pageSize: limit,
          onChange: (page, pageSize) => {
            console.log(page, pageSize); 
            onTablePaginationChange(page, pageSize);
          },
          showSizeChanger: true,
          current: currentPage,
        }}
      />
      <Drawer
        onClose={closeDrawer}
        title={!siteId ? "Add New Site" : "Edit Site"}
        visible={siteId || state.addNew}
      >
        {siteId && <EditSite siteId={siteId} onClose={closeDrawer} />}
        {state.addNew && (
          <FormContainer>
            <SiteForm
              onSubmit={handleCreateNewSite}
              loading={creatingSite}
              onCancel={closeDrawer}
            />
          </FormContainer>
        )}
      </Drawer>
    </div>
  );
}
