import { useSaveSiteMutation } from "generated/graphql";

export default function useSaveSite() {
  const [saveSite, { loading }] = useSaveSiteMutation();

  const onSaveSite = async (values, onComplete?: any) => {
    try {
      // set loading
      let res = await saveSite({
        variables: {
          params: {
            title: values.title,
            description: values.description,
            type: values.type,
            contactId: values.contactId,
            attachments: values.attachments,
            size: values.size,
            url: values?.url,
            // location
            lat: values.lat,
            lng: values.lng,
            placeId: values.placeId,
            fullAddress: values.fullAddress,
            street: values.street,
            postal: values.postal,
            country: values.country,
            state: values.state,
            city: values.city,
            tagIds: values.tagIds,
          },
        },
        refetchQueries: ["sites"],
      });

      onComplete && onComplete();
      return res?.data?.saveSite;
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSaveSite,
  };
}
