import Select from "antd/lib/select";
import useUrlChange from "hooks/useUrlChange";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { useCaseServicesQuery } from "generated/graphql";

const { Option } = Select;

export default function CaseServicesInput(props) {
  const { value, placeholder, onBlur, onChange } = props;
  const { data, loading } = useCaseServicesQuery({});
  const { onUrlChange } = useUrlChange();

  if (loading) return null;

  return (
    <DropdownStyleWrapper>
      <Select
        value={value}
        onBlur={onBlur}
        data-testid={props["data-testid"] || "case-services-input"}
        placeholder={placeholder}
        style={{ width: "100%" }}
        mode="multiple"
        loading={loading}
        filterOption={false}
        notFoundContent={loading ? "searching..." : null}
        onChange={(value) => {
          if (value.includes("add-new-service")) {
            return onUrlChange({ mode: "addNewService" }, `/app/cases`);
          }
          onChange(value);
        }}
      >
        {data?.caseServices?.map((item, index) => {
          return (
            <Option
              key={item?.id}
              value={item?.id}
              data-testid={`case-services-input-option-${index}`}
            >
              {item?.title}
            </Option>
          );
        })}
        {/* <Option key="add-new-service" value="add-new-service">
          + Add New Service
        </Option> */}
      </Select>
    </DropdownStyleWrapper>
  );
}
