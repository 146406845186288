import message from "components/common/message";
import { useNavigate } from "react-router-dom";
import { useSaveContactMutation } from "generated/graphql";

const getContactValues = (contact) => {
  let shapedContact = {
    ...contact,
  };

  return shapedContact;
};

export default function useSaveContact() {
  const [saveContact, { loading }] = useSaveContactMutation();
  const navigate = useNavigate();
  const onSubmitContact = async (
    params: any,
    onComplete?: Function,
    refetchQueries?: any[]
  ) => {
    try {
      // check if it has a title
      if (!params.firstName) {
        return message.error("Contact is required");
      }
      // set loading

      let res = await saveContact({
        variables: {
          params: getContactValues(params),
        },
        refetchQueries: ["contacts", "contactById"],
      });

      onComplete && onComplete();
      navigate(`/app/contacts/${res?.data?.saveContact?.id}`);
    } catch (err: any) {
      console.log(err);
      message.error(err.message);
      throw new Error(err.message);
    }
  };

  return {
    onSubmitContact,
    savingContact: loading,
  };
}
