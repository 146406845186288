// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import userFragment from "ApolloClient/Fragments/userFragment";
import projectFragmentShort from "ApolloClient/Fragments/projectFragmentShort";
import caseOutcomeFragment from "ApolloClient/Fragments/caseOutcomeFragment";

export default gql`
  fragment caseFragment on Case {
    id
    title
    key
    description
    caseStatusId
    createdAt
    updatedAt
    tagIds
    tags {
      id
      title
    }
    dueDate
    resolvedOn
    resolutionNotes
    caseOutcomes {
      ...caseOutcomeFragment
    }
    reporter {
      id
      firstName
      lastName
      avatar {
        id
        url
      }
    }
    reporterId
    caseStatus {
      id
      title
      order
    }
    project {
      ...projectFragmentShort
    }
    attachments {
      id
      url
      filename
    }
    assignedToId
    assignedTo {
      ...userFragment
    }
    caseCategoryId
    caseCategory {
      id
      title
    }
    serviceIds
    services {
      id
      title
    }
    businessId
    business {
      id
      __typename
      title
      description
      phone
      logo {
        id
        filename
        mimetype
        encoding
        url
        key
      }
    }
    contactId
    openTodos
    closedTodos
    contact {
      id
      email
      firstName
      lastName
      title
      website
      workPhone
      avatar {
        id
        url
      }
    }
  }
  ${userFragment}
  ${projectFragmentShort}
  ${caseOutcomeFragment}
`;
