import { Card } from "../index";
import Skeleton from "components/common/Skeleton";
import { ResponsivePie } from "@nivo/pie";
import supportTypes from "lib/constants/supportTypes";
// APOLLO
import { useReports_SupportProgramsByTypeQuery } from "generated/graphql";

export default function SupportProgramsByStage() {
  const { data, loading } = useReports_SupportProgramsByTypeQuery({
    fetchPolicy: "cache-and-network",
  });
  const title = "Support Programs By Program Type";
  if (loading) {
    return (
      <Card title={title}>
        <Skeleton round />
      </Card>
    );
  }

  const chartData = data?.reports_SupportProgramsByType?.map((item) => {
    return {
      id: supportTypes.find((i) => i?.value === item?.title)?.label,
      label: supportTypes.find((i) => i?.value === item?.title)?.label,
      value: item?.total,
    };
  });

  return (
    <Card title={title} empty={!chartData?.[0]}>
      <ResponsivePie
        data={chartData || []}
        colors={{ scheme: "greens" }}
        margin={{ top: 32, right: 88, bottom: 24, left: 88 }}
      />
    </Card>
  );
}
