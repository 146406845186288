// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  query customers {
    customers {
      count
      customers {
        id
        title
        businessCount
        contactsCount
      }
    }
  }
`;
