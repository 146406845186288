import React from 'react';
import styled from 'styled-components';
import Select from 'antd/lib/select';
import 'antd/lib/select/style/css';

const {Option} = Select;

const Container = styled.div`
  position: absolute;
  right: 8px;
  top: 12px;
`;

export default function TopNumberDropdown({value, onChange}) {
  return (
    <Container>
      <Select
        style={{width: 100}}
        value={value}
        onChange={onChange}
        size="small"
      >
        <Option value={5}>Top 5</Option>
        <Option value={10}>Top 10</Option>
        <Option value={15}>Top 15</Option>
        <Option value={20}>Top 20</Option>
      </Select>
    </Container>
  );
}
