// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  query caseStatusReport {
    caseStatusReport {
      id
      status {
        id
        title
        description
        isFinalStep
      }
      total
    }
  }
`;
