// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import eventFragment from 'ApolloClient/Fragments/eventFragment';

export default gql`
  mutation saveEvent($id: ID, $params: EventParams) {
    saveEvent(id: $id, params: $params) {
      ...eventFragment
    }
  }
  ${eventFragment}
`;
