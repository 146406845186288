import styled from "styled-components";

const Container = styled.div<{ topBorder?: boolean }>`
  margin-bottom: 8px;
  margin-top: 24px;
  padding-top: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-top: ${(p) =>
    p.topBorder ? `1px solid ${p.theme.colors.neutral9}` : "inherit"};
`;

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.neutral2};
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: 18px;
  line-height: 24px;
`;

const Description = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.neutral5};
  font-size: 14px;
  font-family: ${({ theme }) => theme.fontFamily};
  line-height: 20px;
`;

export default function FormBreak({
  title,
  description,
  topBorder,
}: {
  title: string;
  description?: string;
  topBorder?: boolean;
}) {
  return (
    <Container topBorder={topBorder}>
      <div>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
      </div>
    </Container>
  );
}
