// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import contactFragment from 'ApolloClient/Fragments/contactFragment';

export default gql`
  query contacts($params: ContactSearchParams, $skip: Int, $limit: Int) {
    contacts(params: $params, skip: $skip, limit: $limit) {
      count
      contacts {
        ...contactFragment
      }
    }
  }
  ${contactFragment}
`;
