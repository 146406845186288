import React from "react";
import Select from "antd/lib/select";
import { useQuery } from "@apollo/client";
import CASE_CATEGORIES from "ApolloClient/Queries/caseCategories";
import useUrlChange from "hooks/useUrlChange";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

const { Option } = Select;

export default function CaseCategoryInput(props) {
  const { value, placeholder, onChange } = props;
  const { data, loading } = useQuery(CASE_CATEGORIES);
  const { onUrlChange } = useUrlChange();
  return (
    <DropdownStyleWrapper background={props.backgroundColor}>
      <Select
        value={value}
        placeholder={placeholder}
        style={{ width: "100%", ...props.style }}
        filterOption={false}
        data-testid={props["data-testid"] || "case-category-input"}
        notFoundContent={loading ? "searching..." : null}
        onChange={(value) => {
          if (value === "add-new-category") {
            return onUrlChange({ mode: "addNewCategory" }, `/app/cases`);
          }
          onChange(value);
        }}
      >
        {data &&
          data.caseCategories &&
          data.caseCategories.map((item, index) => {
            return (
              <Option
                key={item.id}
                data-testid={`case-form-caseCategoryId-option-${index}`}
              >
                {item.title}
              </Option>
            );
          })}
        {/* <Option key="add-new-category" value="add-new-category">
          + Add New Category
        </Option> */}
      </Select>
    </DropdownStyleWrapper>
  );
}
