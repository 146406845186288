import * as React from 'react';

function UserFilled(props) {
  return (
    <svg
      width={33}
      height={33}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5 14.85a4.95 4.95 0 100-9.9 4.95 4.95 0 000 9.9zM4.95 29.7a11.55 11.55 0 1123.1 0H4.95z"
        fill={props.fill || '#000'}
      />
    </svg>
  );
}

export default UserFilled;
