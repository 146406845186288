import { useState } from "react";
import styled from "styled-components";
import moment from "moment";
// COMPONENTS
import message from "components/common/message";
import Comments from "./Comments";
import AttachmentsList from "./AttachmentsList";
import AttachBtn from "./AttachBtn";
import CaseTodos from "./CaseTodos";
import AddTodoButton from "./AddTodoBtn";
import TextEditorArea from "./TextEditorArea";
import { useCaseStatusesQuery } from "generated/graphql";
import CaseTitle from "./CaseTitle";
import useOnSaveCase from "../useOnSaveCase";
import ConfirmCloseModal from "../ConfirmCloseModal";
import BlockItems from "../BlockItems";

const ErrorText = styled.p`
  margin: 0px;
  color: ${({ theme }) => theme.colors.red3};
`;

const Left = styled.div`
  width: 60%;
  margin-right: 5%;
  max-height: 70vh;
  overflow-y: scroll;
  padding-right: 24px;
`;

const Right = styled.div`
  width: 35%;
`;

const Container = styled.div`
  display: flex;
  margin-top: 16px;
  position: relative;
`;

export const Label = styled.div`
  margin-bottom: 0px;
  margin-top: 16px;
  font-family: ${(p) => p.theme.fontFamily};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.003em;
  color: ${(p) => p.theme.colors.neutral1};
`;

export const CaseNumber = styled(Label)`
  color: ${(p) => p.theme.colors.neutral5};
  top: -24px;
  position: absolute;
  margin: 0px;
  font-size: 12px;
  font-weight: 700;
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    top: -16px;
    position: relative;
  }
`;

const LineBreak = styled.div`
  height: 1px;
  background: ${(p) => p.theme.colors.neutral8};
  margin-top: 24px;
  margin-bottom: 16px;
`;

const TimeAgo = styled.p`
  margin: 0px;
  margin-top: 8px;
  color: ${(p) => p.theme.colors.neutral4};
  font-size: 13px;
`;

export enum EditFieldEnum {
  "Status" = "Status",
  "Category" = "Category",
  "Services" = "Services",
  "ResolvedOn" = "Resolved On",
  "Due" = "Due",
  "Assignee" = "Assignee",
  "Business" = "Business",
  "Contact" = "Contact",
  "Tags" = "Tags",
  "Project" = "Project",
  "Outcomes" = "Outcomes",
  "Reporter" = "Reporter",
}

export default function TodoDetailContent({ defaultCase, currentUser }) {
  const [confirmCloseModal, setConfirmCloseModal] = useState(false);
  const [values, setValues] = useState(defaultCase || {});

  const [errors, setErrors] = useState<{
    resolutionNotes?: string;
    resolvedOn?: string;
  }>({});

  const [onSaveCase, { loading: saving }] = useOnSaveCase({
    caseId: defaultCase?.id,
    onSaved: (newCaseValues) => {
      setValues(newCaseValues);
      setConfirmCloseModal(false);
      message.success("Your changes were saved!");
    },
    onErrors: (newErrorsObj) => {
      setErrors({ ...errors, ...newErrorsObj });
    },
  });
  const [editing, setEditing] = useState<EditFieldEnum | false>(false);
  const [addNewTodo, setAddNewTodo] = useState(false);

  const { data: caseStatusQuery, loading: caseStatusesLoading } =
    useCaseStatusesQuery();

  const caseStatuses =
    !caseStatusesLoading && caseStatusQuery?.caseStatuses?.[0]
      ? caseStatusQuery?.caseStatuses
      : [];

  const getCaseStatus = (caseStatusId: any): any => {
    return caseStatuses?.find((status) => status?.id === caseStatusId);
  };

  const caseStatus = getCaseStatus(values.caseStatusId);

  return (
    <>
      {confirmCloseModal && (
        <ConfirmCloseModal
          onComplete={(newValues) => {
            onSaveCase({
              ...values,
              ...newValues,
            });
          }}
          getCaseStatus={getCaseStatus}
          loading={saving}
          onCancel={() => setConfirmCloseModal(false)}
          caseStatusId={values.caseStatusId}
        />
      )}
      <Container>
        <Left>
          <CaseNumber>{defaultCase.key}</CaseNumber>
          <CaseTitle
            title={values.title}
            onSave={(title) => {
              // only save if the titlel changed
              if (title !== values.title) {
                onSaveCase({
                  ...values,
                  title,
                });
              }
            }}
          />
          <AttachBtn
            onChange={(attachment) =>
              onSaveCase({
                ...values,
                attachments: [...values.attachments, attachment],
              })
            }
          />
          <AddTodoButton
            data-testid="case-detail-add-todo-btn"
            onClick={() => setAddNewTodo(true)}
          />
          <TextEditorArea
            value={values.description}
            loading={saving}
            label="Description"
            textInputId={`textarea-description-case=${defaultCase?.id}`}
            onSubmit={(newValue) => {
              onSaveCase({
                ...values,
                description: newValue,
              });
            }}
          />
          {caseStatus && caseStatus.isFinalStep && (
            <TextEditorArea
              value={values.resolutionNotes || ""}
              loading={saving}
              label="Resolution Notes"
              textInputId={`textarea-resolution-notes-case=${defaultCase?.id}`}
              onSubmit={(newValue) => {
                onSaveCase({
                  ...values,
                  resolutionNotes: newValue,
                });
              }}
            />
          )}
          {errors?.resolutionNotes && (
            <ErrorText>{errors?.resolutionNotes}</ErrorText>
          )}
          <AttachmentsList
            attachments={values.attachments}
            onSave={({ attachments }) =>
              onSaveCase({
                ...values,
                attachments,
              })
            }
          />
          <CaseTodos
            caseId={values.id}
            businessId={values.businessId}
            addNewTodo={addNewTodo}
            onCancel={() => setAddNewTodo(false)}
            onNewTodo={() => setAddNewTodo(true)}
          />
          <Comments caseId={values.id} currentUser={currentUser} />
        </Left>{" "}
        <Right>
          <BlockItems
            getCaseStatus={getCaseStatus}
            caseStatus={caseStatus}
            editing={editing}
            onSaveCase={onSaveCase}
            setConfirmCloseModal={setConfirmCloseModal}
            values={values}
            setValues={setValues}
            setEditing={setEditing}
            errors={errors}
          />
          <LineBreak />
          <TimeAgo>
            Created {moment(parseInt(values.createdAt)).fromNow()}
          </TimeAgo>
          <TimeAgo>
            Updated {moment(parseInt(values.updatedAt)).fromNow()}
          </TimeAgo>
        </Right>
      </Container>
    </>
  );
}
