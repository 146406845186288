import styled from "styled-components";
import { useState } from "react";
import FormItem from "components/common/FormItem";
import TextInput from "components/inputs/TextInput";
import TextEditor from "components/inputs/TextEditor";
import CaseCategoryInput from "components/inputs/CaseCategoryInput";
import LinkButton from "components/common/LinkButton";
import Button from "components/common/Button";
import uuid from "lib/helpers/uuid";
import SelectInput from "components/inputs/SelectInput";
import Collapse from "components/common/Collapse";
import { DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import { CaseTemplateInput, useTeamQuery } from "generated/graphql";

const { Panel } = Collapse;

const ButtonContainer = styled.div`
  margin: 32px 0px 16px 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const TodoCard = styled.div`
  border: 2px solid ${({ theme }) => theme.colors?.neutral9};
  padding: 8px;
  margin: 16px;
  border-radius: 5px;
`;

const CASE_ASSIGNEE = "caseAssignee";

const CaseTemplateFormTodoCard = ({
  id,
  onChange,
  title,
  description,
  assignedTo,
  teamMates,
}) => {
  const handleOnChange = (newValues) => {
    onChange({
      id,
      title,
      description,
      assignedTo,
      ...newValues,
    });
  };
  return (
    <>
      <FormItem label="Todo Title">
        <TextInput
          value={title}
          onChange={(title) => handleOnChange({ title })}
        />
      </FormItem>
      <FormItem label="Assign to">
        <SelectInput
          options={[
            {
              id: CASE_ASSIGNEE,
              label: "Default to case asignee",
            },
            ...teamMates,
          ]}
          value={assignedTo}
          onChange={(assignedTo) => handleOnChange({ assignedTo })}
        />
      </FormItem>
      <FormItem label="Description">
        <TextEditor
          value={description}
          onChange={(description) => handleOnChange({ description })}
        />
      </FormItem>
    </>
  );
};

export const CaseTemplateTodos = ({ todos, onTodosChange }) => {
  const { data: teamQuery } = useTeamQuery();

  const teamMates = teamQuery?.team?.map((member) => {
    return {
      id: member?.id,
      label: `${member?.firstName} ${member?.lastName} (${member?.email})`,
    };
  });

  return todos?.[0] ? (
    <Collapse>
      {todos?.map((todo, index) => {
        let number = index + 1;
        return (
          <Panel
            header={
              <>
                Todo #{number}
                <strong>{todo?.title ? `: ${todo?.title}` : ""}</strong>
              </>
            }
            key={todo.id}
            extra={
              <div>
                <Popconfirm
                  title="Remove this todo?"
                  okText="Yes"
                  onConfirm={() => {
                    let newTodos: any[] = [];
                    todos?.forEach((t) => {
                      if (t?.id !== todo?.id) {
                        newTodos.push(t);
                      }
                    });
                    onTodosChange(newTodos);
                  }}
                >
                  <DeleteOutlined
                    onClick={(event) => {
                      // If you don't want click extra trigger collapse, you can prevent this:
                      event.stopPropagation();
                    }}
                  />
                </Popconfirm>
              </div>
            }
          >
            <CaseTemplateFormTodoCard
              key={todo.id}
              id={todo.id}
              onChange={(newTodoValue) => {
                let newTodos: any[] = [];
                todos?.forEach((t) => {
                  if (t?.id === todo?.id) {
                    newTodos.push(newTodoValue);
                  } else {
                    newTodos.push(t);
                  }
                });
                onTodosChange(newTodos);
              }}
              title={todo?.title}
              description={todo?.description}
              assignedTo={todo?.assignedTo}
              teamMates={teamMates}
            />
          </Panel>
        );
      })}
    </Collapse>
  ) : null;
};

export default function CaseTemplateForm({
  onCancel,
  loading,
  onSubmit,
  submitBtnText,
}: {
  onCancel?: any;
  loading?: boolean;
  onSubmit: (args: CaseTemplateInput) => void;
  submitBtnText?: string;
}) {
  const [values, setValues] = useState<any>({});
  const [errors, setErrors] = useState<any>({});

  const handleChange = (newValues) => {
    setValues((oldValues) => ({
      ...oldValues,
      ...newValues,
    }));
  };

  const disabled =
    loading || !values?.templateName?.[1] || !values?.title?.[1] ? true : false;

  const handleSubmit = () => {
    onSubmit({
      ...values,
      description: values?.description,
      todos: values?.todos?.map((todo) => ({
        ...todo,
        description: todo?.description,
      })),
    });
  };

  return (
    <>
      {" "}
      <FormItem
        label="Template Name"
        tooltip="This will be the name you see when selecting a template to use. Choose something that will help you remember what sort of case this templates is for."
        error={errors?.templateName}
        required
      >
        <TextInput
          value={values.templateName}
          onChange={(templateName) => handleChange({ templateName })}
          data-testid="case-template-form-title"
          style={{
            backgroundColor: "#fff",
          }}
        />
      </FormItem>
      <FormItem
        label="Case Title"
        tooltip="If you need to, you can always overwrite this title when you actually create a case using this template."
        error={errors?.title}
        required
      >
        <TextInput
          value={values.title}
          onChange={(title) => handleChange({ title })}
          data-testid="case-template-form-title"
          style={{
            backgroundColor: "#fff",
          }}
        />
      </FormItem>
      <FormItem label="Description" error={errors?.description}>
        <TextEditor
          value={values.description}
          onChange={(description) => handleChange({ description })}
          data-testid="case-template-form-description"
        />
      </FormItem>
      <FormItem label="Cateogry" error={errors?.caseCategoryId}>
        <CaseCategoryInput
          value={values.caseCategoryId}
          onChange={(caseCategoryId) => handleChange({ caseCategoryId })}
          data-testid="case-template-form-caseCategoryId"
          backgroundColor="#fff"
          style={{ width: 400, maxWidth: "100%" }}
        />
      </FormItem>
      <FormItem label="Default Todos:" error={errors?.todos}>
        <CaseTemplateTodos
          todos={values?.todos}
          onTodosChange={(newTodos) => {
            setValues((oldValues) => ({
              ...oldValues,
              todos: newTodos,
            }));
          }}
        />

        <LinkButton
          style={{ marginTop: 24 }}
          onClick={() => {
            setValues((oldValues) => ({
              ...oldValues,
              todos: [
                ...(oldValues?.todos || []),
                {
                  id: uuid(),
                  title: "",
                  assignedTo: CASE_ASSIGNEE,
                },
              ],
            }));
          }}
        >
          + Add a todo
        </LinkButton>
      </FormItem>
      <ButtonContainer>
        {onCancel && (
          <Button
            disabled={loading}
            grey
            style={{ width: 100, marginRight: 16 }}
            onClick={onCancel}
          >
            Cancel
          </Button>
        )}
        <Button
          disabled={disabled}
          loading={loading}
          style={{ width: 160 }}
          onClick={handleSubmit}
          data-testid="case-form-submit-btn"
        >
          {submitBtnText || "Submit"}
        </Button>{" "}
      </ButtonContainer>
    </>
  );
}
