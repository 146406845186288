import * as React from 'react';

function MenuFilled(props) {
  return (
    <svg
      width={33}
      height={33}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.95 8.25A1.65 1.65 0 016.6 6.6h19.8a1.65 1.65 0 110 3.3H6.6a1.65 1.65 0 01-1.65-1.65zm0 8.25a1.65 1.65 0 011.65-1.65h19.8a1.65 1.65 0 110 3.3H6.6a1.65 1.65 0 01-1.65-1.65zm0 8.25A1.65 1.65 0 016.6 23.1h19.8a1.65 1.65 0 110 3.3H6.6a1.65 1.65 0 01-1.65-1.65z"
        fill={props.fill || '#627D98'}
      />
    </svg>
  );
}

export default MenuFilled;
