import message from "components/common/message";
import client from "ApolloClient/index";
import authenticate from "ApolloClient/Mutations/authenticate";
import logout from "ApolloClient/Mutations/logout";
import sendResetPasswordEmail from "ApolloClient/Mutations/sendResetPasswordEmail";
import constants from "lib/constants";
import setLocalStorage from "lib/helpers/setLocalStorage";
import removeLocalStorage from "lib/helpers/removeLocalStorage";
import { AuthenticateMutationResult } from "generated/graphql";
import { MutationResult } from "@apollo/client";

const AuthHelpers: any = {};

AuthHelpers.sendPasswordResetEmail = ({ email }) =>
  new Promise(async (resolve, reject) => {
    try {
      // sign in with email
      let response = await client.mutate({
        mutation: sendResetPasswordEmail,
        variables: { email },
      });
      resolve(response);
    } catch (err) {
      reject(err);
    }
  });

AuthHelpers.handleLogin = ({ email, password }) =>
  new Promise(async (resolve, reject) => {
    try {
      // sign in with email
      let { data } = await client.mutate({
        mutation: authenticate,
        variables: {
          params: {
            password,
            user: {
              email: email,
            },
          },
        },
      });

      if (data?.authenticate?.tokens?.accessToken) {
        setLocalStorage(
          constants.authTokenName,
          data?.authenticate?.tokens?.accessToken
        );
        setLocalStorage(
          constants.refreshTokenName,
          data.authenticate.tokens.refreshToken
        );

        console.log(data?.authenticate?.tokens);
      }

      // let res = await client.query({
      //   query: currentUser
      // });

      resolve(true);
    } catch (err) {
      reject(err);
    }
  });

export default AuthHelpers;
