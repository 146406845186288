import React from 'react';
import styled from 'styled-components';
// APOLLO
import {useMutation} from '@apollo/client';
import SAVE_EVENT from 'ApolloClient/Mutations/saveEvent';
// COMPONENTS
import message from 'components/common/message';
import PageHeader from 'components/text/PageHeader';
import {LeftOutlined} from '@ant-design/icons';
import EventForm from 'components/forms/EventForm';
// LIB
import theme from 'lib/theme';

const BackButton = styled.button`
  background: transparent;
  border: 0px;
  margin-bottom: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: ${(p) => p.theme.colors.neutral2};
  font-family: ${(p) => p.theme.fontFamily};
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    color: ${(p) => p.theme.colors.primary1};
  }
`;

export default function NewEventForm({onCancel}) {
  const [saveEvent, {loading}] = useMutation(SAVE_EVENT);

  const onSubmit = async (values) => {
    try {
      let variables = {
        params: {
          ...values,
        },
      };
      await saveEvent({
        variables,
      });
      onCancel();
      message.success('Event successfully added');
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <BackButton onClick={onCancel}>
        <LeftOutlined
          style={{
            marginRight: 4,
            marginTop: 1,
            fontSize: 9,
            color: theme.colors.neutral1,
          }}
        />
        Cancel
      </BackButton>
      <div
        style={{
          background: '#fff',
          width: '100%',
          margin: 'auto',
          padding: 16,
        }}
      >
        <PageHeader style={{marginLeft: 32}}>Add New Event</PageHeader>
        <EventForm onSubmit={onSubmit} onCancel={onCancel} loading={loading} />
      </div>{' '}
    </>
  );
}
