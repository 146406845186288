import React from "react";
import queryString from "query-string";
// COMPONENTS
import SideNav from "./SideNav";
import SitesTab from "./SitesTab";
import EventsTab from "./EventsTab";
import ProgramsTab from "./ProgramsTab";
import FundingTab from "./FundingTab";
import IncentivesTab from "./IncentivesTab";
import OrganizationsTab from "./OrganizationsTab";
import Row from "components/common/Row";
import Col from "components/common/Col";

class ResourcesTab extends React.PureComponent {
  state = {
    sidenav: "sites",
    newPerson: false,
    newTag: false,
  };
  componentDidMount() {
    const { sidenav } = queryString.parse(this.props.location.search);
    if (!sidenav) return this.onParamsChange({ sidenav: "sites" });
  }
  onParamsChange = (newParams) => {
    // get current url params
    let currentParams = queryString.parse(this.props.location.search);
    // meld the current params with the new incoming params object
    let newData = {
      ...currentParams,
      ...newParams,
    };
    // stringify a new params string
    let newString = queryString.stringify(newData);
    // update the user with the new params
    this.props.navigate(`/app/resources?${newString}`);
  };
  render() {
    const { sidenav } = queryString.parse(this.props.location.search);

    let defaultProps = {
      history: this.props.navigate,
    };

    return (
      <div>
        {sidenav === "sites" && <SitesTab {...defaultProps} />}
        {sidenav === "events" && <EventsTab {...defaultProps} />}
        {sidenav === "programs" && <ProgramsTab {...defaultProps} />}
        {sidenav === "incentives" && <IncentivesTab {...defaultProps} />}
        {sidenav === "funding" && <FundingTab {...defaultProps} />}{" "}
        {sidenav === "organizations" && <OrganizationsTab {...defaultProps} />}
      </div>
    );

    // return (
    //   <Row
    //     style={{
    //       margin: 'auto',
    //     }}
    //     align="top"
    //   >
    //     <Col xs={4}>
    //       <div style={{paddingTop: 40}}>
    //         <SideNav onParamsChange={this.onParamsChange} sidenav={sidenav} />
    //       </div>
    //     </Col>
    //     <Col xs={20}>
    //       {sidenav === 'sites' && <SitesTab {...defaultProps} />}
    //       {sidenav === 'events' && <EventsTab {...defaultProps} />}
    //       {sidenav === 'programs' && <ProgramsTab {...defaultProps} />}
    //       {sidenav === 'incentives' && <IncentivesTab {...defaultProps} />}
    //       {sidenav === 'funding' && <FundingTab {...defaultProps} />}{' '}
    //       {sidenav === 'organizations' && (
    //         <OrganizationsTab {...defaultProps} />
    //       )}
    //     </Col>
    //   </Row>
    // );
  }
}
export default ResourcesTab;
