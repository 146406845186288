import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import useUrlChange from "hooks/useUrlChange";
import Drawer from "components/common/Drawer";
import formTypes from "lib/constants/formTypes";
import helpers from "lib/helpers/GeneralHelpers/index";
import { CustomerFeatureSettingKeyEnum } from "generated/graphql";

const Button = styled.button`
  background: ${(p) => p.theme.colors.primary5};
  border: 0px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  position: fixed;
  bottom: 24px;
  right: 24px;
  z-index: 100;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
`;

const Menu = styled.div`
  background: #fff;
  min-height: 260px;
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 0px;
  z-index: 1000;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
`;

const MenuOption = styled.button`
  border: 0px;
  background: transparent;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral9};
  height: 64px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
`;

export default function GlobalAdd({ currentUser }) {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const { onUrlChange } = useUrlChange();
  return (
    <>
      <Button
        onClick={() => setShowMenu(true)}
        data-testid="global-add-btn-mobile"
      >
        <span style={{ position: "relative", top: -2 }}>+</span>
      </Button>

      <Drawer
        open={showMenu}
        placement="bottom"
        onClose={() => setShowMenu(false)}
      >
        <Menu>
          <MenuOption
            onClick={() => {
              setShowMenu(false);
              onUrlChange({ drawerVisible: formTypes.CONTACTS });
            }}
          >
            + New Contact
          </MenuOption>
          <MenuOption
            onClick={() => {
              setShowMenu(false);
              onUrlChange({ drawerVisible: formTypes.BUSINESSES });
            }}
          >
            + New Business
          </MenuOption>
          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Cases,
          }) && (
            <MenuOption
              onClick={() => {
                setShowMenu(false);
                onUrlChange({ drawerVisible: formTypes.CASES });
              }}
              data-testid="global-add-case"
            >
              + New Case
            </MenuOption>
          )}
          <MenuOption
            onClick={() => {
              setShowMenu(false);
              onUrlChange({ drawerVisible: formTypes.TODOS });
            }}
          >
            + New Todo
          </MenuOption>
          <MenuOption
            onClick={() => {
              setShowMenu(false);
              onUrlChange({ drawerVisible: formTypes.CONTACTS });
            }}
          >
            + New Contact
          </MenuOption>
          <MenuOption
            onClick={() => {
              setShowMenu(false);
              onUrlChange({ drawerVisible: formTypes.VISIT });
            }}
          >
            + New Site Visit
          </MenuOption>
          <MenuOption
            onClick={() => {
              setShowMenu(false);
              onUrlChange({ drawerVisible: formTypes.PHONE });
            }}
          >
            + New Phone Call
          </MenuOption>
          <MenuOption
            onClick={() => {
              setShowMenu(false);
              onUrlChange({ drawerVisible: formTypes.EMAIL });
            }}
          >
            + New Email
          </MenuOption>

          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Surveys,
          }) && (
            <MenuOption onClick={() => navigate("/app/survey")}>
              + New Survey
            </MenuOption>
          )}
        </Menu>
        {/* <Overlay onClick={() => setShowMenu(false)} /> */}
      </Drawer>
    </>
  );
}
