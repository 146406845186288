// import React, { useState, useEffect } from "react";
// import { get } from "lodash";
// import Select from "antd/lib/select";
// import { useQuery } from "@apollo/client";
// import ORGANIZATIONS from "ApolloClient/Queries/organizations";
// import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

// const { Option } = Select;

// interface OrganizationInputProps {
//   value: any;
//   defaultSearch?: string;
//   placeholder?: string;
//   onChange: any;
//   defaultOrganizationId?: string;
// }

// export default function OrganizationInput({
//   value,
//   defaultSearch,
//   placeholder,
//   onChange,
//   defaultOrganizationId, // in cases where
// }: OrganizationInputProps) {
//   const [searchText, setSearchText] = useState<string | undefined>(undefined);
//   const { data, loading } = useQuery(ORGANIZATIONS, {
//     variables: {
//       limit: 10,
//       params: {
//         searchText,
//         // organizationIds:
//         //   !searchText && defaultContactId ? [defaultContactId] : undefined,
//       },
//     },
//   });

//   const organizations = get(data, "organizations.organizations", []);

//   useEffect(() => {
//     setSearchText(defaultSearch);
//   }, [defaultSearch]);

//   return (
//     <DropdownStyleWrapper>
//       <Select
//         value={!loading && value}
//         placeholder={placeholder}
//         onSearch={(value) => setSearchText(value)}
//         showSearch
//         showArrow={false}
//         filterOption={false}
//         style={{ width: "100%" }}
//         notFoundContent={loading ? "searching..." : null}
//         onChange={(value) => {
//           if (value === "clear") {
//             return onChange(undefined);
//           }
//           onChange(value);
//         }}
//       >
//         {organizations &&
//           organizations.map((item) => {
//             return (
//               <Option key={item.id} value={item.id}>
//                 <strong style={{ marginRight: 8, fontSize: 16 }}>
//                   {item.title}{" "}
//                 </strong>
//               </Option>
//             );
//           })}
//       </Select>
//     </DropdownStyleWrapper>
//   );
// }

import { useRef, useState } from "react";
import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import {
  OrganizationsQueryVariables,
  useOrganizationsQuery,
  useSaveOrganizationMutation,
} from "generated/graphql";
import styled from "styled-components";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import message from "components/common/message";
import LinkButton from "components/common/LinkButton";
import TextInput from "../TextInput";
import theme from "lib/theme";

const { Option } = Select;

type OrganizationInputProps = {
  value?: string;
  placeholder?: string;
  onChange: any;
  defaultOrganizationId?: string;
  onBlur?: any;
  canCreateNew?: boolean;
};

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const CreateNewOrganizationBlock = ({ menu, onNewOrganizationCreated }) => {
  const [createNewValue, setCreateNewValue] = useState<string | undefined>(
    undefined
  );
  const [saveOrganizationMutation, { loading }] = useSaveOrganizationMutation();

  const handleCreateNew = async () => {
    try {
      if (!createNewValue?.[1]) {
        return message.error("Please provide a organiation name");
      }

      const result = await saveOrganizationMutation({
        variables: {
          params: {
            title: createNewValue,
          },
        },
      });
      setCreateNewValue(undefined);
      onNewOrganizationCreated(result?.data?.saveOrganization);
      message.success("New business created");
    } catch (err) {}
  };
  return (
    <>
      {menu}
      <div
        style={{
          padding: "8px 16px",
          borderTop: `1px solid ${theme.colors.neutral9}`,
        }}
      >
        <Flex>
          <TextInput
            placeholder="Enter a name here to create a new organization"
            onChange={(newValue) => {
              setCreateNewValue(newValue);
            }}
            value={createNewValue || ""}
          />
          <LinkButton
            style={{ width: 150, marginLeft: 16 }}
            onClick={handleCreateNew}
            disabled={loading}
          >
            {!loading ? (
              <>
                <PlusOutlined /> Add Org
              </>
            ) : (
              <LoadingOutlined />
            )}
          </LinkButton>
        </Flex>
      </div>
    </>
  );
};

export default function OrganizationInput({
  value,
  placeholder,
  onChange,
  defaultOrganizationId,
  onBlur,
  canCreateNew = false,
}: OrganizationInputProps) {
  const [defaultValue, setDefaultBusinessId] = useState(defaultOrganizationId);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(undefined);
  const selectRef = useRef<any>(null);
  const variables: OrganizationsQueryVariables = {
    searchText,
    includesAtleastOrganizationIds: value ? [value] : [],
    organiationIds:
      !searchText?.[0] && defaultValue ? [defaultValue] : undefined,
    limit: 20,
  };

  const { data, loading } = useOrganizationsQuery({
    variables: variables,
    fetchPolicy: "cache-and-network",
  });

  const organizations = data?.organizations?.organizations
    ? [...data?.organizations?.organizations]
    : [selectedBusiness];

  const handleOnChange = (newId) => {
    setDefaultBusinessId(newId);
    onChange(newId);
    setSelectedBusiness(organizations?.find((biz) => biz.id === newId));
    setSearchText(undefined);
    selectRef.current.blur();
  };

  const dropdownRender = (menu) => {
    return (
      <CreateNewOrganizationBlock
        menu={menu}
        onNewOrganizationCreated={(newBusiness) =>
          handleOnChange(newBusiness?.id)
        }
      />
    );
  };

  return (
    <DropdownStyleWrapper>
      <Select
        ref={selectRef}
        data-testid="organization-select-input"
        onBlur={onBlur}
        value={!loading && value ? value : undefined}
        placeholder={placeholder || "Type to search for organizations..."}
        onSearch={(value) => setSearchText(value as string)}
        showSearch
        allowClear
        showArrow={false}
        filterOption={false}
        style={{ width: "100%" }}
        notFoundContent={loading ? "searching..." : null}
        onChange={(newId) => handleOnChange(newId)}
        optionLabelProp="label"
        dropdownRender={canCreateNew ? dropdownRender : undefined}
      >
        {organizations?.map((item) => {
          if (!item?.id) return null;
          return (
            <Option
              key={item.id}
              value={item.id}
              label={item.title}
              data-testid={`organization-select-input-option-${item.title}`}
            >
              <strong style={{ marginRight: 8, fontSize: 16 }}>
                {item.title}{" "}
              </strong>
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
}
