import { useState } from "react";
import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import { useProjectsQuery } from "generated/graphql";

const { Option } = Select;

export default function ProjectInput({
  value,
  placeholder,
  onChange,
  defaultProjectId,
  containerStyle,
  onBlur,
}: {
  value?: string;
  placeholder?: string;
  onChange?: any;
  defaultProjectId?: string;
  containerStyle?: React.CSSProperties;
  onBlur?: any;
}) {
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const { data, loading } = useProjectsQuery({
    variables: {
      searchText,
      projectIds:
        !searchText && defaultProjectId ? [defaultProjectId] : undefined,
    },
    fetchPolicy: "cache-and-network",
  });

  const projects = data?.projects || [];

  return (
    <DropdownStyleWrapper style={containerStyle}>
      <Select
        value={!loading && value ? (value as any) : undefined}
        placeholder={placeholder}
        onSearch={(value) => setSearchText(value as string)}
        showSearch
        onBlur={onBlur}
        loading={loading}
        showArrow={false}
        filterOption={false}
        style={{ width: "100%" }}
        notFoundContent={loading ? "searching..." : null}
        onChange={onChange}
        optionLabelProp="label"
      >
        {projects &&
          projects.map((item) => {
            if (!item?.id) return null;
            return (
              <Option key={item.id} value={item.id} label={item.title}>
                <strong style={{ marginRight: 8, fontSize: 16 }}>
                  {item.title}
                </strong>
              </Option>
            );
          })}
      </Select>
    </DropdownStyleWrapper>
  );
}
