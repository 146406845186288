// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import todoFragment from '../Fragments/todoFragment';

export default gql`
  mutation saveTodo($id: ID, $params: TodoParams) {
    saveTodo(id: $id, params: $params) {
      ...todoFragment
    }
  }
  ${todoFragment}
`;
