// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  fragment tagFragment on Tag {
    id
    title
    description
    type
  }
`;
