// TOP LEVEL IMPORTS
import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ErrorHelpers from "lib/helpers/ErrorHelpers";
// COMPONENTS
import ForgotPasswordForm from "./ForgotPasswordForm";
import message from "components/common/message";
import { CheckCircleFilled } from "@ant-design/icons";
import Caption from "components/text/Caption";
import AuthHelpers from "lib/helpers/AuthHelpers";
import logo from "lib/media/logo2.svg";

const Background = styled.div`
  background-color: #fff;
`;
const TitleContainer = styled.div`
  min-height: 95px;
`;
const Logo = styled.img`
  height: 50px;
  margin: auto;
  margin-bottom: 16px;
`;
const FormContainer = styled.div`
  width: 500px;
  padding: 16px;
  text-align: center;
  max-width: 100%;
  margin: auto;
  padding-top: 20px;
  padding-top: 20px;
  background: #fff;
  padding: 32px 64px;
  border-radius: 5px;
  @media only screen and (max-width: 414px) {
    padding: 20px;
  }
`;

const Container = styled.div`
  align-items: center;
  padding-top: 20px;
  display: flex;
  height: 100vh;
`;

const Title = styled.h2`
  text-align: center;
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral3};
`;

const GreenSuccess = styled(CheckCircleFilled)`
  color: ${(p) => p.theme.colors.primary6};
  font-size: 48px;
  margin-bottom: 8px;
`;

// STYLED-COMPONENTS
// ========================================
const SuccessCard = () => (
  <div style={{ height: 257 }}>
    <div style={{ textAlign: "center" }}>
      <GreenSuccess />
      <Title data-testid="forgot-password-success-card">
        Please Check Your Email!
      </Title>
      <h3 style={{ textAlign: "center", margin: 0, color: "#888" }}>
        A password reset link has been sent to your email
      </h3>
    </div>
  </div>
);

// EXPORTED COMPONENT
// ========================================
class AuthForgotPassword extends React.PureComponent {
  state = {
    loading: false,
    emailSent: false,
    errors: [], // holds errors from server, antd's FormItem handles displaying client validation errors of inputs
  };

  onSucessFullyValidated = async (email) => {
    this.setState({ loading: true });
    try {
      await AuthHelpers.sendPasswordResetEmail({ email });
    } catch (err: any) {
      console.log(err);
      let errMessage = err.message.replace("GraphQL", "");
      if (err && err.message.includes("Incorrect password [403]")) {
        errMessage = "You have entered an invalid username or password";
      }
      return this.setState({
        loading: false,
        errors: [ErrorHelpers.cleanErrorString(errMessage)],
      });
    }
    this.setState({ loading: false, emailSent: true });
    message.success("A recovery email has been sent to your inbox!", 4);
  };

  render() {
    return (
      <Background>
        <Container>
          {" "}
          <FormContainer>
            {!this.state.emailSent ? (
              <React.Fragment>
                <TitleContainer>
                  {" "}
                  <Logo src={logo} />
                  <Caption style={{ width: "90%", margin: "auto" }}>
                    Recover your password
                  </Caption>
                </TitleContainer>
                <div>
                  <ForgotPasswordForm
                    loading={this.state.loading}
                    // errors={this.state.errors}
                    onSucessFullyValidated={this.onSucessFullyValidated}
                  />
                  <Caption style={{ marginTop: 16 }}>
                    <Link to="/">Login</Link>
                  </Caption>
                </div>
              </React.Fragment>
            ) : (
              <SuccessCard />
            )}
          </FormContainer>
        </Container>
      </Background>
    );
  }
}

// EXPORT
// ========================================
export default AuthForgotPassword;
