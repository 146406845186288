// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

export default gql`
  fragment organizationFragment on Organization {
    id
    title
    description
    businessStages
    clusters
    diversity
    orgType
    serviceTypes
    tagIds
    contactIds
    contacts {
      id
      email
      firstName
      lastName
      title
      website
      workPhone
      avatar {
        id
        url
      }
    }
  }
`;
