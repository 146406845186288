import styled from "styled-components";
import { useState } from "react";
import {
  AutomationTriggerType,
  AutomationEventType,
  usePipelinesByCustomerQuery,
  AutomationInput,
  CustomerFeatureSettingKeyEnum,
  CurrentUserFragmentFragment,
} from "generated/graphql";
import Button from "components/common/Button";
import Row from "components/common/Row";
import Col from "components/common/Col";
import theme from "lib/theme";
import SelectInput from "components/inputs/SelectInput";
import TextInput from "components/inputs/TextInput";
import AutomationActionsSelectInput from "components/inputs/AutomationActionsSelectInput";
import EventEditor from "../EventEditor";
import { formatAutomatinInput } from "../index";
import {
  StepCircle,
  PageTitle,
  InlineText,
  TagText,
  Card,
  StepTitle,
  ChevronCircle,
  LeftColLine,
  PageContainer,
} from "../shared";
import featureIsOn from "lib/helpers/featureIsOn";

const BtnContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
`;

const LeftCol = styled.div`
  padding-top: 35px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const inputContainerStyles = {
  marginLeft: 4,
  marginRight: 4,
  maxWidth: "100%",
  width: 270,
  display: "inline-flex",
  height: 40,
  backgroundColor: theme.colors.neutral11,
};

const Container = styled.div`
  .ant-select-selection-item {
    top: 4px;
  }
  .ant-select-selection-placeholder {
    top: 4px !important;
    position: relative;
  }
`;

const AlignedRow = styled.div`
  display: flex;
  align-items: flex-end;
`;

export default function ProjectStatusChangeForm({
  onSubmit,
  loading,
  currentUser,
}: {
  loading?: boolean;
  onSubmit: (values: AutomationInput) => void;
  currentUser: CurrentUserFragmentFragment;
}) {
  const [name, setName] = useState(undefined);
  const [pipelineId, setPipelineId] = useState(undefined);
  const [changedToStageId, setChangedToStageId] = useState(undefined);
  const [events, setEvents] = useState<any[]>([]);
  const { data, loading: loadingPipelines } = usePipelinesByCustomerQuery();

  const ACTIONS_SECTIONS = [
    {
      title: "SEND EMAIL TO",
      options: [
        {
          type: AutomationEventType?.NotifyTeamMember,
          label: "Team Member",
        },
      ],
    },
    // {
    //   title: "NOTIFY INTEGRATION",
    //   options: [
    //     {
    //       type: AutomationEventType?.AddBusinessToConstantContactList,
    //       label: "Add Business to Constant Contact List",
    //     },
    //   ],
    // },

    ...(featureIsOn({
      customer: currentUser.customer,
      feature: CustomerFeatureSettingKeyEnum.Cases,
    })
      ? [
          {
            title: "CREATE THE FOLLOWING",
            options: [
              {
                type: AutomationEventType?.CreateACase,
                label: "Create a Case",
              },
            ],
          },
        ]
      : []),
  ];

  const onHandleSubmit = () => {
    onSubmit(
      formatAutomatinInput({
        name,
        trigger: AutomationTriggerType.ProjectStatusChange,
        pipelineId,
        conditions: {
          all: [
            {
              fact: "stageId",
              operator: "equal",
              value: changedToStageId,
            },
            // ensure old status is not the same as new status (ie make sure they changed the status)
            {
              fact: "previous.stageId",
              operator: "notEqual",
              value: changedToStageId,
            },
          ],
        },
        events,
      })
    );
  };

  const selectedPipeline = data?.pipelinesByCustomer?.find(
    (pipe) => pipe?.id === pipelineId
  );

  const stages = selectedPipeline?.stages?.map((stage) => stage);

  const disabled =
    !name?.[0] || !changedToStageId || !pipelineId || !events?.[0]?.params;

  console.log({
    events,
  });

  return (
    <PageContainer>
      <Container>
        <PageTitle>New “Project Status Change” Automation Rule</PageTitle>
        <StepTitle>
          <StepCircle>1</StepCircle>Alert Name
        </StepTitle>
        <TextInput
          value={name}
          onChange={(value) => setName(value)}
          style={{
            width: 500,
            background: "#fff",
          }}
          placeholder="Enter alert name..."
        />
        <StepTitle>
          <StepCircle>2</StepCircle>Set Conditions
        </StepTitle>
        <Card>
          <Row align="top">
            <Col xs={1}>
              <LeftCol>
                <ChevronCircle />
                <LeftColLine />
                <ChevronCircle />
              </LeftCol>
            </Col>
            <Col xs={23}>
              <AlignedRow
                style={{
                  marginBottom: 56,
                }}
              >
                <InlineText>
                  <TagText>WHEN</TagText>a project in the
                </InlineText>{" "}
                <SelectInput
                  placeholder="Select a pipeline"
                  options={
                    data?.pipelinesByCustomer?.[0]
                      ? data?.pipelinesByCustomer?.map((stage) => ({
                          id: stage?.id,
                          label: stage?.title,
                        }))
                      : []
                  }
                  value={pipelineId}
                  onChange={(newPipelineId) => {
                    setChangedToStageId(undefined);
                    setPipelineId(newPipelineId);
                  }}
                  loading={loadingPipelines}
                  containerStyle={inputContainerStyles}
                />
                <InlineText>pipeline has the stage change to</InlineText>{" "}
                <SelectInput
                  placeholder="Select a stage"
                  options={
                    stages?.[0]
                      ? stages?.map((stage) => ({
                          id: stage?.id,
                          label: stage?.label,
                        }))
                      : []
                  }
                  value={changedToStageId}
                  onChange={(newStageId) => setChangedToStageId(newStageId)}
                  loading={false}
                  containerStyle={inputContainerStyles}
                />
              </AlignedRow>
              <AlignedRow>
                <InlineText>
                  <TagText>THEN</TagText>Perform these actions:
                </InlineText>{" "}
                <AutomationActionsSelectInput
                  placeholder="Add an action"
                  actions={ACTIONS_SECTIONS?.[0] ? ACTIONS_SECTIONS : []}
                  onChange={(newEvent) => {
                    setEvents((oldEvents) => [
                      ...oldEvents,
                      {
                        ...newEvent,
                        params: {
                          pipelineId,
                        },
                      },
                    ]);
                  }}
                  loading={false}
                  containerStyle={{
                    ...inputContainerStyles,
                    width: 400,
                  }}
                />
              </AlignedRow>
              <div>
                {events?.map((event, index) => {
                  return (
                    <EventEditor
                      event={event}
                      key={event.id + index}
                      onParamsChange={(newParams) => {
                        setEvents((oldEvents) =>
                          [...oldEvents]?.map((evt) => {
                            if (evt.id === event.id) {
                              return {
                                ...evt,
                                params: {
                                  ...evt.params,
                                  ...newParams,
                                },
                              };
                            } else {
                              return evt;
                            }
                          })
                        );
                      }}
                      onRemove={() => {
                        setEvents((oldEvents) =>
                          [...oldEvents]?.filter(
                            (evt) => evt.type !== event.type
                          )
                        );
                      }}
                    />
                  );
                })}
              </div>
            </Col>
          </Row>
        </Card>
        <BtnContainer>
          <Button
            disabled={disabled || loading}
            loading={loading}
            onClick={onHandleSubmit}
          >
            Create Rule
          </Button>
        </BtnContainer>
      </Container>
    </PageContainer>
  );
}
