// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";
import businessByIdFragment from "ApolloClient/Fragments/businessByIdFragment";

export default gql`
  query businessById($id: ID!) {
    businessById(id: $id) {
      ...businessByIdFragment
    }
  }
  ${businessByIdFragment}
`;
