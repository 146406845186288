import { Modal, Select, Tabs, Tooltip, message } from "antd";
import Button from "components/common/Button";
import FormItem from "components/common/FormItem";
import Table from "components/common/Table";
import TextInput from "components/inputs/TextInput";
import Title from "components/text/Title";
import moment from "moment";
import {
  GetManyListsOwnerTypeEnum,
  ListFragmentFragment,
  ListTypeEnum,
  useAddManyItemsToSurveyCampaignMutation,
  useCreateOneListMutation,
  useCreateOneSurveyCampaignMutation,
  useGetManyListsQuery,
  useGetManySurveyCampaignsQuery,
} from "generated/graphql";
import GeneralHelpers from "lib/helpers/GeneralHelpers";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { StringParam, useQueryParam } from "use-query-params";

const PageContainer = styled.div`
  width: 1400px;
  margin: auto;
  padding-top: 24px;
  max-width: 100%;
  height: calc(100vh - 61px);
`;

const TabContent = ({ lists, onAddToSurvey }) => {
  const [listId, setListId] = useQueryParam("listId", StringParam);
  const COLUMNS = [
    {
      key: "id",
      title: "Name",
      render: (record: ListFragmentFragment) => record?.name,
    },
    {
      title: "Last modified",
      render: (record: ListFragmentFragment) =>
        record?.updatedAt &&
        moment(record?.updatedAt)?.format("MM/DD/YYYY hh:mma"),
    },
    {
      title: "Owner",
      render: (record: ListFragmentFragment) => record?.createdBy?.email,
    },
    {
      title: "Records",
      render: (record: ListFragmentFragment) => record?.numberOfRecords,
    },
    {
      title: "Actions",
      render: (record: ListFragmentFragment) => {
        return (
          <Tooltip title="Start a survey campaign. Clicking this won't send the survey right now. You will configure your campaign first.">
            <div>
              <Button grey onClick={() => onAddToSurvey(record.id)}>
                Send a Survey
              </Button>
            </div>
          </Tooltip>
        );
      },
    },
  ];
  return (
    <>
      <Table
        columns={COLUMNS}
        dataSource={lists}
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: (e) => {
        //       setListId(record.id);
        //     },
        //     onDoubleClick: (event) => {}, // double click row
        //     onContextMenu: (event) => {}, // right button click row
        //     onMouseEnter: (event) => {}, // mouse enter row
        //     onMouseLeave: (event) => {}, // mouse leave row
        //   };
        // }}
      />
    </>
  );
};

const ListModal = ({
  onClose,
  defaultName,
  open,
  loading,
  onSubmit,
}: {
  onClose: any;
  open: boolean;
  onSubmit: any;
  loading?: boolean;
  defaultName?: string;
}) => {
  const [name, setName] = useState(defaultName);

  const handleOnSubmit = () => {
    if (!name?.[0]) return message.error("Please provide a list name.");
    onSubmit({ name });
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <Title style={{ marginBottom: 40 }}>New List </Title>
      <FormItem label="Name">
        <TextInput onChange={(value) => setName(value)} />
      </FormItem>
      <>
        <Button
          disabled={loading}
          loading={loading}
          onClick={handleOnSubmit}
          style={{ marginTop: 24 }}
        >
          Save
        </Button>
      </>
    </Modal>
  );
};

const SurveyCampaignModal = ({
  onClose,
  open,
  loading,
  listId,
  listType,
  listName,
}: {
  onClose: any;
  open: boolean;
  loading?: boolean;
  listId?: string;
  listType: ListTypeEnum;
  listName?: string;
}) => {
  const { data } = useGetManySurveyCampaignsQuery({
    variables: {
      listType,
    },
  });
  const navigate = useNavigate();
  const [addManyItemsToSurveyCampaignMutation] =
    useAddManyItemsToSurveyCampaignMutation();
  const [surveyCampaignId, setSurveyCampaignId] = useState<string | undefined>(
    undefined
  );

  const handleOnSubmit = async () => {
    try {
      if (!surveyCampaignId || !listId) return;
      const result = await addManyItemsToSurveyCampaignMutation({
        variables: {
          listId,
          surveyCampaignId,
        },
      });
      navigate(
        `/app/surveys/campaigns/${result?.data?.addManyItemsToSurveyCampaign?.id}`
      );
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Modal open={open} onCancel={onClose} footer={null}>
      <Title style={{ marginBottom: 40 }}>
        Add {listName || ""} List to Survey Campaign
      </Title>
      <FormItem label="Campaign">
        <Select
          size="large"
          value={surveyCampaignId || ""}
          onChange={(newValue) => {
            if (newValue === "viewAll") {
              window.open(
                "/app/surveys/campaigns",
                "_blank",
                "rel=noopener noreferrer"
              );
              return undefined;
            }

            setSurveyCampaignId(newValue);
          }}
          style={{ width: "100%" }}
          placeholder="Select a survey campaign..."
          showSearch
        >
          {data?.getManySurveyCampaigns?.map((item) => {
            return (
              <Select.Option key={item?.id} value={item?.id}>
                {item?.name}
              </Select.Option>
            );
          })}
          <Select.Option key="viewAll" value="viewAll">
            View All Survey Campaigns
          </Select.Option>
        </Select>
      </FormItem>
      <>
        <Button
          disabled={loading || !surveyCampaignId}
          loading={loading}
          onClick={handleOnSubmit}
          style={{ marginTop: 24 }}
        >
          Add list to campaign
        </Button>
      </>
    </Modal>
  );
};

export default function AppListsPage() {
  const [createOneListMutation, { loading: creatingList }] =
    useCreateOneListMutation();
  // Define a state variable for the selected list type
  const [selectedListType, setSelectedListType] = useState<ListTypeEnum>(
    ListTypeEnum.Contacts
  );

  const [selectedOwnerType, setSelectedOwnerType] =
    useState<GetManyListsOwnerTypeEnum>(GetManyListsOwnerTypeEnum.My);
  const [showNewList, setShowNewList] = useState<boolean>(false);
  const [showAddToCampaign, setShowAddToCampaign] = useState<string | false>(
    false
  );

  const [listId] = useQueryParam("listId", StringParam);

  const { data } = useGetManyListsQuery({
    variables: {
      listType: selectedListType,
      ownerType: selectedOwnerType,
    },
  });

  if (listId) {
    return <PageContainer>test</PageContainer>;
  }

  return (
    <PageContainer>
      <ListModal
        open={showNewList}
        onClose={() => setShowNewList(false)}
        loading={creatingList}
        onSubmit={async (values) => {
          try {
            await createOneListMutation({
              variables: {
                listType: selectedListType,
                name: values?.name,
              },
              refetchQueries: ["getManyLists"],
            });
            setShowNewList(false);
          } catch (err: any) {
            console.log(err);
            message.error(err.message);
          }
        }}
      />

      <SurveyCampaignModal
        open={showAddToCampaign ? true : false}
        onClose={() => setShowAddToCampaign(false)}
        loading={creatingList}
        listId={showAddToCampaign || undefined}
        listType={selectedListType}
        listName={
          data?.getManyLists?.results?.find(
            (list) => list.id === showAddToCampaign
          )?.name
        }
      />
      <Select
        style={{ width: 300 }}
        value={selectedListType}
        onChange={(value) => {
          setSelectedListType(value);
        }}
      >
        {Object.values(ListTypeEnum)?.map((item) => {
          return (
            <Select.Option value={item}>
              {GeneralHelpers.capitalize(item)}
            </Select.Option>
          );
        })}
      </Select>
      <Tabs
        defaultActiveKey={selectedOwnerType}
        onChange={(value) =>
          setSelectedOwnerType(value as GetManyListsOwnerTypeEnum)
        }
        tabBarExtraContent={{
          right: (
            <>
              <Button secondary onClick={() => setShowNewList(true)}>
                New List
              </Button>
            </>
          ),
        }}
        items={[
          {
            key: GetManyListsOwnerTypeEnum.All,
            label: "All",
            children: (
              <TabContent
                lists={data?.getManyLists?.results}
                onAddToSurvey={(listId) => setShowAddToCampaign(listId)}
              />
            ),
          },
          {
            key: GetManyListsOwnerTypeEnum.My,
            label: "My",
            children: (
              <TabContent
                lists={data?.getManyLists?.results}
                onAddToSurvey={(listId) => setShowAddToCampaign(listId)}
              />
            ),
          },
          {
            key: GetManyListsOwnerTypeEnum.Team,
            label: "Team",
            children: (
              <TabContent
                lists={data?.getManyLists?.results}
                onAddToSurvey={(listId) => setShowAddToCampaign(listId)}
              />
            ),
          },
        ]}
      />
    </PageContainer>
  );
}
