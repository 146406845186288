import { AutomationTriggerType } from "generated/graphql";
import { NodeIndexOutlined, SelectOutlined } from "@ant-design/icons";
import theme from "lib/theme";

export default function AutomationTriggerIcon({
  triggerType,
  size = 32,
  color = theme.colors.primary4,
}: {
  triggerType: AutomationTriggerType;
  size?: number;
  color?: string;
}) {
  return (
    <>
      {(() => {
        switch (triggerType) {
          case AutomationTriggerType?.ProjectStatusChange?.toString():
            return <NodeIndexOutlined style={{ fontSize: size, color }} />;
          case AutomationTriggerType?.FormSurveyResponse?.toString():
            return <SelectOutlined style={{ fontSize: size, color }} />;
          default:
            return null;
        }
      })()}
    </>
  );
}
