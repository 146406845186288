import { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import Button from "components/common/Button";
import FormHeader from "components/text/FormHeader";
import SelectInput from "components/inputs/SelectInput";
import {
  useGetManyFormTemplatesQuery,
  useGetManySurveyTemplatesNewQuery,
} from "generated/graphql";

const Card = styled.div`
  background: #fff;
  padding: 24px;
  border-radius: 5px;
  margin: auto;
`;

export default function SurveyTemplateOptions({ customerId, onChange }) {
  const [value, setValue] = useState(undefined);
  const { data, loading } = useGetManySurveyTemplatesNewQuery();

  return (
    <Card>
      <FormHeader>Select a Form Template to Use</FormHeader>
      <SelectInput
        value={value}
        options={data?.getManySurveyTemplatesNew?.map((temp) => {
          return {
            id: temp?._id || "",
            label: temp?.title || "",
          };
        })}
        containerStyle={{ width: "100%", marginTop: 16 }}
        loading={loading}
        onChange={(newValue) => setValue(newValue)}
      />
      <Button
        disabled={!value}
        style={{ marginTop: 24 }}
        onClick={() => onChange(value)}
      >
        Use this Form
      </Button>
    </Card>
  );
}

// export default function BusinessInput({
//   value,
//   allowClear,
//   placeholder,
//   onChange,
//   defaultSearch,
// }) {
//   const [searchText, setSearchText] = useState(defaultSearch || '');
//   const {data, loading} = useQuery(BUSINESSES, {
//     variables: {
//       params: {
//         searchText,
//       },
//     },
//   });
//   const businesses = get(data, 'businesses.businesses', []);

//   useEffect(() => {
//     setSearchText(defaultSearch);
//   }, [defaultSearch]);

// }
