import styled from "styled-components";
import { PageTitle, Card } from "../index";
import { useNavigate } from "react-router-dom";
// APOLLO
import { useContactByIdQuery } from "generated/graphql";
import DetailImage, {
  DetailImageTypeEnum,
} from "components/detail-components/DetailImage";
import Tabs from "components/common/Tabs";
import { BreadCrumbLink } from "components/detail-components";
import { ArrowLeftOutlined } from "@ant-design/icons";
import Interactions from "components/detail-components/Interactions";
import FilesAndDocs from "components/detail-components/FilesAndDocs";
import Notes, { NoteParentTypeEnum } from "components/detail-components/Notes";

const VALUES = [
  {
    id: "description",
    type: "string",
    label: "Description",
  },
  {
    id: "phone",
    type: "phone",
    label: "Phone",
  },
  {
    id: "website",
    type: "website",
    label: "website",
  },
];

const { TabPane } = Tabs;

const Label = styled.label`
  font-weight: 600;
  color: ${({ theme }) => theme.colors.neutral6};
`;

const Value = ({ type, value }) => {
  if (type === "phone") {
    return <a href={`tel:+${value.replace(/-/g, "")}`}>{value}</a>;
  }
  if (type === "website") {
    return (
      <a href={`${value}`} target="_blank" rel="noreferrer">
        {value}
      </a>
    );
  }
  return <p>{value}</p>;
};

const Email = styled.a`
  display: block;
  margin-top: 0px;
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.neutral5} !important;
  &:hover {
    font-weight: 500;
    color: ${({ theme }) => theme.colors.neutral5} !important;
  }
`;

export default function BusinessDetail({ contactId, currentUser }) {
  const navigate = useNavigate();
  const { data, loading } = useContactByIdQuery({
    variables: {
      id: contactId,
    },
  });

  const contact = data?.contactById;

  if (loading || !contact) return null;

  return (
    <div>
      <div style={{ marginLeft: 8 }}>
        <BreadCrumbLink onClick={() => navigate(`/app/contacts`)}>
          <ArrowLeftOutlined /> Back to People
        </BreadCrumbLink>
      </div>
      <Card style={{ marginTop: 16 }}>
        <DetailImage
          type={DetailImageTypeEnum.contacts}
          src={contact?.avatar?.url || undefined}
        />
        <PageTitle style={{ marginTop: 8 }}>
          {contact.firstName} {contact.lastName}
          <Email href={`mailto:${contact.email}`}>{contact.email}</Email>
        </PageTitle>

        {VALUES.map((item) => {
          const value = contact[item.id];
          if (!value) return null;
          return (
            <div key={item.id} style={{ marginTop: 24 }}>
              <Label>{item.label}</Label>
              <div>
                <Value value={value} type={item.type} />
              </div>
            </div>
          );
        })}
      </Card>
      <Card style={{ marginTop: 16, paddingTop: 0 }}>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Notes" key="1">
            <Notes
              parentId={contact.id}
              parentType={NoteParentTypeEnum.Contacts}
              currentUser={currentUser}
            />
          </TabPane>
          <TabPane tab="Files" key="2">
            <FilesAndDocs parentId={contact?.id || ""} />
          </TabPane>
          <TabPane tab="Interactions" key="3">
            <Interactions
              queryVariables={{
                contactId: contact.id,
              }}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
}
