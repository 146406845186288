import React from 'react';
import styled from 'styled-components';
import Button from 'components/common/Button';
// LIB
import { ReactComponent as EmptyListSVG } from 'lib/media/emptyList.svg';
import { ReactComponent as EmptyListGreenSVG } from 'lib/media/emptyListGreen.svg';

const TodoHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
`;

const Title = styled.div`
  font-family: ${p => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 123%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #52606d;
`;
const Subtitle = styled.div`
  font-family: ${p => p.theme.fontFamily};
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 168.4%;
  text-align: center;
  letter-spacing: 0.04em;
  color: #9aa5b1;
  margin-bottom: 15px;
  margin-top: 10px;
`;

const EmptyState = ({ onNew, title = 'No todos yet' }) => (
  <div>
    <EmptyListGreenSVG
      style={{
        position: 'absolute',
        top: 10,
        left: 380
      }}
    />
    <TodoHolder>
      <EmptyListSVG style={{ marginBottom: 8 }} />
      <EmptyListSVG style={{ marginBottom: 40 }} />
      <Title>{title}</Title>
      <Subtitle>Get started by creating your first todo list</Subtitle>
      {onNew && (
        <Button onClick={onNew} style={{ width: 175 }}>
          Create a todo list
        </Button>
      )}
    </TodoHolder>
  </div>
);

export default EmptyState;
