import { useRef, useState } from "react";
import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";
import {
  BusinessesQueryVariables,
  useBusinessesQuery,
  useSaveBusinessMutation,
} from "generated/graphql";
import styled from "styled-components";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import message from "components/common/message";
import LinkButton from "components/common/LinkButton";
import TextInput from "../TextInput";
import theme from "lib/theme";

const { Option } = Select;

type BusinessInputProps = {
  value?: string;
  placeholder?: string;
  onChange: any;
  defaultBusinessId?: string;
  onBlur?: any;
  canCreateNew?: boolean;
};

const Flex = styled.div`
  display: flex;
  align-items: center;
`;

const CreateNewBusinessBlock = ({ menu, onNewBusinessCreated }) => {
  const [createNewValue, setCreateNewValue] = useState<string | undefined>(
    undefined
  );
  const [saveBusinessMutation, { loading }] = useSaveBusinessMutation();

  const handleCreateNew = async () => {
    try {
      if (!createNewValue?.[1]) {
        return message.error("Please provide a business name");
      }

      const result = await saveBusinessMutation({
        variables: {
          params: {
            title: createNewValue,
          },
        },
      });
      setCreateNewValue(undefined);
      onNewBusinessCreated(result?.data?.saveBusiness);
      message.success("New business created");
    } catch (err) {}
  };
  return (
    <>
      {menu}
      <div
        style={{
          padding: "8px 16px",
          borderTop: `1px solid ${theme.colors.neutral9}`,
        }}
      >
        <Flex>
          <TextInput
            placeholder="Enter a name here to create a new business"
            onChange={(newValue) => {
              setCreateNewValue(newValue);
            }}
            value={createNewValue || ""}
          />
          <LinkButton
            style={{ width: 150, marginLeft: 16 }}
            onClick={handleCreateNew}
            disabled={loading}
          >
            {!loading ? (
              <>
                <PlusOutlined /> Add business
              </>
            ) : (
              <LoadingOutlined />
            )}
          </LinkButton>
        </Flex>
      </div>
    </>
  );
};

export default function BusinessInput({
  value,
  placeholder,
  onChange,
  defaultBusinessId,
  onBlur,
  canCreateNew = false,
}: BusinessInputProps) {
  const [defaultValue, setDefaultBusinessId] = useState(defaultBusinessId);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(undefined);
  const selectRef = useRef<any>(null);
  const variables: BusinessesQueryVariables = {
    params: {
      searchText,
    },
    includesAtleastBusinessIds: value ? [value] : [],
    businessIds: !searchText?.[0] && defaultValue ? [defaultValue] : undefined,
    limit: 20,
  };

  const { data, loading } = useBusinessesQuery({
    variables: variables,
    fetchPolicy: "cache-and-network",
  });

  const businesses = data?.businesses?.businesses
    ? [...data?.businesses?.businesses]
    : [selectedBusiness];

  const handleOnChange = (newId) => {
    setDefaultBusinessId(newId);
    onChange(newId);
    setSelectedBusiness(businesses?.find((biz) => biz.id === newId));
    setSearchText(undefined);
    selectRef.current.blur();
  };

  const dropdownRender = (menu) => {
    return (
      <CreateNewBusinessBlock
        menu={menu}
        onNewBusinessCreated={(newBusiness) => handleOnChange(newBusiness?.id)}
      />
    );
  };

  return (
    <DropdownStyleWrapper>
      <Select
        ref={selectRef}
        data-testid="business-select-input"
        onBlur={onBlur}
        value={!loading && value ? value : undefined}
        placeholder={placeholder || "Type to search for businesses..."}
        onSearch={(value) => setSearchText(value as string)}
        showSearch
        allowClear
        showArrow={false}
        filterOption={false}
        style={{ width: "100%" }}
        notFoundContent={loading ? "searching..." : null}
        onChange={(newId) => handleOnChange(newId)}
        optionLabelProp="label"
        dropdownRender={canCreateNew ? dropdownRender : undefined}
      >
        {businesses?.map((item) => {
          if (!item?.id) return null;
          return (
            <Option
              key={item.id}
              value={item.id}
              label={item.title}
              data-testid={`business-select-input-option-${item.title}`}
            >
              <strong style={{ marginRight: 8, fontSize: 16 }}>
                {item.title}{" "}
              </strong>
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
}
