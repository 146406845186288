// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import commentFragment from 'ApolloClient/Fragments/commentFragment';

export default gql`
  mutation saveComment($id: ID, $params: CommentParams) {
    saveComment(id: $id, params: $params) {
      ...commentFragment
    }
  }
  ${commentFragment}
`;
