import React from "react";
import ResourceTable from "../ResourceTable";
import eventsQuery from "ApolloClient/Queries/events";
import columns from "./columns";
import NewEventForm from "./NewEventForm";

class EventsTab extends React.PureComponent {
  state = {
    addNew: false,
  };
  onRow = (record, rowIndex) => {
    return {
      onClick: (e) => {
        let element = e.srcElement || e.target;
        // only cancel if the user clicked the Link to business detail page
        if (element.id === "organization-click") {
          return null;
        }
        this.props.navigate(`/app/events/${record.id}`);
      },
      onDoubleClick: (event) => {}, // double click e
      onContextMenu: (event) => {}, // right button click row
      onMouseEnter: (event) => {}, // mouse enter row
      onMouseLeave: (event) => {}, // mouse leave row
    };
  };
  render() {
    if (this.state.addNew) {
      return (
        <div>
          <NewEventForm onCancel={() => this.setState({ addNew: null })} />
        </div>
      );
    }
    return (
      <div>
        <ResourceTable
          columns={columns}
          onRow={this.onRow}
          query={eventsQuery}
          dataField="events"
          dataName="Event"
          onNew={() => this.setState({ addNew: true })}
        />
      </div>
    );
  }
}

export default EventsTab;
