import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";
// COMPONENTS
import { LoadingOutlined } from "@ant-design/icons";
import InteractionCard from "components/detail-components/Interactions/InteractionCard";
// icons
import taskSVG from "./icons/task.svg";
import attachmentSVG from "./icons/attachment.svg";
import activitySVG from "./icons/activity.svg";
import noteSVG from "./icons/note.svg";
import ClipboardFilled from "components/icons/ClipboardFilled";
import EmptyState from "components/common/EmptyState";
// LIb
import theme from "lib/theme";
import {
  ActivityItem,
  ActivityItemFragmentFragment,
  ActivityItemParentEnum,
  ActivityItemSourceEnum,
  ActivityTypeEnum,
  useActivityItemsQuery,
} from "generated/graphql";
import checkCircleSVG from "./icons/check-circle.svg";
import { NoteParentTypeEnum } from "../Notes";
import Caption from "components/text/Caption";
import Text from "components/text/Text";
import { Tag } from "antd";
import Button from "components/common/Button";
//

const ItemContainer = styled.div`
  background: #fff;
  border-radius: 5px;
  padding: 16px;
  width: 600px;
  max-width: 100%;
  display: flex;
  min-height: 90px;
  margin-top: 24px;
  box-shadow: ${({ theme }) => theme.boxShadow};
`;

const ItemTitle = styled.h3`
  font-weight: 600;
  color: ${(p) => p.theme.colors.neutral1};
  margin-bottom: 0px;
`;

const ItemDescription = styled.p`
  font-weight: 600;
  color: ${(p) => p.theme.colors.neutral4};
`;

const IconPlaceholder = styled.div`
  height: 32px;
  width: 32px;
  border-radius: 5px;
  background: ${(p) => p.theme.colors.primary10};
  margin-right: 16px;
`;

const DateText = styled.h3`
  margin: 0px;
  font-weight: 600;
  font-size: 18px;
`;

const ActivityLink = ({ item }: { item: ActivityItem }) => {
  if (
    item.sourceType === ActivityItemSourceEnum.SurveyResponses &&
    item?.sourceId
  ) {
    return (
      <div>
        <Button
          grey
          onClick={() => {
            window.open(
              `/app/survey/view/result?surveyResponseId=${item?.sourceId}`,
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          View Result
        </Button>
      </div>
    );
  }

  if (item.sourceType === ActivityItemSourceEnum.Cases) {
    return (
      <Link target="_blank" to={`/app/cases?selectedCase=${item.sourceId}`}>
        View Case
      </Link>
    );
  }
  if (item.sourceType === ActivityItemSourceEnum.Todos) {
    return (
      <Link target="_blank" to={`/app/cases?selectedTodo=${item.sourceId}`}>
        View Todo
      </Link>
    );
  }
  if (item.sourceType === ActivityItemSourceEnum.Notes) {
    let to;
    switch (item.parent) {
      case ActivityItemParentEnum.Contacts:
        to = `/app/contacts/${item.parentId}?tab=2&noteId=${item.sourceId}`;
        break;
      case ActivityItemParentEnum.Businesses:
        to = `/app/businesses/${item.parentId}?tab=2&noteId=${item.sourceId}`;
        break;
      case ActivityItemParentEnum.Sites:
        to = `/app/sites/${item.parentId}?tab=2&noteId=${item.sourceId}`;
        break;
      default:
        return null;
    }
    return (
      <Link target="_blank" to={to}>
        View
      </Link>
    );
  }
  if (item.parent === ActivityItemParentEnum.Businesses) {
    return (
      <Link target="_blank" to={`/app/businesses/${item.parentId}?tab=1`}>
        View
      </Link>
    );
  }
  if (item.parent === ActivityItemParentEnum.Contacts) {
    return (
      <Link target="_blank" to={`/app/contacts/${item.parentId}?tab=1`}>
        View
      </Link>
    );
  }
  if (item.parent === ActivityItemParentEnum.Notes) {
    return (
      <Link target="_blank" to={`/app/contacts/${item.parentId}?tab=1`}>
        View
      </Link>
    );
  }
  return null;
};

const ActivityCard = ({
  item,
  linkToParent,
}: {
  item: ActivityItemFragmentFragment;
  linkToParent?: string;
}) => {
  if (
    item.activityType === ActivityTypeEnum.Phone ||
    item.activityType === ActivityTypeEnum.Email ||
    item.activityType === ActivityTypeEnum.Visit
  ) {
    return item?.interaction ? (
      <InteractionCard interaction={item?.interaction} />
    ) : (
      <></>
    );
  }
  return (
    <ItemContainer data-testid={`activity-item-card-${item.title}`}>
      <div style={{ marginRight: 16 }}>
        {(() => {
          switch (item.activityType) {
            case "taskAdded":
              return <img src={taskSVG} alt="task-icon" />;
            case "attachmentAdded":
              return <img src={attachmentSVG} alt="attachment-icon" />;
            case "projectActivity":
              return <img src={activitySVG} alt="activity-icon" />;
            case "generalActivity":
              return <img src={activitySVG} alt="activity-icon" />;
            case "noteAdded":
              return <img src={noteSVG} alt="note-icon" />;
            case ActivityTypeEnum.CaseCreated:
              return <img src={noteSVG} alt="note-icon" />;
            case ActivityTypeEnum.CaseClosed:
              return <img src={noteSVG} alt="note-icon" />;
            case ActivityTypeEnum.TodoCreated:
              return (
                <img
                  src={checkCircleSVG}
                  alt="note-icon"
                  style={{ height: 32, width: 32 }}
                />
              );
            case ActivityTypeEnum.CaseStatusUpdated:
              return <img src={noteSVG} alt="note-icon" />;
            case ActivityTypeEnum.SurveyResponse:
              return <ClipboardFilled fill={theme.colors.primary7} />;
            default:
              return <IconPlaceholder />;
          }
        })()}
      </div>
      <div>
        <ItemTitle data-testid={`activity-card-title-${item.title}`}>
          {item.title}
        </ItemTitle>
        <ItemDescription data-testid={`activity-card-description`}>
          {item.description}
        </ItemDescription>
        {linkToParent && <ActivityLink item={item} />}

        {item.activityType === ActivityTypeEnum.CaseClosed && (
          <>
            {item?.case?.resolutionNotes && (
              <>
                <Caption>Resolution Notes:</Caption>
                <Text
                  dangerouslySetInnerHTML={createMarkup(
                    item?.case?.resolutionNotes
                  )}
                />
              </>
            )}
            {item?.case?.caseOutcomes?.[0] && (
              <>
                <Caption>Outcomes:</Caption>
                {item?.case?.caseOutcomes?.map((outcome) => {
                  return <Tag>{outcome?.title}</Tag>;
                })}
              </>
            )}
          </>
        )}
      </div>
    </ItemContainer>
  );
};
function createMarkup(text = "First &middot; Second") {
  return { __html: text };
}

const DATE_FORMAT = `MMMM D, YYYY`;

const hadDiffDate = (item, activityItems, i) => {
  const date = activityItems[i - 1]?.sortDate;
  return (
    item?.sortDate &&
    date &&
    moment(parseInt(item?.sortDate)).format(DATE_FORMAT) !==
      moment(parseInt(date)).format(DATE_FORMAT)
  );
};

export const ActivityFeedContent = ({
  activityItems,
  linkToParent,
}: {
  activityItems: ActivityItemFragmentFragment[];
  linkToParent?: any;
}) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        paddingBottom: 60,
      }}
    >
      <div style={{ maxWidth: "100%" }}>
        {activityItems.map((item, i) => {
          const isFirstItem = i === 0;

          const hasDiffDate = hadDiffDate(item, activityItems, i);

          // if first Item, show createdAt or if the current item has a different day than the last item, we need a new date header
          if (isFirstItem || hasDiffDate) {
            return (
              <div key={item.id} style={{ marginTop: 24 }}>
                <DateText>
                  {item.createdAt &&
                    moment(parseInt(item?.sortDate)).format(DATE_FORMAT)}{" "}
                </DateText>
                <ActivityCard item={item} linkToParent={linkToParent} />
              </div>
            );
          }

          // else, just render the item
          return (
            <ActivityCard
              key={item.id}
              item={item}
              linkToParent={linkToParent}
            />
          );
        })}
      </div>
    </div>
  );
};

export default function ActivityFeed({
  parentId,
  parentType,
}: {
  parentType?: NoteParentTypeEnum;
  parentId: string;
}) {
  let { data, error, loading } = useActivityItemsQuery({
    variables: {
      parentId,
      parentType,
    },
    fetchPolicy: "network-only",
  });
  if (loading) return <LoadingOutlined style={{ fontSize: 24 }} />;
  if (error) return null;

  if (data?.activityItems?.length === 0) {
    return (
      <EmptyState
        title="No activity"
        imgHeight={124}
        subtitle="There is no activity for this business yet"
      />
    );
  }

  return (
    <ActivityFeedContent
      activityItems={data?.activityItems || []}
      linkToParent
    />
  );
}
