// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  mutation saveTodoList($id: ID, $params: TodoListParams) {
    saveTodoList(id: $id, params: $params) {
      id
      __typename
      title
      description
      archived
    }
  }
`;
