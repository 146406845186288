// TOP LEVEL IMPORTS
import { gql } from "@apollo/client";

const pipelineStageFragment = gql`
  fragment pipelineStageFragment on PipelineStage {
    id
    label
    order
  }
`;

export default gql`
  fragment pipelineFragment on Pipeline {
    id
    title
    stages {
      ...pipelineStageFragment
    }
  }
  ${pipelineStageFragment}
`;
