import React from "react";
import styled from "styled-components";

// STYLE-COMPONENTS
// ===================================
const Container = styled.div<{ backgroundColor?: string }>`
  height: 100%;
  width: 100%;
  max-width: 100%;
  background-color: ${(p) => (p.backgroundColor ? p.backgroundColor : null)};
`;

// EXPORT
// ===================================
export default function Testing({ children, backgroundColor }: any) {
  return (
    <Container id="home" backgroundColor={backgroundColor}>
      <div>{children}</div>
    </Container>
  );
}
