// TOP LEVEL IMPORTS
import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
// ANTD
import DatePicker from "antd/lib/date-picker";
import "antd/lib/date-picker/style/css";
import moment, { isMoment } from "moment";

const Wrapper = styled.div`
  .ant-picker-large {
    height: 50px;
    border-radius: 5px;
    border: 0px;
    background: ${(p) => p.theme.colors.neutral11};
  }
`;

// EXPORTED COMPONENT
// ===================================
export class DateInput extends React.Component {
  render() {
    let massagedValue = this.props.value;

    // if value does not exist, create a new moment object
    if (!this.props.value) {
      massagedValue = undefined;
    }

    // if this.props.value exists and is a moment object already, we can use this.props.value
    if (
      this.props.value &&
      isMoment(this.props.value) &&
      this.props.value._isUTC
    ) {
      massagedValue = moment(this.props.value);
    }

    if (this.props.value && !isMoment(this.props.value)) {
      massagedValue = moment(parseInt(this.props.value));
    }

    return (
      <Wrapper>
        <DatePicker {...this.props} value={massagedValue} size="large" />
      </Wrapper>
    );
  }
}

DateInput.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
};

// Specifies the default values for props:
DateInput.defaultProps = {
  size: "large",
  style: {},
  // showTime: {use12Hours: true, format: 'HH:mm A'},
  allowClear: false,
  format: "M/D/YY",
};

export default DateInput;
