// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import organizationFragment from 'ApolloClient/Fragments/organizationFragment';

export default gql`
  fragment eventFragment on Event {
    id
    title
    description
    type
    date
    lat
    lng
    placeId
    fullAddress
    businessStages
    street
    postal
    country
    state
    city
    organization {
      ...organizationFragment
    }
  }
  ${organizationFragment}
`;
