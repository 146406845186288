import React from "react";
import styled from "styled-components";
import Header from "./Header/index";

const Container = styled.div`
  max-width: 100%;
  min-height: 100vh;
  background-color: ${(p) => p.theme.colors.neutral10} !important;
`;

// EXPORT
// ===================================
export default function AuthenticatedLayout({
  children,
  showHeader = true,
  currentUser,
}) {
  return (
    <Container id="authenticated-layout">
      <>
        {showHeader && <Header currentUser={currentUser} />}
        <>{children}</>
      </>
    </Container>
  );
}
