// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import resourceFragment from 'ApolloClient/Fragments/resourceFragment';

export default gql`
  mutation saveProgram($id: ID, $params: ResourceParams) {
    saveProgram(id: $id, params: $params) {
      ...resourceFragment
    }
  }
  ${resourceFragment}
`;
