import React from "react";
import Select from "antd/lib/select";
import DropdownStyleWrapper from "components/inputs/DropdownStyleWrapper";

const { Option, OptGroup } = Select;

export default function AutomationActionsSelectInput({
  value,
  actions,
  onChange,
  loading,
  containerStyle,
  placeholder,
}: {
  value?: string;
  actions?: any[];
  onChange?: any;
  loading?: boolean;
  containerStyle?: React.CSSProperties;
  placeholder?: string;
}) {
  // show main component
  return (
    <DropdownStyleWrapper
      background={
        typeof containerStyle?.backgroundColor === "string"
          ? containerStyle?.backgroundColor
          : "#fff"
      }
      style={containerStyle}
    >
      <Select
        optionFilterProp="children"
        loading={loading}
        placeholder={placeholder}
        defaultValue={undefined}
        value={undefined}
        style={{ width: containerStyle?.width || 260 }}
        onChange={(actionId) => {
          onChange(
            actions
              ?.map((action) => action.options)
              ?.flat()
              ?.find((action) => action.type === actionId)
          );
        }}
      >
        {!loading &&
          actions?.map((section) => {
            return (
              <OptGroup label={section.title}>
                {section?.options?.map(({ type, label }) => (
                  <Option value={type}>{label}</Option>
                ))}
              </OptGroup>
            );
          })}
      </Select>
    </DropdownStyleWrapper>
  );
}
