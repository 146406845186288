import { useEffect, useState } from "react";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import FormItem from "components/common/FormItem";
import TextEditor from "components/inputs/TextEditor";
import ContactInput from "components/inputs/ContactInputSimple";
import Button from "components/common/Button";
import BusinessInput from "components/inputs/BusinessInput";
import SiteInput from "components/inputs/SiteInput";
import ProjectInput from "components/inputs/ProjectInput";
import SideFormButtonContainer from "components/common/SideFormButtonContainer";
// LIB
import helpers from "lib/helpers/GeneralHelpers";
import { CustomerFeatureSettingKeyEnum } from "generated/graphql";

export default function NoteForm({
  defaultValues,
  loading,
  customer,
  onCancel,
  onSubmit,
}) {
  const [errors, setErrors] = useState<{
    title?: string;
    description?: string;
  }>({});
  const [values, setValues] = useState(defaultValues || {});

  const { title, businessId, siteId, projectId, description, contactId } =
    values;

  useEffect(() => {
    setValues((oldValues) => ({ ...defaultValues, ...oldValues }));
  }, [defaultValues]);

  const onChange = (newValues) => {
    setValues({
      ...values,
      ...newValues,
    });
  };

  const handleOnSubmit = () => {
    setErrors({});
    if (!title) {
      return setErrors({ title: "A title is required" });
    }
    if (!description) {
      return setErrors({ description: "A note is required" });
    }
    onSubmit({
      ...values,
      description: values.description && values.description.toString("html"),
    });
  };

  const disabled = !title || loading;

  return (
    <div>
      {" "}
      <div style={{ paddingBottom: "20%" }}>
        <FormItem label="Title" required error={errors?.title}>
          <TextInput
            value={title}
            placeholder="Title"
            onChange={(title) => onChange({ title })}
            data-testid="note-form-title"
          />
        </FormItem>
        <FormItem label="Notes" required error={errors?.description}>
          <TextEditor
            value={description}
            onChange={(description) => onChange({ description })}
            data-testid="note-form-description"
          />
        </FormItem>
        <FormItem label="Contact">
          <ContactInput
            value={contactId}
            defaultContactId={contactId}
            onChange={(contactId) => {
              onChange({ contactId });
            }}
          />
        </FormItem>
        <FormItem label="Business">
          <BusinessInput
            value={businessId}
            defaultBusinessId={businessId}
            onChange={(businessId) => {
              onChange({ businessId });
            }}
          />
        </FormItem>
        {helpers.featureIsOn({
          customer,
          feature: CustomerFeatureSettingKeyEnum.Resources,
        }) && (
          <FormItem label="Site">
            <SiteInput
              value={siteId}
              defaultSiteId={siteId}
              onChange={(siteId) => {
                onChange({ siteId });
              }}
            />
          </FormItem>
        )}
        {helpers.featureIsOn({
          customer,
          feature: CustomerFeatureSettingKeyEnum.Projects,
        }) && (
          <FormItem label="Project">
            <ProjectInput
              value={projectId}
              defaultProjectId={projectId}
              onChange={(projectId) => {
                onChange({ projectId });
              }}
            />
          </FormItem>
        )}{" "}
      </div>
      <SideFormButtonContainer>
        <Button
          disabled={disabled}
          loading={loading}
          onClick={handleOnSubmit}
          data-testid="note-form-submit-btn"
          style={{ width: 150, marginLeft: 16 }}
        >
          Save Note
        </Button>
        <Button
          disabled={loading}
          onClick={onCancel}
          grey
          style={{ width: 100, marginLeft: 8 }}
        >
          Cancel
        </Button>
      </SideFormButtonContainer>
    </div>
  );
}

// // TOP LEVEL IMPORTS
// import React from 'react';
// import PropTypes from 'prop-types';
// import styled from 'styled-components';
// // COMPONENTS
// import TextInput from 'components/inputs/TextInput';
// import Row from 'components/common/Row';
// import Col from 'components/common/Col';
// import Button from 'components/common/Button';
// import FormItem from 'components/common/FormItem';
// import SectionBreak from 'components/common/SectionBreak';

// const FormContainer = styled.form`
//   width: 900px;
//   margin: auto;
//   max-width: 100%;
// `;

// class NoteForm extends React.PureComponent {
//   state = {
//     title: this.props.title || null,
//     description: this.props.description || null,
//   };

//   getValues = () => {
//     return {
//       title: this.state.title || null,
//       description: this.state.description || null,
//     };
//   };

//   onSubmit = (e) => {
//     e.preventDefault();

//     if (this.props.loading) return null;
//     // do some logic to verify everything is okay

//     // create the product object to pass up to parent
//     let values = this.getValues();
//     console.log({values});

//     // pass back up to the parent component
//     this.props.onSubmit(values);
//   };

//   onSaveAttacment = async (newAttachment) => {
//     this.setState({images: [...this.state.images, newAttachment]});
//   };

//   onRemoveAttacment = async (url) => {
//     this.setState({
//       images: [...this.state.images.filter((item) => item !== url)],
//     });
//   };
//   render() {
//     return (
//       <FormContainer>
//         <SectionBreak
//           header="Basics"
//           body="Provide a title and description for your note"
//         >
//           <Row gutter={8}>
//             <Col xs={24}>
//               <FormItem
//                 label="Title"
//                 hint="You can always change the title later"
//               >
//                 <TextInput
//                   value={this.state.title}
//                   onChange={(title) => this.setState({title})}
//                 />
//               </FormItem>
//             </Col>
//             <Col xs={24}>
//               <FormItem label="Description">
//                 <TextInput
//                   value={this.state.description}
//                   onChange={(description) => this.setState({description})}
//                 />
//               </FormItem>
//             </Col>
//           </Row>
//         </SectionBreak>
//         <div>
//           <div
//             style={{
//               width: 270,
//               marginTop: 32,
//               marginLeft: 'auto',
//             }}
//           >
//             <Button
//               style={{
//                 width: 100,
//                 marginRight: 8,
//               }}
//               grey
//               disabled={this.props.loading}
//               onClick={this.props.onCancel}
//             >
//               Cancel
//             </Button>{' '}
//             <Button
//               style={{
//                 width: 150,
//               }}
//               disabled={this.props.loading}
//               onClick={this.onSubmit}
//             >
//               {!this.props.loading ? 'Save Note' : '...'}
//             </Button>
//           </div>
//         </div>
//       </FormContainer>
//     );
//   }
// }

// NoteForm.propTypes = {
//   mode: PropTypes.string, // either 'add' or 'save'... determines if we show intermediate "save buttons" in each form section
// };

// NoteForm.defaultProps = {
//   mode: 'add',
//   onCancel: () => {},
//   onSubmit: () => {},
// };

// export default NoteForm;
