// TOP LEVEL IMPORTS
import React, { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import TextAreaInput from "components/inputs/TextAreaInput";
import Button from "components/common/Button";
import FormItem from "components/common/FormItem";
import DiversityInput from "components/inputs/DiversityInput";
import BusinessStagesMultiSelect from "components/inputs/BusinessStagesMultiSelect";
import ClustersMultiselectInput from "components/inputs/ClustersMultiselectInput";
import OrgTypeSelect from "components/inputs/OrgTypeSelect";
import SideFormButtonContainer from "components/common/SideFormButtonContainer";
import CustomTagsInput from "components/inputs/CustomTagsInput";

const FormContainer = styled.form`
  width: 900px;
  margin: auto;
  max-width: 100%;
`;

export default function OrganizationForm({ loading, onSubmit, onClose }) {
  const [values, setvalues] = useState({
    title: null,
    description: null,
    businessStages: [],
    diversity: [],
    clusters: [],
    orgType: null,
  });

  const handleOnSubmit = (e) => {
    e.preventDefault();

    if (loading) return null;
    // do some logic to verify everything is okay

    // pass back up to the parent component
    onSubmit(values);
  };

  const onChange = (newValues) => {
    setvalues({
      ...values,
      ...newValues,
    });
  };

  const disabled = !values?.orgType || !values?.title;

  return (
    <FormContainer onSubmit={handleOnSubmit}>
      <FormItem label="Type of organization" required>
        <OrgTypeSelect
          value={values.orgType}
          onChange={(orgType) => onChange({ orgType })}
        />
      </FormItem>
      <FormItem label="Title" required>
        <TextInput
          value={values.title}
          onChange={(title) => onChange({ title })}
        />
      </FormItem>
      <FormItem label="Description">
        <TextAreaInput
          value={values.description}
          onChange={(description) => onChange({ description })}
        />
      </FormItem>
      <FormItem label="What business stages does this organization work with?">
        <BusinessStagesMultiSelect
          value={values.businessStages}
          onChange={(businessStages) => onChange({ businessStages })}
        />
      </FormItem>
      <FormItem label="Do they work with specifically classified businesses?">
        <DiversityInput
          value={values.diversity}
          onChange={(diversity) => onChange({ diversity })}
        />
      </FormItem>
      <FormItem label="Does this organiation work with specific clusters?">
        <ClustersMultiselectInput
          value={values.clusters}
          onChange={(clusters) => onChange({ clusters })}
        />
      </FormItem>{" "}
      <FormItem label="Tags">
        <CustomTagsInput
          value={values.tagIds}
          onChange={(tagIds) => onChange({ tagIds })}
        />
      </FormItem>
      <SideFormButtonContainer>
        <Button
          disabled={disabled || values.loading}
          onClick={handleOnSubmit}
          style={{ marginLeft: 16 }}
        >
          {!loading ? "Save Organization" : "..."}
        </Button>
        <Button grey style={{ width: 130 }} onClick={onClose}>
          Cancel
        </Button>
      </SideFormButtonContainer>
    </FormContainer>
  );
}

// class OrganizationForm extends React.PureComponent {
//   state = {
//     title: this.props.title || null,
//     description: this.props.description || null,
//     businessStages: this.props.businessStages || [],
//     diversity: this.props.diversity || [],
//     clusters: this.props.clusters || [],
//   };

//   }
// }

// OrganizationForm.propTypes = {
//   mode: PropTypes.string, // either 'add' or 'save'... determines if we show intermediate "save buttons" in each form section
// };

// OrganizationForm.defaultProps = {
//   mode: 'add',
//   showImages: true,
// };

// export default OrganizationForm;
