import MaskedField from "react-masked-field";
import styled from "styled-components";

const formatPhoneNumber = (e) => {
  const phone = e.target.value.replace(/[^\d]/g, "");
  const phoneNumberLength = phone.length;
  if (phoneNumberLength < 4) {
    return phone;
  }
  if (phoneNumberLength < 7) {
    return `(${phone.slice(0, 3)}) ${phone.slice(3)}`;
  } else {
    return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`;
  }
};

const Container = styled.div<{ disabled?: boolean }>`
  input {
    background: ${(p) => p.theme.colors.neutral11};
    border: 0px;
    width: 100%;
    height: 50px;
    padding-left: 8px;
    border-radius: 5px;
    border: 2px solid ${(p) => p.theme.colors.neutral11};
    &:hover {
      outline: 0;
      border: 2px solid
        ${(p) => {
          if (p.disabled) return p.theme.colors.neutral11;
          return p.theme.colors.neutral8;
        }};
    }
    transition: color 0.3s ease, background-color 0.3s ease,
      border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
    &:focus {
      outline: 0;
      background: #fff;
      border: 2px solid ${(p) => p.theme.colors.primary7};
    }

    ::placeholder {
      color: ${(p) => p.theme.colors.neutral7};
      font-weight: 500;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
`;

export default function PhoneInput(props) {
  return (
    <Container>
      <MaskedField
        placeholder={`  -   -    `}
        className="ant-input ant-input-lg"
        mask="999-999-9999"
        {...props}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </Container>
  );
}
