import { useState } from "react";
import styled from "styled-components";
// COMPONENTS
import {
  UserOutlined,
  TeamOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
// LIB
import theme from "lib/theme";
import useUploadFile from "lib/hooks/useUploadFile";
// APOLLO

export const PageContainer = styled.div`
  height: calc(100vh - 177px);
  display: flex;
  align-self: stretch !important;
  align-items: stretch !important;
  justify-content: space-between;
  word-break: break-word;
  /* overflow: visible; */
  overflow-y: hidden;
  flex-grow: 1;
  width: 100%;
  z-index: 1;
`;

export const LeftCol = styled.div`
  flex-basis: 25%;
  max-width: 500px;
  min-width: 280px;
  background: #f9fbfd;
  overflow-y: scroll;
  z-index: 1;
  padding: 24px 40px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  overflow-y: scroll;
`;

export const MiddleContent = styled.div`
  min-width: 500px;
  overflow-y: auto !important;
  display: flex;
  position: relative;
  align-self: stretch !important;
  z-index: 1;
  flex-shrink: 100000;
  width: 100%;
  overflow-x: auto;
  flex-direction: column;
  overflow-y: scroll;
  white-space: normal;
  padding-right: 32px;
  padding-left: 32px;
`;

export const RightCol = styled.div`
  background-color: #fff;
  flex-basis: 25%;
  max-width: 500px;
  z-index: 1;
  overflow-y: scroll;
  transition: flex 0.15s cubic-bezier(0.16, 0.84, 0.44, 1);
  will-change: flex;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  min-width: 280px;
`;

export const HeaderContainer = styled.div`
  height: 124px;
  display: flex;
  align-self: stretch !important;
  align-items: stretch !important;
  justify-content: space-between;
  word-break: break-word;
  overflow: visible;
  flex-grow: 1;
  width: 100%;
  padding-left: 2%;
  padding-right: 2%;
  z-index: 1;
  background: #fff;
  border-bottom: 1px solid ${(p) => p.theme.colors.neutral8};
`;

export const HeadLeft = styled.div`
  flex-basis: 25%;
  max-width: 500px;
  min-width: 280px;
  padding-top: 8px;
`;

export const HeadMiddle = styled.div`
  min-width: 500px;
  overflow-y: auto !important;
  display: flex;
  position: relative;
  align-self: stretch !important;
  z-index: 1;
  flex-shrink: 100000;
  width: 100%;
  overflow-x: auto;
  flex-direction: column;
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const HeadRight = styled.div`
  background-color: #fff;
  flex-basis: 25%;
  max-width: 500px;
  z-index: 1;
  will-change: flex;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 280px;
`;

export const ProjectTitle = styled.h1`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: 22px;
`;

export const DetailsTitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 24px;
  margin-top: 0px;
  line-height: 22px;
`;

export const DetailContainer = styled.div`
  background: #f9fbfd;
  padding: 24px 40px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  height: 100%;
  width: 320px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  min-height: calc(100vh - 170px);
  overflow-y: scroll;
`;

export const DetailImageContainer = styled.div`
  height: 48px;
  width: 48px;
  background: ${(p) => p.theme.colors.neutral9};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DetailImg = styled.img`
  height: 48px;
  width: 48px;
  border-radius: 5px;
`;

const UploadButton = styled.input`
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
`;

const UploadLabel = styled.label`
  font-weight: 600;
  color: ${(p) => p.theme.colors.primary2};
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  transition: color 0.3s ease, background-color 0.3s ease,
    border-color 0.3s ease, width 0.3s ease, opacity 0.3s ease;
  &:hover {
    background: ${(p) => p.theme.colors.primary8};
    color: ${(p) => p.theme.colors.primary1};
  }
`;

export enum DetailImageTypeEnum {
  contacts = "contacts",
  projects = "projects",
  businesses = "businesses",
}

interface DetailImageProps {
  src?: string;
  type: DetailImageTypeEnum;
  onChange?: Function;
}

export default function DetailImage({ src, type, onChange }: DetailImageProps) {
  const [uploadFile] = useUploadFile();
  const [uploading, setUploading] = useState(false);
  const ICON_PROPS = {
    style: {
      fontSize: 24,
      color: theme.colors.neutral6,
    },
  };

  const handleUpload = async (event) => {
    try {
      // if already uploading, return
      if (uploading) return;
      if (!onChange) return;
      // set loader
      setUploading(true);
      let file = event.target.files[0];
      // upload file to s3
      let uploadResult = await uploadFile(file);
      onChange(uploadResult);

      // message.success('Upload successful!');
      setUploading(false);
    } catch (err) {
      setUploading(false);
      console.log(err);
    }
  };

  const getIcon = () => {
    if (type === "contacts") {
      return <UserOutlined {...ICON_PROPS} />;
    }
    if (type === "projects") {
      return <FileTextOutlined {...ICON_PROPS} />;
    }
    if (type === "businesses") {
      return <TeamOutlined {...ICON_PROPS} />;
    }
    return <UserOutlined {...ICON_PROPS} />;
  };
  return (
    <div>
      <UploadLabel htmlFor="file-input">
        {src && <DetailImg src={src} alt={src} />}
        {!src && <DetailImageContainer>{getIcon()}</DetailImageContainer>}
      </UploadLabel>
      {onChange && (
        <UploadButton
          id="file-input"
          name="file-input"
          type="file"
          onChange={handleUpload}
        />
      )}
    </div>
  );
}
