import React from "react";
import styled from "styled-components";
import BusinessInput from "components/inputs/BusinessInput";
import ContactInput from "components/inputs/ContactInputSimple";

import CaseStatusInput from "components/inputs/CaseStatusInput";
import CaseCategoryInput from "components/inputs/CaseCategoryInput";
import FormItem from "components/common/FormItem";
import SelectInput from "components/inputs/SelectInput";
import { useTeamQuery } from "generated/graphql";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";
import theme from "lib/theme";

const ClearBtn = styled.button`
  padding: 0px;
  border: 0px;
  font-size: 14px;
  background: transparent;
  margin-left: 8px;
  color: ${(p) => p.theme.colors.red4};
  cursor: pointer;
`;

export default function Filters({
  businessId,
  caseCategoryId,
  caseStatusIds,
  contactId,
  handleChange,
  assignedToId,
}) {
  const { data: teamQuery } = useTeamQuery();

  return (
    <div>
      {/* <FormItem
        label={
          <>
            Date Range
            {contactId && (
              <ClearBtn onClick={() => handleChange({contactId: undefined})}>
                Clear
              </ClearBtn>
            )}
          </>
        }
      >
        <RangePicker
          onChange={(values) =>
            handleChange({
              startDate: values[0].valueOf().toString(),
              endDate: values[0].valueOf().toString(),
            })
          }
        />
      </FormItem> */}
      <FormItem
        label={
          <>
            Filter by Contact{" "}
            {contactId && (
              <ClearBtn onClick={() => handleChange({ contactId: undefined })}>
                Clear
              </ClearBtn>
            )}
          </>
        }
      >
        <ContactInput
          value={contactId}
          onChange={(contactId) => handleChange({ contactId })}
        />
      </FormItem>
      <FormItem
        label={
          <>
            Filter by Business{" "}
            {businessId && (
              <ClearBtn onClick={() => handleChange({ businessId: undefined })}>
                Clear
              </ClearBtn>
            )}
          </>
        }
      >
        <BusinessInput
          value={businessId}
          onChange={(businessId) => handleChange({ businessId })}
        />
      </FormItem>
      <FormItem
        label={
          <>
            Filter by Category{" "}
            {caseCategoryId && (
              <ClearBtn
                onClick={() => handleChange({ caseCategoryId: undefined })}
              >
                Clear
              </ClearBtn>
            )}
          </>
        }
      >
        <CaseCategoryInput
          value={caseCategoryId}
          onChange={(caseCategoryId) => handleChange({ caseCategoryId })}
        />
      </FormItem>
      <FormItem
        label={
          <>
            Filter by Status{" "}
            {caseStatusIds && (
              <ClearBtn
                onClick={() => handleChange({ caseStatusIds: undefined })}
              >
                Clear
              </ClearBtn>
            )}
          </>
        }
      >
        <CaseStatusInput
          value={caseStatusIds || []}
          mode={"multiple"}
          onChange={(caseStatusIds) => handleChange({ caseStatusIds })}
        />
      </FormItem>
      <FormItem
        label={
          <>
            Filter by Assigned To{" "}
            {assignedToId && (
              <ClearBtn
                onClick={() => handleChange({ assignedToId: undefined })}
              >
                Clear
              </ClearBtn>
            )}
          </>
        }
      >
        <SelectInput
          value={assignedToId || ""}
          containerStyle={{
            maxWidth: "100%",
            width: "100%",
            backgroundColor: theme.colors?.neutral11,
          }}
          options={
            teamQuery?.team?.[0]
              ? teamQuery?.team?.map((user) => {
                  return {
                    id: user?.id,
                    label: getFullName(user),
                  };
                })
              : []
          }
          onChange={(assignedToId) => handleChange({ assignedToId })}
        />
      </FormItem>
      {/* <FormItem
        label={
          <>
            Filter by Services{' '}
            {serviceIds && (
              <ClearBtn onClick={() => handleChange({serviceIds: undefined})}>
                Clear
              </ClearBtn>
            )}
          </>
        }
      >
        <CaseServicesInput
          value={serviceIds}
          onChange={(serviceIds) => handleChange({serviceIds})}
        />
      </FormItem> */}
    </div>
  );
}
