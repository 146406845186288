import moment from 'moment'


type CaseStatus = {
  id: string
  title: string
}

type CaseCategory = {
  id: string
  title: string
}

type Business = {
  id: string
  title: string
}
type CaseService = {
  id: string
  title: string
}

type Person = {
  id: string
  firstName: string
  lastName: string
  email: string
}

type Case = {
  id: string
  title: string
  closedTodos: number
  openTodos: number
  caseStatus: CaseStatus
  business?: Business
  assignedTo?: Person
  caseCategory: CaseCategory
  contact: Person
  createdAt: string
  services: CaseService[]
}

export default function cleanDataForCasesCSV(cases: Case[]) {
  const result = cases.map(item => {
    return {
      'ID': item.id,
      Title: item.title,
      Status: item?.caseStatus?.title,
      Category: item?.caseCategory?.title,
      Business: item?.business?.title,
      'Assigned To': item?.assignedTo && `${item?.assignedTo?.firstName} ${item?.assignedTo?.lastName}`,
      Todos: `${item.closedTodos + "/" + (item.openTodos + item.closedTodos)}`,
      Created: moment(parseInt(item.createdAt)).format('MM/DD/YYYY'),
      Services: item?.services?.map(item => item?.title),
      Contact: item?.contact && `${item?.contact?.firstName} ${item?.contact?.lastName}`,
    }
  })
  return result
};