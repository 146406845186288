// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import commentFragment from 'ApolloClient/Fragments/commentFragment';

export default gql`
  query comments($todoId: String, $caseId: String, $noteId: String) {
    comments(todoId: $todoId, caseId: $caseId, noteId: $noteId) {
      ...commentFragment
    }
  }
  ${commentFragment}
`;
