import React from 'react';
import styled from 'styled-components';
// import
import emptySVG from './empty.svg';

const Container = styled.div`
  padding-top: 40px;
  display: inline-flex;
  justify-content: center;
  width: 100%;
  max-width: 100%;
  text-align: center;
`;

const Image = styled.img`
  height: 150px;
  opacity: 0.35;
  margin: 24px;
`;

const Title = styled.h1`
  font-size: 18px;
  color: ${(p) => p.theme.colors.neutral1};
  font-weight: 600;
  margin-bottom: 4px;
`;

const Caption = styled.p`
  font-size: 16px;
  color: ${(p) => p.theme.colors.neutral4};
  margin-bottom: 24px;
`;
export default () => {
  return (
    <Container>
      <div>
        <Image src={emptySVG} alt="empty" />
        <Title>No interactions yet</Title>
        <Caption>
          Add notes about calls, emails and meetings here <br />
          to let your team see a history of communication
        </Caption>
        <div>
          {/* <DrawerContext.Consumer>
            {(context) => {
              return (
                <Button
                  style={{width: 150}}
                  onClick={() => context.setDrawerVisible('todos')}
                >
                  Create a Todo
                </Button>
              );
            }}
          </DrawerContext.Consumer> */}
        </div>
      </div>
    </Container>
  );
};
