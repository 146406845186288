import React, {useState} from 'react';

import styled from 'styled-components';
// COMPONENTS
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import Loading from 'components/common/Loading';
import SideNavItem from 'components/common/SideNavItem';
// INTERNAL COMPONENTS
import NewTodoForm from './NewTodoForm';
import NewTodoListForm from './NewTodoList';
import TodoList from './TodoList';
import EmptyState from './EmptyState';
import SearchBar from './SearchBar';
// ApolloClient
import TODO_LIST_QUERY from 'ApolloClient/Queries/todoLists';
import {useQuery} from '@apollo/client';
// LIB
import activeTodosSVG from 'lib/media/active-todos-active.svg';
import unActiveTodosSVG from 'lib/media/active-todos-unactive.svg';
import archivedTodosSVG from 'lib/media/archived-todos-active.svg';
import unArchivedTodosSVG from 'lib/media/archived-todos-unactive.svg';

const NewList = styled.button`
  font-family: ${(p) => p.theme.fontFamily};
  height: 50px;
  max-width: 100%;
  width: 1100px;
  margin: auto;
  margin-top: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: ${(p) => p.theme.colors.neutral4};
  display: block;
  background-color: ${(p) => p.theme.colors.neutral9};
  border-radius: 5px;
  border: 1px dashed ${(p) => p.theme.colors.neutral7};
  transition: all 0.5s ease;
  &:hover {
    color: ${(p) => p.theme.colors.neutral2};
    cursor: pointer;
    background-color: ${(p) => p.theme.colors.neutral8};
    border: 1px dashed ${(p) => p.theme.colors.neutral6};
  }
  &:focus {
    outline: 0;
  }
`;

export default function TodosTab(props) {
  const [newTodo, setNewTodo] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [archived, setArchived] = useState(false);
  const [newTodoList, setNewTodoList] = useState(null);
  const [sortBy, setSortBy] = useState('createdAt');
  const [searchText, useSearchText] = useState('');

  let variables = {
    sortBy,
    archived,
    searchText,
  };

  const {data, loading} = useQuery(TODO_LIST_QUERY, {
    variables,
  });

  if (newTodo) {
    return (
      <NewTodoForm
        listId={newTodo}
        onSubmitComplete={() => setNewTodo(false)}
        onCancel={() => setNewTodo(false)}
        variables={variables}
        {...props}
      />
    );
  }

  return (
    <div style={{width: 1000, maxWidth: '95%', margin: 'auto'}}>
      <Row align="top">
        <Col xs={24} md={3}>
          <SideNavItem
            label="Active"
            icon={!archived ? activeTodosSVG : unActiveTodosSVG}
            active={!archived}
            onChange={() => setArchived(false)}
          />
          <SideNavItem
            label="Archived"
            icon={archived ? unArchivedTodosSVG : archivedTodosSVG}
            active={archived}
            onChange={() => setArchived(true)}
          />
        </Col>
        <Col xs={24} md={21}>
          <div>
            {!this.state.newTodoList && (
              <div>
                {data &&
                  data.todoLists &&
                  !loading &&
                  data.todoLists.count === 0 &&
                  !archived && (
                    <EmptyState onNew={() => setNewTodoList(true)} />
                  )}
                {data &&
                  data.todoLists &&
                  !loading &&
                  data.todoLists.count === 0 &&
                  archived && (
                    <EmptyState title="No archived lists yet" onNew={null} />
                  )}

                <>
                  {!archived && (
                    <SearchBar
                      sortBy={sortBy}
                      onChange={(newValue) => console.log(newValue)}
                    />
                  )}
                  {loading ? (
                    <Loading />
                  ) : (
                    <div style={{paddingBottom: 20}}>
                      {data.todoLists.todoLists &&
                        data.todoLists.todoLists.map((list) => {
                          return (
                            <TodoList
                              key={list.id}
                              list={list}
                              variables={variables}
                              currentUser={props.currentUser}
                              onNewTodo={(newTodo) => setNewTodo(newTodo)}
                            />
                          );
                        })}
                      {!archived && (
                        <NewList onClick={() => console.log(true)}>
                          + New List
                        </NewList>
                      )}
                    </div>
                  )}
                </>
              </div>
            )}
            {newTodoList && (
              <NewTodoListForm
                onSubmitComplete={() => console.log(true)}
                onCancel={() => console.log(true)}
                variables={variables}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
}
