// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  mutation savePipeline($id: ID, $params: PipelineParams) {
    savePipeline(id: $id, params: $params) {
      id
      title
      description
    }
  }
`;
