// APOLLO

import client from "ApolloClient/index";
import {
  ProjectsByStageDocument,
  useSaveProjectMutation,
} from "generated/graphql";

export default function useOnDragEnd({ currentPipeline }) {
  const [saveProject] = useSaveProjectMutation();

  const onDragEnd = async ({ destination, source, draggableId }) => {
    if (!destination) return;
    console.log("onDragEnd ran");
    console.log({ destination, source, draggableId });

    if (
      destination.droppableId === source.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    let destinationQuery = client.readQuery({
      query: ProjectsByStageDocument,
      variables: {
        pipelineId: currentPipeline.id,
        stageId: destination.droppableId,
      },
    });

    let sourceQuery = client.readQuery({
      query: ProjectsByStageDocument,
      variables: {
        pipelineId: currentPipeline.id,
        stageId: source.droppableId,
      },
    });

    if (destination.droppableId !== source.droppableId) {
      client.writeQuery({
        query: ProjectsByStageDocument,
        variables: {
          pipelineId: currentPipeline.id,
          stageId: source.droppableId,
        },
        data: {
          ...sourceQuery,
          projectsByStage: [
            ...sourceQuery.projectsByStage.filter(
              (item) => item.id !== draggableId
            ),
          ],
        },
      });
    }

    client.writeQuery({
      query: ProjectsByStageDocument,
      variables: {
        pipelineId: currentPipeline.id,
        stageId: destination.droppableId,
      },
      data: {
        ...destinationQuery,
        projectsByStage: [
          ...addAtIndex(
            destination?.index,
            {
              ...sourceQuery?.projectsByStage?.find(
                (prj) => prj.id === draggableId
              ),
            },
            destinationQuery?.projectsByStage
          ),
        ],
      },
    });

    await saveProject({
      variables: {
        id: draggableId, // the projectId
        params: {
          order: parseInt(destination.index), // the order in the column
          stageId: destination.droppableId, // the column it sits in
        },
      },
      refetchQueries: ["projectsByStage"],
    });
  };

  return { onDragEnd };
}

function addAtIndex(newIndex: number, newItem, array) {
  const newArray = [...array]?.filter((item) => item.id !== newItem?.id);
  newArray.splice(newIndex, 0, newItem);
  return newArray;
}
