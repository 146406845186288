import { useEffect, useState } from "react";
import queryString from "query-string";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import getFullName from "lib/helpers/GeneralHelpers/getFullName";
// COMPONENTS
import {
  PageContainer,
  ProjectTitle,
  BreadCrumb,
  BreadCrumbLink,
  DetailImage,
  LeftCol,
  MiddleContent,
  RightCol,
  HeaderContainer,
  HeadLeft,
  HeadMiddle,
  DetailsTitle,
  HeadRight,
  Tabs,
  BreadCrumbContainer,
} from "components/detail-components";
import Tag from "components/common/Tag";
import ActivityFeed from "components/detail-components/ActivityFeed";
import FilesAndDocs, {
  FilesAndDocsParentType,
} from "components/detail-components/FilesAndDocs";
import Interactions from "components/detail-components/Interactions";
import Notes, { NoteParentTypeEnum } from "components/detail-components/Notes";
import DetailItem, {
  DetailitemFieldTypeEnum,
} from "components/detail-components/DetailItem";
import ExpandableList, {
  ExpandableListTypeEnum,
} from "components/detail-components/ExpandableList";
import AddEntityDrawer, {
  EntityFormParentTypeEnum,
  ShowEntityFormString,
} from "components/detail-components/AddEntityDrawer";
import Todos from "components/detail-components/Todos";
import Loading from "components/common/Loading";
import message from "components/common/message";
import { ArrowLeftOutlined } from "@ant-design/icons";
import helpers from "lib/helpers/GeneralHelpers/index";
import ActionButtons, {
  ActionButtonTypeEnums,
} from "components/detail-components/ActionButtons";
// HOOKS
import useSaveBusiness from "hooks/useSaveBusinessHook";
import useSaveProject from "hooks/useSaveProjectHook";
import Caption from "components/text/Caption";
import {
  ContactByIdDocument,
  CustomerFeatureSettingKeyEnum,
  EntityTypeEnum,
  UserPermissionEnum,
  useAddEntityToBusinessMutation,
  useAddEntityToProjectMutation,
  useContactByIdQuery,
  useRemoveEntityFromBusinessMutation,
  useRemoveEntityFromProjectMutation,
  useSaveContactMutation,
} from "generated/graphql";
import ActionDropdown from "./Actions";
import EmptyState from "components/common/EmptyState";
import SurveyResponses from "routes/app-detail-business/SurveyResponses";

const DetailHeader = ({ contact, setTab, tab, onSave, currentUser }) => {
  const navigate = useNavigate();
  return (
    <HeaderContainer>
      <HeadLeft>
        <BreadCrumbContainer>
          <BreadCrumb>
            <BreadCrumbLink onClick={() => navigate(`/app/contacts`)}>
              <ArrowLeftOutlined /> Back to People
            </BreadCrumbLink>
          </BreadCrumb>
          {currentUser?.permission?.includes(
            UserPermissionEnum.Administrator
          ) && <ActionDropdown contact={contact} />}
        </BreadCrumbContainer>
        <div style={{ display: "flex" }}>
          <DetailImage
            src={contact?.avatar?.url}
            type="contacts"
            onChange={(file) =>
              onSave({
                avatar: file,
              })
            }
          />
          <div style={{ marginLeft: 16 }}>
            <ProjectTitle data-testid="contact-detail-name">
              {contact.firstName} {contact.lastName}
            </ProjectTitle>
            <Caption>{contact.title}</Caption>
          </div>
        </div>
      </HeadLeft>
      <HeadMiddle>
        <Tabs
          active={tab}
          onClick={(tab) => setTab(tab)}
          tabs={[
            {
              label: "Timeline",
              id: "1",
            },
            {
              label: "Notes",
              id: "2",
            },
            {
              label: "Todos",
              id: "3",
            },
            {
              label: "Files",
              id: "4",
            },
            {
              label: "Interactions",
              id: "5",
            },
            {
              label: "Surveys",
              id: "6",
            },
          ]}
        />
      </HeadMiddle>
      <HeadRight>
        <ActionButtons
          contact={contact}
          buttonsToShow={[
            ActionButtonTypeEnums.Email,
            ActionButtonTypeEnums.Call,
            ActionButtonTypeEnums.Note,
            ActionButtonTypeEnums.Visit,
            ActionButtonTypeEnums.Todo,
            ActionButtonTypeEnums.Survey,
          ]}
        />
      </HeadRight>
    </HeaderContainer>
  );
};

const ContactDetails = ({ contact, onSave }) => {
  const communication = () => {
    if (contact?.optResult === true) {
      return "Yes";
    }
    if (contact?.optResult === false) {
      return "No";
    }
    return "Unkown";
  };
  return (
    <>
      <DetailsTitle>Contact Details</DetailsTitle>
      <DetailItem
        label="Name"
        editable
        displayValue={getFullName(contact)}
        value={[contact?.firstName, contact?.lastName]}
        fieldType={DetailitemFieldTypeEnum.fullName}
        fieldName="fullName"
        onSave={(newFullName: { fullName: string[] }) =>
          onSave({
            firstName: newFullName?.fullName?.[0],
            lastName: newFullName?.fullName?.[1],
          })
        }
      />
      <DetailItem
        label="Email"
        editable
        value={contact?.email}
        fieldName="email"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Job title"
        editable
        value={contact?.title}
        fieldName="title"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Tags"
        editable
        value={contact?.tagIds}
        fieldName="tagIds"
        fieldType={DetailitemFieldTypeEnum.tags}
        onSave={onSave}
        displayValue={
          contact.tags && contact.tags.length > 0
            ? contact.tags.map(({ title }) => <Tag key={title}>{title}</Tag>)
            : undefined
        }
      />

      <DetailItem
        label="Dept."
        editable
        value={contact?.department}
        fieldName="department"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Work Phone"
        editable
        value={contact?.workPhone}
        fieldName="workPhone"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Home Phone"
        editable
        value={contact?.homePhone}
        fieldName="homePhone"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Cell Phone"
        editable
        value={contact?.cellPhone}
        fieldName="cellPhone"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Open to Communication"
        editable={false}
        value={communication()}
        fieldName="optResult"
      />
      <DetailItem
        label="Website"
        editable
        value={contact?.website}
        fieldName="website"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Linkedin"
        editable
        value={contact?.linkedin}
        fieldName="linkedin"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Instagram"
        editable
        value={contact?.instagram}
        fieldName="instagram"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Facebook"
        editable
        value={contact?.facebook}
        fieldName="facebook"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
      <DetailItem
        label="Twitter"
        editable
        value={contact?.twitter}
        fieldName="twitter"
        fieldType={DetailitemFieldTypeEnum.text}
        onSave={onSave}
      />
    </>
  );
};

export default function AppDetailContacts({ currentUser }) {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const [saving, setSaving] = useState(false);
  const [showEntityForm, setShowEntityForm] = useState<
    ShowEntityFormString | false
  >(false);
  const [saveContact] = useSaveContactMutation();
  const { onSaveBusiness } = useSaveBusiness();
  const [saveProject] = useSaveProject();
  const [addEntityToBusiness] = useAddEntityToBusinessMutation();
  const [addEntityToProject] = useAddEntityToProjectMutation();
  const [removeEntityFromProject] = useRemoveEntityFromProjectMutation();
  const [removeEntityFromBusiness] = useRemoveEntityFromBusinessMutation();
  const { data, loading, error, networkStatus } = useContactByIdQuery({
    variables: {
      id: params?.id || "",
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
    notifyOnNetworkStatusChange: true,
    skip: !params?.id,
  });

  // set the tab in the URL if one does not exist
  useEffect(() => {
    const { tab } = queryString.parse(location.search);
    if (!tab) {
      navigate(`/app/contacts/${params?.id}?tab=1`);
    }
  }, [params?.id, navigate, location]);
  // grab the tab from the URL
  const { tab } = queryString.parse(location.search);
  // change the tab in the URL on tab change
  const setTab = (tab) => {
    navigate(`/app/contacts/${params?.id}?tab=${tab}`);
  };

  // if we get an error, show that
  if (error)
    return (
      <EmptyState
        title="We could not find this record..."
        subtitle="If you think that you reached this by mistake, please fill in a support request."
      />
    );

  if (loading && networkStatus !== 4) return <Loading />;

  let contact = data?.contactById;

  if (!contact) {
    return (
      <div style={{ marginTop: 32, marginLeft: 32 }}>
        We could not find a contact with this ID...{" "}
        <Link to={`/app/contacts`}>go back to contacts</Link>
      </div>
    );
  }

  const onSave = async (params) => {
    try {
      setSaving(true);

      await saveContact({
        variables: {
          id: contact?.id,
          params,
        },
        optimisticResponse: {
          __typename: "Mutation",
          saveContact: {
            id: params?.id,
            __typename: "Contact",
            ...data?.contactById,
            ...params,
            avatar: {
              ...data?.contactById?.avatar,
              ...params.avatar,
            },
          },
        },
      });
      setSaving(false);
      // todo set editing
      message.success("Contact saved!");
    } catch (err) {
      console.log(err);
    }
  };

  const onRemove = async (removeItem, type = showEntityForm) => {
    try {
      // if the business isn't already selected, we dont want to call the mutation
      if (type === "projects") {
        let projectIds = contact?.projects?.map((i) => i?.id);
        if (!projectIds?.includes(removeItem.id)) {
          return;
        }
      }

      if (type === "businesses") {
        let businessIds = contact?.businesses?.map((i) => i?.id);
        if (!businessIds?.includes(removeItem.id)) {
          return;
        }
      }

      // set loading
      setSaving(true);
      // call mutation
      if (type === "projects" && contact?.id) {
        await removeEntityFromProject({
          variables: {
            projectId: removeItem.id,
            entityId: contact?.id,
            entityType: EntityTypeEnum.Contacts,
          },
          refetchQueries: [
            {
              query: ContactByIdDocument,
              variables: {
                id: params?.id,
              },
            },
          ],
        });
        // success popup message
        message.success("Contact removed from project");
      }

      if (type === "businesses" && contact?.id) {
        await removeEntityFromBusiness({
          variables: {
            businessId: removeItem.id,
            entityId: contact?.id,
            entityType: EntityTypeEnum.Contacts,
          },
          refetchQueries: [
            {
              query: ContactByIdDocument,
              variables: {
                id: params?.id,
              },
            },
          ],
        });
        // success popup message
        message.success("Contact removed from business");
      }

      // turn loading off
      setSaving(false);
      // close the drawer
      setShowEntityForm(false);
    } catch (err) {
      setSaving(false);
      console.log(err);
    }
  };

  // onSaveAdd is called when adding a business or other entity to a contact
  const onSaveAdd = async (saveItems) => {
    try {
      setSaving(true);

      let refetchQueries = [
        {
          query: ContactByIdDocument,
          variables: {
            id: contact?.id,
          },
        },
      ];

      if (showEntityForm === "projects") {
        // add it to each project
        let projectIds = contact?.projects?.map((i) => i?.id);
        saveItems
          .filter((i) => !projectIds?.includes(i.id))
          .forEach(async (item) => {
            contact?.id &&
              (await addEntityToProject({
                variables: {
                  projectId: item.id,
                  entityId: contact?.id,
                  entityType: EntityTypeEnum.Contacts,
                },
                refetchQueries,
              }));
          });
        message.success("Contact added to project");
      }

      if (showEntityForm === "businesses") {
        saveItems.forEach(async (saveItem) => {
          contact?.id &&
            (await addEntityToBusiness({
              variables: {
                businessId: saveItem.id,
                entityId: contact.id,
                entityType: EntityTypeEnum.Contacts,
              },
              refetchQueries,
            }));
        });
        message.success("Contact updated");
      }

      setSaving(false);
      setShowEntityForm(false);
    } catch (err) {
      setSaving(false);
      console.log(err);
    }
  };

  const onCreate = async (newEntity) => {
    try {
      setSaving(true);

      if (showEntityForm === "projects") {
        await saveProject(
          null,
          {
            ...newEntity,
            contactIds: [contact?.id],
            stage: 0,
            order: 0,
          },
          ["contactById"]
        );
        setShowEntityForm(false);
        return message.success(`Project created!`);
      }

      if (showEntityForm === "businesses") {
        await onSaveBusiness(
          {
            ...newEntity,
            contactIds: [contact?.id],
          },
          null
        );
        setShowEntityForm(false);
        return message.success(`Business created!`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div>
      <DetailHeader
        contact={contact}
        setTab={setTab}
        tab={tab}
        onSave={onSave}
        currentUser={currentUser}
      />
      <PageContainer>
        <LeftCol>
          <ContactDetails contact={contact} onSave={onSave} />
        </LeftCol>
        <MiddleContent>
          {tab === "1" && contact?.id && (
            <ActivityFeed
              parentId={contact?.id}
              parentType={NoteParentTypeEnum.Contacts}
            />
          )}
          {tab === "2" && (
            <Notes
              parentId={contact?.id || ""}
              parentType={NoteParentTypeEnum.Contacts}
              currentUser={currentUser}
              businessId={contact?.businesses?.[0]?.id}
            />
          )}
          {tab === "3" && (
            <Todos
              queryVariables={{ contactId: contact?.id }}
              contact={contact}
            />
          )}
          {tab === "4" && contact?.id && (
            <FilesAndDocs
              parentId={contact?.id}
              parentType={FilesAndDocsParentType.Contacts}
            />
          )}
          {tab === "5" && (
            <Interactions
              queryVariables={{
                contactId: contact?.id,
              }}
            />
          )}
          {tab === "6" &&
            helpers.featureIsOn({
              customer: currentUser.customer,
              feature: CustomerFeatureSettingKeyEnum.Surveys,
            }) && <SurveyResponses contactId={contact?.id} />}
        </MiddleContent>
        <RightCol>
          <ExpandableList
            items={
              contact?.businesses?.[0]
                ? contact?.businesses //[contact.businesses[0]]
                : []
            }
            title="Businesses"
            onRemove={(item) => onRemove(item, ShowEntityFormString.businesses)}
            onAdd={() => setShowEntityForm(ShowEntityFormString.businesses)}
            type={ExpandableListTypeEnum.businesses}
          />{" "}
          {helpers.featureIsOn({
            customer: currentUser.customer,
            feature: CustomerFeatureSettingKeyEnum.Projects,
          }) && (
            <ExpandableList
              items={contact?.projects?.[0] ? contact.projects : []}
              title="Projects"
              empty="Use projects to track all your opportunities."
              onAdd={() => setShowEntityForm(ShowEntityFormString.projects)}
              onRemove={(item) => onRemove(item, ShowEntityFormString.projects)}
              type={ExpandableListTypeEnum.projects}
            />
          )}
        </RightCol>
        {contact?.id && (
          <AddEntityDrawer
            // entity={showEntityForm && contact?.[showEntityForm as string]} // project, business, contact
            visible={showEntityForm ? true : false}
            type={showEntityForm ? showEntityForm : undefined}
            defaultSelected={contact?.[showEntityForm as string]}
            loading={saving}
            onSave={onSaveAdd}
            onCreate={onCreate}
            onRemove={onRemove}
            onClose={() => setShowEntityForm(false)}
            parentType={EntityFormParentTypeEnum.contact}
            parentId={contact?.id}
          />
        )}
      </PageContainer>
    </div>
  );
}
