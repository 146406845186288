// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';

export default gql`
  query caseStatuses {
    caseStatuses {
      id
      title
      description
      isFinalStep
    }
  }
`;
