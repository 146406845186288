// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import resourceFragment from 'ApolloClient/Fragments/resourceFragment';

export default gql`
  query fundingPrograms(
    $searchText: String
    $skip: Int
    $limit: Int
    $supportTypes: [String]
    $businessStages: [String]
    $diversityTypes: [String]
  ) {
    fundingPrograms(
      searchText: $searchText
      skip: $skip
      limit: $limit
      supportTypes: $supportTypes
      businessStages: $businessStages
      diversityTypes: $diversityTypes
    ) {
      count
      fundingPrograms {
        ...resourceFragment
      }
    }
  }
  ${resourceFragment}
`;
