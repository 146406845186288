import { Contact, UserProfile } from "generated/graphql";

export default function getFullName(
  user: UserProfile | Contact | any
): string | null {
  if (!user) return null;
  if (user?.firstName && user?.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  if (user.firstName && !user.lastName) {
    return user.firstName;
  }

  return null;
}
