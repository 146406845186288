import styled from "styled-components";

export default styled.div`
  position: fixed;
  padding-left: 16px;
  bottom: 0;
  right: 0;
  width: 550px;
  z-index: 1000;
  height: 65px;
  display: flex;
  align-items: center;
  background: ${(p) => p.theme.colors.neutral10};
  @media only screen and (max-width: ${({ theme }) =>
      theme.breakpoints.mediumSmall}) {
    width: 100%;
  }
`;
