const diversityTypes = [
  {
    label: 'Women-Owned',
    value: 'womenOwned',
  },
  {
    label: 'Economically Disadvantaged Women Owned SMB',
    value: 'economicallyWomenOwned',
  },
  {
    label: 'Minority-Owned',
    value: 'minorityOwned',
  },
  {
    label: 'Veteran-Owned',
    value: 'veteranOwned',
  },
  {
    label: 'HUBZone ',
    value: 'hubZone',
  },
  {
    label: 'LGBT-owned',
    value: 'lgbtOwned',
  },
  {
    label: 'Service Disabled Veteran-owned (SDVOB)',
    value: 'disabledVeteranOwned',
  },
  {
    label: 'Historically Underutilized',
    value: 'underutilizedBusiness',
  },
  {
    label: 'Worked-owned Cooperative',
    value: 'workerOwned',
  },
  {
    label: 'B-corp',
    value: 'bCorp',
  },
];

export default diversityTypes;
