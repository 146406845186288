// TOP LEVEL IMPORTS
import React from 'react';
import PropTypes from 'prop-types';
// ANTD
import InputNumber from 'antd/lib/input-number';
import 'antd/lib/input-number/style/css';

// EXPORTED COMPONENT
// ===================================
export class NumberInput extends React.Component {
  render() {
    return (
      <InputNumber
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/\$\s?|(,*)/g, '')}
        {...this.props}
        onChange={value => this.props.onChange(value)}
      />
    );
  }
}

// PROPS
// ===================================
NumberInput.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
};

// Specifies the default values for props:
NumberInput.defaultProps = {
  size: 'large',
  min: 0,
  onChange: () => {},
  style: {
    width: '100%',
    // minWidth: '120px',
    maxWith: '100%',
    height: 50,
  },
};

// EXPORT
// ===================================
export default NumberInput;
