import {
  MutationSaveSiteArgs,
  SiteInput,
  useSaveSiteMutation,
} from "generated/graphql";
import { omit } from "lodash";

const getCleanParams = (params): SiteInput => {
  const clean = {
    ...omit(params, ["tags", "contacts", "businesses"]),
    description: params?.description || params?.description,
    // title: params?.title,
    // description: params?.description,
    // image: params?.image,
    // lat: params?.lat,
    // image?: Maybe<AttachmentParams>;
    // lat?: Maybe<Scalars["String"]>;
    // lng?: Maybe<Scalars["String"]>;
    // placeId?: Maybe<Scalars["String"]>;
    // fullAddress?: Maybe<Scalars["String"]>;
    // street?: Maybe<Scalars["String"]>;
    // postal?: Maybe<Scalars["String"]>;
    // country?: Maybe<Scalars["String"]>;
    // state?: Maybe<Scalars["String"]>;
    // city?: Maybe<Scalars["String"]>;
    // /** What type of site is this... land, building, etc */
    // type?: Maybe<SiteTypeEnum>;
    // /** The id of the person to contact about the site */
    // contactId?: Maybe<Scalars["ID"]>;
    // /** How many sqft or acres */
    // size?: Maybe<Scalars["Int"]>;
    // attachments?: Maybe<Array<Maybe<AttachmentParams>>>;
    // extraFields?: Maybe<Array<Maybe<SiteExtraFieldInput>>>;
  };
  return clean;
};

export default function useSaveSite() {
  const [saveSiteMutation, { loading }] = useSaveSiteMutation();

  const saveSite = async (args: any, id?: string | undefined | null) => {
    try {
      const cleanParams = getCleanParams(args);

      let variables: MutationSaveSiteArgs = {
        params: cleanParams,
      };

      if (id) {
        variables.id = id;
      }
      await saveSiteMutation({
        variables,
        refetchQueries: ["sites", "siteById"],
      });
    } catch (err) {
      console.log(err);
    }
  };

  return [saveSite, { loading }] as const;
}
