import React from "react";
import { PageHeader, SubHeader, Text } from "../index";
import constants from "lib/constants";

export default function PrivacyPolicy() {
  return (
    <div>
      <PageHeader>Privacy Policy</PageHeader>
      <Text style={{ textAlign: "right" }}>Last Updated: 03/05/2021</Text>
      <SubHeader>INTRODUCTION</SubHeader>
      <Text>
        We at GrowLab (Growlab, LLC) are committed to protecting your privacy.
        This Privacy Policy applies to your use of the GrowLab Marketing Website
        and GrowLab Subscription Services as a customer of GrowLab (Foundry41,
        LLC.). This Privacy Policy describes how we collect, receive, use,
        store, share, transfer, and process your Personal Data. It also
        describes your choices regarding use, as well as your rights of access
        and correction of your Personal Data. This Privacy Policy also describes
        how we process Customer Data on behalf of our customers in connection
        with the GrowLab Subscription Services.
      </Text>
      <Text>
        At the time of registration, We ask You to provide Us with a certain
        amount of personal information. Submission of such personal information
        is voluntary, but without it You may not be able to use our Site. By
        providing Your personal information, You consent to do so for the
        purpose of placing orders through Our Website. Unless specified
        otherwise herein the definitions included in Our Terms of Service shall
        expressly apply to this Privacy Policy (“Policy”) presented below.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>What Personal Information We Collect Includes:</SubHeader>
      <Text>
        We collect various personal information about users of our Services. Our
        collection may include such information as Your full name, address,
        business address, telephone number, email address, or other related
        information. You are solely responsible for ensuring the accuracy of all
        Personal Information that You submit to us. Inaccurate information may
        affect Your experience when using the Services and/or our ability to
        contact You as described in this Policy.
      </Text>
      <Text>
        “Non-personally identifiable information" means information where We
        cannot determine the identity of a natural person. Non-personally
        identifiable information is not treated as Personal Information unless
        We combine it with or link it to Personal Information that You give to
        us. If You do not wish to disclose any required information, then Your
        sole remedies shall be to close this Website You may not become our
        customer and You are required to delete Your account and/or exit the
        Website.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>How We Collect Your Information:</SubHeader>
      <Text>
        We collect information that You provide to Us when You register or use
        Our Services. When You register Your User account on this Website or
        mobile application, You will be assigned a unique account identifier
        which will be associated with any information You give to Us under Your
        Account. You may also give Us information to allow Us to contact You or
        use certain features available through our Services without signing up
        for an Account, such as when You provide feedback, email us, fill out a
        request form. Even if You do not provide any information to Us, We
        automatically collect certain information about Your use and interaction
        with Our Website and/or Services.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>Automatic Information Collection:</SubHeader>
      <Text>
        Each time You visit the Site, We may automatically collect certain types
        of information. may use “cookies” of different types to recognize Your
        computer. A cookie is a small text file that a Website, online
        application, or e-mail may save to Your internet browser and/or Your
        computer’s hard drive for use in subsequent visits to the Sites or other
        sites.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>How do We use Your personal data:</SubHeader>
      <Text>We may use personal information data in-house in order:</Text>
      <ul>
        <li>
          <Text>
            To provide and operate the Services of facilitating Your order;
          </Text>
        </li>
        <li>
          <Text>To develop, customize, expand, and improve Our Site;</Text>
        </li>
        <li>
          <Text>
            To provide You with customer assistance and technical support;
          </Text>
        </li>
        <li>
          <Text>
            To be able to contact You with general or personalized notices and
            promotional messages;
          </Text>
        </li>
        <li>
          <Text>
            To enhance Our data security and fraud prevention capabilities; and
          </Text>
        </li>
        <li>
          <Text>To comply with any applicable laws and regulations.</Text>
        </li>
      </ul>
      {/* 
      
      
       */}
      <SubHeader>
        To Which Extent and to Whom We Disclose Your Personal Data:
      </SubHeader>
      <Text>
        We use personal information collected via Our Website for a variety of
        business purposes described below. We process Your personal information
        for these purposes in reliance on Our legitimate business interests, in
        order to enter into or perform a contract with You, with Your consent,
        and/or for compliance with Our legal obligations. We indicate the
        specific processing grounds We rely on next to each purpose listed
        below.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>We Use the Information We Collect or Receive:</SubHeader>
      <ul>
        <li>
          <Text>To facilitate account creation and login process.</Text>
        </li>
        <li>
          <Text>
            We use the information You allowed Us to collect to facilitate
            account creation and login process for the performance of the
            contract;
          </Text>
        </li>
        <li>
          <Text>To send administrative information to You.</Text>
        </li>
        <li>
          <Text>
            We may use Your personal information to send You product(s), and
            other related information and/or information about changes to Our
            terms, conditions, and policies.
          </Text>
        </li>
        <li>
          <Text>Fulfill and manage Your orders.</Text>
        </li>
        <li>
          <Text>
            We may use Your information to fulfill and manage Your orders,
            payments, made through Our Website;
          </Text>
        </li>
        <li>
          <Text>
            To enforce Our terms, conditions and policies for Business Purposes,
            Legal Reasons and Contractual;
          </Text>
        </li>
        <li>
          <Text>To manage User accounts.</Text>
        </li>
        <li>
          <Text>
            We may use Your information for the purposes of managing Our account
            and keeping it in working order;
          </Text>
        </li>
        <li>
          <Text>To respond to legal requests and prevent harm.</Text>
        </li>
        <li>
          <Text>
            If We receive a subpoena or other legal request, We may need to
            inspect the data We hold to determine how to respond. We may
            disclose aggregate information about Users for such legal purposes;
          </Text>
        </li>
      </ul>
      {/* 
      
      
       */}
      <SubHeader>For other Business Purposes.</SubHeader>
      <Text>
        We may use Your information for other Business Purposes, such as data
        analysis, identifying usage trends, determining the effectiveness of Our
        promotional campaigns, to evaluate and improve Our Services, services,
        marketing and Your experience. We will not use identifiable personal
        information without Your consent.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>Additional Disclosure Policy:</SubHeader>
      <Text>
        We may share Your information with third parties as necessary to provide
        Services to You or to support the technical operation and/or maintenance
        of our Services, including third party applications that You may engage
        within the Services. We may also share information with our affiliates,
        agents, outside vendors or service providers to perform functions on our
        behalf. When a third party acts solely on our behalf and otherwise as
        applicable, We use reasonable commercial efforts to require that party
        to follow the privacy practices stated in this Policy or have
        complementary privacy protections to protect Your information. We do not
        share Your information with unaffiliated third parties except as
        permitted to do so in this Policy.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>What Are Your Privacy Rights:</SubHeader>
      <Text>
        In some regions (like the European Economic Area), You have certain
        rights under applicable data protection laws. These may include the
        right (i) to request access and obtain a copy of Your personal
        information, (ii) to request rectification or erasure; (iii) to restrict
        the processing of Your personal information; and (iv) if applicable, to
        data portability. In certain circumstances, You may also have the right
        to object to the processing of Your personal information. To make such a
        request, please use the contact details provided herein. We will
        consider and act upon any request in accordance with applicable data
        protection laws.
      </Text>
      <Text>
        If We are relying on Your consent to process Your personal information,
        You have the right to withdraw Your consent at any time. Please note,
        however that this will not affect the lawfulness of the processing
        before its withdrawal.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>Will Your Information Be Shared With Anyone:</SubHeader>
      <Text>
        When You create an account with Us You give Your consent that We may
        process or share data based on the following legal basis:
      </Text>
      <ul>
        <li>
          <Text>
            <strong>Legitimate Interests:</strong> We may process Your data when
            it is reasonably necessary to achieve Our legitimate business
            interests.
          </Text>
        </li>
        <li>
          <Text>
            <strong>Legal Obligations:</strong> if We reasonably suspect that
            Your Account has been used for an unauthorized, illegal, or criminal
            purpose, You give Us express authorization to share information
            about You, Your Account, and any of Your transactions with law
            enforcement. We may disclose Your information where We are legally
            required to do so in order to comply with applicable law,
            governmental requests, a judicial proceeding, court order, or legal
            process, such as in response to a court order or a subpoena
            (including in response to public authorities to meet national
            security or law enforcement requirements).
          </Text>
        </li>
        <li>
          <Text>
            <strong>Vital Interests:</strong> Vital Interests: We may disclose
            Your information where We believe it is necessary to investigate,
            prevent, or take action regarding potential violations of Our
            policies, suspected fraud, situations involving potential threats to
            the safety of any person and illegal activities, or as evidence in
            litigation in which We are involved.
          </Text>
        </li>
      </ul>
      {/* 
      
      
       */}
      <SubHeader>Third-party Services:</SubHeader>
      <Text>Payment Processing Third-party Services</Text>
      <Text>
        We use third party payment processing platforms. Such platforms will
        request You to provide sensitive personal data (e.g. credit card number,
        account username and password) in order to complete purchases and
        operations related to our services. Such third party payment processing
        platforms are governed by their own safety standards, policies and terms
        of use. We shall not be liable for their operation, safety or otherwise
        their functioning. We strongly advise our users to engage in online
        safety measures in order to protect their financial information, here in
        theWebsite and elsewhere. We will not collect or store any sensitive
        financial data of yours.
      </Text>
      <Text>With Service Providers</Text>
      <Text>
        We employ other third-party service providers to provide Services on our
        behalf to visitors of Our Website, Our Customers, and Users of the
        Services and may need to share such information with them to provide
        information, products or Services to Customers. Examples may include
        removing repetitive information from prospect lists, analyzing data or
        performing statistical analysis, providing marketing assistance,
        processing credit card payments, supplementing the information you
        provide us in order to provide you with better service, and providing
        customer service or support. These service providers are prohibited from
        using Customers’ Personal Data except for these purposes, and they are
        required to maintain the confidentiality of Customers’ information. In
        all cases where We share Customer’ data with such agents, we explicitly
        require the agent to acknowledge and adhere to Our Privacy Policy.
        Examples of these third-party service providers can be found below:
      </Text>
      <table style={{ width: "70%", marginBottom: 32, marginTop: 32 }}>
        <tr>
          <th>Vendor</th>
          <th>Purpose</th>
          {/* 
          <th>Twilio, Inc.</th>
          <th>Sentry</th>
          <th>Fullstory</th>
          <th>MongoDB, Inc.</th> */}
        </tr>
        <tr>
          <td>Amazon Web Services, Inc.</td>
          <td>Hosting & Infrastructure</td>
        </tr>
        <tr>
          <td>Google, Inc.</td>
          <td>Google places information</td>
        </tr>
        <tr>
          <td>Netlify</td>
          <td>Content delivery network</td>
        </tr>
        <tr>
          <td>Mailgun</td>
          <td>Email sending infrastructure</td>
        </tr>
        <tr>
          <td>Twilio, Inc.</td>
          <td>SMS alerts</td>
        </tr>
        <tr>
          <td>Sentry</td>
          <td>Error Logging</td>
        </tr>
        <tr>
          <td>Fullstory</td>
          <td>Digital Experience Logging</td>
        </tr>
        <tr>
          <td>MongoDB, Inc.</td>
          <td>Database hosting services</td>
        </tr>
      </table>
      {/* 
      
      
       */}
      <SubHeader>For How Long Do We Keep Your Information:</SubHeader>
      <Text>
        We keep Your information for 5 (five) years or as long as it is
        necessary to fulfill the purposes outlined in this privacy policy unless
        otherwise required by law. We will only keep Your personal information
        for 5 (five) years or for as long as it is necessary for the purposes
        set out in this privacy policy, unless a longer retention period is
        required or permitted by law (such as tax, accounting or other legal
        requirements).
      </Text>
      <Text>
        When We have no ongoing legitimate business need to process Your
        personal information, We will either delete or anonymize it, or, if this
        is not possible then We will securely store Your personal information
        and isolate it from any further processing until deletion is possible.
      </Text>
      <Text>
        If You claim Your personal information to be deleted and Your account
        with Our Services (facilitating Your order) to be cancelled, We will
        cancel Your account and information associated with it to exercise Your
        right “to be forgotten”.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>Where Do We store Your Data:</SubHeader>
      <Text>
        We keep Your information for 5 (five) years or as long as it is
        necessary to fulfill the purposes outlined in this privacy policy unless
        otherwise required by law.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>Where Do We store Your Data:</SubHeader>
      <Text>
        We keep Your information for 5 (five) years or as long as it is
        necessary to fulfill the purposes outlined in this privacy policy unless
        otherwise required by law.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>How We Protect Information:</SubHeader>
      <Text>
        Our Website uses industry standard protocols and technology to protect
        Your information and personal data submitted to Us in order to guard and
        encrypt data for transmission in a format that prevents data theft by
        unauthorized third parties, including internal reviews of our data
        collection, storage and processing practices, security measures, and
        physical security measures. But We also urge You to take additional
        steps on Your own to safeguard and maintain the integrity of Your
        information. For example, You should never share Your Account or login
        information with other people and be sure to sign off when finished
        using a shared or public computer. It is Your responsibility to check
        beforehand on the privacy and/or security policy of Your network prior
        to accessing the Services. We are not responsible for Your handling,
        sharing, re-sharing and/or distribution of Your information except as
        set forth in the Policy.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>Opting Out of Email or Social Media Marketing:</SubHeader>
      <Text>
        You can unsubscribe from Our marketing email list at any time by
        contacting Us via Our Contact Center by email {constants.supportEmail}.
        You will then be removed from the marketing email list – however, We
        will still need to send You service-related emails that are necessary
        for the administration and use of Your account.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>Information From Minors Regulation:</SubHeader>
      <Text>
        Our Website is not directed to children. Access to and use of Our
        Website is only for those over the age of 16.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>California Residents Privacy Rights:</SubHeader>
      <Text>
        If You are located in California this provision applies to You.
        California Civil Code Section 1798.83 permits Our Customers who are
        California residents to request and obtain from us, once a year and free
        of charge, information about categories of personal information (if any)
        We disclosed to third parties for direct marketing purposes and the
        names and addresses of all third parties with which We shared personal
        information in the immediately preceding calendar year. If You are a
        California resident and would like to make such a request, please submit
        Your request in writing to Us using the contact information provided
        below.
      </Text>
      <Text>
        If You are under 18 years of age, reside in California, and have a
        registered account with Our Website, You have the right to request
        removal of unwanted data that You publicly post on the Site. To request
        removal of such data, please contact Us using the contact information
        provided below, and include the email address associated with Your
        account and a statement that You reside in California. We will make sure
        the data is not publicly displayed on the Site, but please be aware that
        the data may not be completely or comprehensively removed from Our
        systems.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>California Privacy Disclosure:</SubHeader>
      <Text>
        Under the California Consumer Privacy Protection Act (CCPA), We are
        required to provide You with information about the information We may
        collect, the purpose for which We collect such information, the sources
        of that information, and the categories of third parties with whom We
        share that information. Please see the “What Personal Information We
        Collect Includes” section above.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>DMCA Rights:</SubHeader>
      <Text>
        As required by law We will respond to all claims of intellectual
        property infringement. We will promptly investigate notices of alleged
        infringement and will take appropriate actions required under the
        Digital Millennium Copyright Act, Title 17, United States Code, Section
        512(c)(2) ("DMCA") and other applicable intellectual property laws.
        Pursuant to the DMCA, notifications of claimed copyright infringement
        should be sent to Our Designated Agent. Notification must be submitted
        to the following Designated Agent: ______________________ Address:
        Phone:
      </Text>
      <Text>Notice to Residents of the State of Nevada: </Text>{" "}
      <Text>
        If You are a resident of Nevada, You have the right to opt-out of the
        sale of certain personal information to third parties who intend to
        license or sell that personal information. You can exercise this right
        by contacting Us at {constants.supportEmail} and providing Us with Your
        name and the email address associated with Your account. Please kindly
        note that We do not currently sell Your personal information as sales
        are defined in Nevada Revised Statutes Chapter 603A.
      </Text>
      {/* 
      
      
       */}
      <SubHeader>Contacting Us:</SubHeader>
      <Text>
        For questions or clarifications regarding Our Privacy or to request data
        deletion, please contact Us using the following email address:
        {constants.supportEmail}
      </Text>
    </div>
  );
}
