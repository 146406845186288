import PageContainer from "components/common/PageContainer";
import { useTagSearchQuery, useTagsQuery } from "generated/graphql";
import useUrlChange from "hooks/useUrlChange";
import PageHeader from "components/text/PageHeader";
import Text from "components/text/Text";
import Tag from "components/common/Tag";
import Tabs from "components/common/Tabs";
import BackButton from "components/common/BackButton";
import { useNavigate } from "react-router-dom";
import Loading from "components/common/Loading";
import { isNil } from "lodash";
import EntityCard from "../../components/common/EntityCard";
import Row from "components/common/Row";
import Col from "components/common/Col";

const { TabPane } = Tabs;

const getCount = (count): string | number => {
  if (isNil(count)) return "...";
  if (count === 0) return 0;
  return count;
};

export enum CardTypeEnum {
  "Business" = "Business",
  "Contact" = "Contact",
  "Case" = "Case",
  "Project" = "Project",
  "Site" = "Site",
  "Resource" = "Resource",
  "Todo" = "Todo",
  "Organization" = "Organization",
}

const COLS = [
  {
    type: CardTypeEnum.Business,
    key: "businesses",
    label: "Businesses",
  },
  {
    type: CardTypeEnum.Contact,
    key: "contacts",
    label: "Contacts",
  },
  {
    type: CardTypeEnum.Case,
    key: "cases",
    label: "Cases",
  },
  {
    type: CardTypeEnum.Project,
    key: "projects",
    label: "Projects",
  },
  {
    type: CardTypeEnum.Site,
    key: "sites",
    label: "Sites",
  },
  {
    type: CardTypeEnum.Todo,
    key: "todos",
    label: "Todos",
  },
  {
    type: CardTypeEnum.Resource,
    key: "resources",
    label: "Resources",
  },
  {
    type: CardTypeEnum.Organization,
    key: "organizations",
    label: "Organizations",
  },
];

export default function AppSearch() {
  const { query }: any = useUrlChange();
  const navigate = useNavigate();
  const { data, loading } = useTagSearchQuery({
    variables: {
      tagIds: query?.tagId ? [query?.tagId] : [],
    },
    fetchPolicy: "network-only",
    nextFetchPolicy: "cache-only",
  });
  const { data: tagsData } = useTagsQuery({});
  const tag = tagsData?.tags?.tags?.find((item) => item.id === query?.tagId);

  const results = data?.tagSearch;
  return (
    <PageContainer>
      <div style={{ marginBottom: 32, display: "flex", alignItems: "center" }}>
        <BackButton
          onClick={() => navigate(`/app/settings?sidenav=tags`)}
          text="Tags"
        />
      </div>

      <PageHeader>Tag Search Results</PageHeader>
      <Text style={{ marginTop: 8 }}>
        Showing results for: {tag && <Tag>{tag?.title}</Tag>}{" "}
      </Text>
      {loading && <Loading />}
      {!loading && (
        <>
          <Tabs defaultActiveKey={COLS?.[0]?.key}>
            {COLS?.map((col) => {
              return (
                <TabPane
                  tab={`${col.label} (${getCount(results?.[col.key]?.length)})`}
                  key={col.key}
                >
                  <Row gutter={16} justify="start" align="top">
                    {results?.[col.key]?.map((item) => {
                      return (
                        <Col xs={24} sm={8}>
                          <EntityCard
                            key={item.id}
                            type={col.type}
                            item={item}
                          />
                        </Col>
                      );
                    })}
                  </Row>
                </TabPane>
              );
            })}

            {/* 
             
            <TabPane
              tab={`Organizations (${getCount(
                data?.search?.organizations?.length
              )})`}
              key="4"
            ></TabPane>
             
             
            <TabPane
              tab={`Resources (${getCount(data?.search?.resources?.length)})`}
              key="7"
            ></TabPane>
            */}
          </Tabs>
        </>
      )}
    </PageContainer>
  );
}
