import styled from "styled-components";
import moment from "moment";

// COMPONENTS

import cleanDate from "lib/helpers/GeneralHelpers/cleanDate";
import EmptyState from "components/common/EmptyState";

import {
  SurveyTemplateAnswers,
  useGetManyAnswersQuery,
} from "generated/graphql";
import Loading from "components/common/Loading";

const Container = styled.div`
  padding-top: 24px;
  width: 600px;
  margin: auto;
  margin-top: 24px;
  max-width: 100%;
`;

const SurveyResponseContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  border: 1px solid ${(p) => p.theme.colors.neutral9};
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
  background: #fff;
  padding: 8px;
  border-radius: 5px;
`;

const ActionBtn = styled.button`
  border: 0px;
  color: ${(p) => p.theme.colors.neutral4};
  font-weight: 500;
  background: transparent;
  padding: 0px;
  text-align: center;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.neutral3};
  }
`;

const SurveyResponse = ({
  response,
  onView,
}: {
  onView: any;
  response: SurveyTemplateAnswers;
}) => {
  return (
    <SurveyResponseContainer>
      <div style={{ width: "75%" }}>
        <strong>{response.template?.title}</strong>
        <div>
          {moment(cleanDate(response.createdAt)).format("MMM D, YYYY")}{" "}
          {moment(cleanDate(response.createdAt)).format("h:mma")}
        </div>
      </div>
      <div style={{ marginRight: 16, textAlign: "right", width: "25%" }}>
        <ActionBtn onClick={onView}>View Result</ActionBtn>
      </div>
      {/* <div style={{ marginRight: 16 }}>
        <ActionBtn onClick={() => console.log("edit")}>Edit</ActionBtn>
      </div>
      <div style={{ marginRight: 16 }}>
        <ActionBtn onClick={() => console.log("delete")}>Delete</ActionBtn>
      </div> */}
    </SurveyResponseContainer>
  );
};

export default function SurveyResponses({
  businessId,
  contactId,
}: {
  businessId?: string;
  contactId?: string;
}) {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const { data, loading } = useGetManyAnswersQuery({
    variables: {
      businessId,
      contactId,
    },
    skip: !businessId && !contactId,
  });
  if (loading) return <Loading />;
  const blocks = data?.getManyAnswers;

  if (!blocks?.[0]) {
    return (
      <EmptyState
        title="No surveys results"
        subtitle="We have no received any survey results yet..."
      />
    );
  }

  return (
    <Container>
      {blocks?.map((block) => {
        return (
          <SurveyResponse
            key={block?.id}
            response={block}
            onView={() =>
              openInNewTab(
                `/app/survey/view/result?surveyResponseId=${block.id}`
              )
            }
          />
        );
      })}
    </Container>
  );
}
