// created constants so we make sure we use the same value throughout the app

const FORM_TYPES = {
  TODOS: "todos", // create a todo
  NOTES: "notes", // create a note
  BUSINESSES: "businesses", // create a project
  EMAIL: "email", // log an email
  PROJECTS: "projects", // create a project
  PHONE: "phone", // log a phone interaction
  CONTACTS: "contacts",
  VISIT: "visit",
  COMPANYINVESTMENTS: "companyInvestments",
  CASES: "cases",
  ORGANIZATIONS: "organizations",
  FUNDING: "fundingPrograms",
  SUPPORT: "supportPrograms",
  SITE: "site",
};

export default FORM_TYPES;
