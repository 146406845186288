import styled from "styled-components";
// components

import Avatar from "components/common/Avatar";
import BusinessLogo from "components/common/BusinessLogo";
import { UserOutlined } from "@ant-design/icons";
import {
  TodoText,
  getDueIn,
  Tag,
  ProjectName,
  getPriorityLabel,
} from "components/common/TodoItem";
import AntdTag from "components/common/Tag";
// other
import attachmentsSVG from "./attachments.svg";
import Truncate from "react-truncate";
import {
  EntityIcon,
  EntityTypeEnum,
} from "components/layout/AuthenticatedLayout/Header/AddButton/CreateNewWindow";
import {
  Case,
  CaseFragmentFragment,
  TodoFragmentFragment,
  WorkItem,
} from "generated/graphql";
import theme from "lib/theme";
import Tooltip from "components/common/Tooltip";

const AttachmentNumber = styled.span`
  color: ${({ theme }) => theme.colors.neutral6};
  font-size: 16px;
`;

const CenterCol = styled.div`
  display: flex;
  justify-content: center;
`;

const KeyName = styled.span`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.neutral6};
`;

const RelationshipBlock = styled.div`
  padding: 4px;
`;

const ImageContainer = styled.div`
  width: 34px;
  margin-right: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ColumnValue = ({
  label,
  img,
  width = 250,
}: {
  label?: any;
  img?: any;
  width?: number;
}) => {
  return (
    <RelationshipBlock>
      <Tooltip title={label}>
        <ProjectName>
          <ImageContainer>{img}</ImageContainer>
          <div style={{ width }}>
            <Truncate lines={1} ellipsis={<span>...</span>}>
              {label}
            </Truncate>{" "}
          </div>
        </ProjectName>{" "}
      </Tooltip>
    </RelationshipBlock>
  );
};

export const COLUMNS = [
  {
    key: "id",
    title: "Title",
    fixed: "left",
    width: 250,
    render: ({ title, key }) => (
      <>
        <TodoText data-testid={`todo-column-${title}`}>{title}</TodoText>
        {key && <KeyName>{key}</KeyName>}
      </>
    ),
  },
  {
    title: "Assigned",
    width: 200,
    render: ({ assignedTo }) => (
      <div>
        {assignedTo && (
          <ProjectName>
            <ImageContainer>
              <Avatar
                src={assignedTo.avatar && assignedTo.avatar.url}
                icon={<UserOutlined />}
              />
            </ImageContainer>
            {assignedTo?.firstName} {assignedTo?.lastName}
          </ProjectName>
        )}
      </div>
    ),
  },
  {
    title: "Status",
    width: 100,
    render: (item: WorkItem) => {
      if ("done" in item) {
        return (
          <AntdTag
            color={() => {
              if (item?.done) return "success";
              return "default";
            }}
          >
            {item?.done ? "Done" : "Todo"}
          </AntdTag>
        );
      }
      if ("caseStatus" in item) {
        return (
          <AntdTag
            color={() => {
              if (
                typeof item?.caseStatus?.order === "number" &&
                item?.caseStatus?.order > 0
              ) {
                return theme.colors?.blue8;
              }

              if (item?.caseStatus?.isFinalStep) return theme.colors?.primary8;
              return "default";
            }}
          >
            {item?.caseStatus?.title}
          </AntdTag>
        );
      }

      // <>{status && <Tag priority={status}>{status}</Tag>}</>
    },
  },
  {
    title: "Priority",
    width: 130,
    render: ({ priority }) => (
      <>
        {priority && (
          <Tag priority={priority}>{getPriorityLabel(priority)}</Tag>
        )}
      </>
    ),
  },
  {
    title: "Due",
    width: 130,
    render: (item: Case | TodoFragmentFragment) => {
      const { dueDate } = item;
      if ("done" in item && item?.done) return null;
      if ("caseStatus" in item) {
        if (item?.caseStatus?.isFinalStep) return null;
      }

      return <>{dueDate && getDueIn(dueDate)}</>;
    },
  },
  {
    title: "",
    width: 60,
    render: ({ attachments }) => (
      <>
        {attachments.length > 0 ? (
          <>
            <AttachmentSVG src={attachmentsSVG} alt="attachments" />
            <AttachmentNumber>{attachments.length}</AttachmentNumber>
          </>
        ) : (
          <>
            <AttachmentSVG src={attachmentsSVG} alt="attachments" />{" "}
            <AttachmentNumber>0</AttachmentNumber>
          </>
        )}
      </>
    ),
  },

  {
    title: "Related To",
    width: 275,
    render: ({ project, case: caseDetail, contact, business }) => (
      <>
        {business?.title && (
          <ColumnValue
            label={business?.title}
            img={
              <BusinessLogo
                src={business?.logo?.url}
                alt={business?.logo?.filename}
                width="32"
              />
            }
          />
        )}
        {project?.title && (
          <ColumnValue
            label={project && project.title}
            img={<EntityIcon type={EntityTypeEnum.PROJECT} size={32} />}
          />
        )}
        {caseDetail?.title && (
          <ColumnValue
            label={caseDetail && caseDetail.title}
            img={<EntityIcon type={EntityTypeEnum.CASE} size={40} />}
          />
        )}
        {contact?.firstName && (
          <ColumnValue
            label={`${contact.firstName} ${contact.lastName}`}
            img={
              <Avatar
                src={contact.avatar && contact.avatar.url}
                icon={<UserOutlined />}
              />
            }
          />
        )}
      </>
    ),
  },
  // {
  //   title: "Contact",
  //   width: 120,
  //   render: ({ contact }) => (
  //     <>
  //       {contact && (
  //         <Tooltip title={`${contact.firstName} ${contact.lastName}`}>
  //           <Avatar
  //             src={contact.avatar && contact.avatar.url}
  //             icon={<UserOutlined />}
  //           />
  //         </Tooltip>
  //       )}
  //     </>
  //   ),
  // },
];

const AttachmentSVG = styled.img`
  height: 16px;
`;
