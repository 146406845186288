import Row from "components/common/Row";
import Col from "components/common/Col";
import { useQuery } from "@apollo/client";
import CUSTOMERS from "ApolloClient/Queries/customers";
import { useState } from "react";
import Papa from "papaparse";
import ButtonUploader from "components/common/ButtonUploader";
import SelectInput from "components/inputs/SelectInput";
import { useUploadContactCsvContentMutation } from "generated/graphql";
import message from "components/common/message";

function toCamelCase(inputString) {
  const words = inputString.split(" ");
  if (words.length === 0) {
    return "";
  }

  const firstWord = words[0].toLowerCase();
  const restWords = words
    .slice(1)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join("");

  return firstWord + restWords;
}

export default function AdminContactUploader() {
  const [customer, setCustomer] = useState(null);
  const { data, loading } = useQuery(CUSTOMERS);
  const [uploadContactCSVContent, { loading: CSVUploadLoading }] =
    useUploadContactCsvContentMutation();
  if (loading) return <></>;
  return (
    <div style={{ padding: 32 }}>
      <Row gutter={24} align="top">
        <Col xs={8}>Choose Client:</Col>
        <Col xs={8}>
          <SelectInput
            placeholder="Choose Customer"
            options={
              data.customers.customers.map((dir) => ({
                id: dir.id,
                valueToShow: dir.title,
                label: <>{dir.title}</>,
              })) || []
            }
            optionLabelProp="id"
            containerStyle={{
              width: "100%",
            }}
            onChange={(direction) => setCustomer(direction)}
          />
        </Col>

        <ButtonUploader
          containerStyle={{ marginTop: 32 }}
          name="site-attachment-uploader"
          label="Upload CSV"
          loading={CSVUploadLoading}
          onChange={async (e) => {
            if (!e?.target?.files?.[0]) return;

            Papa.parse(e.target.files[0], {
              header: true,
              skipEmptyLines: true,
              transformHeader: (header) => toCamelCase(header),
              complete: async function (results) {
                console.log(results);
                const res = await uploadContactCSVContent({
                  variables: {
                    customerId: customer || "",
                    params: results.data.map((result) => ({
                      firstName: filterValue(result?.firstName),
                      lastName: filterValue(result?.lastName),
                      title: filterValue(result?.title),
                      email: filterValue(result?.email?.trim()),
                      emailStatus: filterValue(result?.emailStatus),
                      seniority: filterValue(result?.seniority),
                      department: filterValue(result?.department),
                      cellPhone: filterValue(result?.firstPhone)?.replace(
                        `'`,
                        ``
                      ),
                      workPhone: filterValue(result?.corporate)?.replace(
                        `'`,
                        ``
                      ),
                      linkedin: filterValue(result?.personLinkedinUrl),
                      apolloContactId: result?.apolloContactId,
                      business: {
                        title: filterValue(result?.companyNameForEmails),
                        tradeName: filterValue(result?.company),
                        description: filterValue(result?.seoDescription),
                        phone: filterValue(result?.companyPhone)?.replace(
                          `'`,
                          ``
                        ),
                        employeeCount: filterValue(
                          parseInt(result["#Employees"])
                        ),
                        website: filterValue(result?.website),
                        linkedin: filterValue(result?.companyLinkedinUrl),
                        facebook: filterValue(result?.facebookUrl),
                        twitter: filterValue(result?.twitterUrl),
                        city: filterValue(result?.city),
                        state: filterValue(
                          result?.state
                            ?.split(" ")
                            .map((word) => word[0])
                            .join("")
                        ),
                        annualRevenue: filterValue(result?.annualRevenue),
                        numberOfRetailLocations: filterValue(
                          parseInt(result?.numberOfRetailLocations)
                        ),
                        apolloAccountId: result?.apolloAccountId,
                      },
                    })),
                  },
                });
                const result = res.data?.uploadContactCSVContent;
                if (result?.success)
                  message.success(JSON.stringify(result?.count));
                else message.error(JSON.stringify(result?.count));
              },
            });
          }}
        />
      </Row>
    </div>
  );
}

function filterValue(value) {
  if (
    value === 0 ||
    value === "" ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return null;
  } else {
    return value;
  }
}
