import { useEffect, useState } from "react";
import * as FullStory from "@fullstory/browser";
import config from "lib/config";

export default function useFullStory() {
  const [loaded, setLoaded] = useState(false);
  useEffect(() => {
    if (!loaded) {
      const isProduction = config.NODE_ENV === "production";
      if (config.FULLSTORY_ORG) {
        FullStory.init(
          {
            orgId: config.FULLSTORY_ORG,
            debug: !isProduction,
            devMode: !isProduction,
          },
          (readyCallBack) => console.log(`Started session:`, readyCallBack)
        );
        setLoaded(true);
      }
    }
  }, [loaded]);
}
