import Loading from "components/common/Loading";
import PageContainer from "components/common/PageContainer";
import { useIntegrate_ConstantContactMutation } from "generated/graphql";
import queryString from "query-string";
import { useEffect } from "react";

export default function AuthRedirect({ match, navigate, location }) {
  const { service } = match?.params;
  const { client_id, error, error_description, code, state } =
    queryString.parse(location.search);

  const [integrate_ConstantContactMutation] =
    useIntegrate_ConstantContactMutation();

  useEffect(() => {
    if (typeof code === "string") {
      integrate_ConstantContactMutation({
        variables: {
          code,
        },
        refetchQueries: ["currentUser"],
        onCompleted: () => {
          navigate("/app/settings?sidenav=integrations");
        },
      });
    }
  }, [code, integrate_ConstantContactMutation, navigate]);

  return (
    <PageContainer>
      <div style={{ textAlign: "center" }}>
        <Loading />
        Integrating third party service...
      </div>
    </PageContainer>
  );
}
