import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const columns = [
  {
    title: 'title',
    key: 'id',
    render: ({title}) => <div>{title}</div>,
  },
  {
    title: 'type',
    render: ({type}) => <div>{type}</div>,
  },
  {
    title: 'location',
    render: ({fullAddress}) => <div>{fullAddress}</div>,
  },
  {
    title: 'organization',
    render: ({organization}) => <div>{organization && organization.title}</div>,
  },
  {
    title: 'date',
    render: ({date}) =>
      date && <div>{moment(parseInt(date)).format('M/D/YY')}</div>,
  },
];

export default columns;
