import React from 'react';
import styled from 'styled-components';
import DatePicker from 'antd/lib/date-picker';
import 'antd/lib/date-picker/style/css';

const Wrapper = styled.div``;

const {RangePicker: AntdRangePicker} = DatePicker;

export default (props) => {
  return (
    <Wrapper>
      <AntdRangePicker {...props} />
    </Wrapper>
  );
};
