// TOP LEVEL IMPORTS
import React, { useState } from "react";
// COMPONENTS
import TextInput from "components/inputs/TextInput";
import Button from "components/common/Button";
import FormItem from "components/common/FormItem";

export default function ForgotPasswordForm({
  loading,
  onSucessFullyValidated,
}) {
  const [email, setEmail] = useState(undefined);
  const onSubmit = (e) => {
    e.preventDefault();
    onSucessFullyValidated(email);
  };
  return (
    <form onSubmit={onSubmit}>
      <FormItem>
        <TextInput
          placeholder="Email"
          type="email"
          onChange={(email) => setEmail(email)}
          value={email}
          data-testid="email-input"
        />
      </FormItem>
      <Button
        disabled={loading}
        loading={loading}
        onClick={onSubmit}
        fullWidth
        style={{ marginTop: 16 }}
      >
        Send reset email
      </Button>
    </form>
  );
}
