import { Card } from "../index";
import Skeleton from "components/common/Skeleton";
import { ResponsivePie } from "@nivo/pie";
// APOLLO
import { useReports_SupportProgramsByStageQuery } from "generated/graphql";

export default function SupportProgramsByStage() {
  const { data, loading } = useReports_SupportProgramsByStageQuery({
    fetchPolicy: "cache-and-network",
  });

  const title = "Support Programs By Business Stage";
  if (loading) {
    return (
      <Card title={title}>
        <Skeleton round />
      </Card>
    );
  }

  const chartData = data?.reports_SupportProgramsByStage?.map((item) => ({
    id: item?.title,
    label: item?.title,
    value: item?.total,
  }));

  return (
    <Card title={title} empty={!chartData || chartData.length === 0}>
      <ResponsivePie
        data={chartData || []}
        colors={{ scheme: "greens" }}
        margin={{ top: 32, right: 88, bottom: 24, left: 88 }}
      />
    </Card>
  );
}
