import {
  InteractionParams,
  useSaveInteractionMutation,
} from "generated/graphql";
import moment from "moment";
import removeTypename from "lib/helpers/removeTypename";

const getInteractionValues = (params: InteractionParams) => {
  const { title, description, type, date, attachments } = params;
  let shapedTodo = {
    ...params,
    title: title || null,
    description: description || null,
    type: type || null,
    date: date ? date.valueOf().toString() : moment().valueOf().toString(),
    attachments: attachments,
  };

  return removeTypename(shapedTodo);
};

export default function useSaveInteraction() {
  const [saveInteraction, { loading: savingInteraction }] =
    useSaveInteractionMutation();

  const onSubmitInteraction = async (
    params: InteractionParams,
    onComplete?: () => void,
    id?: string
  ) => {
    try {
      await saveInteraction({
        variables: {
          id,
          params: getInteractionValues(params),
        },
        refetchQueries: ["interactions"],
      });
      onComplete && onComplete();
    } catch (err) {
      console.log(err);
    }
  };

  return {
    onSubmitInteraction,
    savingInteraction,
  };
}
