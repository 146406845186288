import moment from "moment";
// COMPONENTS
import { Card } from "../index";
import Skeleton from "components/common/Skeleton";
import { ResponsiveBar } from "@nivo/bar";
// APOLLO
// import TimeRangeDropdown from "./TimeRangeDropdown";
import chartColors from "lib/constants/chartColors";
import { useReports_CasesResolvedByMonthQuery } from "generated/graphql";

export default function CasesResolvedByMonth() {
  const { data, loading } = useReports_CasesResolvedByMonthQuery({
    fetchPolicy: "cache-and-network",
  });

  if (loading || !data) {
    return (
      <Card title="Cases Resolved per Month">
        <Skeleton round />
      </Card>
    );
  }

  let chartData =
    data &&
    data?.reports_CasesResolvedByMonth?.map((item) => {
      return {
        total: item?.total,
        title: item?.title && moment(item.title, "M/YYYY").format("MMM YY"),
      };
    });

  return (
    <Card
      title="Cases Resolved per Month"
      // action={<TimeRangeDropdown />}
      empty={!chartData || chartData.length === 0}
    >
      <ResponsiveBar
        keys={["total"]}
        data={chartData || []}
        colors={chartColors}
        indexBy="title"
        margin={{ top: 24, right: 0, bottom: 50, left: 0 }}
        padding={0.3}
        theme={{
          fontSize: 12,
        }}
        axisBottom={{
          tickRotation: -45,
        }}
      />
    </Card>
  );
}
