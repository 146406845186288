import SelectInput from "components/inputs/SelectInput";
import { useGetManyConstantContactListsQuery } from "generated/graphql";
import theme from "lib/theme";
import {
  ActionContainer,
  RemoveActionBtn,
  LeftCol,
  Rightcol,
} from "../../shared";

export default function ActionAddBusinessToConstantContactList({
  onRemove,
  onChange,
}) {
  const { data, loading } = useGetManyConstantContactListsQuery();
  return (
    <ActionContainer>
      <LeftCol>
        Add Business to a Constant Contact List{" "}
        <SelectInput
          placeholder="Select a list"
          options={
            data?.getManyConstantContactLists?.[0]
              ? data?.getManyConstantContactLists?.map((list) => {
                  return {
                    id: list?.list_id,
                    label: `${list?.name || ""}`,
                  };
                })
              : []
          }
          value={undefined}
          loading={loading}
          onChange={(constantContactListId) => {
            onChange({
              constantContactListId,
            });
          }}
          containerStyle={{
            marginLeft: 6,
            marginRight: 4,
            maxWidth: "100%",
            width: 270,
            display: "inline-flex",
            height: 40,
            backgroundColor: theme.colors.neutral11,
          }}
        />
      </LeftCol>
      <Rightcol>
        <RemoveActionBtn onClick={onRemove} />
      </Rightcol>
    </ActionContainer>
  );
}
