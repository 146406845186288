import styled from "styled-components";

const LinkButton = styled.button<{ danger?: boolean }>`
  height: 23px;
  font-family: ${(p) => p.theme.fontfamily};
  font-size: 14px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.45;
  letter-spacing: normal;
  color: ${(p) => {
    if (p.danger) return p.theme.colors.red2;
    return p.theme.colors.primary2;
  }};
  background: transparent;
  border: 0px;
  padding: 0px;
  &:focus {
    outline: 0;
  }
  &:hover {
    cursor: pointer;
    color: ${(p) => {
        if (p.danger) return p.theme.colors.red1;
        return p.theme.colors.primary1;
      }}
      important!;
  }
`;

export default LinkButton;
