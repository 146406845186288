// TOP LEVEL IMPORTS
import {gql} from '@apollo/client';
import userFragment from 'ApolloClient/Fragments/userFragment';

export default gql`
  fragment commentFragment on Comment {
    id
    content
    createdAt
    updatedAt
    createdByUser {
      ...userFragment
    }
  }
  ${userFragment}
`;
