import { FundingTypeEnum } from "generated/graphql";

const fundingTypes = [
  {
    label: "Business Competition",
    value: FundingTypeEnum.Competition,
  },
  {
    label: "Economic Incentive",
    value: FundingTypeEnum.Incentive,
  },
  {
    label: "Grant",
    value: FundingTypeEnum.Grant,
  },
  {
    label: "Debt/Loan",
    value: FundingTypeEnum.Loan,
  },
  {
    label: "Equity",
    value: FundingTypeEnum.Equity,
  },
  {
    label: "Other",
    value: FundingTypeEnum.Other,
  },
];

export default fundingTypes;
