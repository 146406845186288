import React from 'react';
import styled from 'styled-components';
// COMPONENTS
import Row from 'components/common/Row';
import Col from 'components/common/Col';
import {SearchOutlined, DownOutlined} from '@ant-design/icons';
import Popover from 'components/common/Popover';

const SearchInput = styled.input`
  background: #fff !important;
  min-height: 32px !important;
  min-width: 400px;
  &:focus {
    outline: 0;
  }
`;

const SearchContainer = styled.div`
  width: 944px;
  height: 48px;
  max-width: 100%;
  border-radius: 5px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.04);
  background-color: #ffffff;
  margin-bottom: 24px;
  padding: 8px;
`;

const FilterText = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: ${(p) => p.theme.colors.neutral5};
  font-family: ${(p) => p.theme.fontFamily};
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.neutral4};
  }
`;

const TypeItemContainer = styled.div`
  margin: 16px;
  white-space: nowrap;
  color: ${(p) => p.theme.colors.neutral3};
  font-family: ${(p) => p.theme.fontFamily};
  /* min-width: 150px; */
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.colors.neutral2};
  }
`;

const ActiveDot = styled.span`
  height: 10px;
  display: inline-block;
  width: 10px;
  border-radius: 50%;
  background: ${(p) => p.theme.colors.primary4};
`;

const TypeItem = ({label, onClick, active}) => (
  <TypeItemContainer onClick={onClick}>
    <span style={{width: 15, display: 'inline-block'}}>
      {active && <ActiveDot />}
    </span>
    {label}
  </TypeItemContainer>
);

const getText = (sortBy) => {
  switch (sortBy) {
    case 'createdAt':
      return 'Newest';
    case 'priority':
      return 'Priority';
    case 'dueDate':
      return 'Due Date';
    default:
      return 'Default';
  }
};

const SearchBar = ({onChange, searchText, sortBy}) => {
  return (
    <SearchContainer>
      <Row>
        <Col xs={22}>
          <SearchOutlined style={{marginRight: 8}} />
          <SearchInput
            value={searchText}
            onChange={(e) => onChange({searchText: e.target.value})}
            placeholder="Search Todos..."
          />
        </Col>
        <Col xs={2}>
          <Popover
            placement="bottom"
            content={
              <div>
                <TypeItem
                  label={'Newest'}
                  onClick={() => onChange({sortBy: 'createdAt'})}
                  active={sortBy === 'createdAt'}
                />
                <TypeItem
                  label={'Priority'}
                  onClick={() => onChange({sortBy: 'priority'})}
                  active={sortBy === 'priority'}
                />
                <TypeItem
                  label={'Due Date'}
                  onClick={() => onChange({sortBy: 'dueDate'})}
                  active={sortBy === 'dueDate'}
                />
              </div>
            }
            trigger="click"
          >
            <FilterText>
              {getText(sortBy)} <DownOutlined style={{fontSize: 8}} />
            </FilterText>
          </Popover>
        </Col>
      </Row>
    </SearchContainer>
  );
};

export default SearchBar;
