import * as React from 'react';

function BuildingFilled(props) {
  return (
    <svg
      width={33}
      height={33}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.6 6.6a3.3 3.3 0 013.3-3.3h13.2a3.3 3.3 0 013.3 3.3v19.8a1.65 1.65 0 010 3.3h-4.95a1.65 1.65 0 01-1.65-1.65v-3.3a1.65 1.65 0 00-1.65-1.65h-3.3a1.65 1.65 0 00-1.65 1.65v3.3a1.65 1.65 0 01-1.65 1.65H6.6a1.65 1.65 0 110-3.3V6.6zm4.95 1.65h3.3v3.3h-3.3v-3.3zm3.3 6.6h-3.3v3.3h3.3v-3.3zm3.3-6.6h3.3v3.3h-3.3v-3.3zm3.3 6.6h-3.3v3.3h3.3v-3.3z"
        fill={(props && props.fill) || '#000'}
      />
    </svg>
  );
}

export default BuildingFilled;
