import { AutomationEventType } from "generated/graphql";
import ActionAddBusinessToConstantContactList from "./ActionAddBusinessToConstantContactList";
import ActionCreateACase from "./ActionCreateACase";
import ActionSendNotificationToTeamMember from "./ActionSendNotificationToTeamMember";

export default function EventEditor({ event, onRemove, onParamsChange }) {
  return (
    <div>
      {(() => {
        switch (event?.type) {
          case AutomationEventType?.AddBusinessToConstantContactList:
            return (
              <ActionAddBusinessToConstantContactList
                onRemove={onRemove}
                onChange={onParamsChange}
              />
            );
          case AutomationEventType?.NotifyTeamMember:
            return (
              <ActionSendNotificationToTeamMember
                onRemove={onRemove}
                onChange={onParamsChange}
              />
            );
          case AutomationEventType?.CreateACase:
            return (
              <ActionCreateACase
                onRemove={onRemove}
                onChange={onParamsChange}
                values={event?.params}
              />
            );

          default:
            return null;
        }
      })()}
    </div>
  );
}
