const chartColors = Object.values({
  primary1: "#014D40",
  primary3: "#147D64",
  primary5: "#27AB83",
  primary7: "#65D6AD",
  primary8: "#8EEDC7",
  primary9: "#C6F7E2",
});

export default chartColors;
