import Select from "antd/lib/select";
import businessStatuses from "lib/constants/businessStatuses";
import DropdownStyleWrapper from "../DropdownStyleWrapper";
import { BusinessStatusEnum } from "generated/graphql";

// CONSTANTS AND DESTRUCTURING
// ================================================================
const Option = Select.Option;

// EXPORTED COMPONENT
// ================================================================
const BusinessStatusInput = (props) => {
  const { onChange, value = "" } = props;
  console.log({
    businessStatuses,
  });
  return (
    <DropdownStyleWrapper>
      <Select
        {...props}
        size="large"
        onChange={onChange}
        value={value || undefined}
        optionFilterProp="children"
      >
        {Object.values(BusinessStatusEnum).map((key) => {
          return (
            <Option key={key} value={key}>
              {
                businessStatuses?.find(
                  (businessStatus) => businessStatus.value === key
                )?.label
              }
            </Option>
          );
        })}
      </Select>
    </DropdownStyleWrapper>
  );
};

BusinessStatusInput.defaultProps = {
  style: {
    width: "100%",
  },
  placeholder: "Select one",
};

// EXPORT
// ================================================================
export default BusinessStatusInput;
