import { parsePhoneNumber } from "awesome-phonenumber";

export default function formatPhoneNumber(phoneNumber) {
  const pn = parsePhoneNumber(phoneNumber);
  console.log({
    pn,
  });

  return pn?.number?.national;
}
