import message from "components/common/message";
import {
  CaseFragmentFragment,
  CaseInput,
  useSaveCaseMutation,
} from "generated/graphql";

export default function useSaveCase() {
  const [saveCase, { loading: savingCase }] = useSaveCaseMutation();

  const onSaveCase = async (
    values: CaseInput
  ): Promise<CaseFragmentFragment | undefined> => {
    try {
      const result = await saveCase({
        variables: {
          params: {
            ...values,
          },
        },
        refetchQueries: ["businessById", "cases"],
        onCompleted: (data) => {
          message.success("Case saved");
        },
      });

      return result?.data?.saveCase || undefined;
    } catch (err) {
      console.log(err);
    }
  };
  return {
    onSaveCase,
    savingCase,
  };
}
